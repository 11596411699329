import React, { Component } from 'react';
import moment from 'moment';
import './tab-booking.scss';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Dialog, Button, Input } from 'element-react';
import * as reservationApi from '../../../api/reservation.api';
import fileDialog from "file-dialog";
import { baseURL } from "../../../apiClient"
import PageTable from "../../page-table.component";
import ResultLimit from "../../result-limit.component";
import CheckInDialog from '../check-in-dialog/check-In-compoent';

@translate()
@connect((state) => {
    return {
        staff: state.staff.staff,
    };
})
export default class UserBookingTab extends Component {
    async deleteReport(reservationId) {
        await reservationApi.deleteReport(reservationId);
        await this.getReservations();
    }

    async uploadReport(reservationId) {
        const file = await fileDialog({ accept: 'application/pdf' });
        await reservationApi.uploadReport(reservationId, file[0]);
        await this.getReservations();
    }

    async shareReport(reservationId, shared) {
        await reservationApi.updateReservation(reservationId, { shared });
        await this.getReservations();
    }

    async deletePrescription(reservationId) {
        await reservationApi.deletePrescription(reservationId);
        await this.getReservations();
    }

    async uploadPrescription(reservationId) {
        const file = await fileDialog({ accept: 'text/csv' });
        await reservationApi.uploadPrescription(reservationId, file[0]);
        await this.getReservations();
    }

    async checkout(reservationId) {
        const { remark } = this.state;
        await reservationApi.checkIn(this.state.currentCheckIn.id, 'checked', '', remark);
        this.setState({
            checkInNote: "",
            remark: '',
        });
        await this.getReservations();
    }

    async checkIn() {
        await reservationApi.checkIn(this.state.currentCheckIn.id, 'check-in', this.state.checkInNote);
        this.setState({
            checkInNote: "",
            showCheckIn: false,
        });
        await this.getReservations();
    }

    async bookingPay() {
        const { remark } = this.state;
        await reservationApi.pay(this.state.currentCheckIn.id, remark);
        this.setState({
            checkInNote: "",
            remark: '',
        });
        await this.getReservations();
    }


    state = {
        row:{},
        currentCheckIn: {},
        checkInNote: "",
        showCheckIn: false,
        showConfirm: false,
        confirmTitle: null,
        confirmMsg: null,
        confirmFunction: null,
        currentPage: 1,
        orderBy: "createdAt",
        direction: "ASC",
        limit: 40,
        reservations: [],
        totalResult: 0,
        sort: null,
        loading: true,
        columns: [
            {
                label: this.props.t('tab_booking_table_column_ymd'),
                prop: "ymd",
            },
            {
                label: this.props.t('tab_booking_table_column_time'),
                prop: "time",
            },
            {
                label: this.props.t('tab_booking_table_column_center'),
                prop: "center"
            },
            {
                label: this.props.t('tab_booking_table_column_service'),
                prop: "service"
            },
            {
                label: this.props.t('tab_booking_table_column_doctor'),
                prop: "doctor"
            },
            // {
            //     label: this.props.t('tab_booking_table_column_report'),
            //     prop: "report",
            //     width: 210,
            //     render: (row, column, index) => {
            //         const { t } = this.props;
            //         const { reservations } = this.state;
            //         return <span>{reservations[index].report ?
            //             <span className="report-action">
            //                 <a href={`${baseURL}/${reservations[index].report}`} >
            //                     {this.state.reservations[index].report}
            //                 </a>
            //                 <button onClick={() =>
            //                     this.setState({
            //                         confirmFunction: () => this.deleteReport(reservations[index].id),
            //                         showConfirm: true,
            //                         confirmTitle: t('tab_booking_delete_report'),
            //                     })
            //                 }>{t('button_delete')}</button>
            //                 <button onClick={() => this.setState({
            //                     confirmFunction: () => this.shareReport(reservations[index].id, !reservations[index].shared),
            //                     showConfirm: true,
            //                     confirmTitle: t('tab_booking_share_status'),
            //                 })}>{t(reservations[index].shared ? 'tab_booking_table_button_not_share' : 'tab_booking_table_button_share')}</button>
            //             </span> :
            //             <button onClick={() => this.uploadReport(reservations[index].id)}>{t('tab_booking_table_button_upload')}</button>}
            //         </span>
            //     }
            // },
            // {
            //     label: this.props.t('tab_booking_table_column_prescription'),
            //     prop: "prescription",
            //     render: (row, column, index) => {
            //         const { t } = this.props;
            //         const { reservations } = this.state;
            //         return <span>{reservations[index].prescription ?
            //             <span className="report-action">
            //                 <button onClick={() => this.deletePrescription(this.state.reservations[index].id)}>{t('button_delete')}</button>
            //             </span> :
            //             <button onClick={() => this.uploadPrescription(this.state.reservations[index].id)}>{t('tab_booking_table_button_upload')}</button>}
            //         </span>
            //     }
            // },
            // {
            //     label: this.props.t('tab_booking_table_column_action'),
            //     prop: "action",
            //     render: (row, column, index) => {
            //         const { t } = this.props;
            //         switch (this.state.reservations[index].action) {
            //             case "waiting":
            //                 return <button onClick={() => this.setState({
            //                     currentCheckIn: this.state.reservations[index],
            //                     showCheckIn: true,
            //                 })}>{t('tab_booking_check_in')}</button>;
            //             case "check-in":
            //                 return <button onClick={() => this.setState({
            //                     currentCheckIn: this.state.reservations[index],
            //                     showConfirm: true,
            //                     confirmTitle: t('tab_booking_confirm_title_check_out'),
            //                     confirmMsg: this.state.reservations[index].paid ? null : t('tab_booking_pay_dialog_content', { amount: this.state.reservations[index].price || 0 }),
            //                     confirmFunction: () => this.checkout(),
            //                 })}>{t('tab_booking_check_out')}</button>;
            //             case "checked":
            //                 return <button onClick={() => this.setState({
            //                     currentCheckIn: this.state.reservations[index],
            //                     showCheckIn: true,
            //                 })}>{t('tab_booking_attended')}</button>;
            //             case "unchecked":
            //                 return "Absent";
            //             default:
            //                 return null;
            //         }
            //     }
            // },
            {
                label: this.props.t('tab_booking_table_column_pay'),
                prop: "paid",
                render: (row, column, index) => {
                    const {t} = this.props
                    if (this.state.reservations[index].paid) {
                        return t('tab_booking_paid');
                    } else {
                        return (
                        
                            <Button onClick={()=>{this.setState({checkInVisiable:true,row})}}>{t('tab_booking_pay')}</Button>
                        )
                    }
                    
                    // const { t } = this.props;
                    // if (this.state.reservations[index].paid) {
                    //     return t('tab_booking_paid');
                    // }
                    // else {
                    //     return <button onClick={() => this.setState({
                    //         currentCheckIn: this.state.reservations[index],
                    //         showConfirm: true,
                    //         confirmTitle: t('tab_booking_paid_title'),
                    //         confirmMsg: this.state.reservations[index].paid ? null : t('tab_booking_pay_dialog_content', { amount: this.state.reservations[index].price || 0 }),
                    //         confirmFunction: () => this.bookingPay(),
                    //     })}>{t('tab_booking_pay')}</button>;
                    // }
                }
            }
        ],
        checkInVisiable:false
    };

    async getReservations() {
        const response = await reservationApi.getUserReservations(
            this.props.user.id,
            false,
            this.state.currentPage,
            this.state.orderBy,
            this.state.direction,
            this.state.limit,
        );
        console.log(response);
        this.setState({
            reservations: response.rows.map((reservation) => {
                const data = {
                    id: reservation.id,
                    price: reservation.price,
                    note: reservation.note,
                    ymd: moment(reservation.startTime).format("YYYY-MM-DD"),
                    time: `${moment(reservation.startTime).format("hh:mmA")}-${moment(reservation.endTime).format("hh:mmA")}`,
                    center: reservation.center,
                    shared: reservation.shared,
                    service: `${reservation.category}-${reservation.service}`,
                    doctor: reservation.staff,
                    report: reservation.report,
                    action: reservation.status,
                    paid: reservation.paid,
                    prescription: reservation.prescription,
                };
                return data;
            }),
            totalResult: response.count,
            loading: false
        });
        this.props.refresh();
    }

    async componentDidMount() {
        await this.getReservations();
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.getReservations());
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.getReservations())
    }

    render() {
        const { t } = this.props;
        const { checkInVisiable,limit, columns, reservations, loading, currentPage, totalResult, showCheckIn, currentCheckIn, sort, showConfirm, confirmFunction, confirmMsg, confirmTitle, remark ,row} = this.state;
        return (
            <div className="customer-booking-tab">
                <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} />
                <PageTable
                    limit={limit}
                    currentPage={currentPage}
                    totalResult={totalResult}
                    results={reservations}
                    sort={sort}
                    columns={columns}
                    setSort={(e) => this.setSort(e)}
                    setCurrentPage={(page) => this.setCurrentPage(page)}
                    loading={loading}
                />
                <Dialog
                    title={currentCheckIn.action === "waiting" ? t('tab_booking_check_in_title') : t('tab_booking_attended_title')}
                    visible={showCheckIn}
                    onCancel={() => this.setState({ showCheckIn: false })}
                >
                    <div className="dialog">
                        <div className="dialog-body">
                            <div className="row">
                                <div className="title">
                                    {t('tab_booking_check_in_date')}
                                </div>
                                <div className="value">
                                    {currentCheckIn.ymd} {currentCheckIn.time}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('tab_booking_check_in_name')}
                                </div>
                                <div className="value">
                                    {this.props.user.surname} {this.props.user.name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('tab_booking_check_in_hkid')}
                                </div>
                                <div className="value">
                                    {this.props.user.hkid}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('tab_booking_check_in_appointment')}
                                </div>
                                <div className="value">
                                    {currentCheckIn.category}-{currentCheckIn.service}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('tab_booking_check_in_therapist')}
                                </div>
                                <div className="value">
                                    {currentCheckIn.doctor}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('tab_booking_check_in_balance')}
                                </div>
                                <div className="value">
                                    HK$ {currentCheckIn.price}
                                </div>
                            </div>
                            {
                                currentCheckIn.action !== "waiting" && currentCheckIn.note ?
                                    <div className="row">
                                        <div className="title">
                                            {t('tab_booking_check_in_note')}
                                        </div>
                                        <div className="value">
                                            {currentCheckIn.note}
                                        </div>
                                    </div> : null
                            }
                            {
                                currentCheckIn.action === "waiting" ?
                                    <span>
                                        <div className="row">
                                            <textarea onChange={(e) => this.setState({ checkInNote: e.target.value })}>
                                            </textarea>
                                        </div>
                                        <div className="row">
                                            <Button type="primary" onClick={() => this.checkIn()}>{t('tab_booking_check_in_confirm')}</Button>
                                            <Button onClick={() => this.setState({ showCheckIn: false })}>{t('tab_booking_check_in_cancel')}</Button>
                                        </div>
                                    </span> : null
                            }
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    size={'tiny'}
                    title={confirmTitle || t('confirm_dialog_title')}
                    visible={showConfirm}
                    onCancel={() => this.setState({
                        confirmMsg: null,
                        confirmTitle: null,
                        showConfirm: false,
                    })}
                >
                    <div className="dialog">
                        <div className="dialog-body">
                            {confirmMsg || null}
                            <div className="remark">
                                    <div className="title">
                                        {t('remark')}
                                    </div>
                                    <div className="value">
                                        <Input type="textarea" value={remark} onChange={(value) => this.setState({ remark: value })} />
                                    </div>
                                </div>
                            <div className="row">
                                <div className="row">
                                    <Button type="primary" onClick={() => {
                                        confirmFunction()
                                        this.setState({
                                            confirmMsg: null,
                                            confirmTitle: null,
                                            showConfirm: false,
                                        });
                                    }}>{t('confirm_dialog_button_yes')}</Button>
                                    <Button onClick={() => this.setState({
                                        confirmMsg: null,
                                        confirmTitle: null,
                                        showConfirm: false,
                                    })}>{t('confirm_dialog_button_no')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                {
                    checkInVisiable && <CheckInDialog row={row} closed={()=>this.setState({checkInVisiable:false})} success={()=>{this.setState({checkInVisiable:false},()=>{this.getReservations()})}}></CheckInDialog>
                }
            </div>
        );
    }
}
