import React, { Component } from 'react';
import './user-all.scss';
import * as userApi from "../../../../api/user.api";
import * as serviceApi from '../../../../api/service.api'
import { Input, Button, Cascader, Select } from 'element-react';
import { translate } from 'react-i18next';
import history from '../../../../history';
import PageTable from "../../../../components/page-table.component";
import ResultLimit from "../../../../components/result-limit.component";
@translate()
export default class UserAllPage extends Component {

    state = {
        results: [],
        lastTextChange: 0,
        selectedUser: undefined,
        currentPage: 1,
        orderBy: "createdAt",
        direction: "ASC",
        limit: 40,
        form: {
            reference: "",
            intentionalService: [],
            consumeClass: [],
            customerClass: ""
        },
        intentionalService: [],
        customerClass: [],
        totalResult: 0,
        sort: null,
        columns: [
            {
                label: this.props.t('user_all_table_column_id'),
                prop: "id",
                sortable: "custom",
            },
            {
                label: this.props.t('user_all_table_column_name'),
                prop: "name",
                sortable: "custom",
            },
            {
                label: this.props.t('user_all_table_column_tel'),
                prop: "tel",
            },
            {
                label: this.props.t('user_all_table_column_gender'),
                prop: "gender",
                render: (row) => {
                    return <span>
                        {this.props.t(row.gender)}
                    </span>
                }
            },
            {
                label: this.props.t('remark'),
                prop: "remark",
                sortable: "custom",
                render: (row) => {
                    return <span className='text-limit'>
                        {row.remark}
                    </span>
                }
            },
            {
                label: this.props.t('user_all_table_column_detail'),
                prop: "detail",
                render: (row) => {
                    return <span>
                        <Button onClick={() => this.switchTab("/main/user-detail/" + row.id)}>{this.props.t('button_view')}</Button>
                    </span>
                }
            },
        ],
        loading: true,
        prop: {
            value: 'value',
            label: "name"
        }
    };

    async getServiceList() {
        await serviceApi.getServiceList().then(async result => {
            let data = await this.ServiceTree(result.rows)
            this.setState({intentionalService: data});
        }).catch(error => {
            console.log(error.userMessage);
        })
    }

    async ServiceTree(datas) {
        let parents = []
        datas.forEach(item => {
            if (item.stratum == 1) {
                parents.push({
                    value: item.id,
                    name: item && item.category_details && item.category_details[0].title,
                })
            }
        })
        let childrens = datas.filter(item => item.stratum != 1)
        await this.formatService(parents, childrens)
        return parents
    }

    async formatService(parents, childrens) {
        parents.forEach(parent => {
            childrens.forEach((children, index) => {
                if (children.stratumId == parent.value) {
                    let temp = JSON.parse(JSON.stringify(childrens))
                    temp.splice(index, 1)
                    let services = children.services.map(service => {
                        return {
                            value: service.id,
                            name: service.service_details[0].name
                        }
                    })
                    let child = {
                        value: children.id,
                        name: children && children.category_details && children.category_details[0].title,
                    }
                    if (children.services.length > 0) {
                        child.children = services
                    }
                    parent.children ? parent.children.push(child) : parent.children = [child]
                    this.formatService([child], temp)
                }
            })
        })
    }

    async getVipList() {
        await userApi.getVipList().then((result) => {
            const data = result.map(item => {
                return {
                    value: item.id,
                    label: item.level
                }
            })
            this.setState({ customerClass: data })
        }).catch(error => {
            console.log(error.userMessage);
        });
    }

    onSearch = async (key, value) => {
        const initiateTime = Date.now();
        this.setState({ lastTextChange: initiateTime });
        setTimeout(async () => {
            if (this.state.lastTextChange === initiateTime) {
                await new Promise(res => this.setState({
                    form: {
                        ...this.state.form,
                        [key]: value,
                    },
                }, res));
                this.updateUserRecords();
            }
        }, 500);
    }

    switchTab = (url) => {
        history.push(url);
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.updateUserRecords());
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.updateUserRecords())
    }

    setSort(e) {
        if (e.order) {
            this.setState({
                orderBy: e.prop,
                direction: e.order === "ascending" ? "ASC" : "DESC",
            });
            this.updateUserRecords();
        }
    }

    async updateUserRecords() {
        const { currentPage, orderBy, direction, limit, form } = this.state;
        const response = await userApi.getUsers(currentPage, orderBy, direction, limit, form);
        this.setState({
            results: response.rows.map((user) => {
                const data = {
                    id: user.id,
                    surname: user.surname,
                    name: user.name,
                    tel: user.phone,
                    gender: user.gender,
                    hkid: user.hkid,
                    familyId: user.familyId,
                    balance: user.family&&user.family.balance,
                    family: user.family,
                    remark: user.remark,
                }
                return data;
            }),
            totalResult: response.count,
            loading: false
        });
    }

    async componentDidMount() {
        await this.getVipList()
        await this.getServiceList()
        await this.updateUserRecords();
    }

    render() {
        const { t } = this.props;
        const { limit, columns, currentPage, totalResult, results, sort, loading } = this.state;
        return (
            <div className="user-all-page">
                <div className="main-title">
                    {t('user_all_title')}
                </div>
                <div className="search-bar">
                    <Select
                        clearable
                        className='date-select'
                        value={this.state.form.userLevel ? this.state.form.userLevel : ""} placeholder="請選擇客戶分類"
                        onChange={(value) => this.onSearch("userLevel", value)}>
                        {
                            this.state.customerClass.map(el => {
                                return <Select.Option key={el.value} label={el.label} value={el.value} />
                            })
                        }
                    </Select>
                    <Cascader
                        className='date-select'
                        value={this.state.form.intentionalService && this.state.form.intentionalService.length > 0 ? this.state.form.intentionalService : []}
                        placeholder="請選擇意向服務"
                        options={this.state.intentionalService}
                        props={this.state.prop}
                        filterable={true}
                        clearable = {true}
                        changeOnSelect={true}
                        onChange={(value) => this.onSearch("intentionalService", value)}
                    ></Cascader>
                    <Cascader
                        clearable
                            className='date-select'
                            value={this.state.form.consumeClass && this.state.form.consumeClass.length > 0 ? this.state.form.consumeClass : []}
                            placeholder="請選擇消費分類"
                            options={this.state.intentionalService}
                            props={this.state.prop}
                            clearable = {true}
                            filterable={true}
                            changeOnSelect={true}
                            onChange={(value) => this.onSearch("consumeClass", value)}
                    ></Cascader>
                    <Input placeholder={t('user_all_search_bar_placeholder')} onChange={(text) => this.onSearch("reference",text)} />
                </div>
                <div className="page-header">
                    <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} />
                    <div className="space"></div>
                    <div className="add-user-button">
                        <Button onClick={() => this.switchTab("/main/user-detail/create")}>{t('user_all_button_add_user')}</Button>
                    </div>
                </div>
                <PageTable
                    limit={limit}
                    currentPage={currentPage}
                    totalResult={totalResult}
                    results={results}
                    sort={sort}
                    columns={columns}
                    setSort={(e) => this.setSort(e)}
                    setCurrentPage={(page) => this.setCurrentPage(page)}
                    loading={loading}
                />
            </div>
        );
    }
}