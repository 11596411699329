import client from '../apiClient';

export async function getFamily(familyId) {
    let response = await client.get(`/family/${familyId}`);
    return response.data;
}

export async function updateFamily(familyId, userId, body) {
    let response = await client.patch(`/family/${familyId}/${userId}`, body);
    return response.data;
}
