import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Message, Loading } from 'element-react';
import PageTable from "../../page-table.component";
import ResultLimit from "../../result-limit.component";
import moment from 'moment';
import * as reservationApi from "../../../api/reservation.api";




@translate()
export default class UserTransferTab extends Component {
    state = {
        userId : this.props.user.id,
        limit:40,
        page:1,
        data:[],
        loading:false,
        count:0,
        sort:null,
        columns: [
            {
                label: this.props.t('booking_table_column_date'),
                prop: "startTime",
                sortable: "custom",
                render: this.renderDate
            },
            {
                label: this.props.t('booking_table_column_time'),
                prop: "startTime",
                render: this.renderTime
            },
            {
                label: this.props.t('booking_table_column_user'),
                prop: "user.name",
                render : function(data) {
                  return (
                      <div>
                          <span>{data.user.surname}</span>
                          <span>{data.user.name}</span>
                      </div>
                  )
                }
            },
            {
                label: this.props.t('booking_table_column_phone'),
                prop: "user.phone",
            },
            {
                label: this.props.t('booking_table_column_staff'),
                render : function(data) {
                  return (
                    <div>
                      <span>{data.staff_schedule.staff.staff_details[0].surname}</span>
                      <span>{data.staff_schedule.staff.staff_details[0].name}</span>
                    </div>
                  )
                }
            },
            {
                label: this.props.t('booking_table_column_server'),
                render: function(data){
                  return (
                    <span>{data.service.service_details[0].name}</span>
                  )
                }
            },
            {
                label: "價格",
                prop: "price",
            },
            {
                label: '減免金額',
                render: function(data){
                    if(data.type === 'discount') {
                        return (
                            <span>{(data.price && data.discount && (data.price * (1 - data.discount / 100)).toFixed(2)) || 0 }</span>
                        ) 
                    } else {
                        return (
                            <span>{data.discount || 0}</span>
                        ) 
                    }
                }
            },
            {
                label: '交易价格',
                prop:"discountPrice",
                render: function(data){
                    <span>{data.discountPrice || 0}</span>
                }
            },
            {
                label: this.props.t('booking_table_column_status'),
                prop: "status",
            },
        ],
    };

    async componentDidMount(){
        const { userId } = this.props
        await this.getList()
    }

    async getList(){
        const { page,limit,userId } = this.state 
        
        let obj = {
            page,
            limit,
            userId:userId,
            status:"checked"
        }
        this.setState({
            loading:true
        })
        await reservationApi.getReservationList(obj).then(result => {
            this.setState({
                data:result.rows,
                count: result.count
            })
        }).catch(error => {
            Message.error(error)
            }).finally(()=>{
            this.setState({
                loading:false
            })
        })
    }

    setCurrentPage(page) {
        this.setState({ page }, () => this.getList())
    }
    
    renderDate(date) {
        var date = new Date(date.startTime).toJSON();
        var val = new Date(+new Date(date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        return moment(new Date(val)).format('YYYY-MM-DD')
    }
  
    renderTime(date) {
        var startTime = new Date(date.startTime).toJSON();
        var endTime = new Date(date.endTime).toJSON();
        var from = new Date(+new Date(startTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        var to = new Date(+new Date(endTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        return moment(new Date(from)).format('HH:mm:ss') + "-" + moment(new Date(to)).format('HH:mm:ss')
    }

    render() {
        const { loading, columns, data, count, limit, page,sort } = this.state
        return (
            <div>
                <Loading loading={loading}>
                    <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit}/>
                    <PageTable
                        limit={limit}
                        currentPage={page}
                        totalResult={count}
                        results={data}
                        sort={sort}
                        columns={columns}
                        setSort={(e) => this.setSort(e)}
                        setCurrentPage={(page) => this.setCurrentPage(page)}
                        loading={loading}
                    />
                </Loading>
            </div>
        );
    }
}
