import React, { Component } from 'react';
import './staff-holiday.scss';
import * as reservationApi from "../../../../api/reservation.api";
import * as scheduleApi from '../../../../api/schedule.api';
import * as centerApi from "../../../../api/center.api";
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, Form, Select , TimeSelect, Switch, DatePicker,Message  } from 'element-react';
import moment from 'moment';
import { mergeDetails,ServiceTree, normalize } from "../../../../utils/dataTransform";

const now = new Date()
const defaultAddScheduleForm = () => ({
    centerId: null,
    from: undefined,
    to: undefined,
    staffScheduleId:null,
    alldays: false,
    month: new Date(now.getFullYear(), now.getMonth()+1, 1),
    scheduleDays: [],
    date: now,
    errorMessage: '',
});

@withRouter
@translate()

export default class HolidayInfo extends Component {
    state = {
        addingSchedule:true,
        addScheduleForm: defaultAddScheduleForm(),
        centers:[],
        scheduleDayOptions:[],
        scheduleDays:[]
    }

    setAllDays(date) {
        this.state.scheduleDays = [];
        var month = moment(date).month() + 1;
        var year = moment(date).year();
        var dayNum = new Date(year, month, 0).getDate();
        var scheduleDays = [];
        var scheduleDayOptions = [];
        for(var i=1;i<=dayNum;i++) {
            var day = moment(date).date(i).toDate();
            scheduleDayOptions.push({id:day,name:i+"/"+month,value:day});
            scheduleDays.push(day);
        }
        this.setState({scheduleDayOptions: scheduleDayOptions});
        this.setAddScheduleForm('scheduleDays', scheduleDays)
    }

    setAddScheduleForm(key, value, callback) {

        this.setState({
            addScheduleForm: {
                ...this.state.addScheduleForm,
                [key]: value,
            }
        }, () => {
            callback && callback();
        })
    }

    validateAddScheduleForm() {
        const { t } = this.props;
        const {
            centerId,
            staffId,
            from,
            to,
            date,
            alldays,
            scheduleDays
        } = this.state.addScheduleForm;

        if (!centerId) {
            this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('center') }));
            return false;
        }
        if (!staffId) {
            this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('staff') }));
            return false;
        }
        if (!from) {
            this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('time_from') }));
            return false;
        }
        if (!to) {
            this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('time_to') }));
            return false;
        }
         if (alldays) {
            if (!scheduleDays || scheduleDays.length == 0) {
                this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('date') }));
                return false;
            }
        } else {
            if (!date) {
                this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('date') }));
                return false;
            }
        }

        this.setAddScheduleForm('errorMessage', '');
        return true;
    }

    // 添加日程表前判断休息日和工作日的时间段有没有重复
    isHasRepeatTime(data) {
        const startTimeArr = []
        const endTimeArr = [];
        (data || []).map(function(item) {
          startTimeArr.push(item.startTime)
          endTimeArr.push(item.endTime)
        })
        const allStartTime = startTimeArr.sort()
        const allEndTime = endTimeArr.sort()
        let result = 0
        for (let k = 1; k < allStartTime.length; k++) {
          if (allStartTime[k] < allEndTime[k - 1]) {
            result += 1
          }
        }
        return result > 0
    }

    async submit() {
        if (!this.validateAddScheduleForm()){
            return;
        }
        
        const { addScheduleForm:{centerId, staffId, from, to, staffScheduleId,date } } = this.state
        const { selectedBooking } = this.props

        let start = moment(date).format("YYYY-MM-DD") + " " + moment(from).format("HH:mm")
        let end = moment(date).format("YYYY-MM-DD") + " " + moment(to).format("HH:mm")

        let query = {
            staffId,
            centerId,
            startTime: moment(new Date(start)).toISOString(),
            endTime:moment(new Date(end)).toISOString(),
            bookable: "no",
            rest: "yes",
            remark: ""
        }

        if (selectedBooking) {
            query.scheduleId = staffScheduleId
        }

        let request = []
        request.push(query)

        let apiPost = selectedBooking ?  scheduleApi.updateStaffSchedule:scheduleApi.createStaffSchedule
        
        apiPost(request).then(result => {
            const { data, code, msg } = result
            const { success } = this.props   

            if (code == 200) {
                if ( !selectedBooking ) {
                    // 還要插進預約表
                    let obj = {
                        targetUserId:'',
                        bookUserId:'',
                        staffScheduleIds:[data[0].id],
                        serviceId:'rest',
                        bookingUser:'',
                        remark:'',
                        startTime:query.startTime,
                        endTime:query.endTime,
                        insert:"rest"
                    }
                    reservationApi.createRest(obj).then(() => {
                        this.MessageUtice('success',msg,true,2000)
                        success()
                    }).catch(error => {
                        this.MessageUtice('error',error,true,2000)
                    })
                } else {
                    let data = {
                        startTime : query.startTime,
                        endTime:query.endTime,
                    }
                    reservationApi.updateReservation(this.state.addScheduleForm.id, data).then(res => {
                        this.MessageUtice('success',msg,true,2000)
                        success() 
                    }).catch(error => {
                        this.MessageUtice('error',error.userMessage)
                    });
                }
            } else {
                this.MessageUtice('error',msg,true,2000)
            }
        }).catch(error => {
            console.log(error)
        })     
    }

    delete(){
        const { addScheduleForm } = this.state
        const { selectedBooking } = this.props
        const { success } = this.props  

        if (!selectedBooking || !addScheduleForm.id || !addScheduleForm.staffScheduleId) return

        let query = [addScheduleForm.staffScheduleId]
        
        scheduleApi.deleteStaffSchedule(query).then(result => {
            this.MessageUtice('success',"刪除成功",true,2000)
            success() 
            // reservationApi.cancelReservation(addScheduleForm.id).then(result => {
                
            // })
        })
    }

    MessageUtice(type, message, showClose = true,num = 5000){
        return Message({
            type,
            message,
            customClass: 'zZindex',
            showClose,
            duretion: num
        })
    }

    async componentDidMount() {
        const centers = await centerApi.getCenters()
        this.setState({ 
            center: normalize(centers.map(x => mergeDetails(x, 'center'))),
            centers,   
        });

        if (this.props.selectedBooking) {
            this.setState({
                addScheduleForm:{...this.props.selectedBooking}
            })
        }
    }


    render(){
        const { addingSchedule, addScheduleForm, centers,scheduleDayOptions  } = this.state 
        const { closed,t,selectedBooking, staffs } = this.props   
        var obj = this;
        return (
            <main>
                <Dialog title={selectedBooking ? "修改休息日程" : "新增休息日程"} visible={addingSchedule} onCancel={() => closed()}>
                    <Dialog.Body>
                        <Form model={addScheduleForm}>
                            <Form.Item label={t('staff_schedule_dialog_center')} labelWidth="120">
                                <Select disabled={selectedBooking?true:false} value={addScheduleForm.centerId} onChange={(value) => this.setAddScheduleForm('centerId', value)}>
                                    {
                                        centers.map(center => (
                                            <Select.Option
                                                key={center.id}
                                                label={center.center_details[0].name}
                                                value={center.id}
                                            />
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="員工" labelWidth="120">
                                <Select disabled={selectedBooking?true:false} value={addScheduleForm.staffId} onChange={(value) => this.setAddScheduleForm('staffId', value)}>
                                    {
                                        staffs.map(staff => (
                                            <Select.Option
                                                key={staff.id}
                                                label={staff.title}
                                                value={staff.id}
                                            />
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label={t('staff_schedule_dialog_time')}  labelWidth="120">
                                <TimeSelect start="08:00" step="00:15" end="23:00" onChange={(time) => this.setAddScheduleForm('from', time)} value={addScheduleForm.from} placeholder="From"/>
                                {' - '}
                                <TimeSelect start="08:00" step="00:15" end="23:00" minTime={addScheduleForm.from} onChange={(time) => this.setAddScheduleForm('to', time)} value={addScheduleForm.to} placeholder="To"/>
                            </Form.Item>
                            {
                                selectedBooking ? null :
                                <Form.Item label={t('staff_schedule_dialog_alldays')} labelWidth="120">
                                    <Switch value={addScheduleForm.alldays} onChange={(value) => this.setAddScheduleForm('alldays', value,function() {obj.setAllDays(obj.state.addScheduleForm.month)})} />
                                </Form.Item>
                            }
                            
                            {
                                addScheduleForm.alldays ?
                                    <Form.Item label={t('staff_schedule_dialog_month')} labelWidth="120">
                                        <DatePicker
                                            selectionMode="month"
                                            value={addScheduleForm.month}
                                            onChange={(value) => this.setAddScheduleForm('month', value, function() {obj.setAllDays(value)})}
                                        />
                                    </Form.Item> : null
                            }
                            { 
                                addScheduleForm.alldays ?
                                    <Form.Item label={t('staff_schedule_dialog_day')}  labelWidth="120">
                                        <Select
                                            value={addScheduleForm.scheduleDays}
                                            multiple={true}
                                            disabled={false}
                                            onChange={(values) => this.setAddScheduleForm('scheduleDays', values)}
                                        >
                                            {
                                                scheduleDayOptions.map(day => (
                                                    <Select.Option
                                                        key={day.id}
                                                        label={day.name}
                                                        value={day.id}
                                                    />
                                                ))
                                            }
                                        </Select>
                                    </Form.Item> : null
                             }
                            {
                                addScheduleForm.alldays ?
                                null : 
                                <Form.Item label={t('staff_schedule_dialog_date')} labelWidth="120">
                                    <DatePicker
                                        value={addScheduleForm.date}
                                        onChange={(value) => this.setAddScheduleForm('date', value)}
                                    />
                                </Form.Item>
                            }
                            <Form.Item abelWidth="120">
                                <div className="error-message">
                                    {addScheduleForm.errorMessage}
                                </div>
                            </Form.Item>
                        </Form>
                    </Dialog.Body>
                    <Dialog.Footer>
                        {
                            selectedBooking ? <Button onClick={() => this.delete()}>刪除</Button> : null
                        }
                        <Button onClick={() => this.submit()}>{selectedBooking ? "確認":t('staff_schedule_dialog_add')}</Button>
                        <Button onClick={() => closed()}>{t('staff_schedule_dialog_cancel')}</Button>
                    </Dialog.Footer>
                </Dialog>
            </main>
        )
    }
}