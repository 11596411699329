import client from '../apiClient';

export async function getRooms(page, orderBy, direction, limit, reference) {
    let response = await client.get('/room', {
        params: {
            page,
            orderBy,
            direction,
            limit,
            reference,
        }
    });
    return response.data;
}

export async function getRoom(roomId) {
    let response = await client.get(`/room/${roomId}`);
    return response.data;
}

export async function createRoom(body) {
    let response = await client.post(`/room`, body);
    return response.data;
}


export async function updateRoom(roomId, body) {
    let response = await client.patch(`/room/${roomId}`, body);
    return response.data;
}

export async function deleteRoom(roomId) {
    let response = await client.delete(`/room/${roomId}`);
    return response.data;
}

export async function assignService(roomId, services) {
    console.log('services:', services)
    let response = await client.post(`/room/${roomId}/assignService`, {services});
    return response.data;
}