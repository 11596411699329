
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-edit.scss';
import { Tabs, Button, Input } from 'element-react';
import * as webApi from "../../../../api/web.api";
import { baseURL } from "../../../../apiClient";
import fileDialog from "file-dialog";
import ImageBoard from "../../../image-board.component";

@translate()
export default class HomepageEditBoard extends Component {
    state = {
        localImages: [`${process.env.PUBLIC_URL}/resources/upload.png`, `${process.env.PUBLIC_URL}/resources/upload.png`, `${process.env.PUBLIC_URL}/resources/upload.png`],
        images: null,
        web: null,
        webUpdate: null,
        tabs: [
            {
                id: "eng",
                language: "English",
                title: "Title:",
                description: "Description:",
            },
            {
                id: "cht",
                language: "繁體中文",
                title: "標題:",
                description: "描述:",
            },
            {
                id: "chs",
                language: "简体中文",
                title: "标题:",
                description: "描述:",
            },
        ],
        selectedTab: "0",
    }

    async uploadImage(index, remove = false) {
        const newImages = this.state.images ? { ...this.state.images } : {};
        newImages[index] = {};
        if (remove) {
            newImages[index].file = null;
        } else {
            newImages[index].file = (await fileDialog({ accept: 'image/*' }))[0];
        }
        this.setState({ images: newImages },
            () => {
                if (this.state.images[index].file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const newLocalImages = [...this.state.localImages]
                        newLocalImages[index] = e.target.result;
                        this.setState({ localImages: newLocalImages });
                    }
                    reader.readAsDataURL(this.state.images[index].file);
                } else {
                    const newLocalImages = [...this.state.localImages]
                    newLocalImages[index] = `${process.env.PUBLIC_URL}/resources/upload.png`;
                    this.setState({ localImages: newLocalImages });
                }
            }
        );
    }

    async submit() {
        const { webUpdate, images } = this.state;
        if (webUpdate) {
            await webApi.updateWeb(webUpdate);
        }
        if (images) {
            console.log('images', images);
            await webApi.uploadSlides(images);
        }
        this.props.changeMode();
    }

    updateWebDetail(id, languageId, title, value) {
        const newWebUpdate = { ...this.state.webUpdate };
        newWebUpdate.details = newWebUpdate.details || [];
        let newDetail = { id, languageId, value: {} };
        let detailIndex = -1;
        for (let i = 0; i < newWebUpdate.details.length; i++) {
            const detail = newWebUpdate.details[i];
            if (detail.languageId === languageId && detail.id === id) {
                newDetail = { ...detail };
                detailIndex = i;
                break;
            }
        }
        newDetail.value[title] = value;

        if (detailIndex === -1) {
            newWebUpdate.details.push(newDetail);
        } else {
            newWebUpdate.details[detailIndex] = newDetail;
        }
        const newWeb = { ...this.state.web };
        if (!newWeb.details) {
            newWeb.details = {};
        }
        if (!newWeb.details[id]) {
            newWeb.details[id] = {};
        }
        if (!newWeb.details[id][languageId]) {
            newWeb.details[id][languageId] = {};
        }
        newWeb.details[id][languageId][title] = value;
        this.setState({
            webUpdate: newWebUpdate,
            web: newWeb,
        }, () => {
            console.log('webUpdate:', this.state.webUpdate);
        });
    }

    async componentDidMount() {
        const { web } = this.props;
        let newLocalImages = [...this.state.localImages];
        if (web && web.slides) {
            for (let i = 0; i < web.slides.length; i += 1) {
                if (web.slides[i].path) {
                    newLocalImages[parseInt(web.slides[i].id)] = `${baseURL}/${web.slides[i].path}`;
                }
            }
        }
        this.setState({
            web: web,
            localImages: newLocalImages,
        });
    }

    render() {
        const { t } = this.props;
        const { tabs, selectedTab, web, localImages } = this.state;
        let details = {};
        if (web && web.details) {
            details = web.details;
        }
        return (
            web ? <div className="homepage-board-edit">
                <ImageBoard edit={true} localImages={localImages} uploadImage={(index, remove) => this.uploadImage(index, remove)} />
                <div className="tab">
                    <Tabs activeName={selectedTab} onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })}>
                        {
                            tabs.map((tab, i) => {
                                return (
                                    <Tabs.Pane key={i} label={tabs[i].language} name={i.toString()}>
                                        <div className="block">
                                            <div className="sub-title">
                                                {t('web_homepage_title_section_1')}
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].title}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        value={
                                                            (details && details.section_1 && details.section_1[tabs[i].id] && details.section_1[tabs[i].id].title) || ""}
                                                        onChange={(value) => this.updateWebDetail('section_1', tabs[i].id, "title", value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].description}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        type="textarea"
                                                        autosize={{ minRows: 5 }}
                                                        value={
                                                            (details.section_1 && details.section_1[tabs[i].id] && details.section_1[tabs[i].id].description) || ""}
                                                        onChange={(value) => this.updateWebDetail('section_1', tabs[i].id, "description", value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="sub-title">
                                                {t('web_homepage_title_section_2')}
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].title}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        value={
                                                            details.section_2 && details.section_2[tabs[i].id] && details.section_2[tabs[i].id].title || ""}
                                                        onChange={(value) => this.updateWebDetail('section_2', tabs[i].id, "title", value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].description}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        type="textarea"
                                                        autosize={{ minRows: 5 }}
                                                        value={
                                                            details.section_2 && details.section_2[tabs[i].id] && details.section_2[tabs[i].id].description || ""}
                                                        onChange={(value) => this.updateWebDetail('section_2', tabs[i].id, "description", value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Tabs.Pane>
                                )
                            })
                        }
                    </Tabs>
                </div>
                <div className="board-footer">
                    <Button onClick={() => this.submit()}>{t('button_save')}</Button>
                </div>
            </div> : null
        );
    }
}