import React, { Component } from 'react';
import './login.scss';
import { connect } from 'react-redux';
import { Input, Button, Select } from 'element-react';
import { login, setLanguage } from "../../redux/staff/staff.action"
import { translate } from 'react-i18next';
import logo from '../../assets/logo_nav.png';

const mapDispatchToProps = (dispatch) => {
    return {
        login: (staffId, password) => {
            dispatch(login(staffId, password))
        },
        setLanguage: (languageId) => {
            dispatch(setLanguage(languageId))
        }
    }
}


@translate()
@connect(null, mapDispatchToProps)
export default class LoginPage extends Component {

    state = {
        staffId: '',
        password: '',
        lang: { name:"繁", key:"cht" }
    };

    submit() {
        const { staffId, password } = this.state;
        this.props.login(staffId, password);
    }

    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.submit();
        }
    }

    render() {
        const { t, i18n, setLanguage } = this.props;
        const { staffId, password } = this.state;
        const langs = [
            { name:"ENG", key:"eng" },
            { name:"繁", key:"cht" }
        ];
        return (
            <div className="login-page">
                <div className="language-panel" style={{ marginRight: "10px", marginTop: "10px" }}>
                    <Select value={this.state.lang.key}
                        onChange={(key) => setLanguage(key)}>
                        {langs.map(lang => (
                            <Select.Option key={lang.key} label={lang.name} value={lang.key} />
                        ))}
                    </Select>
                </div>
                <div className="login-board" style={{ height: "75vh" }}>
                    <div>
                        <div className="login-panel-title" style={{ borderBottom: "unset" }}>
                            {/* {t('login_title')} */}
                            <img className="sidebar-logo" src={logo} width="340" alt=""/>
                        </div>
                        <div className="login-panel-form login-panel" style={{ margin: "5px" }}>
                            <Input placeholder={t('login_staff_id')} value={staffId} onChange={(value) => this.setState({ staffId: value})} /><br />
                            <Input type="password"
                                   placeholder={t('login_password')}
                                   value={password}
                                   onChange={(value) => this.setState({ password: value })}
                                   onKeyDown={(e) => this.onKeyDown(e)} /><br />
                            <Button onClick={() => this.submit()}>{t('login_button')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
