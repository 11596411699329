import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Form, Input, Layout, DateRangePicker, Button, Tabs, Loading,Checkbox,Dialog } from 'element-react';
import "./index.scss"
import ListUserReportPage from './list-user-report'
import ListServiceReportPage from './list-service-report'
import * as reportApi from "../../../api/report.api";
import moment from 'moment'
import ExprotSelectPage from './exprot.select';

@translate()
export default class ConsumeReportPage extends Component {
    state = {
        search:{
            page:1,
            limit:40,
            date:[new Date(),new Date()]
        },
        tabsValue:"1",
        totalResult:{},
        loading:false,
        checkLists:[],
        exportVisiabled:false,
        exportLoading : false,
        checkAll:true,
        isIndeterminate:true,
        exprotLists:[
            {label:"日期",value:'date'},
            {label:"客人姓名",value:'name'},
            {label:"客人電話",value:'phone'},
            {label:"購買項目",value:'service'},
            {label:"師傅",value:'staff'},
            {label:"價格",value:'price'},
            {label:"優惠",value:'discount'},
            {label:"優惠後的價格",value:'discountPrice'},
            {label:"結算方式",value:'settlement'},
            {label:"支付方式",value:'payment'}
        ]
    }

    onChange(key, value) {
        this.setState({
            search: Object.assign({}, this.state.search, { [key]: value })
        },()=>{
            this.getTotal()
        });
    }

    setResultLimit(limit) {
        this.setState({search: Object.assign({},this.state.search,{limit})}, () => this.getData());
    }

    setCurrentPage(page) {
        this.setState({search: Object.assign({},this.state.search,{page})}, () => this.getData());
    }

    onClickTabs(tabs){
        this.setState({tabsValue:tabs.props.name} )
    }

    getTotal(){
        const { search } = this.state
        let query = {...search}

        if (search.date && search.date.length > 0) {
            let startTime = moment(search.date[0]).startOf('day').format('YYYY-MM-DD HH:mm')
            let endTime = moment(search.date[1]).endOf('day').format('YYYY-MM-DD HH:mm')
            query.startTime = startTime
            query.endTime = endTime
        }
        
        Object.keys(query).forEach((item) => {
            const key = query[item];
            if (key === "" || key === null || key === undefined) {
                delete query[item];
            }
        });
        delete query['date']

        this.setState({
            loading:true
        })
        reportApi.listTotalReport(query).then(result => {
            const {data} = result
            this.setState({
                totalResult:data
            })
        }).catch(error => {
            this.setState({
                loading:false
            })
        }).finally(()=>{
            this.setState({
                loading:false
            })
        })
    }

    async exprotExecl() {
        const { search,tabsValue,exportLoading,checkLists  } = this.state
        let query = {...search}

        if (search.date && search.date.length > 0) {
            let startTime = moment(search.date[0]).startOf('day').format('YYYY-MM-DD HH:mm')
            let endTime = moment(search.date[1]).endOf('day').format('YYYY-MM-DD HH:mm')
            query.startTime = startTime
            query.endTime = endTime
        }
        
        Object.keys(query).forEach((item) => {
            const key = query[item];
            if (key === "" || key === null || key === undefined) {
                delete query[item];
            }
        });
        delete query['date']

        let obj = { ...query,type:tabsValue == '1'?'user':'service',checkLists }

        if (exportLoading ) return
        this.setState({exportLoading :true})
        await reportApi.listExprotExecl(obj).then(blob => {
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement('a');
            a.setAttribute('download', "數據報表.xlsx")
            a.href = url;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).catch(error => {
            this.setState({exportLoading :false})
        }).finally(()=>{
            this.setState({exportLoading :false})
        })
    }

    handleCheckedChange(value) {
        const checkedCount = value.length;
        const checkedLength = this.state.checkLists.length;

        this.setState({
            checkList:value,
            checkAll: checkedCount === checkedLength,
            isIndeterminate: checkedCount > 0 && checkedCount < checkedLength,
        });
    }

    handleCheckAllChange(checked) {
        const {exprotLists} = this.state
        const checkLists = checked ? exprotLists.map(item=>item.value) : [];
        console.log(checkLists)
        this.setState({
          isIndeterminate: false,
          checkAll: checked,
          checkLists,
        });
    }

    componentDidMount(){
        this.getTotal()
        this.setState({
            checkLists:this.state.exprotLists.map(item=>item.value)
        })
    }


    render() {
        const {search, tabsValue,totalResult,loading,exportVisiabled,checkLists,isIndeterminate,checkAll} = this.state
        return (
            <main className='list-user-report-contanier'>
                <section className='search-info'>
                    <Form ref="form" model={search} labelWidth="75" labelPosition="left" className="rule-form">
                        <Layout.Row gutter="20">
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="日期時間" prop="date">
                                    <DateRangePicker value={search.date} placeholder="選擇日期範圍" align="right" onChange={ this.onChange.bind(this,'date') } style={{width:'100%'}} />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="客戶查詢">
                                    <Input value={search.userName} placeholder="請輸入" onChange={this.onChange.bind(this, 'userName')}></Input>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="項目查詢">
                                    <Input value={search.serviceName} placeholder="請輸入" onChange={this.onChange.bind(this, 'serviceName')}></Input>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="師父查詢">
                                    <Input value={search.staffName} placeholder="請輸入" onChange={this.onChange.bind(this, 'staffName')}></Input>
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row>
                            <div className='submit-buttons'>
                                <Button onClick={() => this.setState({exportVisiabled:true})}>導出</Button>
                            </div>
                        </Layout.Row>
                    </Form>
                </section>
                <Loading loading={loading}>
                    <Layout.Row gutter={20}>
                        <Form labelPosition='left' labelWidth={100}>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="已確認金額">
                                        <Input value={totalResult.checked_total_price - totalResult.checked_total_discount || 0} disabled />
                                    </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                <Form.Item label="+ 未確認金額">
                                    <Input value={totalResult.unchecked_total_price - totalResult.unchecked_total_discount || 0} disabled />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                <Form.Item label="= 總金額" className="total-num-label">
                                    <Input value={(totalResult.checked_total_price + totalResult.unchecked_total_price) - (totalResult.checked_total_discount + totalResult.unchecked_total_discount)  || 0} disabled />
                                </Form.Item>
                            </Layout.Col>
                        </Form>
                    </Layout.Row>
                    <Layout.Row gutter={20}>
                        <Form labelPosition='left' labelWidth={100}>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="已確認優惠金額">
                                        <Input value={totalResult.checked_total_discount || 0} disabled />
                                    </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                <Form.Item label="+ 未確認優惠金額">
                                    <Input value={totalResult.unchecked_total_discount || 0} disabled />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                <Form.Item label="= 總金額" className="total-num-label">
                                    <Input value={(totalResult.checked_total_discount + totalResult.unchecked_total_discount) || 0} disabled />
                                </Form.Item>
                            </Layout.Col>
                        </Form>
                    </Layout.Row>
                    <Layout.Row gutter={20}>
                        <Form labelPosition='left' labelWidth={100}>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="已確認提成金額">
                                        <Input value={totalResult.checked_total_commission || 0} disabled />
                                    </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                <Form.Item label="+ 未確認提成金額">
                                    <Input value={totalResult.unchecked_total_commission || 0} disabled />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                <Form.Item label="= 總金額" className="total-num-label">
                                    <Input value={(totalResult.checked_total_commission + totalResult.unchecked_total_commission) || 0} disabled />
                                </Form.Item>
                            </Layout.Col>
                        </Form>
                    </Layout.Row>
                    <Layout.Row gutter={20}>
                        <Form labelPosition='left' labelWidth={100}>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="充值總金額">
                                        <Input value={totalResult.top_up_total || 0} disabled />
                                    </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                <Form.Item label="點數扣除總金額">
                                    <Input value={totalResult.top_deduct_total || 0} disabled />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                <Form.Item label="用戶帳戶剩餘總金額">
                                    <Input value={totalResult.balance || 0} disabled />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="套餐購買總金額">
                                        <Input value={totalResult.secondary_card_total || 0} disabled />
                                    </Form.Item>
                            </Layout.Col>
                        </Form>
                    </Layout.Row>
                </Loading>
                <section>
                    <Tabs type="card" value={tabsValue} onTabClick={(tabs)=>{ this.onClickTabs(tabs) }}>
                        <Tabs.Pane label="以客人為主" name="1">
                            {
                                tabsValue == "1" ? <ListUserReportPage search={search}></ListUserReportPage> : null
                            } 
                        </Tabs.Pane>
                        <Tabs.Pane label="以服務為主" name="2">
                            {
                                tabsValue == "2" ? <ListServiceReportPage search={search}></ListServiceReportPage> : null
                            } 
                        </Tabs.Pane>
                    </Tabs>
                </section>
                {
                    exportVisiabled && <ExprotSelectPage search={search} tabsValue={tabsValue} closed={()=>this.setState({ exportVisiabled : false })}></ExprotSelectPage>
                }
            </main>
        )
    }
}