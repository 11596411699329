
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-view.scss';
import moment from 'moment';
import * as userApi from "../../../api/user.api";
import * as serviceApi from '../../../api/service.api'
import * as staffApi from "../../../api/staff.api";
import { baseURL } from "../../../apiClient";
import AccountIcon from "../../icon.component";
import { Input, Cascader, Select, MessageBox } from 'element-react';

@translate()
export default class UserViewBoard extends Component {

    state = {
        customerClass: [],
        intentionalService: [],
        introducer: [],
        prop: {
            value: "value",
            label: "name"
        }
    }

    async componentDidMount() {
        await this.getUsersAndStaffs()
        await this.getServiceList()
        await this.getVipList()
    }

    async getVipList() {
        await userApi.getVipList().then((result) => {
            const data = result.map(item => {
                return {
                    value: item.id,
                    label: item.level
                }
            })
            this.setState({ customerClass: data })
        }).catch(error => {
            MessageBox.alert(error.userMessage);
        });
    }

    async getServiceList() {
        await serviceApi.getServiceList().then(async result => {
            let data = await this.ServiceTree(result.rows)
            this.setState({ intentionalService: data })
        }).catch(error => {
            MessageBox.alert(error.userMessage);
        })
    }

    async ServiceTree(datas) {
        let parents = []
        datas.forEach(item => {
            if (item.stratum === 1) {
                parents.push({
                    value: item.id,
                    name: item && item.category_details && item.category_details[0].title,
                })
            }
        })
        let childrens = datas.filter(item => item.stratum != 1)
        await this.formatService(parents, childrens)
        return parents
    }

    async formatService(parents, childrens) {
        parents.forEach(parent => {
            childrens.forEach((children, index) => {
                if (children.stratumId === parent.value) {
                    let temp = JSON.parse(JSON.stringify(childrens))
                    temp.splice(index, 1)
                    let services = children.services.map(service => {
                        return {
                            value: service.id,
                            name: service.service_details[0].name
                        }
                    })
                    let child = {
                        value: children.id,
                        name: children && children.category_details && children.category_details[0].title,
                    }
                    if (children.services.length > 0) {
                        child.children = services
                    }
                    parent.children ? parent.children.push(child) : parent.children = [child]
                    this.formatService([child], temp)
                }
            })
        })
    }

    async getUsersAndStaffs() {
        const datas = await Promise.all([userApi.getAllList(), staffApi.getStaffsAll()]);
        let all = datas.map((data, index) => {
            let arr = data.map(item => {
                return {
                    value: item.id,
                    label: item.staff_details ? item.staff_details[0].name + " " + item.staff_details[0].surname : item.name
                }
            })

            return {
                value: index === 0 ? 'user' : 'staff',
                label: index === 0 ? 'users' : 'staffs',
                children: arr
            }
        })
        this.setState({ introducer: all })
    }

    render() {
        const { t, user } = this.props;
        console.log('user',user)
        let cosFication;//消费分类
        return (
            <div className="customer-detail">
                <div className="customer-detail-left">
                    <div className="customer-detail-row">
                        <div className="lable">
                            △{t('user_board_view_surname')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                value={user.surname}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            △{t('user_board_view_name')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                value={user.name}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            △{t('user_board_view_gender')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                value={user.gender === 'male' ? t('male') : t('female')}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('user_board_view_birthday')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                value={moment(user.birthday).format("YYYY/MM/DD")}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('user_board_view_family_id')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                value={user.familyId}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            △{t('user_board_view_hkid')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                value={user.hkid}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            △{t('user_board_view_tel')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                value={user.phone}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            △{t('user_board_view_email')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                value={user.email}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('user_board_view_member_id')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                value={user.id}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('user_board_view_address')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                value={user.address}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            △{t('user_board_view_family_balance')}
                        </div>
                        <div className="concat">
                            {user.family ?
                                <Input
                                    disabled
                                    value={`HK$ ${user.family.balance}`}
                                />
                                : null
                            }
                        </div>
                    </div>
                </div>
                <div className="customer-detail-right">
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('user_board_edit_intentional_service')}
                        </div>
                        <div className="concat">
                             <Cascader disabled 
                                options={this.state.intentionalService}
                                props={this.state.prop}
                                value={user.intentionalService && user.intentionalService.length > 0 ? user.intentionalService : []}
                            ></Cascader>
                        </div>
                    </div>         
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('user_board_edit_consume_class')}
                        </div>
                        <div className="concat">
                             <Cascader disabled 
                                options={this.state.intentionalService}
                                props={this.state.prop}
                                value={user.consumeClass && user.consumeClass.length > 0 ? user.consumeClass : []}
                            ></Cascader>
                        </div>
                    </div>          
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('user_board_edit_customer_class')}
                        </div>
                        <div className="concat">
                            <Select disabled 
                                value={user.customerClass ? user.customerClass : ""} >
                                {
                                    this.state.customerClass.length > 0 ?
                                        this.state.customerClass.map(el => {
                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                        })
                                        : null
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('user_board_edit_introducer')}
                        </div>
                        <div className="concat">
                            <Cascader disabled 
                                options={this.state.introducer}
                                value={user.introducer && user.introducer.length > 0 ? user.introducer : []} 
                            ></Cascader>
                        </div>
                    </div>
                    {/* 消费分类 */}
                    {user.consumClass&&user.consumClass.length>0
                        ?<div className="customer-detail-row">
                            <div className="lable">
                                {t('user_board_edit_classification')}
                            </div>
                            <div className="concat">
                                <Cascader disabled 
                                    options={this.state.intentionalService}
                                    props={this.state.prop}
                                    value={user.consumClass && user.consumClass.length > 0 ? user.consumClass : []}
                                ></Cascader>
                            </div>
                        </div>
                        :null
                    }
                    {/* 服务积分 */}
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('service_detail_content_integral')}
                        </div>
                        <div className="concat">
                            <Input disabled value={ user.integral ? user.integral : 0 } />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            △{t('user_board_view_body_condition')}
                        </div>
                        <div className="concat">
                            {user.bodyConditions.length > 0
                                ? user.bodyConditions.map((bodyCondition) => {
                                    return (bodyCondition.active ?
                                        <div className="userBodyCondition" key={bodyCondition.id}>
                                            {bodyCondition.name}
                                        </div> : null
                                    )
                                })
                                : <Input disabled value={''} />
                            }
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('remark')}
                        </div>
                        <div className="concat">
                            <Input
                                disabled
                                type="textarea" autosize={{ minRows: 3 }}
                                value={user.remark ? user.remark : ''}
                            />
                        </div>
                    </div>
                    <div className="customer-detail-row">
                        <div className="lable">
                            {t('user_board_view_photo')}
                        </div>
                        <div className="concat">
                            <AccountIcon className="icon" icon={user.cmsIcon} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}