import React, { Component } from 'react';
import './service-detail.scss';
import { translate } from 'react-i18next';
import * as serviceApi from "../../../../api/service.api";
import * as centerApi from "../../../../api/center.api";
import * as categoryApi from "../../../../api/category.api";
import { withRouter } from 'react-router-dom';
import { Button } from 'element-react';
import ServiceViewBoard from "../../../../components/service/board-view/board-view.component";
import ServiceEditBoard from "../../../../components/service/board-edit/board-edit.component";
import history from '../../../../history';
// import { map } from 'jquery';

@withRouter
@translate()
export default class ServiceDetailPage extends Component {
    state = {
        edit: false,
        service: null,
        centers: null,
        categories: null,
    }
    //生命周期~~第一次render()之后执行
    async componentDidMount() {
        const centerRes = await centerApi.getCenters();
        const categoryRes = await categoryApi.getCategories();//查询分类
        await this.setState({
            ...this.state,
            centers: centerRes,
            categories: categoryRes,
        });
        let { serviceId } = this.props.match.params;
        if (serviceId === "create") {
            // 新增服务
            this.setState({
                edit: true
            });
        } else {
            //检查详情
            await this.getData(serviceId);
        }
    }
    //服务详情
    async getData(serviceId) {
        const res = await serviceApi.getService(serviceId);
        const newService = {
            ...res,
            service_details: res.service_details.filter(item => item.languageId === 'cht')[0],
            centers: res.center_services.map((e) => {
                return {
                    ...e.center,
                    extraFee: e.extraFee,
                }
            }),
        };
        await this.setState({
            service: newService,
        });
    }
    //保存回调
    async changeMode(serviceId, bool) {
        await this.getData(serviceId);
        this.setState({
            edit: !this.state.edit,
        },
            () => {
                if (bool) {
                    history.push('/main/service');
                }
            }
        );
    }

    render() {
        const { t } = this.props;
        const { service, centers, categories, edit } = this.state;
        return (
            <div className="service-detail-page" >
                <div className="main-title">
                    {t(!edit
                        ? 'service_detail_title_view'
                        : (service ? 'service_detail_title_edit' : 'service_detail_title_create')
                    )
                    }
                </div>
                <div className="page-back">
                    <Button onClick={() => history.goBack()}>{t('add_booking_button_back')}</Button>
                </div>
                    <div className="page-body">
                        {edit
                            ? <ServiceEditBoard service={service}
                                centers={centers} categories={categories}
                                changeMode={(id) => this.changeMode(id, true)}
                            />
                            : (service ? <ServiceViewBoard categories={categories} service={service} /> : null)
                        }
                    </div>
                <div className="page-footer" >
                    {!edit
                        ? <Button
                            disabled={service ? false : true}
                            onClick={() => this.setState({
                                ...this.state,
                                edit: !edit
                            })}
                        >{t('button_edit')}</Button>
                        : null
                    }
                </div>
            </div>
        );
    }
}