import React, { Component } from 'react';
import './tab-transaction.scss';
import { translate } from 'react-i18next';
import * as transactionApi from "../../../api/transaction.api";
import moment from 'moment';
import PageTable from "../../page-table.component";
import ResultLimit from "../../result-limit.component";

@translate()
export default class UserTransactionTab extends Component {
    state = {
        currentPage: 1,
        orderBy: "createdAt",
        direction: "ASC",
        limit: 40,
        transactions: [],
        totalResult: 0,
        sort: null,
        columns: [
            {
                label: this.props.t('tab_transaction_table_column_date'),
                prop: "date",
            },
            {
                label: this.props.t('tab_transaction_table_column_time'),
                prop: "time",
            },
            {
                label: this.props.t('tab_transaction_table_column_staff'),
                prop: "staff",
            },
            {
                label: "用戶",
                prop: "user",
            },
            {
                label: this.props.t('tab_transaction_table_column_action'),
                prop: "action",
            },
            {
                label: this.props.t('tab_transaction_table_column_service'),
                prop: "service",
            },
            {
                label: this.props.t('remark'),
                prop: "remark",
            },
            {
                label: this.props.t('tab_transaction_table_column_amount'),
                prop: "amount",
            },
        ],
        loading: true,
    };

    async getTransactions () {
        const response = await transactionApi.getTransactions(
            this.state.familyId,
            this.state.currentPage,
            this.state.orderBy,
            this.state.direction,
            this.state.limit,
        );
        this.setState({
            transactions: response.rows.map((transaction) => {
                let action = transaction.action == 'Top-up' ? this.props.t('tab_transaction_action_top_up') : this.props.t(`tab_transaction_action_${transaction.action.toLowerCase()}`)
                const data = {
                    date: moment(transaction.createdAt).format("YYYY-MM-DD"),
                    time: moment(transaction.createdAt).format("hh:mm:ss"),
                    staff: transaction.staffId,
                    user: transaction.user,
                    action,
                    service: transaction.service? transaction.service:transaction.note,
                    remark: transaction.remark,
                    amount: transaction.amount,
                };
                return data;
            }),
            totalResult: response.count,
            loading: false
        });
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.getTransactions());
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.getTransactions())
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
           this.setState({
               familyId: nextProps.user.familyId,
           }, () => this.getTransactions());
        }
    }

    async componentDidMount() {
        this.setState({
            familyId: this.props.user.familyId,
        }, () => this.getTransactions());
    }

    render() {
        const { t } = this.props;
        const { transactions, columns, limit, currentPage, totalResult, sort, loading } = this.state;
        return (
            <div className="customer-transaction-tab">
                <div className="main-title">
                    {t('tab_transaction_title')}
                </div>
                <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit}/>
                <PageTable
                    limit={limit}
                    currentPage={currentPage}
                    totalResult={totalResult}
                    results={transactions}
                    sort={sort}
                    columns={columns}
                    setSort={(e) => this.setSort(e)}
                    setCurrentPage={(page) => this.setCurrentPage(page)}
                    loading={loading}
                />
            </div>
        );
    }
}
