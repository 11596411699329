import React, { Component } from 'react';
import './user-detail.scss';
import { translate } from 'react-i18next';
import * as userApi from "../../../../api/user.api";
import * as bodyConditionApi from "../../../../api/bodyCondition.api";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tabs, Button } from 'element-react';
import UserViewBoard from "../../../../components/user/board-view/board-view.component";
import UserEditBoard from "../../../../components/user/board-edit/board-edit.component";
import UserBookingTab from "../../../../components/user/tab-booking/tab-booking.component";
import UserBodyConditionTab from "../../../../components/user/tab-body-condition/tab-body-condition.component";
import UserFamilyTab from "../../../../components/user/tab-family/tab-family.component";
import UserTransactionTab from "../../../../components/user/tab-transaction/tab-transaction.component";
import UserTopUpTab from "../../../../components/user/tab-top-up/tab-top-up.component";
import UserTransferTab from "../../../../components/user/tab-transfer/tab-transfer.component";
import history from '../../../../history';
import UserExpensesRecord from '../../../../components/user/tab-expenses-record/tab-expenses-record.component'
import UserIntegral from '../../../../components/user/tab-expenses-record/user-integral.js'
import UserServicePackages from '../../../../components/user/tab-service-packages/index'

@withRouter
@translate()
@connect((state) => {
    return {
        permissions: state.staff.permissions,
    };
})
export default class UserViewPage extends Component {
    state = {
        edit: false,
        user: null,
        bodyConditions: null,
        selectedTab: "1",
    }
    async componentDidMount() {
        const bodyResponse = await bodyConditionApi.getBodyConditions();
        this.setState({
            bodyConditions: bodyResponse,
        });
        if (this.props.match.params.userId !== "create") {
            await this.getData(this.props.match.params.userId);
        } else {
            this.setState({ edit: true });
        }
    }
    async getData(userId) {
        this.setState({ user: await userApi.getUser(userId) });
    }

    refresh() {
        this.onClickItem(this.state.user.id);
    }

    onClickItem = async (userId) => {
        this.setState({ user: await userApi.getUser(userId) });
    }

    render() {
        const { t, permissions } = this.props;
        const { user, bodyConditions, edit, selectedTab } = this.state;
        return (
            <div className="user-all-page" >
                <div className="main-title">
                    {t('user_view_title')}
                </div>
                <div className="page-go-back"><Button onClick={() => history.goBack()}>{t('add_booking_button_back')}</Button></div>
                <div className="page-body">
                    {user && !edit ? <UserViewBoard user={user} /> : null}
                    {edit ? <UserEditBoard user={user} bodyConditions={bodyConditions} /> : null}
                </div>
                <div className="page-footer">
                    {user && !edit ? <Button onClick={() => this.setState({ edit: !edit })}>{t('button_edit')}</Button> : null}
                </div>
                {user ?
                    <div className="customer-information">
                        <Tabs onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })} type="card" value={selectedTab}>
                            {permissions.reservation ? <Tabs.Pane label={t('user_summary_tab_booking')} name="1">{selectedTab === "1" ? <UserBookingTab user={user} refresh={() => this.refresh()} /> : null}</Tabs.Pane> : null}
                            {permissions.bodyCondition ? <Tabs.Pane label={t('user_summary_tab_body_condition')} name="2">{selectedTab === "2" ? <UserBodyConditionTab bodyConditions={user.bodyConditions} /> : null}</Tabs.Pane> : null}
                            {permissions.transaction ? <Tabs.Pane label={t('user_summary_tab_transaction')} name="4">{selectedTab === "4" ? <UserTransactionTab user={user} /> : null}</Tabs.Pane> : null}
                            {permissions.transaction ? <Tabs.Pane label={t('user_summary_tab_top_up')} name="5">{selectedTab === "5" ? <UserTopUpTab user={user} refresh={() => this.refresh()} /> : null}</Tabs.Pane> : null}
                            <Tabs.Pane label="次卡管理" name="7">{selectedTab === "7" ? <UserServicePackages user={user} refresh={() => this.refresh()} /> : null}</Tabs.Pane>
                            <Tabs.Pane label={t('Point_Expenses_record')} name="8">{selectedTab === "8" ? <UserExpensesRecord user={user} refresh={() => this.refresh()} /> : null}</Tabs.Pane>
                            <Tabs.Pane label={t('Point_consumption_record')} name="9"> {selectedTab === "9" ? <UserIntegral user={user} refresh={() => this.refresh()} /> : null}</Tabs.Pane>
                        </Tabs>
                    </div> : null
                }
            </div>
        );
    }
}