
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-edit.scss';
import { Checkbox, Select, Radio, Button, Loading, Input, ColorPicker, Message, Cascader } from 'element-react';
import * as serviceApi from "../../../api/service.api";
import { baseURL } from "../../../apiClient";
import fileDialog from "file-dialog";
import { SketchPicker } from 'react-color';

@translate()
export default class ServiceEditBoard extends Component {
    state = {
        defaultColor: "transparent",
        displayColorPicker: false,
        mediaOption: true,
        localImages: [
            `${process.env.PUBLIC_URL}/resources/upload.png`,
            `${process.env.PUBLIC_URL}/resources/upload.png`,
            `${process.env.PUBLIC_URL}/resources/upload.png`,
        ],
        images: null,
        localVideo: null,
        video: null,
        localPreview: `${process.env.PUBLIC_URL}/resources/upload.png`,
        preview: null,
        service: {
            integral: '',//服务积分
            type: '',
            active: '',
            color: '',
            sessionInterval: '',
            price: '',
            service_details: {
                name: '',
                description: '',
                note: '',
            },
        },
        centers: null,
        options: [],
        loading: false,
        group: []
    }
    //上传照片
    async uploadImage(index, remove = false) {
        const newImages = this.state.images ? [...this.state.images] : [];
        newImages[index] = {};
        if (remove) {
            newImages[index].file = null;
        } else {
            newImages[index].file = (await fileDialog({ accept: 'image/*' }))[0];
        }
        this.setState({
            ...this.state,
            images: newImages
        },
            () => {
                if (this.state.images[index].file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const newLocalImages = [...this.state.localImages]
                        newLocalImages[index] = e.target.result;
                        this.setState({
                            ...this.state,
                            localImages: newLocalImages
                        });
                    }
                    reader.readAsDataURL(this.state.images[index].file);
                } else {
                    const newLocalImages = [...this.state.localImages]
                    newLocalImages[index] = `${process.env.PUBLIC_URL}/resources/upload.png`;
                    this.setState({
                        ...this.state,
                        localImages: newLocalImages
                    });
                }
            }
        );
    }
    //上传影片相关图片
    async uploadpreview(remove = false) {
        let newPreview = this.state.preview;
        if (remove) {
            newPreview = null;
        } else {
            newPreview = (await fileDialog({ accept: 'image/*' }))[0];
        }
        this.setState({
            ...this.state,
            preview: newPreview
        },
            () => {
                if (this.state.preview) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.setState({
                            ...this.state,
                            localPreview: e.target.result
                        });
                    }
                    reader.readAsDataURL(this.state.preview);
                } else {
                    this.setState({
                        ...this.state,
                        localPreview: `${process.env.PUBLIC_URL}/resources/upload.png`
                    });
                }
            }
        );
    }
    //上传影片
    async uploadVideo() {
        this.setState({
            ...this.state,
            video: (await fileDialog({ accept: 'video/mp4' }))[0]
        },
            () => {
                if (this.state.video) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.setState({
                            ...this.state,
                            localVideo: e.target.result
                        });
                    }
                    reader.readAsDataURL(this.state.video);
                }
            }
        );
    }
    //提交
    async submit() {
        const { service, centers, images, video, preview, mediaOption, group } = this.state;
        if (Object.values(service['service_details']).length < 3) {
            Message({
                message: '服务基本信息不能为空!!!',
                type: 'warning'
            });
            return;
        } else if (!service['price']) {
            Message({
                message: '价钱不能为空!!!',
                type: 'warning'
            });
            return;
        } else if (group.length <= 0) {
            Message({
                message: '所属类别不能为空!!!',
                type: 'warning'
            });
            return;
        } else if (!service['sessionInterval']) {
            Message({
                message: '服务时间不能为空!!!',
                type: 'warning'
            });
            return;
        } else if (!service['active']) {
            Message({
                message: '状态不能为空!!!',
                type: 'warning'
            });
            return;
        } else if (!service['integral']) {
            Message({
                message: '服务积分不能为空!!!',
                type: 'warning'
            });
            return;
        } else if (Number.isNaN(service['integral'])){
            Message({
                message: '服务积分只能为数字!!!',
                type: 'warning'
            });
            return;
        }
        this.setState({
            ...this.state,
            loading: true,
        });
        let len = group.length

        const req = {
            integral: service.integral,
            active: service.active,
            categoryId: group[len - 1],
            stratum: len,
            color: service.color,
            price: service.price,
            sessionInterval: service.sessionInterval,
            type: service.type,
            details: [{
                languageId: 'cht',
                value: {
                    ...service.service_details
                }
            }]
        }
        try {
            let serviceId;
            if (service && service.id) {
                serviceId = service.id;
                await serviceApi.updateService(service.id, req);
            } else {
                serviceId = (await serviceApi.createService(req)).id;
            }
            let newCenters = [];
            for (let i = 0; i < centers.length; i += 1) {
                if (centers[i].selected) {
                    newCenters.push({
                        id: centers[i].id,
                        extraFee: centers[i].extraFee,
                    });
                }
            }
            await serviceApi.assignCenter(serviceId, newCenters);

            if (mediaOption) {
                if (video || preview) {
                    await serviceApi.uploadVideo(serviceId, video, preview);
                }
            } else {
                if (images) {
                    await serviceApi.uploadImages(serviceId, images);
                }
                await serviceApi.uploadVideo(serviceId, null);
            }
            this.setState({
                ...this.state,
                loading: false,
            });
            this.props.changeMode(serviceId);
        }
        catch (err) {
            console.log(err)
            this.setState({
                ...this.state,
                loading: false,
            });
        }
    }

    updateServiceCenter(index, extraFee = null) {
        const newCenters = [...this.state.centers];
        if (extraFee !== null) {
            newCenters[index].extraFee = extraFee;
        } else {
            newCenters[index].selected = !newCenters[index].selected;
        }
        this.setState({ centers: newCenters });
    }

    updateService(title, value) {
        const newService = { ...this.state.service };
        newService[title] = value;
        this.setState({ service: newService });
    }

    updateServiceDetail(key, val) {
        const newUpdate = { ...this.state.service.service_details };
        newUpdate[key] = val;
        this.setState({
            service: {
                ...this.state.service,
                service_details: newUpdate
            }
        })
    }

    // 处理分类树结构
    async getServiceList(data) {
        let datas = await this.ServiceTree(data)
        this.setState({ options: datas })
    }

    async ServiceTree(datas) {
        let parents = []
        datas.forEach(item => {
            if (item.stratum === 1) {
                parents.push({
                    value: item.id,
                    label: item && item.category_details && item.category_details[0].title,
                })
            }
        })
        let childrens = datas.filter(item => item.stratum > 1)
        await this.formatService(parents, childrens);
        return parents;
    }

    async formatService(parents, childrens) {
        parents.forEach(parent => {
            childrens.forEach((children, index) => {
                if (children.stratumId === parent.value) {
                    let temp = JSON.parse(JSON.stringify(childrens))
                    temp.splice(index, 1)
                    let child = {
                        value: children.id,
                        label: children && children.category_details && children.category_details[0].title,
                    }
                    parent.children ? parent.children.push(child) : parent.children = [child]
                    this.formatService([child], temp)
                }
            })
        })
    }

    async componentDidMount() {
        const { service, centers, categories } = this.props;
        let newLocalImages = [...this.state.localImages];
        let slidesArr = service ? service.service_slides : [];
        if (service && slidesArr.length > 0) {
            for (let i = 0; i < slidesArr.length; i += 1) {
                if (slidesArr[i].path) {
                    let obj = slidesArr[i];
                    newLocalImages[i] = `${baseURL}/${obj.path}`;
                }
            }
        }

        await this.getServiceList(categories.rows);

        let title = []
        if (service) {
            const { category } = service;
            if (category.stratumId) {
                title.push(category.stratumId)
                if (category.stratum === 2) {
                    title.push(category.id)
                }
            }
        }


        await this.setState({
            ...this.state,
            service: service ? service : this.state.service,
            localImages: newLocalImages,
            localPreview: service && service.preview ? `${baseURL}/${service.preview}` : this.state.localPreview,
            mediaOption: service && service.video ? true : false,
            centers: centers.map((center) => {
                let extraFee = 0;
                let selected = false;
                if (service) {
                    for (let i = 0; i < service.centers.length; i++) {
                        if (service.centers[i].id === center.id) {
                            selected = true;
                            extraFee = service.centers[i].extraFee
                            break;
                        }
                    }
                }
                const newCenter = {
                    selected,
                    extraFee,
                    ...center,
                }

                return newCenter;
            }),
            group: title
        });
    }

    render() {
        const { t } = this.props;
        const { service, centers, options, localImages, mediaOption, video, loading, localPreview, group } = this.state;

        return (centers && centers.length > 0
            ? <div className="service-board-edit">
                <div className="service-board-main">
                    <div className="row">
                        <div className="title">
                            △{t('service_all_table_column_service_name')}
                        </div>
                        <div className="value">
                            <Input
                                value={service.service_details.name}
                                onChange={(e) => this.updateServiceDetail("name", e)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('board_service_description')}
                        </div>
                        <div className="value">
                            <Input
                                type="textarea"
                                autosize={{ minRows: 4 }}
                                value={service.service_details.description}
                                onChange={(e) => this.updateServiceDetail("description", e)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('board_precautions')}
                        </div>
                        <div className="value">
                            <Input
                                type="textarea"
                                autosize={{ minRows: 4 }}
                                value={service.service_details.note}
                                onChange={(e) => this.updateServiceDetail("note", e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="service-board-main">
                    <div className="row">
                        {/* 分店 */}
                        <div className="title">
                            △{t('service_detail_content_title_center')}
                        </div>
                        <div className="value">
                            <div className="select-box">
                                {
                                    centers.map((e, i) => {
                                        return (
                                            <div className="select-option" key={e.id}>
                                                <Checkbox checked={e.selected} onChange={() => this.updateServiceCenter(i)}>
                                                    {e.center_details[0].name}
                                                </Checkbox>
                                                <div className="space"></div>
                                                <Input disabled={!e.selected} value={e.extraFee} onChange={(val) => this.updateServiceCenter(i, val)} />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('service_detail_content_title_category')}
                        </div>
                        <div className="value">
                            {options.length > 0
                                ? <Cascader
                                    options={options}
                                    value={group}
                                    onChange={(e) => this.setState({ group: e })}
                                />
                                : null
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('service_detail_content_title_walk_in')}
                        </div>
                        <div className="value">
                            <Select onChange={(e) => this.updateService("type", e)} value={service.type}>
                                <Select.Option key="walk-in" label={t('service_detail_content_value_walk_in')} value="walk-in" />
                                <Select.Option key="book" label={t('service_detail_content_value_reservation')} value="book" />
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('service_detail_content_title_status')}
                        </div>
                        <div className="value">
                            <Select onChange={(value) => this.updateService("active", value)} value={service.active}>
                                <Select.Option key={true} label={t('service_detail_content_value_active')} value={true} />
                                <Select.Option key={false} label={t('service_detail_content_value_inactive')} value={false} />
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('service_detail_content_title_session_interval')}
                        </div>
                        <div className="value">
                            <Select onChange={(key) => this.updateService("sessionInterval", key)} className="result-limit-select" value={service.sessionInterval ? service.sessionInterval : "30"}>
                                {
                                    [15, 30, 45, 60, 90, 120, 150].map(e => {
                                        return <Select.Option key={e} label={e} value={e} />
                                    })
                                }
                            </Select>
                            <span>{t('service_detail_content_value_minutes')}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('service_detail_content_title_price')}
                        </div>
                        <div className="value">
                            HK$
                            <Input
                                value={service.price ? service.price : ""}
                                onChange={(value) => this.updateService("price", value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('service_detail_content_integral')}
                        </div>
                        <div className="value">
                            <Input
                                value={service.integral ? service.integral : ""}
                                onChange={(value) => this.updateService("integral", value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('board_service_color')}
                        </div>
                        <div className="value">
                            <ColorPicker
                                value={service.color}
                                onChange={(e) => {
                                    this.updateService("color", e)
                                }}
                            ></ColorPicker>
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="row">
                        <div className="title">
                            <Radio
                                value="images"
                                checked={!mediaOption}
                                onChange={() => this.setState({
                                    ...this.state,
                                    mediaOption: !mediaOption
                                })}
                            />
                            △{t('service_detail_content_title_images')}
                        </div>
                        <div className="value">
                            {
                                localImages.map((item, i) => {
                                    return (
                                        <div key={i} className="image-box">
                                            <img src={item} onClick={() => mediaOption ? null : this.uploadImage(i)} alt="" />
                                            <i onClick={() => mediaOption ? null : this.uploadImage(i, true)} className="el-icon-delete" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            <Radio
                                value="video"
                                checked={mediaOption}
                                onChange={() => this.setState({
                                    ...this.state,
                                    mediaOption: !mediaOption
                                })}
                            />
                            △{t('service_detail_content_title_video')}
                        </div>
                        <div className="value">
                            <div className="video-upload">
                                <Button disabled={!mediaOption}
                                    onClick={() => this.uploadVideo()}
                                    type="primary" icon="upload2"
                                >{t('service_detail_content_value_video')}</Button>
                                {service.video || video ? <div className='uploadMessage'>{t('video_uploaded')}</div> : null}
                            </div>
                            <div className="image-box">
                                <img onClick={() => !mediaOption ? null : this.uploadpreview()} src={localPreview} alt="" />
                                <i onClick={() => !mediaOption ? null : this.uploadpreview(true)} className="el-icon-delete"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="board-footer">
                    <Button onClick={() => this.submit()}>{t('button_save')}</Button>
                </div>
                {
                    loading && <Loading fullscreen={true} />
                }
            </div >
            : null
        );
    }
}