
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-edit.scss';
import moment from 'moment';
import { DatePicker, Select, InputNumber, Radio, Checkbox, Tabs, Button, Input } from 'element-react';
import { baseURL } from "../../../apiClient";
import fileDialog from "file-dialog";
import UserSearchBar from "../../user/search-bar/search-bar.component";
import UserPreviewCard from "../../user/search-bar/user-preview-card.component";
import * as notificationApi from "../../../api/notification.api";
import * as userApi from "../../../api/user.api";
import { setError } from "../../../redux/staff/error.action"
import store from '../../../redux/store';
import ImageBoard from "../../image-board.component";

@translate()
export default class NotificationEditBoard extends Component {
    state = {
        localImages: [`${process.env.PUBLIC_URL}/resources/upload.png`],
        images: null,
        notification: {},
        notificationUpdate: null,
        date: null,
        time: null,
        selectedTargetGroup: 0,
        selectedTargetUsers: [],
        targets: {
            all_users: {
                selected: false,
                value: '',
            },
            age_range: {
                selected: false,
                value: '-',
            },
            gender: {
                selected: false,
                value: '',
            },
            birthday: {
                selected: false,
                value: '',
            },
            body_condition: {
                selected: false,
                value: '',
            },
            users: {
                selected: false,
                value: '',
            },
        },
        bodyConditions: null,
        targetBodyConditions: {},
        tabs: [
            {
                id: "eng",
                language: "English",
                title: "Title:",
                content: "Content:",
            },
            {
                id: "cht",
                language: "繁體中文",
                title: "標題:",
                content: "內容:",
            },
            {
                id: "chs",
                language: "简体中文",
                title: "标题:",
                content: "内容:",
            },
        ],
        selectedTab: "0",
    }

    async uploadImage(index, remove = false) {
        const newImages = this.state.images ? { ...this.state.images } : {};
        newImages[index] = {};
        if (remove) {
            newImages[index].file = null;
        } else {
            newImages[index].file = (await fileDialog({ accept: 'image/*' }))[0];
        }
        this.setState({ images: newImages },
            () => {
                if (this.state.images[index].file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const newLocalImages = [...this.state.localImages]
                        newLocalImages[index] = e.target.result;
                        this.setState({ localImages: newLocalImages });
                    }
                    reader.readAsDataURL(this.state.images[index].file);
                } else {
                    const newLocalImages = [...this.state.localImages]
                    newLocalImages[index] = `${process.env.PUBLIC_URL}/resources/upload.png`;
                    this.setState({ localImages: newLocalImages });
                }
            }
        );
    }

    async submit() {
        try {
            const { notificationUpdate, notification, images, targets } = this.state;
            const { t } = this.props;
            const newTargets = [];
            for (const targetType of Object.keys(targets)) {
                if (targets[targetType].selected) {
                    let newTarget = {};
                    switch (targetType) {
                        case 'all_users':
                            break;
                        case 'age_range':
                            const range = targets[targetType].value.split('-').map(function (item) {
                                if (item === '') {
                                    throw new Error(t('error:please_input_field', { field: t('notification_all_target_type_age_range') }));
                                } else if (isNaN(item)) {
                                    throw new Error(t('error:field_type_number', { field: t('notification_all_target_type_age_range') }));
                                }
                                else {
                                    return parseInt(item, 10);
                                }
                            });
                            if (range[0] > range[1]) {
                                throw new Error(t('error:max_min_correct'));
                            }
                            break;
                        case 'gender':
                            if (targets[targetType].value === '') {
                                throw new Error(t('error:please_input_field', { field: t('notification_all_target_type_gender') }));
                            }
                            break;
                        case 'birthday':
                            if (targets[targetType].value === '') {
                                throw new Error(t('error:please_input_field', { field: t('notification_all_target_type_birthday') }));
                            }
                            if (isNaN(targets[targetType].value)) {
                                throw new Error(t('error:field_type_number', { field: t('notification_all_target_type_birthday') }));
                            }
                            break;
                        case 'body_condition':
                            if (targets[targetType].value === '') {
                                throw new Error(t('error:please_input_field', { field: t('notification_all_target_type_body_condition') }));
                            }
                            break;
                        case 'users':
                            if (targets[targetType].value === '') {
                                throw new Error(t('error:please_input_field', { field: t('notification_all_target_type_users') }));
                            }
                            break;
                        default:
                            break;
                    }
                    newTarget = {
                        targetType,
                        target: targets[targetType].value,
                    };
                    if (typeof newTarget !== 'undefined') {
                        newTargets.push(newTarget);
                    }
                }
            }
            let notificationId;
            if (!newTargets.length) {
                throw new Error('Please select the target(s)!');
            }
            if (notification.id) {
                notificationId = notification.id;
                if (notificationUpdate) {
                    await notificationApi.updateNotification(notification.id, notificationUpdate);
                }

            } else {
                notificationId = (await notificationApi.createNotification(notificationUpdate)).id;
            }
            if (images) {
                await notificationApi.uploadImage(notificationId, images[0].file);
            }
            await notificationApi.assignTargets(notificationId, newTargets);
            this.props.changeMode(notificationId);
        } catch (error) {
            store.dispatch(setError(error.message));
        }
    }

    updateNotification(updateObj) {
        const newNotificationUpdate = {
            ...this.state.notificationUpdate,
        };
        newNotificationUpdate.basic = {
            ...newNotificationUpdate.basic,
            ...updateObj,
        }
        const newNotification = {
            ...this.state.notification,
            ...updateObj,
        };
        this.setState({
            notificationUpdate: newNotificationUpdate,
            notification: newNotification,
        });
    }

    updateNotificationDetail(languageId, title, value) {
        const newNotificationUpdate = { ...this.state.notificationUpdate };
        newNotificationUpdate.details = newNotificationUpdate.details || [];
        let newDetail = { languageId, value: {} };
        let detailIndex = -1;
        for (let i = 0; i < newNotificationUpdate.details.length; i++) {
            const detail = newNotificationUpdate.details[i];
            if (detail.languageId === languageId) {
                newDetail = { ...detail };
                detailIndex = i;
                break;
            }
        }
        newDetail.value[title] = value;

        if (detailIndex === -1) {
            newNotificationUpdate.details.push(newDetail);
        } else {
            newNotificationUpdate.details[detailIndex] = newDetail;
        }
        const newNotification = { ...this.state.notification };
        if (!newNotification.notification_details) {
            newNotification["notification_details"] = {};
        }
        if (!newNotification.notification_details[languageId]) {
            newNotification.notification_details[languageId] = {};
        }
        newNotification.notification_details[languageId][title] = value;
        this.setState({
            notificationUpdate: newNotificationUpdate,
            notification: newNotification,
        });
    }

    setTargetRadio(value) {
        this.setState({
            selectedTargetGroup: value,
        }, () => {
            switch (value) {
                case 0:
                    this.setTargets([
                        { targetType: "all_users", selected: true },
                        { targetType: "age_range", selected: false },
                        { targetType: "gender", selected: false },
                        { targetType: "birthday", selected: false },
                        { targetType: "body_condition", selected: false },
                        { targetType: "users", selected: false },
                    ]);
                    break;
                case 1:
                    this.setTargets([
                        { targetType: "all_users", selected: false },
                        { targetType: "users", selected: false },
                    ]);
                    break;
                case 2:
                    this.setTargets([
                        { targetType: "all_users", selected: false },
                        { targetType: "age_range", selected: false },
                        { targetType: "gender", selected: false },
                        { targetType: "birthday", selected: false },
                        { targetType: "body_condition", selected: false },
                        { targetType: "users", selected: true },
                    ]);
                    break;
                default:
                    return;
            }
        });

    }

    setTargets(targets) {
        const newTargets = { ...this.state.targets };
        for (const target of targets) {
            newTargets[target.targetType].selected = target.selected;
        }
        this.setState({
            targets: newTargets,
        }, () => {
            console.log("Targets:", this.state.targets);
        });
    }

    setTargetValue(targetType, value) {
        const newTargets = { ...this.state.targets };
        newTargets[targetType].value = value;
        this.setState({
            targets: newTargets,
        },  console.log("Targets:", newTargets));
    }

    setTargetBodyCondition(bodyConditionId, selected, index) {
        const newBodyConditions = this.state.bodyConditions;
        newBodyConditions[index].selected = selected;
        const newBodyTargets = { ...this.state.targetBodyConditions };
        if (selected) {
            newBodyTargets[bodyConditionId] = '';
        } else {
            delete newBodyTargets[bodyConditionId];
        }
        const targetValue = [];
        for (const name of Object.keys(newBodyTargets)) {
            targetValue.push(name);
        }
        this.setTargetValue('body_condition', targetValue.toString());
        this.setState({
            bodyConditions: newBodyConditions,
            targetBodyConditions: newBodyTargets,
        });
    }

    getTargetDetail(index) {
        const { t } = this.props;
        const { targets, bodyConditions, selectedTargetUsers } = this.state;
        const { age_range, gender, birthday, body_condition } = targets;
        switch (index) {
            case 0:
                return;
            case 1:
                return (
                    <div className="target-group">
                        <div className="target-group-input">
                            <Checkbox checked={age_range.selected} onChange={(value) => this.setTargets([
                                { targetType: "age_range", selected: value },
                            ])}>
                                {t('notification_detail_target_age_range')}
                            </Checkbox>
                            {age_range.selected ?
                                <div>
                                    <input value={age_range.value.split('-')[0]} onChange={(e) => {
                                        this.setTargetValue('age_range', `${e.target.value}-${age_range.value && age_range.value.split('-')[1] ? age_range.value.split('-')[1] : ''}`)
                                    }} />
                                    -
                                    <input value={age_range.value.split('-')[1]} onChange={(e) => {
                                        this.setTargetValue('age_range', `${age_range.value && age_range.value.split('-')[0] ? age_range.value.split('-')[0] : ''}-${e.target.value}`)
                                    }} />
                                </div> : null}
                        </div>
                        <div className="target-group-input">
                            <Checkbox checked={gender.selected} onChange={(value) => this.setTargets([
                                { targetType: "gender", selected: value },
                            ])}>
                                {t('notification_detail_target_gender')}
                            </Checkbox>
                            {gender.selected ?
                                <div>
                                    <Radio.Group value={gender.value} onChange={(value) => this.setTargetValue('gender', value)}>
                                        <Radio value='male'>{t('notification_detail_gender_male')}</Radio>
                                        <Radio value='female'>{t('notification_detail_gender_female')}</Radio>
                                    </Radio.Group></div> : null}
                        </div>
                        <div className="target-group-input">
                            <Checkbox checked={birthday.selected} onChange={(value) => this.setTargets([
                                { targetType: "birthday", selected: value },
                            ])}>
                                {t('notification_detail_target_birthday')}
                            </Checkbox>
                            {birthday.selected ?
                                <div className="birthday-select">
                                    {/* <div>
                                        {t('notification_detail_birthday_message')}
                                        <input value={birthday.value} onChange={(e) => this.setTargetValue('birthday', e.target.value)} />
                                        {t('notification_detail_birthday_message_days')}
                                    </div> */}
                                    <Select onChange={(value) => this.setTargetValue('birthday', value)} value={birthday.value}>
                                        <Select.Option key={1} label={t('notification_birthday_january')} value={"1"} />
                                        <Select.Option key={2} label={t('notification_birthday_february')} value={"2"} />
                                        <Select.Option key={3} label={t('notification_birthday_march')} value={"3"} />
                                        <Select.Option key={4} label={t('notification_birthday_april')} value={"4"} />
                                        <Select.Option key={5} label={t('notification_birthday_may')} value={"5"} />
                                        <Select.Option key={6} label={t('notification_birthday_june')} value={"6"} />
                                        <Select.Option key={7} label={t('notification_birthday_july')} value={"7"} />
                                        <Select.Option key={8} label={t('notification_birthday_august')} value={"8"} />
                                        <Select.Option key={9} label={t('notification_birthday_september')} value={"9"} />
                                        <Select.Option key={10} label={t('notification_birthday_october')} value={"10"} />
                                        <Select.Option key={11} label={t('notification_birthday_november')} value={"11"} />
                                        <Select.Option key={12} label={t('notification_birthday_december')} value={"12"} />
                                    </Select>
                                </div> : null}
                        </div>
                        <div className="target-group-input">
                            <Checkbox checked={body_condition.selected} onChange={(value) => this.setTargets([
                                { targetType: "body_condition", selected: value },
                            ])}>
                                {t('notification_detail_target_body_condition')}
                            </Checkbox>
                            {body_condition.selected ?
                                <div className="select-box">
                                    {
                                        bodyConditions.map((bodyCondition, i) => {
                                            return (
                                                <div className="select-option" key={bodyCondition.id}>
                                                    <Checkbox checked={bodyCondition.selected} onChange={(value) => this.setTargetBodyCondition(bodyCondition.id, value, i)}>
                                                        {bodyCondition.body_condition_details[0].name}
                                                    </Checkbox>
                                                </div>
                                            );
                                        })
                                    }
                                </div> : null}
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className="user-info">
                        <UserSearchBar className="search-bar" onUserSelected={(user) => {
                            this.addUser(user);
                        }} />
                        {selectedTargetUsers ? <div className="user-row">{selectedTargetUsers.map((user) => {
                            return <div className={"user-data"} key={user.id}>
                                <Button onClick={() => this.removeUser(user)}>X</Button>
                                <UserPreviewCard
                                    user={user}
                                /></div>
                        })}
                        </div> : null}
                    </div>
                )
            default:
                return null;
        }
    }

    removeUser(user) {
        const targetUsers = this.state.selectedTargetUsers;
        for (let i = 0; i < targetUsers.length; i += 1) {
            if (user.id === targetUsers[i].id) {
                targetUsers.splice(i, 1);
            }
        }
        const targetUserIds = [];
        for (const user of targetUsers) {
            targetUserIds.push(user.id);
        }
        this.setState({
            selectedTargetUsers: targetUsers,
        });
        this.setTargetValue('users', targetUserIds.toString());
    }

    addUser(user) {
        const targetUsers = this.state.selectedTargetUsers;
        console.log('targetUsers', targetUsers);
        for (const targetUser of targetUsers) {
            if (user.id === targetUser.id) {
                return;
            }
        }
        targetUsers.push(user);
        const targetUserIds = [];
        for (const user of targetUsers) {
            targetUserIds.push(user.id);
        }
        this.setState({
            selectedTargetUsers: targetUsers,
        });
        this.setTargetValue('users', targetUserIds.toString());
    }
    async componentDidMount() {
        const { notification, bodyConditions } = this.props;
        let bodyTarget = null;
        const newTargets = { ...this.state.targets };
        let selectedTargetGroup = null;
        let selectedTargetUsers = [];
        let newTargetBodyConditions = { ...this.state.targetBodyConditions };
        if (notification) {
            for (const target of notification.notification_targets) {
                switch (target.targetType) {
                    case 'age_range':
                        newTargets.age_range.selected = true;
                        newTargets.age_range.value = target.target;
                        selectedTargetGroup = 1;
                        break;
                    case 'gender':
                        newTargets.gender.selected = true;
                        newTargets.gender.value = target.target;
                        selectedTargetGroup = 1;
                        break;
                    case 'birthday':
                        newTargets.birthday.selected = true;
                        newTargets.birthday.value = target.target;
                        selectedTargetGroup = 1;
                        break;
                    case 'body_condition':
                        bodyTarget = target;
                        newTargets.body_condition.selected = true;
                        newTargets.body_condition.value = target.target;
                        const newBodyIds = target.target.split(',');
                        for (const newBodyId of newBodyIds) {
                            newTargetBodyConditions[newBodyId] = '';
                        }
                        selectedTargetGroup = 1;
                        break;
                    case 'users':
                        newTargets.users.selected = true;
                        newTargets.users.value = target.target;
                        selectedTargetGroup = 2;
                        selectedTargetUsers = (await userApi.getUsers(1, 'id', 'ASC', 20, '', target.target.split(','))).rows;
                        break;
                    default:
                        newTargets.all_users.selected = true;
                        selectedTargetGroup = 0;
                        break;
                }
            }
        }
        this.setState({
            selectedTargetUsers,
            selectedTargetGroup,
            target: newTargets,
            targetBodyConditions: newTargetBodyConditions,
            bodyConditions: bodyConditions.map((bodyCondition) => {
                let selected = false;
                if (bodyTarget) {
                    const bodyTargetIds = bodyTarget.target.split(',');
                    for (let i = 0; i < bodyTargetIds.length; i++) {
                        if (bodyCondition.id === bodyTargetIds[i]) {
                            selected = true;
                            break;
                        }
                    }
                }
                const newBodyCondition = {
                    selected,
                    ...bodyCondition,
                }
                return newBodyCondition;

            }),
            notification: notification ? notification : {},
            localImages: [notification && notification.slide ? `${baseURL}/${notification.slide}` : `${process.env.PUBLIC_URL}/resources/upload.png`],
        }, () => {
            console.log("user:", selectedTargetUsers);
        });
    }

    render() {
        const { t } = this.props;
        const { tabs, selectedTab, notification, localImages, selectedTargetGroup } = this.state;
        return (
            <div className="notification-board-edit">
                <div className="tab">
                    <Tabs activeName={selectedTab} onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })}>
                        {
                            tabs.map((tab, i) => {
                                return (
                                    <Tabs.Pane key={i} label={tabs[i].language} name={i.toString()}>
                                        <div className="block">
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].title}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        value={notification.notification_details && notification.notification_details[tabs[i].id] && notification.notification_details[tabs[i].id].title ? notification.notification_details[tabs[i].id].title : ""}
                                                        onChange={(value) => this.updateNotificationDetail(tabs[i].id, "title", value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].content}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        type="textarea"
                                                        autosize={{ minRows: 5 }}
                                                        value={notification.notification_details && notification.notification_details[tabs[i].id] && notification.notification_details[tabs[i].id].content ? notification.notification_details[tabs[i].id].content : ""}
                                                        onChange={(value) => this.updateNotificationDetail(tabs[i].id, "content", value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Tabs.Pane>
                                )
                            })
                        }
                    </Tabs>
                </div>
                <div className="row">
                    <div className="title">
                        {t('notification_detail_content_title_push_time')}
                    </div>
                    <div className="value">
                        <DatePicker
                            isShowTime={true}
                            value={notification.pushTime ? moment(notification.pushTime).toDate() : null}
                            placeholder={t('notification_detail_content_push_time_place_holder')}
                            onChange={(date) => {
                                console.log(date);
                                this.updateNotification({ pushTime: date ? date : moment() })
                            }}
                            disabledDate={time => time.getTime() < Date.now() - 8.64e7}
                        />
                    </div>
                </div>
                {notification.id ?
                    <div className="row">
                        <div className="title">
                            {t('notification_detail_content_title_status')}
                        </div>
                        <div className="value">
                            <Select onChange={(value) => this.updateNotification({ pushed: value })} value={notification.pushed}>
                                <Select.Option key={true} label={t('notification_all_status_pushed')} value={true} />
                                <Select.Option key={false} label={t('notification_all_status_un_sent')} value={false} />
                            </Select>
                        </div>
                    </div> : null
                }

                <div className="row">
                    <div className="title">
                        {t('notification_detail_content_title_repeat')}
                    </div>
                    <div className="value">
                        <InputNumber
                            defaultValue={0}
                            value={notification.repeat || 0}
                            onChange={(value) => value ? this.updateNotification({ repeat: value }) : null}
                            min="0"
                        />{t('notification_detail_content_repeat_per_day')}
                    </div>
                </div>
                <ImageBoard edit={true} localImages={localImages} uploadImage={(index, remove) => this.uploadImage(index, remove)} />
                <div className="block">
                    <div className="row">
                        <div className="title">
                            {t('notification_detail_content_title_target')}
                        </div>
                        <div className="value">
                            <Radio.Group value={selectedTargetGroup} onChange={(value) => this.setTargetRadio(value)}>
                                <Radio value={0}>{t('notification_detail_radio_all_users')}</Radio>
                                <Radio value={1}>{t('notification_detail_radio_group')}</Radio>
                                <Radio value={2}>{t('notification_detail_radio_users')}</Radio>
                            </Radio.Group>
                        </div>

                    </div>
                    {selectedTargetGroup ? <div className="row">
                        <div className="title">
                        </div>
                        <div className="value">
                            {
                                this.getTargetDetail(selectedTargetGroup)
                            }
                        </div>
                    </div> : null}
                </div>
                <div className="board-footer">
                    <Button onClick={() => this.submit()}>{t(notification.id ? 'button_save' : 'button_create')}</Button>
                </div>
            </div>
        );
    }
}