import React, { Component } from 'react';
import './booking-overview.scss';
import { DayView, Event } from '../../../components/day-view';
import * as reservationApi from "../../../api/reservation.api";
import * as serviceApi from "../../../api/service.api";
import { Button, Select, Switch, Dialog, Form, Loading, Input,TimeSelect,Cascader,MessageBox } from "element-react";
import * as centerApi from "../../../api/center.api";
import moment from 'moment';
import { translate } from 'react-i18next';
import withRouter from "react-router-dom/es/withRouter";
import * as staffApi from "../../../api/staff.api";
import { mergeDetails,ServiceTree } from "../../../utils/dataTransform";
import DatePickerButton from "../../../components/date-picker-button.component";
import BookingInform from "../../../components/user/booking-inform/booking-inform.component";
import Timeline, { TimelineMarkers, TodayMarker } from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import classNames from "classnames";
import FaPhone from 'react-icons/lib/fa/phone';

const VIEW_TYPE_TIMELINE = 'timeline';
const VIEW_TYPE_SCHEDULE = 'schedule';

const defaultAddBookingForm = () => ({
    client: null,
    center: null,
    centerId: '',
    centerName: '',
    service: null,
    from: undefined,
    to: undefined,
    date: new Date(),
    days: [],
    errorMessage: '',
    currentView: undefined
});

@withRouter
@translate()
export default class extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.nodeLoading = document.createElement("div");
        this.nodeLoading.style.width = "100px";
        this.nodeLoading.style.height = "100px";
        this.nodeLoading.style.background = "white";
        this.nodeLoading.style.position = "absolute";
        this.nodeLoading.style.zIndex = "10000";
    }

    nodeLoading = null;
    nodeApp = null;
    nodeRoot = null;

    state = {
        reservations: [],
        centers: [],
        services: [],
        staffs: [],
        center: '',
        service: [],
        staff: '',
        day: moment().toDate(),
        showBookingInform: false,
        selectedBooking: null,
        groups: [],
        minTime: moment().startOf('day').add(10, 'hours').toDate(),
        maxTime: moment().startOf('day').add(21, 'hours').toDate(),
        scheduleHourHeight: 120,
        scheduleWidth: 140,
        viewType: VIEW_TYPE_SCHEDULE,
        addBooking: false,
        addBookingForm: defaultAddBookingForm(),
        centerList: [],
        change: 1,
        calendarLoading: true,
        startDate:null,
        endDate:null,
        intentionalService: [],
        prop: {
            value: 'value',
            label: "name"
        },
    };

    onAddBookingClick() {
        this.props.history.push("/main/booking-overview/add");
        // this.setState({
        //     addBookingForm: {
        //         ...this.state.addBookingForm,
        //         ["center"]: this.state.center
        //     },
        //     addBooking: true,
        // });
        // console.log(this.state.addBookingForm)
    }

    cancelAddBooking() {
        this.setState({
            addBooking: false,
        });
    }

    setAddBookingForm(key, value, callback) {
        this.setState({
            addBookingForm: {
                ...this.state.addBookingForm,
                [key]: value,
            }
        }, () => {
            callback && callback();
        })
    }

    async componentDidMount() {
        console.log(this.state.prop);
        let [
            services,
            centers,
            staffs
        ] = await Promise.all([
            serviceApi.getServices(1, 'id', 'ASC', 1000, ''),
            centerApi.getCenters(),
            staffApi.getStaffs(1, null, null, 1000, '', 'working'),
        ]);

        staffs = staffs.rows.map(staff => mergeDetails(staff, 'staff')).filter(staff => staff.type === 'therapist');
        const groups = staffs.map((staff) => {
            return {
                id: staff.id,
                title: staff.surname + ' ' + staff.name
            }
        });

        let servicesFilter = services.rows.filter(item=>item.id)

        this.setAndRefresh({
            services: servicesFilter,
            centers,
            center: centers[0] ? centers[0] : null,
            staffs,
            groups,
        });

        if (this.myRef && this.myRef.current) {
            this.nodeApp = this.myRef.current.parentElement.parentElement.parentElement.parentElement;
            this.nodeRoot = this.myRef.current.parentElement.parentElement.parentElement.parentElement.parentElement;
        }
        await this.getServiceList();
    }

    getMonday(date){
        var day=date.getDay();
        var deltaDay;
        if (day==0){
            deltaDay=6;
        } else{
            deltaDay=day-1;
        }
        var monday =new Date(date.getTime()-deltaDay*24*60*60*1000);
        monday.setHours(0);
        monday.setMinutes(0);
        monday.setSeconds(0);
        return monday;  //返回本周的周一的0时0分0秒
    }
    getLastDay(year, month) {
        return new Date(new Date(`${month<12?year:++year}-${month==12?1:++month} 00:00`).getTime() - 1).getDate()
    }

    handleTimeChange(type,value){
        this.setState( Object.assign({}, this.state, { [type]: value }),()=>{
            if(this.state.startDate && this.state.endDate) {
                this.refreshData()
            }else if(!this.state.startDate && !this.state.endDate) {
                this.refreshData()
            }
        });
    }

    async getServiceList() {
        await serviceApi.getServiceList().then(async result => {
            let data = await ServiceTree(result.rows)
            console.log(data);
            this.setState({ intentionalService: data })
        })
        .catch(error => {
            Message.error(error.userMessage);
        })
    }

    getYMD(day){
        let year = new Date(day).getFullYear()
        let month = new Date(day).getMonth()+1
        let date = new Date(day).getDate()
        return year + "-" + month + "-" + date
    }

    async refreshData(view) {

        console.log(this.state.startDate,this.state.endDate)

        this.setState({ calendarLoading: true });
        // this.showRootLoading();
        if (!view) {
            view = this.state.currentView;
        }
        else {
            this.setState({ currentView: view });
        }
        

        let startTime = '00:00:00'
        let endTime = '23:59:59'

        if(this.state.startDate && this.state.endDate) {
            let start = new Date(+new Date(this.state.startDate) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            let end = new Date(+new Date(this.state.endDate) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            startTime = start.replace(/\d{4}-\d{1,2}-\d{1,2}/,'')
            endTime = end.replace(/\d{4}-\d{1,2}-\d{1,2}/,'')
        }

        var start, end, day = this.state.day;
        let year = new Date(day).getFullYear()
        let month = new Date(day).getMonth()+1
        let date = new Date(day).getDate()
        let getDay =  year + "-" + month + "-" + date
        if (view === 'month') {
            let lastDay =  this.getLastDay(year,month)
            let ymd = year + "-" + month + "-" + "01"
            let emd = year + "-" + month + "-" + lastDay
            start =  ymd+" "+startTime ;
            end =  emd+" "+endTime;
        }
        else if (view === 'week') {
            let startDay = this.getMonday(day)
            startDay.setDate(startDay.getDate()-1)
            let startYMD = this.getYMD(startDay)
            start = startYMD +" "+startTime ;
            let endDay = this.getMonday(day)
            endDay.setDate(endDay.getDate()+6)
            let endYMD =this.getYMD(endDay)
            end = endYMD+" "+endTime 
        }
        else {
            start = getDay + " "+startTime ;
            end = getDay + " "+endTime;
        }
        
        const reservations = await reservationApi.getDayReservations(
            moment(start,'YYYY-MM-DD HH:mm:ss').toISOString(),
            moment(end,'YYYY-MM-DD HH:mm:ss').toISOString(),
            {
                centerId: this.state.center ? this.state.center.id : undefined,
                serviceId: this.state.service || undefined,
                staffId: this.state.staff || undefined,
                orderBy:'startTime',
                direction:'DESC'
            }
        );
        this.setState({
            reservations,
            addBookingForm: {
                ...this.state.addBookingForm,
                ["center"]: this.state.center,
                ["centerName"]: this.state.center ? this.state.center.center_details[0].name : ''
            },
            calendarLoading: false
        });
    }

    async setDayAndRefresh(day) {
        await this.setAndRefresh({
            day,
            minTime: moment(day).startOf('day').add(10, 'hours').valueOf(),
            maxTime: moment(day).startOf('day').add(21, 'hours').valueOf()
        });
    }

    async setAndRefresh(changes) {
        await new Promise(res => {
            this.setState(changes, res);
        });
        await this.refreshData();
    }

    timeLimit = (visibleTimeStart, visibleTimeEnd) => {
        const minTime = moment(this.state.day).startOf('day').valueOf();
        const maxTime = moment(this.state.day).endOf('day').valueOf();

        const updateScrollCanvas = (min, max) => {
            this.setState({
                minTime: min,
                maxTime: max,
            })
        };

        if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
            updateScrollCanvas(minTime, maxTime)
        } else if (visibleTimeStart < minTime) {
            updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
        } else if (visibleTimeEnd > maxTime) {
            updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
        } else {
            updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
        }
    };

    itemRenderer = ({ item, selected, timelineContext }) => {
        const reservation = item.reservation;
        const schedule = reservation.staff_schedule;
        const user = reservation.user;
        return (
            <div onClick={() => {
                this.setState({
                    showBookingInform: true,
                    selectedBooking: reservation,
                });
            }}
            >
                <span>
                    {reservation.service.service_details[0].name},&nbsp;
                    {`${reservation.room.title}${reservation.room.active ? "" : "(Inactive)"}`},&nbsp;
                    {/* moment(schedule.startTime).format('HH:mm')} - {moment(schedule.endTime).format('HH:mm') */}&nbsp;
                    {moment(reservation.startTime).format('HH:mm')} - {moment(reservation.endTime).format('HH:mm')}&nbsp;
                </span>
                {/* {user.remind ?
                    <span className="remind">
                        <FaPhone />
                    </span> : null} */}
            </div>
        )
    };

    ChildrenChange = (event) => {
        if(event && event.rest == 'yes') return  
        this.setState({
            showBookingInform: true,
            selectedBooking: event
        })
    }

    showRootLoading() {
        this.nodeLoading.style.width = this.nodeApp.clientWidth + "px";
        this.nodeLoading.style.height = this.nodeApp.clientHeight + "px";
        this.nodeRoot.appendChild(this.nodeLoading);
        this.nodeRoot.appendChild(this.nodeApp);
    }

    hideRootLoading() {
        this.nodeRoot.removeChild(this.nodeLoading);
    }

    render() {
        const { t } = this.props;
        const { scheduleWidth, scheduleHourHeight, reservations, calendarLoading, viewType, day, center, centers, service, services, staffs, showBookingInform, selectedBooking, groups, minTime, maxTime, staff, addBooking, addBookingForm, centerList } = this.state;
        const defaultTime = { start: moment().add(-6, 'hour').valueOf(), end: moment().add(6, 'hour').valueOf() };

        return (
            <div className="booking-overview-page" ref={this.myRef}>
                <div className="page-title">{t('booking_overview_title')}</div>
                <div className="control-bar">
                    <div className="filtering-controls" style={{ flex: "unset" }}>
                        <div className="row">
                            <span>{t('booking_overview_sub_title_center')}</span>
                            {center
                                ? (<Select value={center.id}
                                        onChange={(center) => this.setAndRefresh({ center })}>
                                        {centers.map(center => (
                                            <Select.Option key={center.id} label={center.center_details[0].name} value={center.id} />
                                        ))}
                                    </Select>)
                                : (<Select value={center} disabled={true}></Select>)
                            }
                        </div>
                        <div className="row">
                            <span>{t('booking_overview_sub_title_service')}</span>
                            {/* <Select value={service} */}
                            {/*     onChange={(service) => this.setAndRefresh({ service })}> */}
                            {/*     <Select.Option label={t('booking_overview_option_all')} value={''} /> */}
                            {/*     {services.map(service => ( */}
                            {/*         <Select.Option key={service.id} label={service.name} value={service.id} /> */}
                            {/*     ))} */}
                            {/* </Select> */}
                            <Cascader
                                        value={service}
                                        placeholder={t('please_choose')}
                                        props={this.state.prop}
                                        options={this.state.intentionalService}
                                        filterable={true}
                                        onChange={(service) => this.setAndRefresh({ service })}
                                    ></Cascader>
                        </div>
                        <div className="row">
                            <span>{t('booking_overview_sub_title_therapist')}</span>
                            <Select value={staff} onChange={(staff) => this.setAndRefresh({ staff })}>
                                <Select.Option label={t('booking_overview_option_all')} value={''} />
                                {staffs.map(staff => (
                                    <Select.Option key={staff.id} label={staff.name} value={staff.id} />
                                ))}
                            </Select>
                        </div>
                        <div className="row">
                            <span>時間段 :</span>
                            <div>
                                <TimeSelect clear start="08:00" step="00:30"  end="22:30" value={this.state.startDate} onChange={this.handleTimeChange.bind(this,'startDate')}  placeholder="选择时间"/>
                                -
                                <TimeSelect start="08:00" step="00:30" end="22:30" value={this.state.endDate} onChange={this.handleTimeChange.bind(this,'endDate')} minTime={this.state.startDate} placeholder="选择时间"/>
                            </div>
                        </div>
                    </div>
                    <div className="date" style={{ flex: "unset", width: "100%" }}>
                        <DatePickerButton
                            style={{ border: 'none' }}
                            value={day}
                            placeholder="Select Date"
                            onChange={date => {
                                if (!date) {
                                    this.setDayAndRefresh(new Date());
                                } else {
                                    this.setDayAndRefresh(date);
                                }
                            }}
                        >
                            <div style={{ flex: 1, fontSize: '1.4em', textAlign: 'right' }}>
                                {moment(day).format('MMMM DD, YYYY')}
                            </div>
                        </DatePickerButton>
                    </div>
                    <div className="action-controls" style={{ flex: "unset", width: "100%" }}>
                        <div className="timeline-control">
                            <span style={{ marginRight: 8 }}>{t('timeline')}</span>
                            <Switch
                                value={viewType === VIEW_TYPE_TIMELINE}
                                onChange={(v) => this.setState({ viewType: v ? VIEW_TYPE_TIMELINE : VIEW_TYPE_SCHEDULE })}>
                            </Switch>
                        </div>
                        <Button onClick={() => this.onAddBookingClick()}>{t('booking_overview_button_add_booking')}</Button>
                    </div>
                </div>

                <Loading className={'calendar-loading'}
                    loading={calendarLoading}>
                    <div className="day-view-container">
                        {
                            viewType === VIEW_TYPE_TIMELINE ?
                                <Timeline
                                    groups={groups.filter(g => g.id === staff || staff === '')}
                                    itemRenderer={this.itemRenderer}
                                    lineHeight={30}
                                    itemHeightRatio={0.7}
                                    items={reservations.map(resv => {
                                        const schedule = resv.staff_schedule;
                                        return {
                                            id: resv.id,
                                            group: resv.staff_schedule.staffId,
                                            title: resv.service.service_details[0].name,
                                            start_time: moment(resv.startTime),
                                            end_time: moment(resv.endTime),
                                            canMove: false,
                                            canResize: false,
                                            canChangeGroup: false,
                                            reservation: resv,
                                            // className: classNames('custom-item', { warning: !resv.room.active || resv.staff_schedule.staff.status !== "working", remind: resv.user.remind })
                                        }
                                    })}
                                    defaultTimeStart={moment(defaultTime.start)}
                                    defaultTimeEnd={moment(defaultTime.end)}
                                    visibleTimeStart={typeof minTime.getTime === 'function' ? minTime.getTime() : 0}
                                    visibleTimeEnd={typeof maxTime.getTime === 'function' ? maxTime.getTime() : 0}
                                    // onItemClick={(id, e, time) => {
                                    //     const item = this.state.reservations.find(x => x.id === id);
                                    //     if (item) {
                                    //         this.setState({
                                    //             showBookingInform: true,
                                    //             selectedBooking: item,
                                    //         });
                                    //     }
                                    // }}
                                    traditionalZoom={true}
                                    onTimeChange={this.timeLimit}

                                >
                                    <TimelineMarkers>
                                        <TodayMarker interval={2000}>
                                            {({ styles }) => <div style={{ ...styles, backgroundColor: 'red' }} />}
                                        </TodayMarker>
                                    </TimelineMarkers>
                                </Timeline> :
                                <div style={{position:"relative"}}>
                                    <div style={{position:"absolute",right:0}}>
                                        <div>
                                            <TimeSelect clear start="08:00" step="00:30"  end="22:30" value={this.state.startDate} onChange={this.handleTimeChange.bind(this,'startDate')}  placeholder={t('select_time')}/>
                                            -
                                            <TimeSelect start="08:00" step="00:30" end="22:30" value={this.state.endDate} onChange={this.handleTimeChange.bind(this,'endDate')} minTime={this.state.startDate} placeholder={t('select_time')}/>
                                        </div>
                                    </div>
                                    <DayView
                                        hourHeight={scheduleHourHeight}
                                        scheduleWidth={scheduleWidth}
                                        groups={groups.filter(g => g.id === staff || staff === '')}
                                        day={day}
                                        showBookingInform={showBookingInform}
                                        selectedBooking={selectedBooking}
                                        refreshData={this.refreshData.bind(this)}
                                        minTime={8 * 3600000}
                                        maxTime={23 * 3600000 - 1}
                                        >
                                        {reservations.map(resv => (
                                            <Event
                                                key={resv.id}
                                                reservation={resv}
                                                ChildChange={this.ChildrenChange}
                                            />
                                        ))}
                                    </DayView>
                                </div>
                        }
                    </div>
                </Loading>
                {
                    showBookingInform? <BookingInform booking={selectedBooking} visible={showBookingInform} onCancel={() => this.setState({ showBookingInform: false }, () => this.refreshData())} />:null
                }
                <Dialog
                    title={t('staff_schedule_dialog_title')}
                    visible={addBooking}
                    onCancel={() => this.cancelAddBooking()}
                >
                    <Dialog.Body>
                        <Form model={addBookingForm}>
                            <Form.Item label={t('staff_schedule_dialog_center')} labelWidth="120">
                                <Select
                                    value={addBookingForm.centerId}
                                    onChange={(value) => this.setAddBookingForm('centerId', value)}
                                >
                                    {
                                        centerList.map(center => (
                                            <Select.Option
                                                key={center.id}
                                                label={center.name}
                                                value={center.id}
                                            />
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                        </Form>
                    </Dialog.Body>
                    <Dialog.Footer>
                        <Button onClick={() => this.finishAddSchedule()}>{t('staff_schedule_dialog_add')}</Button>
                        <Button onClick={() => this.cancelAddBooking()}>{t('staff_schedule_dialog_cancel')}</Button>
                    </Dialog.Footer>
                </Dialog>
            </div >
        );
    }
}