import React, { Component } from 'react';
import './category-detail.scss';
import { translate } from 'react-i18next';
import * as categoryApi from "../../../../api/category.api";
import { Button } from 'element-react';
import { withRouter } from 'react-router-dom';
import CategoryViewBoard from "../../../../components/category/board-view/board-view.component";
import CategoryEditBoard from "../../../../components/category/board-edit/board-edit.component";
import history from '../../../../history';

@withRouter
@translate()
export default class CategoryDetailPage extends Component {
    state = {
        edit: false,
        category: {},
        bool: false
    }
    // render()之后执行的生命周期
    async componentDidMount() {
        if (this.props.match.params.categoryId !== "create") {
            // 分类详情
            await this.getData(this.props.match.params.categoryId);
        } else {
            // 新增分类
            this.setState({
                ...this.state,
                edit: true
            });
        }
    }

    async getData(id) {
        const res = await categoryApi.getCategory(id);//分类详情
        const newData = { ...res };
        this.setState({
            ...this.state,
            category: newData,
            bool: true
        });
    }

    render() {
        const { t } = this.props;
        const { category, edit, bool } = this.state;
        return (
            <div className="category-detail-page">
                <div className="page-title">
                    {t(edit
                        ? (category ? 'category_detail_title_edit' : 'category_detail_title_create')
                        : 'category_detail_title_view')
                    }
                </div>
                <div className="page-back">
                    <Button onClick={() => history.goBack()}>{t('add_booking_button_back')}</Button>
                </div>
                <div className="page-body">
                    {edit
                        ? <CategoryEditBoard />
                        : (bool ? <CategoryViewBoard category={category} /> : null)
                    }
                </div>
            </div>
        );
    }
}