import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Form, Input, Layout, DateRangePicker, Button, Loading, Message, Cascader, Table, Select } from 'element-react';
import "./expend-report.scss"
import * as reportApi from "../../../api/report.api";
import * as serviceApi from "../../../api/service.api";
import * as userApi from "../../../api/user.api";
import { ServiceTree } from "../../../utils/dataTransform";
import { isJSON } from '../../../utils/isJSON'
import moment from 'moment'

@translate()
export default class ExpendReportPage extends Component {
    state = {
        search:{
            date:[new Date(),new Date()],
            intentionalService:[],
            firstConsumeService:[]
        },
        loading:false,
        exportVisiabled:false,
        datas:[],
        services:[],
        prop: {
            value: 'value',
            label: "name"
        },
        recharge:{
            topUp : 0,
            deduct: 0,
            total: 0
        },
        intergal:{
            total:0,
            deduct:0,
        },
        packages:{
            total:0,
            remnant:0
        },
        title:'',
        columns: [
            {
                label:"縱覽",
                render: (row) => {
                    const { title } = this.state
                    if(title != row.stratumTitle) {
                        this.state.title = row.stratumTitle
                        return <div>{row.stratumTitle}</div> 
                    } else {
                        return <div></div> 
                    }
                }
            },
            {
                label:"分類",
                prop:'title',
            },
            {
                label:"點數消費",
                prop:"deduct",
                render:(row)=>{
                    return <div>{row.deduct || 0}</div>
                }
            },
            {
                label:"積分消費",
                prop:"intergalDeduct",
                render:(row)=>{
                    return <div>{row.intergalDeduct || 0}</div>
                }
            }
        ],
        customerClass:[]
    }

    onChange(key, value) {
        this.setState({
            search: Object.assign({}, this.state.search, { [key]: value })
        });
    }

    getData() {
        const { search } = this.state
        let query = {...search}

        if (search.date && search.date.length > 0) {
            let startTime = moment(search.date[0]).startOf('day').format('YYYY-MM-DD HH:mm')
            let endTime = moment(search.date[1]).endOf('day').format('YYYY-MM-DD HH:mm')
            query.startTime = startTime
            query.endTime = endTime
        }

        if (search.firstConsumeService.length > 0) {
            let length = search.firstConsumeService.length
            query.firstConsumeService = search.firstConsumeService[length-1]
        }

        Object.keys(query).forEach((item) => {
            const key = query[item];
            if (key === "" || key === null || key === undefined || key.length == 0) {
                delete query[item];
            }
        });

        delete query['date']

        this.setState({
            loading:true
        })

        reportApi.getExpendExprot(query).then(result => {
            const {data:{intergal , packages, recharge,total}} = result

            let topUp = 0
            let deduct = 0
            let datasMaps = {}

            recharge.forEach(item => {
                datasMaps[item.id] = datasMaps[item.id] || {
                    id:item.id,
                    title:item.title,
                    datas:[]
                }

                item.datas.forEach(e => {
                    datasMaps[item.id].datas.push({
                        id:e.id,
                        title:e.title,
                        stratumTitle:item.title,
                        stratumId:e.stratumId,
                        deduct:e.total || 0
                    })
                })
    

                if (item.id == 1) {
                    topUp += item.datas.reduce((previousValue, currentValue) => {
                        console.log('currentValue',currentValue)
                        return previousValue + parseFloat(currentValue.total || 0) 
                     },0)
                }else {
                    deduct += item.datas.reduce((previousValue, currentValue) => {
                        return previousValue + parseFloat(currentValue.total || 0) 
                    },0)
                }
            })

            let intergalDeduct = 0

            intergal.forEach(item => {
                if ( datasMaps[item.id] ) {
                    item.datas.forEach(e => {
                        datasMaps[item.id].datas.forEach(data => {
                            if (e.id == data.id) {
                                data.intergalDeduct = e.total
                            }
                        })
                    })
                } else {
                    datasMaps[item.id] = datasMaps[item.id] || {
                        id:item.id,
                        title:item.title,
                        datas:[]
                    }
                    item.datas.forEach(e => {
                        datasMaps[item.id].datas.push({
                            id:e.id,
                            title:e.title,
                            stratumTitle:item.title,
                            stratumId:e.stratumId,
                            intergalDeduct:e.total
                        })
                    })
                }


                intergalDeduct += item.datas.reduce((previousValue, currentValue) => {
                    return previousValue + parseFloat(currentValue.total || 0) 
                },0)
            })


            let remnant = 0 

            let packagTotal = packages.reduce((previousValue, currentValue) => {
                let results = isJSON(currentValue.extra)
                let datas = results  ? JSON.parse(currentValue.extra)  : currentValue.extra
                let newDatas = isJSON(datas) ? JSON.parse(datas) : datas

                newDatas.forEach(item => {
                    remnant +=  parseInt(item.overTimes) * parseFloat(item.price) 
                })

                return previousValue + parseFloat(currentValue.price || 0) 
            },0)

            let newData = [].concat(...Object.keys(datasMaps).map(item => datasMaps[item].datas))

            this.setState({
                recharge:{
                    topUp,
                    deduct,
                    total:total.balanceDatas || 0
                },
                intergal:{ 
                    total:total.intergalTotal || 0,
                    deduct:intergalDeduct
                },
                packages:{
                    total:packagTotal,
                    remnant
                },
                datas:newData.filter(item => item.id != 2 && item.title != 'OFF')
            })
        }).catch(error => {
            this.setState({
                loading:false
            })
        }).finally(() => {
            this.setState({
                loading:false
            })
        })
    }

    async getServiceList() {
        await serviceApi.getServiceList().then(async result => {
            let data = await ServiceTree(result.rows)
            this.setState({ services: data })
        })
        .catch(error => {
            Message.error(error.userMessage);
        })
    }

    async getVipList() {
        await userApi.getVipList().then((result) => {
            const data = result.map(item => {
                return {
                    value: item.id,
                    label: item.level
                }
            })
            this.setState({ customerClass: data })
        }).catch(error => {
            Message.error(error.userMessage);
        });
    }

    exportExcel(query){
        if (query.length == 0) return
        const {recharge,intergal,packages} = this.state
        reportApi.expendExecl({datas:query,total:{recharge,intergal,packages}}).then(blob => {
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement('a');
            a.setAttribute('download', "數據報表.xlsx")
            a.href = url;
            document.body.appendChild(a);
            a.click();
            a.remove();
        })
    }

    async componentDidMount() {
        await this.getServiceList()
        await this.getVipList()
        await this.getData()
    }

    render() {
        const { search, prop, services, loading, recharge, intergal, packages, datas, columns, customerClass } = this.state
        const { t } = this.props

        return (
            <main className='expend-report-contanier'>
                <section className='search-info'>
                    <Form ref="form" model={search} labelWidth="75" labelPosition="left" className="rule-form">
                        <Layout.Row gutter="20">
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="日期時間" prop="date">
                                    <DateRangePicker value={search.date} placeholder="選擇日期範圍" align="right" clearable={true} onChange={ this.onChange.bind(this,'date') } style={{width:'100%'}}/>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="客戶查詢">
                                    <Input value={search.userName} placeholder="請輸入" onChange={this.onChange.bind(this, 'userName')}></Input>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="客户意向服务分类">
                                    <Cascader value={search.intentionalService} clearable={true} placeholder={t('please_choose')} props={prop} options={services} changeOnSelect = {true} filterable={true} onChange={this.onChange.bind(this, 'intentionalService')} style={{width:'100%'}}></Cascader>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="客户消费服务分类">
                                    <Cascader value={search.firstConsumeService} clearable={true} placeholder={t('please_choose')} props={prop} options={services} changeOnSelect = {false} filterable={true} onChange={this.onChange.bind(this, 'firstConsumeService')} style={{width:'100%'}}></Cascader>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="客户分类">
                                    <Select clearable value={search.userClass} placeholder="請選擇客戶分類" onChange={this.onChange.bind(this, 'userClass')}>
                                        {
                                            customerClass.map(el => {
                                                return <Select.Option key={el.value} label={el.label} value={el.value} />
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="24" md="12" lg="8">
                                <Form.Item label="師父查詢">
                                    <Input value={search.staffName} placeholder="請輸入" onChange={this.onChange.bind(this, 'staffName')}></Input>
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row>
                            <div className='submit-buttons'>
                                <Button onClick={() => this.getData()}>查詢</Button>
                                <Button onClick={() => this.exportExcel(datas)}>導出</Button>
                            </div>
                        </Layout.Row>
                    </Form>
                </section>
                <section className='total-info'>
                    <Loading loading={loading}>
                        <Layout.Row gutter={20}>
                            <Form labelPosition='left' labelWidth={100}>
                                <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                        <Form.Item label="增值點數">
                                            <Input value={recharge.topUp || 0} disabled />
                                        </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="消費點數">
                                        <Input value={recharge.deduct || 0} disabled />
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="總剩餘點數" className="total-num-label">
                                        <Input value={(recharge.total)  || 0} disabled />
                                    </Form.Item>
                                </Layout.Col>
                            </Form>
                        </Layout.Row>
                        <Layout.Row gutter={20}>
                            <Form labelPosition='left' labelWidth={100}>
                                <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                        <Form.Item label="累積積分">
                                            <Input value={intergal.total || 0} disabled />
                                        </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="消費積分">
                                        <Input value={intergal.deduct || 0} disabled />
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="剩餘積分" className="total-num-label">
                                        <Input value={(intergal.total - intergal.deduct) || 0} disabled />
                                    </Form.Item>
                                </Layout.Col>
                            </Form>
                        </Layout.Row>
                        <Layout.Row gutter={20}>
                            <Form labelPosition='left' labelWidth={100}>
                                <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                        <Form.Item label="次卡總額">
                                            <Input value={packages.total || 0} disabled />
                                        </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="次卡消費額度">
                                        <Input value={(packages.total - packages.remnant) || 0} disabled />
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg={8} md={8} sm={8} xs={24}>
                                    <Form.Item label="次卡剩餘額度" className="total-num-label">
                                        <Input value={(packages.remnant) || 0} disabled />
                                    </Form.Item>
                                </Layout.Col>
                            </Form>
                        </Layout.Row>
                    </Loading>
                </section>
                <section>
                    <Loading loading={loading}>
                        <Table columns={columns} data={datas}></Table>
                    </Loading>
                </section>
            </main>
        )
    }
}