import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Pagination } from 'element-react';
import "./index.scss"
import * as reportApi from "../../../api/report.api";
import moment from 'moment';
import ResultLimit from "../../../components/result-limit.component";


@translate()
export default class ListUserReportPage extends Component {
    state = {
        data:[],
        search:{},
        category:[],
        total:0,
        columns: [
            {
                label:"",
                render: (row,_,index) => {
                    if(index == 0) {
                        return <div>{row.title ? `${row.title}客人` : row.suppId == 'service_times_package_transactions' ? '次卡套餐購買':'充值'}</div> 
                    } else {
                        return <div></div> 
                    }
                }
            },
            {
                label: "日期",
                prop:'startTime',
                render:(row) => {
                    return <div>{row.suppId == "total" ? "" : moment(row.suppId ? row.createdAt : row.startTime).format('YYYY/MM/DD')}</div>
                }
            },
            {
                label: "客人姓名",
                render:(row) => {
                    return <div>{row.surname}{row.name}</div>
                }
            },
            {
                label: "電話",
                prop: "phone"
            },
            {
                label: "購買項目",
                prop: "servicesName",
                render:(row) => {
                    if (row.suppId == "total") {
                        return (<div></div>)
                    }
                    return <div>{row.suppId ? row.suppId == 'service_times_package_transactions' ? row.packageName :'充值':row.servicesName}</div>
                }
            },
            {
                label: "師傅",
                render:(row) => {
                    return <div>{row.staffSurname}{row.staffName}</div>
                }
            },
            {
                label: "價格",
                prop: "price",
                render:(row) => {
                    if (row.suppId == "total") {
                        return (<div></div>)
                    }
                    return <div>{row.price || row.amount || 0}</div>
                }
            },
            {
                label: "优惠",
                render:(data) => {
                    if (data.suppId == "total") {
                        return (<div></div>)
                    }
                    if (data.discountType === 'discount') {
                        return (
                            <span>{((data.price && data.discount && data.price * (1 - data.discount / 100)).toFixed(2)) || 0}</span>
                        )
                    } else {
                        return (
                            <span>{(data.discount && (data.discount).toFixed(2)) || 0}</span>
                        )
                    }
                }
            },
            {
                label: "优惠後價格",
                render:(data) => {
                    if (data.suppId == "total") {
                        return (<div></div>)
                    }
                    if (data.discountType === 'discount') {
                        return (
                            <span>{data.price * (data.discount / 100) || 0}</span>
                        )
                    } else {
                        return (
                            <span>{ data.price - data.discount || 0}</span>
                        )
                    }
                }
            },
            {
                label: "结算方式",
                prop: "payType",
                render:(row)=>{
                    if(row.payType) {
                        return <div>{this.props.t(`main_tab_report_management_${row.payType}`)}</div>
                    }
                }
            },
            {
                label: "付款方式",
                prop: "payment",
                render:(row)=>{
                    if(row.payment && row.payment != '0') {
                        return <div>{row.payment || ''}</div>
                    }
                }
            },
           
        ],
        payment:{},
        sumPrice:0
    }

    async getData(){
        const { search } = this.state
        let query = {...search}

        if (search.date && search.date.length > 0) {
            let startTime = moment(search.date[0]).startOf('day').format('YYYY-MM-DD HH:mm')
            let endTime = moment(search.date[1]).endOf('day').format('YYYY-MM-DD HH:mm')
            query.startTime = startTime
            query.endTime = endTime
        }
        
        Object.keys(query).forEach((item) => {
            const key = query[item];
            if (key === "" || key === null || key === undefined) {
                delete query[item];
            }
        });
        delete query['date']

        await reportApi.listUserReport(query).then(result => {
            const { data } = result
            let categoriesMaps = {}
            const { data:listData,count } = data 

            if(Object.keys(listData).length < 0) return

            const {payment} = this.state
            listData.userReservation.forEach(item => {
                payment[item.payment] = payment[item.payment] || 0
                payment[item.payType] = payment[item.payType] || 0
                let type = payment[item.payType]
                type++
                let sum = payment[item.payment]
                sum++
                payment[item.payment] = sum
                payment[item.payType] = type
                this.setState({
                    payment
                })
                if (item.title != 'OFF') {
                    categoriesMaps[item.fatherCategoryId] = categoriesMaps[item.fatherCategoryId] || {
                        title:item.title,
                        id:item.fatherCategoryId,
                        data:[]
                    }
                    categoriesMaps[item.fatherCategoryId].data.push(item)
                } 
            })

            let sumPrice = listData.userReservation.reduce(function (previousValue, currentValue) {
                let price = currentValue.price
                if (currentValue.discountType == "relief") {
                    price = currentValue.price - currentValue.discount
                } else if (currentValue.discountType == "discount") {
                    price = ( currentValue.price * currentValue.discount ) / 100 || currentValue.price
                }
                return previousValue + parseFloat(price) 
            }, 0)

            if (listData.consumption.length > 0) {
                categoriesMaps['consumption'] = {
                    title:"充值",
                    id:1,
                    data:[...listData.consumption]
                }
                sumPrice += listData.consumption.reduce(function (previousValue, currentValue) {
                    return previousValue + parseFloat( currentValue.amount) 
                },0)
            }

            if (listData.secondaryCardReport.length > 0) {
                listData.secondaryCardReport.sort((a, b) => { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()  })
                categoriesMaps['secondaryCardReport'] = {
                    title:"次卡套餐購買",
                    id:1,
                    data:[...listData.secondaryCardReport]
                }
                sumPrice += listData.secondaryCardReport.reduce(function (previousValue, currentValue) {
                    return previousValue + parseFloat( currentValue.price) 
                },0)
            }

            this.setState({ sumPrice})

            let dataReservation = Object.keys(categoriesMaps).map(item => categoriesMaps[item])

            dataReservation.forEach(item => {
                let userMap = {}
                item.data.sort((a,b) => {
                    return (new Date(b.startTime).getTime() - new Date(a.startTime).getTime() )
                })
                item.data.forEach(e => {
                    userMap[e.id] = userMap[e.id] || {
                        id:e.id,
                        name:e.name,
                        surname:e.surname,
                        total: item.data.filter(item=>item.id == e.id).reduce(function (previousValue, currentValue) {
                            if (item.title == '美容儀器療程' || item.title == '半永久療程') {
                                let price = 0
                                if (currentValue.discountType === 'discount') {
                                    price = currentValue.price * (currentValue.discount / 100) || 0
                                    
                                } else {
                                    price = currentValue.price - currentValue.discount || 0
                                }
                                return previousValue + price
                            } else if (item.title == '充值') {
                                return previousValue +  currentValue.amount
                            } else {
                                return previousValue +  currentValue.price
                            }
                        },0),
                        reservation:[]
                    }
                    userMap[e.id].reservation.push(e)
                })

                
                let userSort = Object.keys(userMap).map(user => {
                    userMap[user].reservation.push({
                        suppId:"total",
                        payment:`合计：${userMap[user].total}` 
                    })
                    return userMap[user]
                })
                
                userSort.sort((a,b) => {
                    return (b.total - a.total)
                })

                let newDatas = []
                userSort.forEach(user => newDatas = [...newDatas,...user.reservation])
                item.data = newDatas
            })

            this.setState({
                total:count,
                category:dataReservation
            })
        })
    }

    setResultLimit(limit) {
        this.setState({search: Object.assign({},this.state.search,{limit})}, () => this.getData());
    }

    setCurrentPage(page) {
        this.setState({search: Object.assign({},this.state.search,{page})}, () => this.getData());
    }

    async componentDidMount(){
        const { search } = this.props
        this.setState({
            search:{...search}
        },()=>{
            this.getData()
        })
    }

    componentWillReceiveProps(props) {
        if (props.search) {
            const { search } = this.state
            if (props.search.page != search.page || props.search.limit != search.limit 
                || props.search.userName != search.userName 
                || props.search.staffName != search.staffName
                || props.search.serviceName != search.serviceName
                || props.search.startTime != search.startTime
                || props.search.endTime != search.endTime
                || props.search.date != search.date
            ) {
                this.setState({search: props.search},()=>{
                    this.getData()
                })
            }
        }
    }

    render() {
        const {category , columns, search, total, sumPrice} = this.state
        return (
            <main className='list-user-report-contanier'>
                <section>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={search.limit}/>
                        {total > 0 ? <Pagination layout="prev, pager, next"  total={total} pageSize={search.limit} currentPage={search.page} onCurrentChange={(page) => this.setCurrentPage(page)}/> : null} 
                        <p>消費總額 ：{ sumPrice }</p>
                    </div>
                    {
                        category.length > 0 ? category.map((item,index) => {
                            return (
                                <div key={index} >
                                    {item.data.length > 0 ? 
                                    <Table  columns={columns} data={item.data} showHeader={index == 0 ? true: false}></Table>
                                    :null    
                                }
                                </div>
                            )
                        }) : <Table columns={columns} data={category}></Table>
                    }
                </section>
            </main>
        )
    }
}