export const mergeDetails = (obj, name) => {
    obj = {
        ...obj[`${name}_details`][0],
        ...obj
    };
    delete obj[`${name}_details`];
    return obj;
};

/**
 * @template T
 * @param {Array.<T>} arr
 * @param {string} id
 * @return {Object.<string, T>}
 */
export const normalize = (arr, id = 'id') => {
    const result = {};
    for(const obj of arr) {
        result[obj[id]] = obj;
    }
    return result;
};

export const ServiceTree = async (datas) => {
    let parents = []
    datas.forEach(item => {
        if (item.stratum == 1) {
            parents.push({
                value: item.id,
                name: item && item.category_details && item.category_details[0].title,
            })
        }
    })
    console.log("parents",parents)
    let childrens = datas.filter(item => item.stratum != 1)
    console.log("childrens",childrens)
    await formatService(parents, childrens)
    return parents
}
    
const formatService = async (parents, childrens) => {
    parents.forEach(parent => {
        childrens.forEach((children, index) => {
            if (children.stratumId == parent.value) {
                let temp = JSON.parse(JSON.stringify(childrens))
                temp.splice(index, 1)
                let services = children.services.map(service => {
                    return {
                        value: service.id,
                        name: service.service_details[0].name
                    }
                })
                let child = {
                    value: children.id,
                    name: children && children.category_details && children.category_details[0].title,
                }
                if (children.services.length > 0) {
                    child.children = services
                }
                parent.children ? parent.children.push(child) : parent.children = [child]
                formatService([child], temp)
            }
        })
    })
}