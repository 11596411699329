import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Form, Input, Layout, DateRangePicker, Button, Tabs, Loading,Checkbox,Dialog } from 'element-react';
import "./index.scss"
import ListUserReportPage from './list-user-report'
import ListServiceReportPage from './list-service-report'
import * as reportApi from "../../../api/report.api";
import moment from 'moment'

@translate()
export default class ExprotSelectPage extends Component {
    state = {
        checkLists:[],
        exportVisiabled:true,
        exportLoading : false,
        checkAll:true,
        isIndeterminate:true,
        exprotLists:[
            {label:"日期",value:'date'},
            {label:"客人姓名",value:'name'},
            {label:"客人電話",value:'phone'},
            {label:"購買項目",value:'service'},
            {label:"師傅",value:'staff'},
            {label:"價格",value:'price'},
            {label:"優惠",value:'discount'},
            {label:"優惠後的價格",value:'discountPrice'},
            {label:"結算方式",value:'settlement'},
            {label:"支付方式",value:'payment'}
        ]
    }

    componentWillReceiveProps(props) {
        this.setState({search: props.search,tabsValue:props.tabsValue})
    }

    async exprotExecl() {
        const { exportLoading,checkLists  } = this.state
        const { search,tabsValue } = this.props
        let query = {...search}

        if (search.date && search.date.length > 0) {
            let startTime = moment(search.date[0]).startOf('day').format('YYYY-MM-DD HH:mm')
            let endTime = moment(search.date[1]).endOf('day').format('YYYY-MM-DD HH:mm')
            query.startTime = startTime
            query.endTime = endTime
        }
        
        Object.keys(query).forEach((item) => {
            const key = query[item];
            if (key === "" || key === null || key === undefined) {
                delete query[item];
            }
        });
        delete query['date']

        let obj = { ...query,type:tabsValue == '1'?'user':'service',checkLists }

        if (exportLoading ) return
        this.setState({exportLoading :true})
        await reportApi.listExprotExecl(obj).then(blob => {
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement('a');
            a.setAttribute('download', "數據報表.xlsx")
            a.href = url;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).catch(error => {
            this.setState({exportLoading :false})
        }).finally(()=>{
            this.setState({exportLoading :false})
        })
    }

    handleCheckedChange(value) {
        const checkedCount = value.length;
        const checkedLength = this.state.checkLists.length;

        this.setState({
            checkList:value,
            checkAll: checkedCount === checkedLength,
            isIndeterminate: checkedCount > 0 && checkedCount < checkedLength,
        });
    }

    handleCheckAllChange(checked) {
        const {exprotLists} = this.state
        const checkLists = checked ? exprotLists.map(item=>item.value) : [];
        this.setState({
          isIndeterminate: false,
          checkAll: checked,
          checkLists,
        });
    }

    componentDidMount(){
        let indexMaps =  this.state.exprotLists.map(item=> item.value)
        if (this.props.tabsValue != '1') {
           let index = indexMaps.indexOf('service')
           indexMaps.splice(index,1)
           this.state.exprotLists.splice(index,1)
        }
        this.setState({
            checkLists:indexMaps
        })
    }


    render() {
        const {exportVisiabled,checkLists,isIndeterminate,checkAll} = this.state
        const { closed } =this.props
        return (
            <main className='list-user-report-contanier'>
                <Dialog title="導出選項" size="tiny"  visible={ exportVisiabled } onCancel={ () => closed() } lockScroll={ false } >
                    <Dialog.Body>
                        <Checkbox checked={checkAll} indeterminate={isIndeterminate} onChange={this.handleCheckAllChange.bind(this)}>全选</Checkbox>
                        <div style={{margin: '15px 0'}}></div>
                        <Checkbox.Group value={checkLists} onChange={this.handleCheckedChange.bind(this)}>
                            {
                                this.state.exprotLists.map((item, index) =>
                                    <Checkbox key={index} label={item.value}>{item.label}</Checkbox>
                                )
                            }
                        </Checkbox.Group>
                    </Dialog.Body>
                    <Dialog.Footer className="dialog-footer">
                    <Button onClick={() => this.exprotExecl()}>導出</Button>
                    <Button onClick={() => closed() }>取消</Button>
                    </Dialog.Footer>
                </Dialog>
            </main>
        )
    }
}