import client from '../apiClient';

export async function getServices(page, orderBy, direction, limit, reference,serviceForm) {
    let response = await client.get('/service', {
        params: {
            page,
            orderBy,
            direction,
            limit,
            reference,
            ...serviceForm
        }
    });
    return response.data;
}

export async function getService(serviceId) {
    let response = await client.get(`/service/${serviceId}`);
    return response.data;
}

export async function createService(body) {
    let response = await client.post('/service', body);
    return response.data;
}

export async function updateService(serviceId, body) {
    let response = await client.patch(`/service/${serviceId}`, body);
    return response.data;
}

export async function deleteService(serviceID) {
    let response = await client.delete(`/service/${serviceID}`);
    return response.data;
}

export async function uploadVideo(serviceId, video, preview) {
    const formData = new FormData();
    formData.append("video", video);
    formData.append("preview", preview);
    let response = await client.post(`/service/${serviceId}/video`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function assignCenter(serviceId, centers) {
    let response = await client.post(`/service/${serviceId}/assignCenter`, {
        centers,
    });
    return response.data;
}

export async function uploadImages(serviceId, images) {
    const formData = new FormData();
    for (let id of Object.keys(images)) {
        formData.append("slides", images[id].file);
    }
    let response = await client.post(`/service/${serviceId}/slides`, formData, {
        params: {
            ids: Object.keys(images),
        },
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function getServiceListAll(){
    let response = await client.get(`/service/serviceList`);
    return response.data;
}

export async function getServiceList(){
    let response = await client.get(`/category`);
    return response.data;
} 