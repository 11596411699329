import React, { Component } from 'react';
import logo from './logo.svg';
import { Provider } from 'react-redux';
import store from './redux/store';
import history from './history';
import { Router, Route, Switch, withRouter } from 'react-router-dom'
import { Dialog, Button } from 'element-react';
import LoginPage from './screens/login/login.screen';
import MainPage from './screens/main/main.screen';
import { connect } from 'react-redux';
import './App.scss';
import { setSession, setLanguage } from './redux/staff/staff.action';
import { clearError } from "./redux/staff/error.action"
import 'element-theme-default';
import { translate, Trans } from 'react-i18next';
import './utils/i18n';
const NotFound = () => (
  <div className="not-found">
    NotFound
  </div>
);

const mapDispatchToProps = (dispatch) => {
  return {
    setSession: (session) => {
      dispatch(setSession(session))
    },
    setLanguage: (languageId) => {
      dispatch(setLanguage(languageId))
    },
    clearError: () => {
      dispatch(clearError())
    }
  }
}

@withRouter
@translate()
@connect((state) => {
  return {
    errorMessage: state.error.errorMessage,
  };
}, mapDispatchToProps)
class LoginGuard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      windowWidth: document.documentElement.clientWidth
    };
  }

  handleResize = e => {
    if (e.target.innerWidth < 750) {
      window.document.documentElement.style.fontSize = "16px"
    }
    this.setState({
      windowWidth: e.target.innerWidth
    })
  }

  componentDidMount() {
    const { history } = this.props;
    const session = JSON.parse(sessionStorage.getItem("session"));
    const languageId = sessionStorage.getItem("languageId");
    if (languageId) {
      this.props.setLanguage(languageId);
    } else {
      this.props.setLanguage("cht");
    }
    if (session) {
      this.props.setSession(session);
    } else {
      history.push('/login')
    }
    if (this.state.windowWidth < 750) {
      window.document.documentElement.style.fontSize = "16px"
    }
    window.addEventListener('resize', this.handleResize.bind(this)) //监听窗口大小改变
  }

  componentWillUnmount() { //一定要最后移除监听器，以防多个组件之间导致this的指向紊乱
    window.removeEventListener('resize', this.handleResize.bind(this))
  }

  render() {
    const { t, errorMessage, clearError } = this.props;
    return (
      <div className="App">
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/main`} component={MainPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/login`} component={LoginPage} />
          <Route component={NotFound} />
        </Switch>
        <Dialog
          title={t('error_board_title')}
          visible={!!errorMessage}
          onCancel={() => clearError()}
        >
          <div className="error">
            <div className="error-title">
              {errorMessage}
            </div>
            <div className="error-footer">
              <Button onClick={() => clearError()}>{t('error_board_button_ok')}</Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

class App extends Component {

  constructor() {
    super();

    this.state = {
      store: store,
    };
  }

  render() {
    return (
      <Provider store={this.state.store}>
        <Router basename="/cms" history={history}>
          <LoginGuard />
        </Router>
      </Provider>
    );
  }
}

export default App;
