import React, { Component } from 'react';
import './tab-cms-permission-edit.scss';
import { translate } from 'react-i18next';
import { Radio, Button } from 'element-react';

@translate()
export default class StaffCMSPermissionViewTab extends Component {
    render() {
        const { t, staffPermissions, permissions, updatePermission, submit } = this.props;
        return (
            staffPermissions && permissions ?
                <div className="cms-permission-tab-edit">
                    <div className="tab-body">
                        <div className="row">
                            <div className="title">
                            </div>
                            <div className="value">
                                <div className="sub-value">
                                    {t('staff_tab_cms_permission_view')}
                                </div>
                                <div className="sub-value">
                                    {t('button_edit')}
                                </div>
                                <div className="sub-value">
                                    {t('staff_tab_cms_permission_no_permission')}
                                </div>
                            </div>
                        </div>
                        <div className="sub-title">
                            {t('staff_tab_cms_permission_sub_title_hwl_center')}
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_center')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["center"] ? staffPermissions["center"] : ""} onChange={(value) => {
                                    updatePermission("center", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_category')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["category"] ? staffPermissions["category"] : ""} onChange={(value) => {
                                    updatePermission("category", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_service')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["service"] ? staffPermissions["service"] : ""} onChange={(value) => {
                                    updatePermission("service", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_company')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["company"] ? staffPermissions["company"] : ""} onChange={(value) => {
                                    updatePermission("company", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_document')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["document"] ? staffPermissions["document"] : ""} onChange={(value) => {
                                    updatePermission("document", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_health_tips')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["healthTips"] ? staffPermissions["healthTips"] : ""} onChange={(value) => {
                                    updatePermission("healthTips", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_body_condition')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["bodyCondition"] ? staffPermissions["bodyCondition"] : ""} onChange={(value) => {
                                    updatePermission("bodyCondition", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_notification')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["notification"] ? staffPermissions["notification"] : ""} onChange={(value) => {
                                    updatePermission("notification", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_web')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["web"] ? staffPermissions["web"] : ""} onChange={(value) => {
                                    updatePermission("web", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="sub-title">
                            {t('staff_tab_cms_permission_sub_title_customers')}
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_user')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["user"] ? staffPermissions["user"] : ""} onChange={(value) => {
                                    updatePermission("user", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_family')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["family"] ? staffPermissions["family"] : ""} onChange={(value) => {
                                    updatePermission("family", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_reservation')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["reservation"] ? staffPermissions["reservation"] : ""} onChange={(value) => {
                                    updatePermission("reservation", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_transaction')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["transaction"] ? staffPermissions["transaction"] : ""} onChange={(value) => {
                                    updatePermission("transaction", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="sub-title">
                            {t('staff_tab_cms_permission_sub_title_staff')}
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_staff')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["staff"] ? staffPermissions["staff"] : ""} onChange={(value) => {
                                    updatePermission("staff", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_schedule')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["staffSchedule"] ? staffPermissions["staffSchedule"] : ""} onChange={(value) => {
                                    updatePermission("staffSchedule", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_self')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["self"] ? staffPermissions["self"] : ""} onChange={(value) => {
                                    updatePermission("self", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_permission')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["permission"] ? staffPermissions["permission"] : ""} onChange={(value) => {
                                    updatePermission("permission", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_cms_permission_title_press')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staffPermissions["press"] ? staffPermissions["press"] : ""} onChange={(value) => {
                                    updatePermission("press", value)
                                }}>
                                    <Radio value="read" > </Radio>
                                    <Radio value="write" > </Radio>
                                    <Radio value="" > </Radio>
                                </Radio.Group>
                            </div>
                        </div>
                    </div>
                    <div className="board-footer">
                        <Button onClick={() => submit()}>{t('button_save')}</Button>
                    </div>
                </div> : null
        );
    }
}