import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Dialog, Button, Message, Table,Form,Select} from 'element-react';
import * as reservationApi from "../../../../api/reservation.api";

@translate()
@connect((state) => {
    return {
        staffId: state.staff.staff.id,
    };
})
export default class BatchConfirmation extends Component {
    state = {
        visible:true,
        columns: [
            {
                label: this.props.t('booking_table_column_date'),
                prop: "startTime",
                sortable: "custom",
                render: this.renderDate,
                width: 150,
            },
            {
                label: this.props.t('booking_table_column_time'),
                prop: "startTime",
                render: this.renderTime,
                width: 160,
            },
            {
                label: this.props.t('booking_table_column_user'),
                render: function (data) {
                    if (data.user) {
                        return (
                            <div>
                                <span>{data.user.surname}</span>
                                <span>{data.user.name}</span>
                            </div>
                        )
                    } else {
                        return (
                            <span>{data.userInfo?data.userInfo.name:''}</span>
                        )
                    }
                }
            },
            {
                label: this.props.t('booking_table_column_staff'),
                render: function (data) {
                    return (
                        <div>
                            <span>{data.staff_schedule && data.staff_schedule.staff ?data.staff_schedule.staff.staff_details[0].surname:''}</span>
                            <span>{data.staff_schedule && data.staff_schedule.staff ?data.staff_schedule.staff.staff_details[0].name:''}</span>
                        </div>
                    )
                }
            },
            {
                label: this.props.t('booking_table_column_server'),
                render: function (data) {
                    return (
                        <span>{data.service?data.service.service_details[0].name:''}</span>
                    )
                }
            },
            {
                label: this.props.t('booking_revise_record_price'),
                prop: "price",
            },
            {
                label: this.props.t('booking_table_column_status'),
                prop: "status",
            },
        ],
        payTypeOptions:[
            {
                value: "visa",
                label: "visa"
            },{
                value: "Maste",
                label: "Maste"
            },{
                value: "AE",
                label: "AE"
            },{
                value: "EPS",
                label: "EPS"
            },{
                value: "FPS",
                label: "FPS（轉數快）"
            },{
                value: "Bank Transfer",
                label: "Bank Transfer"
            },{
                value: "ATM Transfer",
                label: "ATM Transfer"
            },{
                value: "CASH",
                label: "CASH"
            },{
                value: "Payme",
                label: "Payme"
            },{
                value: "Ailpay",
                label: "Ailpay"
            },{
                value: "WeChat Pay",
                label: "WeChat Pay"
            },{
                value: "銀聯支付",
                label: "銀聯支付"
            },{
                value: "Octopus",
                label: "Octopus"
            },{
                value: "Atome",
                label: "Atome"
            },{
                value: "Tap & Go",
                label: "Tap & Go"
            }
        ],
        payment:null
    }

    renderDate(date) {
        var date = new Date(date.startTime).toJSON();
        var val = new Date(+new Date(date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        return val.replace(/\d{1,2}:\d{1,2}:\d{1,2}/,'')
    }

    renderTime(date) {
        var startTime = new Date(date.startTime).toJSON();
        var endTime = new Date(date.endTime).toJSON();
        var from = new Date(+new Date(startTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        var to = new Date(+new Date(endTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        return from.replace(/\d{4}-\d{1,2}-\d{1,2}/,'') + "-" + to.replace(/\d{4}-\d{1,2}-\d{1,2}/,'')
    }

    handleSubmit(){
        const {success,selectionChange} = this.props
        const {payment} = this.state
        if ( !selectionChange || selectionChange.length <= 0) return

        let request = selectionChange.map(item => {
            return {
                userId:item.userId,
                reservationId:item.id,
                payment,
                commission:100,
                remark:item.remark,
                status:item.status,
                type:'normal',
                price:item.price,
                serviceId:item.serviceId,
                staffId:item.staff_schedule.staffId     
            }
        }) 
        let isRequest = true
        request.forEach(item => {
            if (item.status == 'checked') {
                isRequest = false
                Message({
                    type:'error',
                    message:'选中的订单中包含有已确认的订单',
                    customClass: 'zZindex',
                    showClose:true,
                    duretion: 500
                })
                return 
            }
        })
        let body = {checkInConfig:request}
        if (isRequest) {
            reservationApi.batchCheckIn(body).then(result => {
                const {data} = result
                if (data.code !== 200) {
                    Message({
                        type:'error',
                        message:data.msg,
                        customClass: 'zZindex',
                        showClose:true,
                        duretion: 500
                    })
                    return
                }
                success()
            }).catch(error => {
                Message({
                    type:'error',
                    message:error.msg,
                    customClass: 'zZindex',
                    showClose:true,
                    duretion: 500
                })
            })
        }
    }

    async componentDidMount() {
        this.setState({
            payment:this.state.payTypeOptions[0].value
        })
    }

    render() {
        const {isClosed,selectionChange} =this.props;
        const {visible} = this.state
        return (
            <main className='batchConfirmation-contanier'>
                <Dialog title="多笔订单普通结算确认" size="tiny" visible={ visible } onCancel={ isClosed } lockScroll={ false }>
                    <Dialog.Body>
                        <Form labelWidth={120} labelPosition={'left'}>
                            <Form.Item label="结算方式">
                                <Select value={this.state.payment} onChange={(value) => this.setState({payment:value})} placeholder="請選擇">
                                    {
                                        this.state.payTypeOptions.map(el => {
                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    {selectionChange.length > 0 && (
                        <Table style={{ width: '100%' }} columns={this.state.columns} data={selectionChange} border={true}></Table>
                    )}
                    </Dialog.Body>
                    <Dialog.Footer className="dialog-footer" >
                        <Button onClick={()=>this.handleSubmit()}>确认</Button>
                    </Dialog.Footer>
                </Dialog>
            </main>
        )
    }
}