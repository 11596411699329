import {baseURL} from "../apiClient";
import React from 'react';

const AccountIcon = (props) => {
    let icon = props.icon;
    if (icon) {
        icon = icon.indexOf('uploads/') !== -1 ? `${baseURL}/${props.icon}` : icon;
    } else {
        icon = require('../assets/user_placeholder.jpg');
    }
    return <img {...props} src={icon} />
};
export default AccountIcon;