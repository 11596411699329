// @flow
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import { persistStore } from 'redux-persist';
import reducer from './root.reducer';

export default function configureStore(onCompletion?:()=>void):any {
  const composeEnhancers = composeWithDevTools({ name: 'Hong Wo Lok', realtime: true });
  const enhancer = composeEnhancers(applyMiddleware(thunk));

  const store = createStore(reducer, enhancer);
  // persistStore(store, onCompletion);

  return store;
}