import React, { Component } from 'react';
import './category-all.scss';
import * as categoryApi from "../../../../api/category.api";
import { Table, Button, Loading } from 'element-react';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import history from '../../../../history';


@translate()
@withRouter
export default class CategoryAllPage extends Component {
    state = {
        loading: true,
        data: [],
        columns: [//列数+操作
            {
                label: this.props.t('category_all_table_column_category'),
                prop: "name",
            },
            {
                label: this.props.t('category_all_table_column_category_tow'),
                prop: "name_tow",
                render: (row) => {
                    return (row.name_tow.length > 0
                        ? row.name_tow.map((item) => {
                            return <div>{item}</div>
                        })
                        : null
                    )
                }
            },
            {
                label: this.props.t('category_all_table_column_view_detail'),
                prop: "id",
                render: (row) => {
                    return (
                        <span>
                            <Button onClick={() => this.switchTab("/main/category-detail/" + row.id)}>
                                {this.props.t('button_view')}
                            </Button>
                        </span>
                    )
                }
            },
        ],
        count: null
    };

    async deleteCategory(id) {
        await categoryApi.deleteCategory(id);
        this.updateCategoryRecords();
    }

    switchTab = (url) => {
        history.push(url);
    }

    async updateCategoryRecords() {
        const res = await categoryApi.getCategories();
        let newData = [];
        if (res.rows.length > 0) {
            res.rows.forEach((item) => {
                if (item.stratumId === null || item.stratumId === '0') {
                    newData.push({
                        id: item.id,
                        name: item.category_details[0].title,
                        name_tow: [],
                    })
                }
            })
        }

        if (newData.length > 0 && res.rows.length > 0) {
            res.rows.forEach((item) => {
                if (item.stratumId !== null && item.stratumId !== '0') {
                    newData.forEach((e, i) => {
                        if (e.id === item.stratumId) {
                            newData[i].name_tow.push(item.category_details[0].title);
                        }
                    })
                }
            })
        }

        this.setState({
            ...this.state,
            data: newData,
            count: res.count,
            loading: false
        });
    }

    async componentDidMount() {
        await this.updateCategoryRecords();
    }

    render() {
        const { t } = this.props;
        const { columns, data, loading } = this.state;
        return (
            <div className="category-all-page">
                <Loading loading={loading}>
                    <div className="page-header">
                        <div className="page-title">
                            {t('category_all_main_title')}
                        </div>
                        <div className="space"></div>
                        {/* 新增分类 */}
                        <Button onClick={() => this.switchTab("/main/category-detail/create")}>{t('category_all_button_add_new_category')}</Button>
                    </div>
                    <Table
                        stripe={true}
                        border={true}
                        columns={columns}
                        rowKey='id'
                        data={data}
                    />
                </Loading>
            </div>
        );
    }
}