
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './image-board.scss';

@translate()
export default class ImageBoard extends Component {
    render() {
        const { t, localImages, uploadImage, edit } = this.props;
        return (localImages.length > 0 
            ?   <div className="image-board">
                    <main className="row">
                        <section className="title">
                            {t('image_board_title')}
                        </section>
                        <section className="value">
                            {
                                localImages.map((localImage, i) => {
                                    if (edit) {
                                        return (
                                            <div key={i} className="image-box">
                                                <img onClick={() => uploadImage(i)} src={localImages[i]} alt='' />
                                                <i onClick={() => uploadImage(i, true)} className="el-icon-delete"></i>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={i} className="image-box">
                                                <img src={localImage} alt='' />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </section>
                    </main>
                </div>
            : null
        );
    }
}