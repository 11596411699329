import './day-view.scss';
import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import FaPhone from 'react-icons/lib/fa/phone';
import { default as IconBase } from 'react-icon-base';

export default class Event extends Component{

    constructor(props) {
        super(props);
        // IconBase.prototype = React.Component.prototype;
    }

    static defaultProps = {
        _eventComponent: true
    };

    /*
    * propsTypes: {
    *   reservation: {
    *     id: string,
    *     userId
    *     staffScheduleId
    *     status
    *     overallRating
    *     therapistRating
    *     feedBack
    *     bookedBy
    *     report
    *     shared
    *     note
    *     createdAt
    *     updatedAt
    *     staff_schedule: {
    *       id
    *       staffId
    *       serviceId
    *       centerId
    *       startTime
    *       endTime
    *       price
    *       bookable
    *       booked
    *       remark
    *       createdAt
    *       updatedAt
    *     },
    *     user: {
    *       id
    *       familyId
    *       surname
    *       name
    *       chineseName
    *       phone
    *       email
    *       gender
    *       address
    *       status
    *       icon
    *       birthday
    *       hkid
    *       remark
    *       remind
    *       createdAt
    *       updatedAt
    *     }
    *   }
    * }
    * */

    render() {
        const { className, style, onClick, reservation, t } = this.props;
        const schedule = reservation.staff_schedule;
        const user = reservation.user;
        // console.log(style);
        return (
            <div className={classNames('day-view-event', { warning: !reservation.room.active || reservation.staff_schedule.staff.status !== "working"}, className)}
                 style={style}
                 onClick={onClick}
            >
                <div>
                    {/* moment(schedule.startTime).format('HH:mm')} - {moment(schedule.endTime).format('HH:mm') */}
                    {moment(reservation.startTime).format('HH:mm')} - {moment(reservation.endTime).format('HH:mm')}
                </div>
                {/* <div> */}
                {/*     { */}
                {/*         reservation.user */}
                {/*             ? reservation.user.surname + ' ' + reservation.user.name */}
                {/*             : reservation.userInfo.name */}
                {/*     } */}
                {/* </div> */}
                <div>
                    {reservation.service.service_details[0].name}
                </div>
                <div>
                    {`${reservation.room.title}${reservation.room.active?"":"(Inactive)"}`}
                </div>
                <div>
                    {`${reservation.remark}`}
                </div>
                { reservation.user && user.remind ?
                    <div className="remind">
                        
                    </div> : null}

            </div>
        )
    }
}