import React, { Component } from 'react';
import './staff-all.scss';
import * as staffApi from "../../../../api/staff.api";
import { Input, Button, DatePicker } from 'element-react';
import { translate } from 'react-i18next';
import history from '../../../../history';
import withRouter from "react-router-dom/es/withRouter";
import PageTable from "../../../../components/page-table.component";
import ResultLimit from "../../../../components/result-limit.component";
import client from '../../../../apiClient';
import i18n from '../../../../utils/i18n';
import { connect } from "react-redux";

@withRouter
@translate()
export default class StaffAllPage extends Component {

    state = {
        results: [],
        lastTextChange: 0,
        currentPage: 1,
        orderBy: "createdAt",
        direction: "ASC",
        limit: 40,
        reference: "",
        totalResult: 0,
        reportMonth: new Date(),
        sort: null,
        loading: true,
    };

    onSearch = async (reference) => {
        const initiateTime = Date.now();
        this.setState({ lastTextChange: initiateTime });
        setTimeout(async () => {
            if (this.state.lastTextChange === initiateTime) {
                this.setState({ reference });
                this.updateStaffRecords();
            }
        }, 500);
    }

    switchTab = (url) => {
        history.push(url);
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.updateStaffRecords());
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.updateStaffRecords())
    }

    setSort(e) {
        if (e.order) {
            this.setState({
                orderBy: e.prop,
                direction: e.order === "ascending" ? "ASC" : "DESC",
            });
            this.updateStaffRecords();
        }
    }

    async downloadMonthlyReport() {
        this._downloadMonthlyReport(this.state.reportMonth);
    }

    _downloadMonthlyReport = async (month) => {
        const response = await client.get('/staffSchedule/report/booked', {
            params: {
                languageId: i18n.language,
                month: month,
            },
            responseType: 'blob', // important
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.csv');
        document.body.appendChild(link);
        link.click();
    };

    async deleteStaff(row) {
        const confirmed = window.confirm(`确定要删除吗？`,"提示信息");
        if (confirmed) {
            staffApi.deleteStaff(row.id).then(res => {
                this.updateStaffRecords()
            })
        }
    }

    async updateStaffRecords() {
        const { currentPage, orderBy, direction, limit, reference } = this.state;
        const response = await staffApi.getStaffs(currentPage, null, null, limit, reference);
        this.setState({
            results: response.rows.map((staff) => {
                const data = {
                    id: staff.id,
                    type: staff.type,
                    surname: staff.staff_details[0] ? staff.staff_details[0].surname : null,
                    name: staff.staff_details[0] ? staff.staff_details[0].name : null,
                    mobile: staff.phone,
                    gender: staff.gender,
                    nowCommission: staff.nowCommission,
                    expectedCommission: staff.expectedCommission,
                }
                return data;
            }),
            totalResult: response.count,
            loading: false,
        });
    }

    async componentDidMount() {
        window.downloadReport = this._downloadMonthlyReport;
        await this.updateStaffRecords();
    }

    render() {
        const { t, match } = this.props;
        const { limit, currentPage, totalResult, results, sort, loading } = this.state;
        const isScheduleView = match.url.indexOf('staff-schedule-all') !== -1;

        const columns = [
            {
                label: this.props.t('staff_all_table_column_id'),
                prop: "id",
                sortable: "custom",
                width: "99"
            },
            {
                label: this.props.t('staff_all_table_column_type'),
                prop: "type",
                width: "99",
            },
            {
                label: this.props.t('staff_all_table_column_surname'),
                prop: "surname",
                width: "99"
            },
            {
                label: this.props.t('staff_all_table_column_name'),
                prop: "name",
                width: "99"
            },
            {
                label: this.props.t('staff_all_table_column_salary_commission'),
                prop: "nowCommission",
                // render: (row) => {
                //     return <span onClick={() => this.switchTab("/main/staff-detail/" + row.id)}>
                //         { row.nowCommission ? row.nowCommission : 0 }
                //     </span>
                // }
                width: "99"
            },
            {
                label: this.props.t('staff_all_table_column_expected_commission'),
                prop: "expectedCommission",
                // render: (row) => {
                //     return <span onClick={() => this.switchTab("/main/staff-detail/" + row.id)}>
                //         預計提成
                //     </span>
                // }
                width: "99"
            },
            {
                label: this.props.t('staff_all_table_column_mobile'),
                prop: "mobile",
                // width: "99"
            },
            {
                label: this.props.t('staff_all_table_column_gender'),
                prop: "gender",
                render: (row) => {
                    return <span>
                        {this.props.t(row.gender)}
                    </span>
                },
                width: "99"
            },
            {
                label: this.props.t('staff_all_table_column_detail'),
                prop: "detail",
                render: (row, column, index) => {
                    return <span>
                        {
                            <Button onClick={() => this.switchTab("/main/staff-detail/" + row.id)}>{this.props.t('button_view')}</Button>
                        }
                    </span>
                },
                width: "99",
                fixed: "right"
            },
            {
                label: this.props.t('staff_all_table_column_schedule'),
                prop: "detail",
                render: (row, column, index) => {
                    return <span>
                        {
                            <Button onClick={() => this.switchTab("/main/staff-schedule/" + row.id)}>{this.props.t('button_view')}</Button>
                        }
                    </span>
                },
                width: "99",
                fixed: "right"
            },
            {
                label: this.props.t('operate'),
                render: (row, column, index) => {
                    return <span>
                        {
                            <Button onClick={() => { this.deleteStaff(row) }}>删除</Button>
                        }
                    </span>
                },
                width: "99",
                fixed: "right"
            },
        ];

        return (
            <div className="staff-all-page">
                <div className="main-title">
                    {t('staff_all_title')}
                </div>
                <div className="page-header">
                    <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} />
                    <div className="space"></div>
                    {/*<div style={{ padding: 4, border: '1px solid #000'}}>*/}
                    {/*<DatePicker*/}
                    {/*value={this.state.reportMonth}*/}
                    {/*placeholder={t('select_date')}*/}
                    {/*selectionMode="month"*/}
                    {/*onChange={date=>{ this.setState({reportMonth: date })}}*/}
                    {/*/>*/}
                    {/*<Button onClick={() => this.downloadMonthlyReport()} >{t('download_report')}</Button>*/}
                    {/*</div>*/}
                    <div className="search-bar">
                        <Input placeholder={t('staff_all_search_bar_placeholder')} onChange={(text) => this.onSearch(text)} />
                    </div>
                    {!isScheduleView ? <div className="add-staff-button">
                        <Button onClick={() => this.switchTab("/main/staff-detail/create")}>{t('staff_all_button_add_staff')}</Button>
                    </div> : null}
                </div>
                <PageTable
                    limit={limit}
                    currentPage={currentPage}
                    totalResult={totalResult}
                    results={results}
                    sort={sort}
                    columns={columns}
                    setSort={(e) => this.setSort(e)}
                    setCurrentPage={(page) => this.setCurrentPage(page)}
                    loading={loading}
                />
            </div>
        );
    }
}