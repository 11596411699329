import React, { Component } from 'react';
import * as staffApi from "../../../../api/staff.api";
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import history from '../../../../history';
import { Button } from 'element-react';
import PageTable from "../../../../components/page-table.component";
import StaffServiceDetailViewTab from "../../../../components/staff/tab-service-detail-view/tab-service-detail-view.component";
@withRouter
@translate()
export default class StaffCommissionPage extends Component {
    state = {
        staff: {
            centers: [],
            services: []
        },
        currentPage: 1,
        loading: true,
        status: "checked", // checked-已提成 waiting-预计
    }

    async componentDidMount() {
        let { status, staffId } = this.props.match.params
        this.setState({
            status
        })
        await this.getStaffCommission(staffId, status)
    }

    async getStaffCommission(staffId, status) {
        const staff = staffId ? await staffApi.getStaffPrice(staffId, status) : null;
        console.log(staff)
        this.setState({
            staff,
            loading: false
        });
    }

    parseTime(time, fm) {
        // 解析时间  time: 时间戳或者实践对象 fm: 格式 默认是{y}-{m}-{d} {h}:{i}:{s}
        if (arguments.length === 0) {
            return null;
        }
        const format = fm || "{y}-{m}-{d} {h}:{i}:{s}";
        let date;
        if (typeof time === "object") {
            date = time;
        } else {
            if (("" + time).length === 10) time = parseInt(time) * 1000;
            date = new Date(time);
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay(),
        };
        const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
            let value = formatObj[key];
            if (key === "a")
                return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
            if (result.length > 0 && value < 10) {
                value = "0" + value;
            }
            return value || 0;
        });
        return time_str;
    }

    // async getData(staffId, type) {
    //     const staff = staffId ? await staffApi.getStaffCommission(staffId, type) : null;
    //     this.setState({
    //         staff,
    //         loading: false
    //     }, () => {
    //         console.log('staff', this.state.staff);
    //     });
    // }

    render() {
        const { t } = this.props;
        const { staff, loading, status } = this.state

        const columns = [
            {
                label: this.props.t('staff_tab_service_detail_title_project'),
                prop: "serviceName",
            },
            {
                label: this.props.t('staff_tab_service_detail_title_price'),
                prop: "extraFee",
                render: (data) => {
                    return (
                        <span>{data.extraFee ? data.extraFee : 0}</span>
                    )
                }
            },
            {
                label: this.props.t('staff_tab_service_detail_title_commission'),
                prop: "commissionSalary",
            },
            {
                label: this.props.t('staff_tab_consumption_time'),
                prop: "createdAt",
                render: (row) => {
                    return (
                        <span>{ row.createdAt ? this.parseTime(row.createdAt) : null }</span>
                    )
                }
            },
        ];

        return (
            <div className="staff-commission-page" >
                <div className="main-title">
                    {status === "checked" ? t('staff_commission_salary_title') : t('staff_commission_expected_title')}
                </div>
                <div className="service-detail-tab">
                    {
                        staff.centers.map((center, i) => {
                            return (
                                <div key={i}>
                                    <div className="row">
                                        <div className="title">
                                            {t('staff_tab_service_detail_title_center')}
                                        </div>
                                        <span className="value">{center.name}</span>
                                    </div>
                                    <div className="row">
                                        <div className="title">
                                            {t('staff_tab_service_detail_title_service_type')}
                                        </div>
                                        <div className="value">
                                            <PageTable
                                                results={staff.list.filter(service => service.centerId == center.id)}
                                                totalResult={staff.list.length}
                                                columns={columns}
                                                limit={staff.list.length}
                                                currentPage={1}
                                                loading={loading}
                                            />
                                            <div>提成總計：{staff.total}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className="page-footer">
                    <Button onClick={() => history.goBack()}>{t('add_booking_button_back')}</Button>
                </div>
            </div >
        );
    }
}