import React, { Component } from 'react';
import * as staffApi from "../../../../api/staff.api";
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import history from '../../../../history';
import { Button } from 'element-react';
import PageTable from "../../../../components/page-table.component";
import moment from 'moment';

@withRouter
@translate()

export default class StaffSalesPage extends Component {
    state = {
        staff: {
            centers: [],
        },
        search:{
            page:1,
            limit:10,
            start : moment(new Date()).startOf('month').toISOString(),
            end : moment(new Date()).endOf('day').toISOString(),
            salesType: this.props.match.params.type, // main-主销售 minor-副销售
            staffId:this.props.match.params.staffId
        },
        loading: true,
    }

    async componentDidMount() {
        console.log(this.props)
        await this.getStaffSales(this.state.search)
    }

    async getStaffSales(query) {
        const salesCommission = query.staffId ? await staffApi.getSalesCommission(query) : null;
        const { data } =  salesCommission
        this.setState({
            staff:Object.assign({},this.state.staff,{data}),
            loading: false
        });
    }

    parseTime(time, fm) {
        // 解析时间  time: 时间戳或者实践对象 fm: 格式 默认是{y}-{m}-{d} {h}:{i}:{s}
        if (arguments.length === 0) {
            return null;
        }
        const format = fm || "{y}-{m}-{d} {h}:{i}:{s}";
        let date;
        if (typeof time === "object") {
            date = time;
        } else {
            if (("" + time).length === 10) time = parseInt(time) * 1000;
            date = new Date(time);
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay(),
        };
        const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
            let value = formatObj[key];
            if (key === "a")
                return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
            if (result.length > 0 && value < 10) {
                value = "0" + value;
            }
            return value || 0;
        });
        return time_str;
    }

    render() {
        const { t } = this.props;
        const { staff, loading, type,search } = this.state

        console.log(staff)
        const columns = [
            {
                label: "套餐名字",
                prop: "name",
            },
            {
                label: "套餐价格",
                prop: "price",
            },
            {
                label: "客户",
                prop: "userId",
            },
            {
                label: "套餐分成",
                render: (row) => {
                    return (
                        <span>{ parseFloat(row.price * row.staff[this.props.match.params.type] / 100) }</span>
                    )
                }
            },
            {
                label: "交易时间",
                prop: "createdAt",
                render: (row) => {
                    return (
                        <span>{ row.createdAt ? this.parseTime(row.createdAt) : null }</span>
                    )
                }
            },
        ];

        return (
            <div className="staff-commission-page" >
                <div className="main-title">
                    {search.salesType == "mainSales" ? '主销售分成交易表' : '副销售分成交易表'}
                </div>
                <div className="service-detail-tab">
                    <div>
                        <div className="row">
                            <div className="title">
                                分店*：
                            </div>
                            <span className="value">MIUSEE BEAUTY</span>
                        </div>
                    </div>
                    <div className="value">
                        {
                            staff.data ?
                            <PageTable
                                results={staff.data.rows}
                                totalResult={staff.data.count}
                                columns={columns}
                                limit={search.limit}
                                currentPage={search.page}
                                loading={loading}
                            /> : null
                        } 
                        {/* <div>提成總計：{staff.total}</div> */}
                    </div>
                </div>
                <div className="page-footer">
                    <Button onClick={() => history.goBack()}>{t('add_booking_button_back')}</Button>
                </div>
            </div >
        );
    }
}