

const initialState = {
    staff: {},
    details: {},
    permissions: {},
    fetching: false,
    fetched: false,
    error: null,
}


function counter(state = initialState, action) {
    switch (action.type) {
        case "FETCH_STAFF": {
            return { ...state, fetching: true }
        }
        case "FETCH_STAFF_REJECTED": {
            return { ...state, fetching: false, error: action.payload }
        }
        case "FETCH_STAFF_FULFILLED": {
            console.log("Permission:", action.payload.permissions);
            return {
                ...state,
                fetching: false,
                fetched: true,
                staff: action.payload,
                details: action.payload.staff_details,
                permissions: (action.payload.permissions),
            }
        }
        default:
            return state;
    }
}

export default counter;