import React, { Component } from 'react';
import './staff-holiday.scss';
import * as staffApi from "../../../../api/staff.api";
import * as reservationApi from "../../../../api/reservation.api";
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import history from '../../../../history';
import { Button,Loading } from 'element-react';
import moment from 'moment';
import { DayView, Event } from '../../../../components/day-view';
import DatePickerButton from "../../../../components/date-picker-button.component";
import { mergeDetails,ServiceTree } from "../../../../utils/dataTransform";
import { getYMD, getMonday, getLastDay } from '../../../../utils/date'
import HolidayInfo from './holiday-info'

@withRouter
@translate()

export default class StaffHoliday extends Component {
    state = {
        scheduleHourHeight: 120,
        scheduleWidth: 140,
        groups:[],
        staff:'',
        day: moment().toDate(),
        showBookingInform: false,
        selectedBooking: null,
        minTime: moment().startOf('day').add(10, 'hours').toDate(),
        maxTime: moment().startOf('day').add(21, 'hours').toDate(),   
        calendarLoading:true,
        currentView: 'day',
        reservations:[],
        visible:false
    }

    async refreshData(view) {
        this.setState({ calendarLoading: true });

        if (!view) {
            view = this.state.currentView;
        }
        else {
            this.setState({ currentView: view });
        }

        console.log(view)
        console.log(this.state.currentView)
        

        let startTime = '00:00:00'
        let endTime = '23:59:59'

        if(this.state.startDate && this.state.endDate) {
            let start = new Date(+new Date(this.state.startDate) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            let end = new Date(+new Date(this.state.endDate) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            startTime = start.replace(/\d{4}-\d{1,2}-\d{1,2}/,'')
            endTime = end.replace(/\d{4}-\d{1,2}-\d{1,2}/,'')
        }

        var start, end, day = this.state.day;
        let year = new Date(day).getFullYear()
        let month = new Date(day).getMonth()+1
        let date = new Date(day).getDate()
        let getDay =  year + "-" + month + "-" + date
        if (view === 'month') {
            let lastDay =  getLastDay(year,month)
            let ymd = year + "-" + month + "-" + "01"
            let emd = year + "-" + month + "-" + lastDay
            start =  ymd+" "+startTime ;
            end =  emd+" "+endTime;
        }
        else if (view === 'week') {
            let startDay = getMonday(day)
            startDay.setDate(startDay.getDate()-1)
            let startYMD = getYMD(startDay)
            start = startYMD +" "+startTime ;
            let endDay = getMonday(day)
            endDay.setDate(endDay.getDate()+6)
            let endYMD =getYMD(endDay)
            end = endYMD+" "+endTime 
        }
        else {
            start = getDay + " "+startTime ;
            end = getDay + " "+endTime;
        }
        
        const reservations = await reservationApi.getDayReservations(
            moment(start,'YYYY-MM-DD HH:mm:ss').toISOString(),
            moment(end,'YYYY-MM-DD HH:mm:ss').toISOString(),
            {
                centerId: this.state.center ? this.state.center.id : undefined,
                serviceId: this.state.service || undefined,
                staffId: this.state.staff || undefined,
                orderBy:'startTime',
                direction:'DESC'
            }
        );
        this.setState({
            reservations:reservations.filter(item=> item.rest == "yes"),
            calendarLoading: false
        });
    }

    async getDatas(){
        let staffs = await staffApi.getStaffs(1, null, null, 1000, '', 'working')

        staffs = staffs.rows.map(staff => mergeDetails(staff, 'staff')).filter(staff => staff.type === 'therapist' || staff.type === 'beautician');
        const groups = staffs.map((staff) => {
            return {
                id: staff.id,
                title: staff.surname + ' ' + staff.name
            }
        });
        this.setAndRefresh({ groups });
    }

    async setAndRefresh(changes) {
        await new Promise(res => {
            this.setState(changes, res);
        });
        await this.refreshData();
    }

    async setDayAndRefresh(day) {
        await this.setAndRefresh({
            day,
            minTime: moment(day).startOf('day').add(10, 'hours').valueOf(),
            maxTime: moment(day).startOf('day').add(21, 'hours').valueOf()
        });
    }

    ChildrenChange = (event) => {
        console.log("evevnt",event)
        let data = {
            centerId: event.staff_schedule.center.id,
            from: new Date(event.startTime) ,
            to: new Date(event.endTime),
            repeat: false,
            alldays: false,
            month: new Date(new Date(event.startTime).getFullYear(), new Date(event.startTime).getMonth()+1, 1),
            scheduleDays: [],
            date: new Date(event.startTime),
            staffScheduleId:event.staffScheduleId,
            staffId:event.staff_schedule.staff.id,
            id:event.id,
        }
        this.setState({
            selectedBooking: data,
            visible:true,
        })
    }

    success(){
        this.setState({visible:false,selectedBooking:null},()=>{
            this.refreshData()
        })
    }

    async componentDidMount() {
        await this.getDatas()
    }
    
    render() {
        const { t } = this.props
        const {scheduleHourHeight, scheduleWidth, groups, day, showBookingInform, selectedBooking, calendarLoading, reservations,staff ,visible} = this.state
        return (
            <main className='staff-holiday-contanier'>
                <section className='staff-holiday-header'>
                    <div className="date" style={{ flex: "unset", width: "100%" }}>
                        <DatePickerButton
                            style={{ border: 'none' }}
                            value={day}
                            placeholder="Select Date"
                            onChange={date => {
                                if (!date) {
                                    this.setDayAndRefresh(new Date());
                                } else {
                                    this.setDayAndRefresh(date);
                                }
                            }}
                        >
                            <div style={{ flex: 1, fontSize: '1.4em', textAlign: 'right' }}>
                                {moment(day).format('MMMM DD, YYYY')}
                            </div>
                        </DatePickerButton>
                    </div>
                    <div className="action-controls" style={{ flex: "unset", width: "100%" }}>
                        <Button onClick={() => this.setState({visible:true})}>新增休息</Button>
                    </div>
                </section>
                
                <Loading loading={calendarLoading}>
                    <div className='day-view-contanier'>
                        <DayView hourHeight={scheduleHourHeight} scheduleWidth={scheduleWidth} groups={groups.filter(g => g.id === staff || staff === '')} day={day}
                            showBookingInform={showBookingInform} selectedBooking={selectedBooking} refreshData={this.refreshData.bind(this)} minTime={8 * 3600000}
                            maxTime={23 * 3600000 - 1}>
                            {reservations.map(res => (
                                <Event key={res.id} reservation={res} ChildChange={this.ChildrenChange} />
                            ))}
                        </DayView>
                    </div>
                </Loading>
                {
                    visible ? <HolidayInfo closed={()=>this.setState({visible:false,selectedBooking:null})} selectedBooking={selectedBooking} staffs={groups} reservations={reservations} success = {()=>this.success()}></HolidayInfo> : null
                }
            </main>
        )
    }
}