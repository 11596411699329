
import './search-bar.scss';

import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import classNames from 'classnames';

@translate()
export default class UserPreviewCard extends PureComponent {

    render() {
        const { t } = this.props;
        const user = this.props.user || {};
        return (
            <div
                className={classNames('user-preview-card', this.props.className)}
                onClick={this.props.onClick}
                style={this.props.style}
            >
                <div>{t('user_search_item_member_id')} {user.id}</div>
                <div>{t('user_search_item_name')} {user.name} {user.surname}</div>
                <div>{t('user_search_item_gender')} {t(user.gender)}</div>
                <div>{t('user_search_item_hkid')} {user.hkid}</div>
                <div>{t('user_search_item_tel')} {user.phone}</div>
            </div>
        );
    }
}