import * as staffApi from "../../api/staff.api";
import sha256 from 'sha256';
import history from '../../history';

export function login(staffId, password) {
    return async function (dispatch) {
        dispatch({ type: "FETCH_SESSION" });
        return staffApi.login(staffId, sha256(password))
            .then(async (response) => {
                dispatch({ type: "FETCH_SESSION_FULFILLED", payload: response })
                await dispatch(getStaff('me'));
                sessionStorage.setItem('session', JSON.stringify(response));
                history.push('/main');
            })
            .catch((err) => {
                dispatch({ type: "FETCH_SESSION_REJECTED", payload: err })
            })
    }
}

export function refreshAccessToken(refreshToken) {

    return async function (dispatch) {
        const response = await staffApi.refreshToken(refreshToken);
        dispatch({ type: "REFRESH_TOKEN", payload: response })
    }
}

export function setSession(session) {
    return async function (dispatch) {
        dispatch({ type: "FETCH_SESSION_FULFILLED", payload: session })
        await dispatch(getStaff('me'));
    }
}

export function getStaff(staffId) {
    return async function (dispatch) {
        dispatch({ type: "FETCH_STAFF" });
        return staffApi.getStaff(staffId)
            .then((response) => {
                dispatch({ type: "FETCH_STAFF_FULFILLED", payload: response })
            })
            .catch((err) => {
                dispatch({ type: "FETCH_STAFF_REJECTED", payload: err })
            })
    }
}

export function setLanguage(languageId) {
    sessionStorage.setItem('languageId', languageId);
    return async function (dispatch) {
        dispatch({ type: "SET_LANGUAGE_ID", payload: languageId });
    }
}


export async function changePassword(staffId, oldPassword, newPassword, confirmNewPassword) {
    return await staffApi.changePassword(staffId, sha256(oldPassword), sha256(newPassword));
}
