import client from '../apiClient';

export async function getCenters() {
    let response = await client.get('/center');
    return response.data;
}

export async function getCenter(centerId) {
    let response = await client.get(`/center/${centerId}`);
    return response.data;
}

export async function createCenter(body) {
    let response = await client.post('/center', body);
    return response.data;
}

export async function updateCenter(centerId, body) {
    let response = await client.patch(`/center/${centerId}`, body);
    return response.data;
}

export async function uploadImages(centerId, images) {
    const formData = new FormData();
    for (let id of Object.keys(images)) {
        formData.append("slides", images[id].file);
    }
    let response = await client.post(`/center/${centerId}/slides`, formData, {
        params: {
            ids: Object.keys(images),
        },
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}