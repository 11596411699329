import React, { Component } from 'react';
import './result-limit.scss';
import { Select } from 'element-react';
import { translate } from 'react-i18next';

@translate()
export default class ResultLimit extends Component {
    render() {
        const { t, limit, setResultLimit } = this.props;
        return (
            <div className="result-limit">
                 {t('tab_booking_result_limit_show')}
                 <Select className="selector" onChange={(key) => setResultLimit(key)} value={limit}>
                     {
                         [10, 20, 30, 40].map((value,i) => {
                             return <Select.Option key={i} label={value} value={value} />
                         })
                     }
                 </Select>
                 {t('tab_booking_result_limit_per')}
            </div>
        )
    }
}