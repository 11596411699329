import $ from 'jquery';

const keysPressed = [];

$(() => {
    const keyDown = (code) => {
        keysPressed.push(code);
    };
    const keyUp = (code) => {
        const idx = keysPressed.indexOf(code);
        if (idx >= 0)
            keysPressed.splice(idx, 1);
    };

    $(document).on("keyup keydown", function(e) {
        switch(e.type) {
            case "keydown" :
                keyDown(e.keyCode);
                if (e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 93 || e.keyCode === 224) {
                    keyDown(COMMAND_OR_CTRL);
                }
                break;
            case "keyup" :
                keyUp(e.keyCode);
                if (e.keyCode === 17 || e.keyCode === 91 || e.keyCode === 93 || e.keyCode === 224) {
                    keyUp(COMMAND_OR_CTRL);
                }
                break;
            default:
                break;
        }
    });
});

export function isKeyPressed(code) {
    return keysPressed.indexOf(code) >= 0;
}

export const SHIFT_KEY_CODE = 16;
export const COMMAND_OR_CTRL = 1000001;