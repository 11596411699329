
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-view.scss';
import { Input } from 'element-react';
import moment from 'moment';
import { baseURL } from "../../../apiClient";

@translate()
export default class ServiceViewBoard extends Component {

    state = {
        categoryTitle: ''
    }

    async componentDidMount() {
        const { service, categories } = this.props;
        let { stratum, stratumId } = service.category
        let title = '';
        if (stratum === 2 && stratumId) {
            categories.rows.forEach(item => {
                if (item.id === stratumId && item.stratum === 1) {
                    title = `${item.category_details[0].title} / ${service.category.category_details[0].title}`
                }
            });
        }
        if (stratum === 1) {
            title = service.category.category_details[0].title;
        }
        await this.setState({
            categoryTitle: title
        })
    }

    render() {
        const { t, service } = this.props;
        let { categoryTitle } = this.state;
        return (service
            ? <div className="service-board-view">
                <div className="block">
                    <div className="row">
                        <div className="title">
                            △{t('service_all_table_column_service_name')}
                        </div>
                        <div className="value">
                            <Input
                                disabled
                                value={service.service_details.name}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('board_service_description')}
                        </div>
                        <div className="value">
                            <Input
                                disabled
                                type="textarea"
                                autosize={{ minRows: 4 }}
                                value={service.service_details.description}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('board_precautions')}
                        </div>
                        <div className="value">
                            <Input
                                disabled
                                type="textarea"
                                autosize={{ minRows: 4 }}
                                value={service.service_details.note}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('service_detail_content_title_category')}
                    </div>
                    <div className="value">
                        <Input
                            disabled
                            value={categoryTitle}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('service_detail_content_title_center')}
                    </div>
                    <div className="value">
                        {
                            service.centers.map((e) => {
                                return (
                                    <div key={e.id} style={{ backgroundColor: "#EEF1F6", padding: "7px", color: '#999' }}>
                                        {`${e.center_details[0].name} - HK$${e.extraFee}`}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('service_detail_content_title_walk_in')}
                    </div>
                    <div className="value">
                        <Input
                            disabled
                            value={t(service.type === "book" ? 'service_detail_content_value_reservation' : 'service_detail_content_value_walk_in')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('service_detail_content_title_status')}
                    </div>
                    <div className="value">
                        <Input
                            disabled
                            value={t(service.active ? 'service_detail_content_value_active' : 'service_detail_content_value_inactive')}
                        />
                    </div>
                </div>
                {service && service.color ?
                    <div className="row">
                        <div className="title">
                            △{t('board_service_color')}
                        </div>
                        <div className="value">
                            <div style={{ width: "28px", height: "28px", backgroundColor: service.color }}></div>
                        </div>
                    </div>
                    : null
                }
                <div className="block">
                    {service.video || service.service_slides.length > 0
                        ?   <div className="row">
                                <div className="title">
                                    {t(service.video ? 'service_detail_content_title_video' : 'service_detail_content_title_images')}
                                </div>
                                <div className="value">
                                    {service.video
                                        ? <div className="video-player">
                                            <video controls poster={`${baseURL}/${service.preview}`}>
                                                <source src={`${baseURL}/${service.video}`} type="video/mp4" />
                                            </video>
                                        </div>
                                        : service.service_slides.map((e) => {
                                            return (
                                                e.path
                                                    ? <img key={e.id} className="image" src={`${baseURL}/${e.path}`} alt="" />
                                                    : null
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        : null
                    }
                </div>
                <div className="block">
                    <div className="row">
                        <div className="title">
                            {t('service_detail_content_title_session_interval')}
                        </div>
                        <div className="value">
                            <Input
                                disabled
                                value={`${service.sessionInterval} ${t('service_detail_content_value_minutes')}`}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('service_detail_content_integral')}
                        </div>
                        <div className="value">
                            <Input
                                disabled
                                value={service.integral}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('service_detail_content_title_price')}
                        </div>
                        <div className="value">
                            <Input
                                disabled
                                value={`HK$ ${service.price}`}
                            />
                        </div>
                    </div>
                </div>
            </div> : null
        );
    }
}