import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Pagination } from 'element-react';
import "./index.scss"
import * as reportApi from "../../../api/report.api";
import moment from 'moment';
import ResultLimit from "../../../components/result-limit.component";


@translate()
export default class ListUserReportPage extends Component {
    state = {
        data:[],
        search:{},
        category:[],
        total:0,
        sumPrice:0,
        columns: [
            {
                label:"",
                render: (row,_,index) => {
                    if(index == 0) {
                        return <div>{row.name}</div> 
                    } else {
                        return <div></div> 
                    }
                }
            },
            {
                label: "日期",
                prop:'startTime',
                render:(row) => {
                    return <div>{moment(row.startTime).format('MM/DD/YYYY')}</div>
                }
            },
            {
                label: "客人姓名",
                render:(row) => {
                    return <div>{row.userSurName}{row.userName}</div>
                }
            },
            {
                label: "電話",
                prop: "phone"
            },
            {
                label: "師傅",
                render:(row) => {
                    return <div>{row.staffSurname}{row.staffName}</div>
                }
            },
            {
                label: "结算方式",
                prop: "payme",
                render:(row)=>{
                    if(row.payme) {
                        return <div>{this.props.t(`main_tab_report_management_${row.payme}`)}</div>
                    }
                }
            },
            {
                label: "付款方式",
                prop: "payment",
                render:(row)=>{
                    if(row.payment && row.payment != '0') {
                        return <div>{row.payment || ''}</div>
                    }
                }
            },
            {
              label: "價格",
              prop: "price"
            },
            {
                label: "优惠",
                render:(data) => {
                    if (data.discountType === 'discount') {
                        return (
                            <span>{((data.price && data.discount && data.price * (1 - data.discount / 100)).toFixed(2)) || 0}</span>
                        )
                    } else {
                        return (
                            <span>{(data.discount && (data.discount).toFixed(2)) || 0}</span>
                        )
                    }
                }
            },
            {
                label: "优惠後價格",
                render:(data) => {
                    if (data.discountType === 'discount') {
                        return (
                            <span>{data.price * (data.discount / 100) || 0}</span>
                        )
                    } else {
                        return (
                            <span>{ data.price - data.discount || 0}</span>
                        )
                    }
                }
            },
        ]
    }

    async getData(){
        const { search } = this.state
        let query = {...search}

        if (search.date && search.date.length > 0) {
            let startTime = moment(search.date[0]).startOf('day').format('YYYY-MM-DD HH:mm')
            let endTime = moment(search.date[1]).endOf('day').format('YYYY-MM-DD HH:mm')
            query.startTime = startTime
            query.endTime = endTime
        }
        
        Object.keys(query).forEach((item) => {
            const key = query[item];
            if (key === "" || key === null || key === undefined) {
                delete query[item];
            }
        });
        delete query['date']

        await reportApi.listSerivceReport(query).then(result => {
            const { data } = result
            if (data && data.length < 0) return
            let categoriesMaps = {}
            const { data:listData,count } = data 
            listData.forEach(item => {
                    categoriesMaps[item.id] = categoriesMaps[item.id] || {
                        title:item.name,
                        id:item.id,
                        data:[]
                    }
                    categoriesMaps[item.id].data.push(item)
            })

            let sumPrice = listData.reduce(function (previousValue, currentValue) {
                return previousValue + parseFloat(currentValue.price ) 
            }, 0)

            this.setState({ sumPrice})

            
            let newCategory = Object.keys(categoriesMaps).map(item => categoriesMaps[item])  

            this.setState({
                total:count,
                category:newCategory
            })
        })
    }

    setResultLimit(limit) {
        this.setState({search: Object.assign({},this.state.search,{limit})}, () => this.getData());
    }

    setCurrentPage(page) {
        this.setState({search: Object.assign({},this.state.search,{page})}, () => this.getData());
    }

    async componentDidMount(){
        const { search } = this.props
        this.setState({
            search:{...search}
        },()=>{
            this.getData()
        })
    }

    componentWillReceiveProps(props) {
        if (props.search) {
            const { search } = this.state
            if (props.search.page != search.page || props.search.limit != search.limit 
                || props.search.userName != search.userName 
                || props.search.staffName != search.staffName
                || props.search.serviceName != search.serviceName
                || props.search.startTime != search.startTime
                || props.search.endTime != search.endTime
                || props.search.date != search.date
            ) {
                this.setState({search: props.search},()=>{
                    this.getData()
                })
            }
        }
    }

    render() {
        const {category , columns, search, total,sumPrice} = this.state
        return (
            <main className='list-user-report-contanier'>
                <section>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={search.limit}/>
                        {total > 0 ? <Pagination layout="prev, pager, next"  total={total} pageSize={search.limit} currentPage={search.page} onCurrentChange={(page) => this.setCurrentPage(page)}/> : null} 
                        <p>消費總額 ：{ sumPrice }</p>
                    </div>
                    {
                        category.length > 0 ? category.map((item,index) => {
                            return (
                                <Table  key={index} columns={columns} data={item.data} showHeader={index == 0 ? true: false}></Table>
                            )
                        }) : <Table columns={columns} data={category}></Table>
                    }
                </section>
            </main>
        )
    }
}