
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-view.scss';
import { Input, Loading, Table, Button, Select, Notification } from 'element-react';
import { baseURL } from "../../../apiClient";
import * as categoryApi from "../../../api/category.api";
import ImageBoard from "../../image-board.component";
import BoardDialog from "../board-category/board-dialog.js";

@translate()
export default class CategoryViewBoard extends Component {
    state = {
        dialogVisible: false,
        loading: true,
        edit: true,
        isReadonly: true,
        data: [],
        columns: [
            {
                label: this.props.t('category_tow_name'),
                prop: "name",
            }, {
                label: this.props.t('category_all_table_column_view_detail'),
                prop: "id",
                render: (row) => {
                    return (
                        <span>
                            <Button onClick={() => this.selectTow(row.id)}>
                                {this.props.t('button_view')}
                            </Button>
                            <Button onClick={() => this.selectTow(row.id, false)}>
                                {this.props.t('button_edit')}
                            </Button>
                            <Button onClick={() => this.deleteTow(row.id)}>{this.props.t('button_delete')}</Button>
                        </span>
                    )
                }
            }
        ],
        info_cht: {},
        info_eng: {},
        obj: {},
        newCategory: {},
        lang: '',//语言
        secondary: {},//二级分类详情
    }
    //初始化
    async componentDidMount() {
        const { category, lng = 'cht' } = this.props;
        console.log('一级分类详情', category)
        let newData = await this.getTowList(category.id)
        await this.setState({
            ...this.state,
            newCategory: category,
            loading: false,
            lang: lng,
            data: newData,
        })
        let length = category.category_details.length;
        if (category && length > 0) {
            await this.setState({
                ...this.state,
                obj: category.category_details.filter(item => item.languageId === lng)[0],
                info_cht: category.category_details.filter(item => item.languageId === 'cht')[0],
                info_eng: category.category_details.filter(item => item.languageId === 'eng')[0],
            })
        }
    }
    //获取二级分类列表
    async getTowList(id) {
        const list = (await categoryApi.getCategories()).rows;
        let newList = []
        list.forEach((item) => {
            if (item.stratumId === id && item.stratum === 2) {
                newList.push({
                    id: item.id,
                    name: item.category_details[0].title
                });
            }
        })
        return newList;
    }
    //设置一级详情
    setOneCategory(key, e) {
        this.setState({
            ...this.state,
            obj: {
                ...this.state.obj,
                [key]: e
            }
        })
    }
    //修改一级详情
    async saveOneCategory() {
        let categoryUpdate = { ...this.state.newCategory };
        let arr = [...categoryUpdate.category_details];
        const { info_cht, info_eng, obj } = this.state;
        if (arr.length > 0) {
            arr.forEach((item, i) => {
                if (item.languageId === 'eng') {
                    categoryUpdate.category_details[i] = info_eng;
                }
                if (item.languageId === 'cht') {
                    categoryUpdate.category_details[i] = info_cht;
                }
                if (item.languageId === obj.languageId) {
                    categoryUpdate.category_details[i] = obj;
                }
            });
        }
        if (categoryUpdate) {
            await categoryApi.updateCategory(categoryUpdate.id, categoryUpdate);
        }
        this.setState({
            ...this.state,
            edit: true,
        })
    }
    //一级详情语言包选择
    async selectLang(e) {
        const { edit, obj } = this.state;
        if (!edit && obj) {
            let len = Object.values(obj).length;
            let isNull = Object.values(obj).includes('');
            if (len !== 5 || isNull) {
                Notification({
                    title: '警告',
                    message: '請將所選語言填充完整！',
                    type: 'warning'
                });
                return;
            }
            if (e !== 'cht') {
                await this.setState({
                    ...this.state,
                    info_cht: obj
                })
            } else {
                await this.setState({
                    ...this.state,
                    info_eng: obj
                })
            }
        }
        const { info_cht, info_eng } = this.state;
        let language = e === 'cht' ? info_cht : info_eng;
        await this.setState({
            ...this.state,
            lang: e,
            obj: language
        })
    }
    //二级详情信息
    async selectTow(id, isReadonly = true) {
        const res = await categoryApi.getCategory(id);
        this.setState({
            ...this.state,
            secondary: res,
            dialogVisible: true,
            isReadonly
        })
    }
    //删除二级分类
    async deleteTow(id) {
        await this.setState({
            ...this.state,
            loading: true,
        })
        await categoryApi.deleteCategory(id)
        const { category } = this.props;
        let newData = await this.getTowList(category.id)
        await this.setState({
            ...this.state,
            loading: false,
            data: newData
        })
    }
    //修改二级详情
    async success(id, e) {
        if (e) {
            await categoryApi.updateCategory(id, e);
        }
        const { category } = this.props;
        let newData = await this.getTowList(category.id)
        await this.setState({
            ...this.state,
            data: newData
        })
    }
    //关闭弹窗
    close() {
        this.setState({
            ...this.state,
            dialogVisible: false
        })
    }

    render() {
        const { loading, data, columns, dialogVisible, edit, newCategory, obj, lang, isReadonly, secondary } = this.state;
        const { t } = this.props;
        return (newCategory && Object.values(newCategory).length > 0
            ? <Loading loading={loading}>
                <div className="category-board-view" style={{ color: '#666' }}>
                    <div className="block">
                        <div style={{ textAlign: 'left', padding: "20px 0" }}>
                            {lang ?
                                <Select value={lang} placeholder={ t('please_select_a_language') } onChange={(e) => this.selectLang(e)} style={{ textAlign: 'center' }}>
                                    {
                                        ['cht', 'eng'].map(e => {
                                            return <Select.Option key={e} label={t(e)} value={e} />
                                        })
                                    }
                                </Select>
                                : null
                            }
                        </div>
                        <main className="row">
                            <section className="title">
                                { t('first_class_classification_name') }
                            </section>
                            <section className="value">
                                <Input
                                    disabled={edit}
                                    value={obj.title}
                                    onChange={(e) => this.setOneCategory('title', e)}
                                />
                            </section>
                        </main>
                        <main className="row">
                            <section className="title">
                                { t('borad_category_description') }
                            </section>
                            <section className="value">
                                <Input
                                    disabled={edit}
                                    type="textarea"
                                    autosize={{ minRows: 4 }}
                                    value={obj.description}
                                    onChange={(e) => this.setOneCategory('description', e)}
                                />
                            </section>
                        </main>
                        <ImageBoard edit={false} localImages={newCategory.slide ? [`${baseURL}/${newCategory.slide}`] : []} />
                        <div style={{ padding: '20px' }}>
                            {edit
                                ? <Button onClick={() => this.setState({ edit: false })}>{t('button_edit')}</Button>
                                : <Button onClick={() => this.saveOneCategory()}>{t('save')}</Button>
                            }
                        </div>
                    </div>
                    <main className='main'>
                        <p>{ t('service_secondary_classification_table') }</p>
                        <Table
                            stripe={true}
                            border={true}
                            columns={columns}
                            rowKey='id'
                            data={data}
                        />
                        {dialogVisible ?
                            <BoardDialog
                                success={(id, e) => this.success(id, e)}
                                close={() => this.close()}
                                secondary={secondary}
                                isReadonly={isReadonly}
                            />
                            : null
                        }
                    </main>
                </div>
            </Loading>
            : null
        );
    }
}