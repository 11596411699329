import React, { Component } from 'react';
import './center-all.scss';
import * as centerApi from "../../../../api/center.api";
import { Table, Button } from 'element-react';
import { translate } from 'react-i18next';
import history from '../../../../history';

@translate()
export default class CenterAllPage extends Component {

    state = {
        results: [],
        columns: [
            {
                label: this.props.t('center_all_table_column_center'),
                prop: "name",
            },
            {
                label: this.props.t('center_all_table_column_status'),
                prop: "status",
            },
            {
                label: this.props.t('center_all_table_column_address'),
                prop: "address",
            },
            {
                label: this.props.t('center_all_table_column_phone'),
                prop: "phone",
            },
            {
                label: this.props.t('center_all_table_column_opening'),
                prop: "opening",
            },
            {
                label: this.props.t('center_all_table_column_view'),
                prop: "detail",
                render: (row) => {
                    return <span>
                        <Button onClick={() => this.switchTab("/main/center-detail/" + row.id)}>{this.props.t('button_view')}</Button>
                    </span>
                }
            },
        ],
    };

    switchTab = (url) => {
        history.push(url);
    }

    async updateCenterRecords() {
        const response = await centerApi.getCenters();
        console.log("center", response);
        const { t } = this.props;
        this.setState({
            results: response.map((center) => {
                const data = {
                    id: center.id,
                    name: center.center_details[0].name,
                    status: center.active ? t('active') : t('inactive'),
                    address: center.center_details[0].address,
                    phone: center.phone,
                    opening: center.center_details[0].openingHour,
                }
                return data;
            }),
            totalResult: response.count,
        });
    }

    async componentDidMount() {
        await this.updateCenterRecords();
    }

    render() {
        const { t } = this.props;
        const { columns, results } = this.state;
        return (
            <div className="center-all-page">
                <div className="main-title">
                    {t('center_all_main_title')}
                </div>
                <div className="page-header">
                    <div className="space"></div>
                    <div className="add-center-button">
                        <Button onClick={() => this.switchTab("/main/center-detail/create")}>{t('center_all_button_add_new_center')}</Button>
                    </div>
                </div>
                <div className="page-body">
                    <Table
                        border={true}
                        columns={columns}
                        data={results}
                    />
                </div>
            </div>
        );
    }
}