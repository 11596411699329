
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-view.scss';
import { Tabs } from 'element-react';
import moment from 'moment';
import { baseURL } from "../../../apiClient";
import ImageBoard from "../../image-board.component";

@translate()
export default class CompanyViewBoard extends Component {
    state = {
        tabs: [
            {
                id: "eng",
                language: "English",
                title: "Title:",
                content: "Content:",
            },
            {
                id: "cht",
                language: "繁體中文",
                title: "標題:",
                content: "內容:",
            },
            {
                id: "chs",
                language: "简体中文",
                title: "标题:",
                content: "内容:",
            },
        ],
        selectedTab: "0",
    }

    getTypeName(type) {
        const { t } = this.props;
        switch (type) {
            case 'age_range':
                return t('notification_detail_target_age_range');
            case 'gender':
                return t('notification_detail_target_gender');
            case 'birthday':
                return t('notification_detail_target_birthday');
            case 'body_condition':
                return t('notification_detail_target_body_condition');
            case 'users':
                return t('notification_detail_radio_users');
            default:
                return t('notification_detail_radio_all_users');
        }
    }

    getTypeValue(type, value) {
        const { t } = this.props;
        switch (type) {
            case 'age_range':
                return value;
            case 'gender':
                switch (value) {
                    case "male":
                        return t('notification_detail_gender_male');
                    default:
                        return t('notification_detail_gender_female');
                }
            case 'birthday':
                switch (value) {
                    case '1':
                        return t('notification_birthday_january');
                    case '2':
                        return t('notification_birthday_february');
                    case '3':
                        return t('notification_birthday_march');
                    case '4':
                        return t('notification_birthday_april');
                    case '5':
                        return t('notification_birthday_may');
                    case '6':
                        return t('notification_birthday_june');
                    case '7':
                        return t('notification_birthday_july');
                    case '8':
                        return t('notification_birthday_august');
                    case '9':
                        return t('notification_birthday_september');
                    case '10':
                        return t('notification_birthday_october');
                    case '11':
                        return t('notification_birthday_november');
                    case '12':
                        return t('notification_birthday_december');
                    default:
                        return t('notification_birthday_january');
                }
            //     return `${t('notification_detail_birthday_message')} ${value} ${t('notification_detail_birthday_message_days')}`;
            case 'body_condition':
                return value.split(',').map((id) => this.getBodyName(id)).toString();
            case 'users':
                return value;
            default:
                return;
        }
    }

    getBodyName(id) {
        const { bodyConditions } = this.props;
        for (const bodyCondition of bodyConditions) {
            if (bodyCondition.id === id) {
                return bodyCondition.body_condition_details[0].name;
            }
        }
    }

    render() {
        const { t, notification } = this.props;
        const { tabs, selectedTab } = this.state;
        return (
            notification ? <div className="notification-board-view">
                <div className="tab">
                    <Tabs activeName={selectedTab} onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })}>
                        {
                            tabs.map((tab, i) => {
                                return (
                                    <Tabs.Pane key={i} label={tabs[i].language} name={i.toString()}>
                                        <div className="block">
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].title}
                                                </div>
                                                <div className="value">
                                                    {notification.notification_details[tabs[i].id] ? notification.notification_details[tabs[i].id].title : null}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].content}
                                                </div>
                                                <div className="value">
                                                    {notification.notification_details[tabs[i].id] ? notification.notification_details[tabs[i].id].content : null}
                                                </div>
                                            </div>
                                        </div>
                                    </Tabs.Pane>
                                )
                            })
                        }
                    </Tabs>
                </div>
                <div className="row">
                    <div className="title">
                        {t('notification_detail_content_title_push_time')}
                    </div>
                    <div className="value">
                        {moment(notification.pushTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('notification_detail_content_title_status')}
                    </div>
                    <div className="value">
                        {t(notification.pushed ? 'notification_all_status_pushed' : 'notification_all_status_un_sent')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('notification_detail_content_title_repeat')}
                    </div>
                    <div className="value">
                        <div>{`${notification.repeat} ${t('notification_detail_content_repeat_per_day')}`}</div>
                    </div>
                </div>
                {
                    notification.notification_targets.length > 0 ?
                        <div>
                            <div className="main-title">
                                {t('notification_detail_content_title_target')}
                            </div>
                            <div className="block">
                                {notification.notification_targets.map((target) => {
                                    return (
                                        <div key={target.id} className="row">
                                            <div className="title">
                                                {
                                                    this.getTypeName(target.targetType)
                                                }
                                            </div>
                                            <div className="value">
                                                {this.getTypeValue(target.targetType, target.target)}
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="row">
                                </div>
                            </div>
                        </div> : null
                }
                <ImageBoard edit={false} localImages={notification.slide ? [`${baseURL}/${notification.slide}`] : []} />
            </div> : null
        );
    }
}