
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-edit.scss';
import { Tabs, Button, Input, Select } from 'element-react';
import * as centerApi from "../../../api/center.api";
import { baseURL } from "../../../apiClient";
import fileDialog from "file-dialog";
import ImageBoard from "../../image-board.component";

@translate()
export default class CenterEditBoard extends Component {
    state = {
        localImages: [`${process.env.PUBLIC_URL}/resources/upload.png`, `${process.env.PUBLIC_URL}/resources/upload.png`, `${process.env.PUBLIC_URL}/resources/upload.png`],
        images: null,
        center: {},
        centerUpdate: null,
        tabs: [
            {
                id: "eng",
                title: "English",
                center: "Center Name:",
                address: "Address:",
                description: "Description:",
                openingHour: "Opening Hour:",
            },
            {
                id: "cht",
                title: "繁體中文",
                center: "服務名稱：",
                address: "地址：",
                description: "描述：",
                openingHour: "辦公時間：",
            },
            // {
            //     id: "chs",
            //     title: "简体中文",
            //     center: "服务名称：",
            //     address: "地址：",
            //     description: "描述：",
            //     openingHour: "办公时间：",
            // },
        ],
        selectedTab: "0",
    }

    async uploadImage(index, remove = false) {
        const newImages = this.state.images ? { ...this.state.images } : {};
        newImages[index] = {};
        if (remove) {
            newImages[index].file = null;
        } else {
            newImages[index].file = (await fileDialog({ accept: 'image/*' }))[0];
        }
        this.setState({ images: newImages },
            () => {
                if (this.state.images[index].file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const newLocalImages = [...this.state.localImages]
                        newLocalImages[index] = e.target.result;
                        this.setState({ localImages: newLocalImages });
                    }
                    reader.readAsDataURL(this.state.images[index].file);
                } else {
                    const newLocalImages = [...this.state.localImages]
                    newLocalImages[index] = `${process.env.PUBLIC_URL}/resources/upload.png`;
                    this.setState({ localImages: newLocalImages });
                }
            }
        );
    }

    async submit() {
        const { centerUpdate, center, images } = this.state;
        let centerId;
        if (center && center.id) {
            centerId = center.id;
            console.log('centerUpdate', centerUpdate);
            if (centerUpdate) {
                await centerApi.updateCenter(center.id, centerUpdate);
            }
        } else {
            centerId = (await centerApi.createCenter(centerUpdate)).id;
        }
        if (images) {
            await centerApi.uploadImages(centerId, images);
        }
        if (centerId)
            this.props.changeMode(centerId);
    }

    updateCenter(updateObj) {
        const newCenterUpdate = {
            ...this.state.centerUpdate,
            ...updateObj,
        };
        const newCenter = {
            ...this.state.center,
            ...updateObj,
        };
        this.setState({
            centerUpdate: newCenterUpdate,
            center: newCenter,
        }, () => {

        });
    }

    updateCenterDetail(languageId, title, value) {
        const newCenterUpdate = { ...this.state.centerUpdate };
        newCenterUpdate.details = newCenterUpdate.details || [];
        let newDetail = { languageId, value: {} };
        let detailIndex = -1;
        for (let i = 0; i < newCenterUpdate.details.length; i++) {
            const detail = newCenterUpdate.details[i];
            if (detail.languageId === languageId) {
                newDetail = { ...detail };
                detailIndex = i;
                break;
            }
        }
        newDetail.value[title] = value;

        if (detailIndex === -1) {
            newCenterUpdate.details.push(newDetail);
        } else {
            newCenterUpdate.details[detailIndex] = newDetail;
        }
        const newCenter = { ...this.state.center };
        if (!newCenter.center_details) {
            newCenter["center_details"] = {};
        }
        if (!newCenter.center_details[languageId]) {
            newCenter.center_details[languageId] = {};
        }
        newCenter.center_details[languageId][title] = value;
        this.setState({
            centerUpdate: newCenterUpdate,
            center: newCenter,
        });
    }

    async componentDidMount() {
        const { center } = this.props;
        let newLocalImages = [...this.state.localImages];
        if (center && center.center_slides) {
            for (let i = 0; i < center.center_slides.length; i += 1) {
                if (center.center_slides[i].path) {
                    newLocalImages[parseInt(center.center_slides[i].id)] = `${baseURL}/${center.center_slides[i].path}`;
                }
            }
        }
        this.setState({
            center: center ? center : {},
            localImages: newLocalImages,
        });
    }

    render() {
        const { t } = this.props;
        const { tabs, selectedTab, center, localImages } = this.state;
        return (
            <div className="center-board-edit">
                <div className="tab">
                    <Tabs activeName={selectedTab} onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })}>
                        {
                            tabs.map((tab, i) => {
                                return (
                                    <Tabs.Pane key={i} label={tabs[i].title} name={i.toString()}>
                                        <div className="block">
                                            <div className="row">
                                                <div className="title">
                                                    * {tabs[i].center}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        value={center.center_details && center.center_details[tabs[i].id] && center.center_details[tabs[i].id].name ? center.center_details[tabs[i].id].name : ""}
                                                        onChange={(value) => this.updateCenterDetail(tabs[i].id, "name", value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].address}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        value={center.center_details && center.center_details[tabs[i].id] && center.center_details[tabs[i].id].address ? center.center_details[tabs[i].id].address : ""}
                                                        onChange={(value) => this.updateCenterDetail(tabs[i].id, "address", value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].description}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        type="textarea"
                                                        autosize={{ minRows: 5 }}
                                                        value={center.center_details && center.center_details[tabs[i].id] && center.center_details[tabs[i].id].description ? center.center_details[tabs[i].id].description : ""}
                                                        onChange={(value) => this.updateCenterDetail(tabs[i].id, "description", value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].openingHour}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        type="textarea"
                                                        autosize={{ minRows: 5 }}
                                                        value={center.center_details && center.center_details[tabs[i].id] && center.center_details[tabs[i].id].openingHour ? center.center_details[tabs[i].id].openingHour : ""}
                                                        onChange={(value) => this.updateCenterDetail(tabs[i].id, "openingHour", value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Tabs.Pane>
                                )
                            })
                        }
                    </Tabs>
                </div>
                <div className="board-body">
                    <div className="row">
                        <div className="title">
                            * {t('center_detail_content_title_status')}
                        </div>
                        <div className="value">
                            <Select onChange={(value) => this.updateCenter({ active: value })} value={center.active}>
                                <Select.Option key={true} label={t('center_detail_content_value_active')} value={true} />
                                <Select.Option key={false} label={t('center_detail_content_value_inactive')} value={false} />
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                        {t('center_detail_content_title_tel')}
                        </div>
                        <div className="value">
                            <Input
                                value={center && center.phone ? center.phone : ""}
                                onChange={(value) => this.updateCenter({ phone: value })}
                            />
                        </div>
                    </div>
                    <div className="row">
                    <div className="title">
                        {t('center_detail_content_title_email')}
                    </div>
                    <div className="value">
                    <Input
                                value={center && center.email ? center.email : ""}
                                onChange={(value) => this.updateCenter({ email: value })}
                            />
                    </div>
                </div>
                    {/*
                    <div className="row">
                        <div className="title">
                            △{t('enter_detail_content_title_latitude')}
                        </div>
                        <div className="value">
                            <Input
                                value={center.latitude ? center.latitude : ""}
                                onChange={(value) => this.updateCenter({ latitude: value })}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            △{t('enter_detail_content_title_longitude')}
                        </div>
                        <div className="value">
                            <Input
                                value={center.longitude ? center.longitude : ""}
                                onChange={(value) => this.updateCenter({ longitude: value })}
                            />
                        </div>
                    </div>
                    */}
                </div >
                <ImageBoard edit={true} localImages={localImages} uploadImage={(index, remove) => this.uploadImage(index, remove)} />
                <div className="board-footer">
                    <Button onClick={() => this.submit()}>{t('button_save')}</Button>
                </div>
            </div>
        );
    }
}