
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-edit.scss';
import { Button, Input, Select, Notification } from 'element-react';
import * as categoryApi from "../../../api/category.api";
import { baseURL } from "../../../apiClient";
import fileDialog from "file-dialog";
import ImageBoard from "../../image-board.component";
import history from '../../../history';

@translate()
export default class CategoryEditBoard extends Component {
    state = {
        localImages: [`${process.env.PUBLIC_URL}/resources/upload.png`],
        images: null,
        categoryUpdate: {},
        category: {},
        obj: {
            title: null,
            description: null,
            languageId: null
        },
        info_cht: {},
        info_eng: {},
        type: 'oneClass',
        lang: 'cht',
        primary: [],//父级分类
        primaryId: '',//父级Id
    }

    async componentDidMount() {
        const res = await categoryApi.getCategories();
        console.log('一级', res.rows)
        console.log('process', process.env.PUBLIC_URL)
        let newPrimary = []
        if (res.rows.length > 0) {
            res.rows.forEach((item) => {
                if (item.stratumId === null || item.stratumId === '0') {
                    newPrimary.push({
                        id: item.id,
                        title: item.category_details[0].title
                    })
                }
            });
        }
        await this.setState({
            ...this.state,
            primary: newPrimary
        })
    }
    //上传,更新照片
    async uploadImage(index, remove = false) {
        const newImages = this.state.images ? { ...this.state.images } : {};
        newImages[index] = {};
        if (remove) {
            newImages[index].file = null;
        } else {
            newImages[index].file = (await fileDialog({ accept: 'image/*' }))[0];
        }
        this.setState({ images: newImages },
            () => {
                if (this.state.images[index].file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const newLocalImages = [...this.state.localImages]
                        newLocalImages[index] = e.target.result;
                        this.setState({ localImages: newLocalImages });
                    }
                    reader.readAsDataURL(this.state.images[index].file);
                } else {
                    const newLocalImages = [...this.state.localImages]
                    newLocalImages[index] = `${process.env.PUBLIC_URL}/resources/upload.png`;
                    this.setState({ localImages: newLocalImages });
                }
            }
        );
    }
    //提交
    async submit() {
        const { categoryUpdate, images, type, info_cht, info_eng, obj, primaryId, lang } = this.state;
        let categoryId;
        if (Object.values(info_cht).length < 3 || Object.values(obj).length < 2) {
            Notification({
                title: '警告',
                message: '请中英文语言全部填充完整！',
                type: 'warning'
            });
            return;
        }
        categoryUpdate.category_details = [];
        if (info_cht.languageId === 'cht') {
            categoryUpdate.category_details.push({
                languageId: 'cht',
                value: info_cht
            })
        }
        if (info_eng.languageId === 'eng') {
            categoryUpdate.category_details.push({
                languageId: 'eng',
                value: info_eng
            })
        }
        if (info_eng.languageId !== 'eng' || info_cht.languageId !== 'cht') {
            if (obj.languageId === 'eng') {
                categoryUpdate.category_details.push({
                    languageId: 'eng',
                    value: obj
                })
            } else if (obj.languageId === 'cht') {
                categoryUpdate.category_details.push({
                    languageId: 'cht',
                    value: obj
                })
            } else {
                obj.languageId = lang;
                categoryUpdate.category_details.push({
                    languageId: lang,
                    value: obj
                })
            }
        }
        if (type === 'oneClass') {
            categoryUpdate.stratum = 1;
            if (categoryUpdate) {
                categoryId = (await categoryApi.createCategory(categoryUpdate)).id;
            }
        }
        if (type === 'towClass') {
            if (!primaryId) {
                Notification({
                    title: '警告',
                    message: '请将所选语言填充完整！',
                    type: 'warning'
                });
                return;
            }
            categoryUpdate.stratum = 2;
            categoryUpdate.stratumId = primaryId;
            if (categoryUpdate) {
                categoryId = (await categoryApi.createCategory(categoryUpdate)).id;
            }
        }
        if (images) {
            await categoryApi.uploadImage(categoryId, images[0].file);
        }
        Notification({
            title: '提示',
            message: '创建成功！',
            type: 'success'
        });
        history.push('/main/category');
    }
    //选择创建类型
    selectType(e) {
        this.setState({
            ...this.state,
            type: e
        })
    }
    //设置详情
    setOneCategory(key, e) {
        this.setState({
            ...this.state,
            obj: {
                ...this.state.obj,
                [key]: e
            }
        })
    }
    //语言包选择
    async selectLang(e) {
        const { obj } = this.state;
        let len = Object.values(obj).length;
        let isNull = Object.values(obj).includes('');
        if (len < 2 || isNull) {
            Notification({
                title: '警告',
                message: '请将所选语言填充完整！',
                type: 'warning'
            });
            return;
        }
        if (e !== 'cht') {
            obj.languageId = 'cht';
            await this.setState({
                ...this.state,
                info_cht: obj
            })
        } else {
            obj.languageId = 'eng';
            await this.setState({
                ...this.state,
                info_eng: obj
            })
        }
        const { info_cht, info_eng } = this.state;
        let language = e === 'cht' ? info_cht : info_eng;
        await this.setState({
            ...this.state,
            lang: e,
            obj: language
        })
    }

    render() {
        const { t } = this.props;
        const { obj, type, lang, localImages, primary, primaryId } = this.state;
        return (
            <div className="category-board-edit">
                <main className="row">
                    <section className="title">
                        △{'请选择创建分类类型'}
                    </section>
                    <section className="value">
                        <Select value={type} placeholder={t('please_choose')} clearable={true} onChange={(e) => this.selectType(e)}>
                            {
                                ['oneClass', 'towClass'].map((e, i) => {
                                    return <Select.Option key={i} label={t(e)} value={e} />
                                })
                            }
                        </Select>
                    </section>
                </main>
                {type === 'oneClass'
                    ? (<div className="block" key="oneClass">
                        <div style={{ textAlign: 'left', padding: "20px 0" }}>
                            <Select value={lang} placeholder={t('please_select_a_language')} onChange={(e) => this.selectLang(e)} style={{ textAlign: 'center' }}>
                                {
                                    ['cht', 'eng'].map(e => {
                                        return <Select.Option key={e} label={t(e)} value={e} />
                                    })
                                }
                            </Select>
                        </div>
                        <div>

                        </div>
                        <main className="row">
                            <section className="title">
                                {t('first_class_classification_name')}
                            </section>
                            <section className="value">
                                <Input
                                    value={obj.title}
                                    onChange={(e) => this.setOneCategory('title', e)}
                                />
                            </section>
                        </main>
                        <main className="row">
                            <section className="title">
                                {t('borad_category_description')}
                            </section>
                            <section className="value">
                                <Input
                                    type="textarea"
                                    autosize={{ minRows: 4 }}
                                    value={obj.description}
                                    onChange={(e) => this.setOneCategory('description', e)}
                                />
                            </section>
                        </main>
                    </div>)
                    :
                    (<div className="block" key="towClass">
                        <div style={{ textAlign: 'left', padding: "20px 0" }}>
                            <Select value={lang} placeholder={t('please_select_a_language')} onChange={(e) => this.selectLang(e)} style={{ textAlign: 'center' }}>
                                {
                                    ['cht', 'eng'].map(e => {
                                        return <Select.Option key={e} label={t(e)} value={e} />
                                    })
                                }
                            </Select>
                        </div>
                        <main className="row">
                            <section className="title">
                                {t('Owning_parent')}
                            </section>
                            <section className="value">
                                <Select value={primaryId} placeholder={t('please_choose')} onChange={(e) => this.setState({ ...this.state, primaryId: e })} style={{ textAlign: 'center' }}>
                                    {primary.length > 0
                                        ? primary.map(e => {
                                            return <Select.Option key={e.id} label={e.title} value={e.id} />
                                        })
                                        : null
                                    }
                                </Select>
                            </section>
                        </main>
                        <main className="row">
                            <section className="title">
                                {t('category_tow_name')}
                            </section>
                            <section className="value">
                                <Input
                                    value={obj.title}
                                    onChange={(e) => this.setOneCategory('title', e)}
                                />
                            </section>
                        </main>
                        <main className="row">
                            <section className="title">
                                {t('borad_category_description')}
                            </section>
                            <section className="value">
                                <Input
                                    type="textarea"
                                    autosize={{ minRows: 4 }}
                                    value={obj.description}
                                    onChange={(e) => this.setOneCategory('description', e)}
                                />
                            </section>
                        </main>
                    </div>)
                }
                <ImageBoard
                    edit={true} localImages={localImages}
                    uploadImage={(index, remove) => this.uploadImage(index, remove)}
                />
                <div className="board-footer" style={{ padding: '20px 20px 30px' }}>
                    <Button onClick={() => this.submit()}>{t('button_save') + '~' + t('button_create')}</Button>
                </div>
            </div>
        );
    }
}