import React, { Component } from 'react';
import './user-feedback.scss';
import * as reservationApi from "../../../../api/reservation.api";
import { Dialog, Button } from 'element-react';
import { translate } from 'react-i18next';
import moment from 'moment';
import history from '../../../../history';
import PageTable from "../../../../components/page-table.component";
import ResultLimit from "../../../../components/result-limit.component";

@translate()
export default class UserFeedbackPage extends Component {

    state = {
        results: [],
        lastTextChange: 0,
        selectedUser: undefined,
        currentPage: 1,
        orderBy: "createdAt",
        direction: "ASC",
        limit: 40,
        totalResult: 0,
        selectedFeedback: null,
        sort: null,
        columns: [
            {
                label: this.props.t('user_feedback_table_column_date'),
                prop: "date",
                sortable: "custom",
                width: 110,
            },
            {
                label: this.props.t('user_feedback_table_column_surname'),
                prop: "surname",
                width: 120,
            },
            {
                label: this.props.t('user_feedback_table_column_name'),
                prop: "name",
                width: 120,
            },
            {
                label: this.props.t('user_feedback_table_column_tel'),
                prop: "tel",
            },
            {
                label: this.props.t('user_feedback_table_column_center'),
                prop: "center",
            },
            {
                label: this.props.t('user_feedback_table_column_appointment'),
                prop: "appointment",
                width: 200,
            },
            {
                label: this.props.t('user_feedback_table_column_staff_name'),
                prop: "staff_name",
            },
            {
                label: this.props.t('user_feedback_table_column_general_rating'),
                prop: "general_rating",
                sortable: "custom",
            },
            {
                label: this.props.t('user_feedback_table_column_staff_rating'),
                prop: "staff_rating",
                sortable: "custom",
            },
            {
                label: this.props.t('user_feedback_table_column_view_feedback'),
                prop: "feedback",
                render: (row) => {
                    return <span>
                        <Button onClick={() => this.setState({
                            selectedFeedback: row,
                        })}>{this.props.t('button_view')}</Button>
                    </span>
                }
            },
        ],
        loading: true
    };

    switchTab = (url) => {
        history.push(url);
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.updateUserRecords());
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.updateUserRecords())
    }

    setSort(e) {
        if (e.order) {
            this.setState({
                orderBy: e.prop,
                direction: e.order === "ascending" ? "ASC" : "DESC",
            });
            this.updateUserRecords();
        }
    }

    async updateUserRecords() {
        // const { currentPage, orderBy, direction, limit } = this.state;
        // const response = await reservationApi.getUserReservations(null, false, currentPage, orderBy, direction, limit);
        // console.log('response:',  response);
        // this.setState({
        //     results: response.rows.map((reservation) => {
        //         const data = {
        //             date: moment(reservation.createdAt).format("YYYY/MM/DD"),
        //             time: `${moment(reservation.startTime).format("hh:mmA")}-${moment(reservation.endTime).format("hh:mmA")}`,
        //             surname: reservation.user.surname,
        //             name: reservation.user.name,
        //             tel: reservation.user.phone,
        //             center: reservation.center,
        //             appointment: `${reservation.category}-${reservation.service}`,
        //             staff_name: reservation.staff,
        //             general_rating: reservation.overallRating ? `${reservation.overallRating}/5` : null,
        //             staff_rating: reservation.therapistRating ? `${reservation.therapistRating}/5` : null,
        //             feedBack: reservation.feedBack,
        //         }
        //         return data;
        //     }),
        //     totalResult: response.count,
        //     loading: false,
        // });
    }

    async componentDidMount() {
        await this.updateUserRecords();
    }

    render() {
        const { t } = this.props;
        const { limit, columns, currentPage, totalResult, results, selectedFeedback, sort, loading } = this.state;
        return (
            <div className="user-feedback-page">
                <div className="main-title">
                    {t('user_feedback_title')}
                </div>
                <div className="page-header">
                    <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} />
                    <div className="space"></div>
                    <div className="add-user-button">
                        <Button onClick={() => this.switchTab("/main/user-detail/create")}>{t('user_all_button_add_user')}</Button>
                    </div>
                </div>
                <PageTable
                    limit={limit}
                    currentPage={currentPage}
                    totalResult={totalResult}
                    results={results}
                    sort={sort}
                    columns={columns}
                    setSort={(e) => this.setSort(e)}
                    setCurrentPage={(page) => this.setCurrentPage(page)}
                    loading={loading}
                />
                {selectedFeedback ?
                    <Dialog
                        size={'tiny'}
                        title={t('user_feedback_dialog_title')}
                        visible={!!selectedFeedback}
                        onCancel={() => this.setState({ selectedFeedback: null })}
                    >
                        <div className="dialog-body">
                            <div className="row">
                                <div className="title">
                                    {t('user_feedback_dialog_title_date_time')}
                                </div>
                                <div className="value">
                                    {`${selectedFeedback.date} ${selectedFeedback.time}`}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('user_feedback_dialog_title_user_name')}
                                </div>
                                <div className="value">
                                    {`${selectedFeedback.name} ${selectedFeedback.surname}`}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('user_feedback_dialog_title_contact')}
                                </div>
                                <div className="value">
                                    {selectedFeedback.tel}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('user_feedback_dialog_title_center')}
                                </div>
                                <div className="value">
                                    {selectedFeedback.center}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('user_feedback_dialog_title_appointment')}
                                </div>
                                <div className="value">
                                    {selectedFeedback.appointment}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('user_feedback_dialog_title_staff_name')}
                                </div>
                                <div className="value">
                                    {selectedFeedback.staff_name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('user_feedback_dialog_title_overall')}
                                </div>
                                <div className="value">
                                    {selectedFeedback.general_rating}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('user_feedback_dialog_title_therapy')}
                                </div>
                                <div className="value">
                                    {selectedFeedback.staff_rating}
                                </div>
                            </div>
                            <div className="row">
                                <div className="title">
                                    {t('user_feedback_dialog_title_note')}
                                </div>
                                <div className="value">
                                    {selectedFeedback.feedBack}
                                </div>
                            </div>
                        </div>
                    </Dialog> : null}
            </div>
        );
    }
}