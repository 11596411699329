import React, { Component } from 'react';
import './tab-salary-view.scss';
import { translate } from 'react-i18next';
import { Button } from 'element-react';
import * as staffApi from "../../../api/staff.api";
import moment from 'moment';

@translate()
export default class StaffCSalaryViewTab extends Component {

    state={
        staff:{}
    }

    async componentDidMount() {
        const { staff } = this.props
        if(staff.id){
            let response = await staffApi.getStaffSaraly(staff.id)
           

            let salesQuery = {
                start : moment(new Date()).startOf('month').toISOString(),
                end : moment(new Date()).endOf('day').toISOString(),
                staffId : staff.id
            }

            let salesResponse = await staffApi.getSalesCommission(salesQuery)

            if(salesResponse && response) {
                this.setState({
                    staff:Object.assign({},this.props.staff,{...response,...salesResponse.data}) 
                })
            }
        }
    }

    render() {
        const { t, switchTab } = this.props;
        const { staff } = this.state
        return (
            <div className="salary-tab">
                <div className="tab-left">
                    <div className="row">
                        <div className="title">
                            {t('staff_tab_basic_info_title_basic_salary')}
                        </div>
                        <div className="value">
                            {staff.basicSalary}
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('staff_tab_salary_commission')}
                        </div>
                        <div className="value">
                            {staff.commissionRecordChecked}
                            <Button onClick={() => switchTab(`/main/staff-commission/checked/${this.props.staff.id}`)}>{t('button_view')}</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('staff_tab_salary_expected_commission')}
                        </div>
                        <div className="value">
                            {staff.commissionSalaryWaiting}
                            <Button onClick={() => switchTab(`/main/staff-commission/waiting/${this.props.staff.id}`)}>{t('button_view')}</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            月初至今主销售分成
                        </div>
                        <div className="value">
                            {staff.main}
                            <Button onClick={() => switchTab(`/main/staff-sales/main/${this.props.staff.id}`)}>{t('button_view')}</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            月初至今副销售分成
                        </div>
                        <div className="value">
                            {staff.minor}
                            <Button onClick={() => switchTab(`/main/staff-sales/minor/${this.props.staff.id}`)}>{t('button_view')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}