import React, { Component } from 'react';
import './staff-schedule.scss';
import * as staffApi from "../../../../api/staff.api";
import * as centerApi from "../../../../api/center.api";
import * as serviceApi from "../../../../api/service.api";
import * as scheduleApi from '../../../../api/schedule.api';
import * as reservationApi from '../../../../api/reservation.api'
import { translate } from 'react-i18next';
import withRouter from "react-router-dom/es/withRouter";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Button, DatePicker, Dialog, Form, Loading, MessageBox, Select, Switch, TimeSelect} from "element-react";
import {mergeDetails, normalize} from "../../../../utils/dataTransform";
import AccountIcon from "../../../../components/icon.component";
import DatePickerButton from "../../../../components/date-picker-button.component";
import * as repeatHelper from './schedule-repeat';
import uuid from 'uuid/v4';
import {COMMAND_OR_CTRL, isKeyPressed, SHIFT_KEY_CODE} from "../../../../utils/keyEvent";
import { setError } from "../../../../redux/staff/error.action";
import {connect} from "react-redux";
import history from '../../../../history';
const localizer = momentLocalizer(moment);

const now = new Date()
const defaultAddScheduleForm = () => ({
    centerId: null,
    serviceIds: [],
    from: undefined,
    to: undefined,
    repeat: false,
    alldays: false,
    month: new Date(now.getFullYear(), now.getMonth()+1, 1),
    startingDate: now,
    endDate: now,
    days: [],
    scheduleDays: [],
    date: now,
    errorMessage: '',
});

@withRouter
@translate()
@connect(null, dispatch => ({
    setError: (e) => dispatch(setError(e)),
}))
export default class StaffSchedulePage extends Component {

    state = {
        events: [],
        eventsToDelete: [],
        staff: null,
        isEditing: false,
        date: new Date(),
        loading: false,

        centers: {},
        categories: {},
        services: {},

        centerList: [],
        categoryList: [],
        serviceList: [],

        editingSchedule: null,
        addingSchedule: false,
        selectedEvents: [],
        anchorEvent: null,

        scheduleDayOptions: [],

        addScheduleForm: defaultAddScheduleForm(),

        failedScheduleList: [],
        failedSchedule: false,
        currentView: undefined,
        working:true,
        show:false
    };

    async componentDidMount() {

        const [
            staff,
            centers,
            services,
        ] = await Promise.all([
            staffApi.getStaff(this.props.match.params.staffId),
            centerApi.getCenters(),
            serviceApi.getServices(1, 'id', 'ASC', 1000, ''),
        ]);

        // console.log("staff:", staff);
        const staffCenters = staff.centers;
        const centerList = staffCenters;//.map(x => mergeDetails(x, 'center'));
        const serviceList = staff.services;
        this.setState({
            staff: mergeDetails(staff, 'staff'),
            centerList,
            serviceList,
            centers: normalize(centers.map(x => mergeDetails(x, 'center'))),
            services: normalize(services.rows),
        });

        await this.onNavigate(this.state.date);
    }

    setAllService() {
        const {
            addScheduleForm,
            staff,
            centerList,
        } = this.state;

        const staffServices = staff ? staff.services : [];
        const ids = staffServices.filter(service => {
                        if (!addScheduleForm.centerId)
                            return false;
                        const found = centerList.find(c => c.id === addScheduleForm.centerId).services.find(ser => {
                            return ser === service.id
                        });
                        return !!found;
                    })
                    .map(service => service.id);

        this.setAddScheduleForm('serviceIds', ids);
    }

    setAllDays(date) {
        this.state.scheduleDays = [];
        var month = moment(date).month() + 1;
        var year = moment(date).year();
        var dayNum = new Date(year, month, 0).getDate();
        var scheduleDays = [];
        var scheduleDayOptions = [];
        for(var i=1;i<=dayNum;i++) {
            var day = moment(date).date(i).toDate();
            scheduleDayOptions.push({id:day,name:i+"/"+month,value:day});
            scheduleDays.push(day);
        }
        this.setState({scheduleDayOptions: scheduleDayOptions});
        this.setAddScheduleForm('scheduleDays', scheduleDays)
    }

    async promptSave() {
        // await this.save();
    }

    async prevWeek() {
        // if (this.state.isEditing) {
        //     await this.promptSave();
        // }
        await this.onNavigate(moment(this.state.date).add(-1, 'week').toDate());
    }

    async nextWeek() {
        // if (this.state.isEditing) {
        //     await this.promptSave();
        // }
        await this.onNavigate(moment(this.state.date).add(1, 'week').toDate());
    }

    async save() {
        // try {
            this.setState({
                loadingSchedule: true,
            });
            let toCreate = this.state.events.filter(ev => ev.state === 'toBeCreated');
            let toUpdate = this.state.events.filter(ev => ev.state === 'toBeUpdated');
            let toDelete = this.state.eventsToDelete;

            toCreate = toCreate.map(s => {
                s = {...s};
                delete s.id;
                delete s.state;
                return s;
            });
            toUpdate = toUpdate.map(s => ({
                scheduleId: s.id,
                services: s.services,
                centerId: s.centerId,
                startTime: s.startTime,
                endTime: s.endTime,
                bookable: s.bookable,
                remark: s.remark,
            }));
            toDelete = toDelete.map(s => s.id);

            await Promise.all([
                scheduleApi.createStaffSchedule(toCreate),
                scheduleApi.updateStaffSchedule(toUpdate),
                scheduleApi.deleteStaffSchedule(toDelete),
            ])
            .then(data => {
                var createRes = data[0].data;
                var updateRes = data[1].data;
                var deleteRes = data[2];

                //alert not created dates
                const { centerList } = this.state;
                var createNotSucceedDates = [];
                for(var i in toCreate) {
                    var found = false;
                    
                    for(var j=0;j<createRes.length;j++) {
                        if(toCreate[i].startTime.getTime() === new Date(createRes[j].startTime).getTime()
                            && toCreate[i].endTime.getTime() === new Date(createRes[j].endTime).getTime()
                            && toCreate[i].centerId == createRes[j].centerId) {
                            found = true;
                            break;
                        }
                    }
                    if(!found) {
                        createNotSucceedDates.push(toCreate[i]);
                    }
                }
                var alertTexts = [];
                for(var i=0;i<createNotSucceedDates.length;i++) {
                    var txt = '';
                    txt += this.formatTimeStr(createNotSucceedDates[i].startTime);
                    txt += ' - ';
                    txt += this.formatTimeStr(createNotSucceedDates[i].endTime);
                    txt += ' at ';
                    txt += centerList.find(c => c.id === createNotSucceedDates[i].centerId).name;
                    txt += '\r\n';
                    alertTexts.push(txt);
                }
                if(alertTexts.length > 0) {
                    this.setState({
                        failedSchedule: true,
                        failedScheduleList: alertTexts
                    });
                }
                return {createRes,updateRes}
            }).then((response)=>{
                if (response.createRes) {
                    for(var i = 0 ; i<response.createRes.length ; i++){
                        if(response.createRes[i].rest == 'yes') {
                            let obj = {
                                targetUserId:'',
                                bookUserId:'',
                                staffScheduleIds:[response.createRes[i].id],
                                serviceId:'rest',
                                bookingUser:'',
                                remark:'',
                                startTime:response.createRes[i].startTime,
                                endTime:response.createRes[i].endTime,
                                insert:"rest"
                            }
                            reservationApi.createRest(obj)
                        }
                    }
                }else if (response.updateRes) {
                    for(var i = 0 ; i<response.updateRes.length ; i++){
                        if (response.updateRes[i].rest == 'yes') {
                            let data = {
                                startTime : response.updateRes[i].startTime,
                                endTime : response.updateRes[i].endTime
                            }
                            for (var j = 0; j < response.updateRes[i].reservations.length; j++) {
                                let id = response.updateRes[i].reservations[j].id
                                if (id) {
                                    reservationApi.updateReservation(id, data)
                                }
                            }
                        }                       
                    }
                }
            })
            this.setState({
                loadingSchedule: false,
            });
            return this.exitEditMode();
    }
    async cancel() {
        await this.exitEditMode();
    }
    async exitEditMode() {
        await new Promise(cb => {
            this.setState({
                events: [],
                isEditing: false,
                eventsToDelete: [],
                selectedEvents: [],
            }, cb);
        });

        await this.refreshSchedule();
    }

    strToTime(timeStr) {
        timeStr = timeStr.replace('T', ' ').replace('Z', '');
        return new Date(timeStr);
    }

    formatTimeStr(date) {
        return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' ' +date.getHours()+":"+String(date.getMinutes()).padStart(2, "0");
    }

    cancelAddSchedule() {
        this.setState({
            addingSchedule: false,
            addScheduleForm: defaultAddScheduleForm(),
        });
    }

    validateAddScheduleForm() {
        const { t } = this.props;
        const {
            centerId,
            from,
            to,
            repeat,
            days,
            startingDate,
            endDate,
            date,
            alldays,
            scheduleDays
        } = this.state.addScheduleForm;

        if (!centerId) {
            this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('center') }));
            return false;
        }
        if (!from) {
            this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('time_from') }));
            return false;
        }
        if (!to) {
            this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('time_to') }));
            return false;
        }
        if (repeat) {
            if (!days) {
                this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('day_of_week') }));
                return false;
            }
            if (!startingDate) {
                this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('starting_date') }));
                return false;
            }
            if (!endDate) {
                this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('end_date') }));
                return false;
            }
        } else if (alldays) {
            if (!scheduleDays || scheduleDays.length == 0) {
                this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('date') }));
                return false;
            }
        } else {
            if (!date) {
                this.setAddScheduleForm('errorMessage', t('error:please_input_field', { field: t('date') }));
                return false;
            }
        }

        this.setAddScheduleForm('errorMessage', '');
        return true;
    }

    async finishAddSchedule() {
        const staffId = this.props.match.params.staffId;
        if (!this.validateAddScheduleForm()){
            return;
        }
        const {
            centerId,
            serviceIds,
            scheduleDays,
            from,
            to,
            repeat,
            days,
            startingDate,
            endDate,
            date,
            month,
            alldays
        } = this.state.addScheduleForm;

        const { working } = this.state

        const toCreate = [];

        const duration = moment.duration(moment(to).diff(moment(from))).asMilliseconds();


        const intervals = serviceIds.map((id) => this.state.services[id].sessionInterval);
        const gcdInterval = intervals.length > 0 ?
            repeatHelper.gcdList(intervals) :
            (to.getTime() - from.getTime()) / 60000;
        const serviceDuration = gcdInterval * 60000; // this.state.services[serviceIds[0]].sessionInterval * 60000;

        let datesToAddSchedule;
        if (repeat) {
            datesToAddSchedule = repeatHelper.getDatesForRepeatedDays(moment(startingDate), moment(endDate), days);
        } else if (alldays) {
            datesToAddSchedule = []
            for(var item in scheduleDays) {
                datesToAddSchedule.push(moment(scheduleDays[item]));
            }
        } else {
            datesToAddSchedule = [moment(date)];
        }

        datesToAddSchedule.forEach((date) => {

            const slots = repeatHelper.getTimeForRepeatedTimeInADay(date, moment(from), moment(to), serviceDuration);
            // slots.forEach(t => {
            //    toCreate.push({
            //        id: uuid(),
            //        staffId,
            //        services: serviceIds,
            //        centerId,
            //        startTime: t.toDate(),
            //        endTime: t.clone().add(serviceDuration, 'ms').toDate(),
            //        bookable: 'yes',
            //        remark: '',
            //        state: 'toBeCreated',
            //    })
            // });

            const year = date.year();
            const month = date.month();
            const day = date.date();
            var fromTime = moment(from)
            var toTime = moment(to)
            fromTime.year(year); toTime.year(year);
            fromTime.month(month); toTime.month(month);
            fromTime.date(day); toTime.date(day);

            toCreate.push({
               id: uuid(),
               staffId,
               services: serviceIds,
               centerId,
               startTime: fromTime.toDate(),
               endTime: toTime.toDate(),
               bookable: working?'yes':'no',
               rest: working?'no':'yes',
               remark: '',
               state: 'toBeCreated',
            })
        });

        const { events } = this.state
        let newData = [...events,...toCreate]
        let response = newData.filter(item=> moment(item.startTime).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD'))
        let result = this.isHasRepeatTime(response)

        // 添加前判断有没有日程冲突
        if(!result) {
            this.setState({
                events: this.state.events.concat(toCreate)
            });
            this.cancelAddSchedule();
        }else {
            MessageBox.alert('日期中的时间段有冲突，请注意检查！', '警告');
        }
    }

    // 添加日程表前判断休息日和工作日的时间段有没有重复
    isHasRepeatTime(data) {
        const startTimeArr = []
        const endTimeArr = [];
        (data || []).map(function(item) {
          startTimeArr.push(item.startTime)
          endTimeArr.push(item.endTime)
        })
        const allStartTime = startTimeArr.sort()
        const allEndTime = endTimeArr.sort()
        let result = 0
        for (let k = 1; k < allStartTime.length; k++) {
          if (allStartTime[k] < allEndTime[k - 1]) {
            result += 1
          }
        }
        return result > 0
    }

    setAddScheduleForm(key, value, callback) {
        console.log(key)
        this.setState({
            addScheduleForm: {
                ...this.state.addScheduleForm,
                [key]: value,
            }
        }, () => {
            callback && callback();
        })
    }

    async onNavigate(date) {
        await new Promise(cb => this.setState({ date }, cb));
        await this.refreshSchedule();
    }

    async refreshSchedule(view) {
        if(!view) {
            view = this.state.currentView;
        }
        else {
            this.setState({currentView: view});
        }
        var begin, end
        , day = this.state.date;
        if(view === 'month') { 
            begin = (moment(day).startOf('month'));
            end = (moment(day).endOf('month'));
        }
        else if(view === 'day') {
            begin = moment(day).startOf('day');
            end = (moment(day).startOf('day').add(2, 'day'));
            // console.log('date.........');
            // console.log(begin.toDate());
            // console.log(end.toDate());
        }
        else {
            begin = moment(day).startOf('week');
            end = begin.clone().add(1, 'week');
        }


        console.log(" ======================== beign ===========================")
        console.log(begin)


        this.setState({ loadingSchedule: true });
        var response = await scheduleApi.getStaffSchedule(this.props.match.params.staffId, begin.toDate(), end.toDate());
        this.setState({ loadingSchedule: false });
        console.log("apiRes:", response);
        var initEvents = this.state.events;
        for(var i=0;i<initEvents.length;i++) {
            if(initEvents[i].state === 'toBeUpdated') {
                var found = false;
                for(var j=0;j<response.length;j++) {
                    if(initEvents[i].id === response[j].id) {
                        found = true;
                        response[j] = initEvents[i];
                    }
                }
                if(!found)
                    response.push(initEvents[i]);
            }
            else if(initEvents[i].state === 'toBeCreated') {
                response.push(initEvents[i]);
            }
        }

        for(var i=0;i<this.state.eventsToDelete.length;i++) {
            for(var j=0;j<response.length;j++) {
                if(this.state.eventsToDelete[i].id === response[j].id) {
                    response.splice(j, 1);
                }
            }
        }
        console.log(response);

        this.setState({
            events: response.map(ev => {
                return {
                    ...ev,
                    startTime: moment(ev.startTime).toDate(),
                    endTime: moment(ev.endTime).toDate(),
                    // services: ev.service_schedules.map(s => s.service.id),
                    // services: ev.service_schedules.map(s => s.service),
                }
            }),
        });
    }

    addSchedulePopup(startTime, to, type) {
        const from = moment(startTime).startOf('hour');
        if (!to) {
            to = from.clone().add(1, 'hour');
        } else {
            to = moment(to).startOf('hour');
        }
        if (from.isSame(to)) {
            to.add(1, 'hour');
        }
        // const date = from.clone().startOf('day');
        const date = from.clone();
        
        this.setState({
            addScheduleForm: {
                ...this.state.addScheduleForm,
                from: from.toDate(),
                to: to.toDate(),
                date: date.toDate(),
                startingDate: date.toDate(),
                endDate: to.startOf('day').toDate(),
            },
            addingSchedule: true,
        });

        if (type && type == 1) {
            this.setState({
                working:true
            })
        } else {
            this.setState({
                working:false
            })
        }

        this.setAllDays(this.state.addScheduleForm.month);
    }

    toggleEvent(eventList, ev) {
        const index = eventList.findIndex((v) => {
            return v.id === ev.id;
        });
        if (index === -1) {
            eventList.push(ev);
        } else {
            eventList.splice(index, 1);
        }
        return eventList;
    }

    onSelectEvent(ev) {
        if (!this.state.isEditing) {
            return;
        }
        if (isKeyPressed(SHIFT_KEY_CODE)) {
            if (this.state.selectedEvents.length === 0) {
                const clonedList = [...this.state.selectedEvents];
                this.toggleEvent(clonedList, ev);
                this.setState({
                    selectedEvents: clonedList,
                    anchorEvent: ev,
                });
            } else {
                let start = this.state.anchorEvent.startTime;
                let end = ev.endTime;
                if (start > end) {
                    start = ev.startTime;
                    end = this.state.anchorEvent.endTime;
                }
                const evs = this.state.events.filter((ev) => {
                    return (ev.startTime > start) && (ev.endTime <= end);
                });
                const clonedList = [...this.state.selectedEvents];
                for(const ev of evs) {
                    this.toggleEvent(clonedList, ev);
                }
                this.setState({
                    anchorEvent: ev,
                    selectedEvents: clonedList,
                });
            }
        } else if (isKeyPressed(COMMAND_OR_CTRL)) {
            const clonedList = [...this.state.selectedEvents];
            this.toggleEvent(clonedList, ev);
            this.setState({
                selectedEvents: clonedList,
                anchorEvent: ev,
            });
        } else {
            this.setState({
                selectedEvents: [ev],
                anchorEvent: ev,
            }, () => {
                this.editSelectedSchedule();
            });
        }
    }

    editSelectedSchedule() {
        let [ev] = this.state.selectedEvents;
        ev = {
            ...ev,
            serviceIds: []
        };
        if (this.state.selectedEvents.length > 1) {
            ev.centerId = null;
            ev.serviceIds = [];
        }
        this.setState({
            editingSchedule: ev,
            show:true
        }, () => {
            this.setState({
                editingSchedule: {
                    ...this.state.editingSchedule,
                    serviceIds: this.state.editingSchedule.serviceIds
                }
            });
        });
        console.log(ev);
    }

    cancelEditSchedule() {
        this.setState({
            editingSchedule: null,
            selectedEvents: [],
            anchorEvent: null,
            show:false
        });
    }

    closeFailedSchedule() {
        this.setState({
            failedSchedule: false
        });
    }

    editScheduleProperty(key, value) {
        this.setState({
            editingSchedule: {
                ...this.state.editingSchedule,
                [key]: value,
            }
        });
    }
    editScheduleDate(date) {
        date = moment(date);
        const year = date.year();
        const month = date.month();
        const day = date.day();
        let startTime = moment(this.state.editingSchedule.startTime);
        let endTime = moment(this.state.editingSchedule.endTime);

        startTime.year(year); endTime.year(year);
        startTime.month(month); endTime.month(month);
        startTime.day(day); endTime.day(day);

        console.log(date);
        this.setState({
            editingSchedule: {
                ...this.state.editingSchedule,
                startTime: startTime.toDate(),
                endTime: endTime.toDate(),
            }
        });
    }
    editScheduleFrom(from) {
        from = moment(from);
        const { editingSchedule } = this.state;
        const startTime = moment(editingSchedule.startTime);
        from.year(startTime.year()).month(startTime.month()).date(startTime.date());

        this.editScheduleProperty('startTime', from.toDate());
    }
    editScheduleTo(to) {
        to = moment(to);
        const { editingSchedule } = this.state;
        const endTime = moment(editingSchedule.endTime);
        to.year(endTime.year()).month(endTime.month()).date(endTime.date());

        this.editScheduleProperty('endTime', to.toDate());
    }

    // finishEditSchedule() {
    //     const index = this.state.events.findIndex(e => e.id === this.state.editingSchedule.id);
    //     const event = this.state.events[index];
    //     const newEvents = [...this.state.events];
    //     newEvents[index] = {
    //         ...this.state.editingSchedule,
    //         state: event.state === 'toBeCreated' ? 'toBeCreated' : 'toBeUpdated',
    //     };
    //     this.setState({
    //         events: newEvents,
    //     });
    //     this.cancelEditSchedule();
    // }

    validateEditScheduleForm() {
        const { editingSchedule } = this.state;
        const { t } = this.props;
        const {
            centerId,
        } = editingSchedule;

        if (!centerId) {
            this.setState({
                editingSchedule: {
                    ...editingSchedule,
                    errorMessage: t('error:please_input_field', { field: t('center') })
                },
            });
            return false;
        }

        this.setState({
            editingSchedule: {
                ...editingSchedule,
                errorMessage: ''
            },
        });
        return true;
    }

    finishEditSchedule() {
        if (!this.validateEditScheduleForm()) {
            return;
        }
        const { editingSchedule } = this.state;
        const newEvents = [...this.state.events];
        if (this.state.selectedEvents.length === 1) {
            this.editScheduleSingle(newEvents, this.state.selectedEvents[0].id, editingSchedule, false);
        } else {
            console.log(this.state.selectedEvents.map(e => e.id));
            for(const event of this.state.selectedEvents) {
                this.editScheduleSingle(newEvents, event.id, editingSchedule, true);
            }
        }
        this.setState({
            events: newEvents,
            tmpEvents: newEvents
        });
        this.cancelEditSchedule();
    }

    deleteSchedule() {
        const newEvents = [...this.state.events];
        for(const event of this.state.selectedEvents) {
            this.deleteScheduleSingle(newEvents, event.id);
        }
        this.setState({
            events: newEvents,
            eventsToDelete: this.state.eventsToDelete.concat(this.state.selectedEvents),
        });
        this.cancelEditSchedule();
    }

    editScheduleSingle(events, evId, eventUpdate, isBulkEdit) {
        const index = events.findIndex(e => e.id === evId);
        const event = events[index];

        if (isBulkEdit) {
            events[index] = {
                ...eventUpdate,
                startTime: events[index].startTime,
                endTime: events[index].endTime,
                id: events[index].id,
                state: event.state === 'toBeCreated' ? 'toBeCreated' : 'toBeUpdated',
            };
        } else {
            events[index] = {
                ...eventUpdate,
                state: event.state === 'toBeCreated' ? 'toBeCreated' : 'toBeUpdated',
            };
        }
    }

    deleteScheduleSingle(events, evId) {
        const index = events.findIndex(e => e.id === evId);
        if (index !== -1) {
            events.splice(index, 1);
        }
    }
    
    eventStyleGetter(event) {
        var style = {};
        if(event.rest == 'yes') {
            style.backgroundColor = '#e8e8e8'
            style.borderColor = '#fff'
        }
        return {
            style: style
        };
    }

    render() {
        const { t } = this.props;
        const {
            addScheduleForm,
            isEditing,
            staff,
            date,
            loadingSchedule,
            centers,
            services,
            centerList,
            addingSchedule,
            editingSchedule,
            scheduleDayOptions,
            failedSchedule,
            failedScheduleList,
            working
        } = this.state;
        const staffServices = staff ? staff.services : [];
        const events = this.state.events;

        const anchor = moment(date);
        const begin = anchor.clone().startOf('week');
        const end = anchor.endOf('week');

        let timeRangeFormat = ({ start, end }, culture, local)=> 
            local.format(start, 'HH:mm', culture)
        let timeGutterFormat = (data, culture, local)=> 
            local.format(data, 'HH:mm', culture)
        var obj = this;

        console.log(staff)

        return (
            <div className="staff-schedule-page">
                <div className="page-title">{t('staff_schedule_title')}</div>
                <div className="page-back"><Button onClick={() => history.goBack()}>{t('add_booking_button_back')}</Button></div>
                {
                    staff ?
                        <div className="staff-preview">
                            <AccountIcon className="icon" icon={staff.icon}/>
                            <div className="details">
                                <div className="row">
                                    <div className="title">{t('staff_tab_basic_info_title_id')}</div>
                                    <div className="value">{staff.id}</div>
                                </div>
                                <div className="row">
                                    <div className="title">{t('staff_tab_basic_info_title_surname')}</div>
                                    <div className="value">{staff.surname}</div>
                                </div>
                                <div className="row">
                                    <div className="title">{t('staff_tab_basic_info_title_name')}</div>
                                    <div className="value">{staff.name}</div>
                                </div>
                                <div className="row">
                                    <div className="title">{t('staff_tab_basic_info_title_gender')}</div>
                                    <div className="value">{t(staff.gender)}</div>
                                </div>
                                <div className="row">
                                    <div className="title">{t('type')}</div>
                                    <div className="value">{t(`staff_type_${staff.type}`)}</div>
                                </div>
                            </div>
                        </div> :
                        <Loading className="staff-preview" />
                }

                <div className="calendar-controls">
                    <div className="left">
                        <Button onClick={() => this.prevWeek()} disabled={isEditing}>
                        {t('staff_schedule_button_prev')}
                        </Button>
                        <Button onClick={() => this.nextWeek()} disabled={isEditing}>
                        {t('staff_schedule_button_next')}
                        </Button>
                    </div>
                    <div className="middle">
                        <DatePickerButton
                            disabled={isEditing}
                            value={this.state.date}
                            placeholder="Select Date"
                            onChange={date=>this.onNavigate(date)}
                        >
                            <div style={{ flex: 1, fontSize: '1.4em', textAlign:'right'}}>
                                {begin.format('MM/DD')} - {end.format('MM/DD')}
                            </div>
                        </DatePickerButton>
                    </div>
                    <div className="right">
                    {
                        isEditing ?
                            <div className="edit-button">
                                <Button onClick={() => this.save()}>{t('button_save')}</Button>
                                <Button onClick={() => this.cancel()}>{t('staff_schedule_button_cancel')}</Button>
                                <Button onClick={() => this.addSchedulePopup(null,null,1)}>{t('staff_schedule_button_add_schedule')}</Button>
                                <Button onClick={() => this.addSchedulePopup(null,null,2)}>+ 休息日程</Button>
                            </div>:
                            <div className="edit-button">
                                <Button onClick={() => this.setState({ isEditing: true })}>{t('button_edit')}</Button>
                            </div>
                    }   
                    </div>
                </div>
                <div className="calendar-container">
                    <Loading loading={loadingSchedule}>
                        <Calendar
                            formats={{eventTimeRangeFormat: timeRangeFormat, timeGutterFormat: timeGutterFormat}}
                            localizer={localizer}
                            events={events}
                            date={date}
                            step={15}
                            onNavigate={(date) => this.onNavigate(date)}
                            startAccessor='startTime'
                            endAccessor='endTime'
                            defaultView="week"
                            min={new Date(2017,1,1,9,0,0)}
                            max={new Date(2017,1,1,23,0,0)}
                            style={{ height: "100vh" }}
                            selectable={isEditing}
                            onSelectSlot={(e) => this.addSchedulePopup(e.start, e.end, 1)}
                            drilldownView={null}
                            onSelectEvent={(e) => this.onSelectEvent(e)}
                            onView={(event) => this.refreshSchedule(event)}
                            selected={this.state.selectedEvents[0]}
                            eventPropGetter={(this.eventStyleGetter)}
                            // toolbar={false}
                            components={{
                                event: ({ event, title }) => {
                                    const getEventServiceName = (service) => {
                                        if (typeof service === 'string') {
                                            return services[service].name;
                                        }
                                        return service.service_details[0].name;
                                    }
                                    return (
                                        <div className="my-display">
                                        <div>{centers[event.centerId].name}</div>
                                            <div>
                                                {
                                                    // event.services.map(getEventServiceName).join(', ')
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            }}
                            views={
                                {
                                    day: true,
                                    week: true,
                                    month: true,
                                }
                            }
                        />
                    </Loading>
                </div>

                <Dialog title={working ? t('staff_schedule_dialog_title') : "新增休息日程"} visible={addingSchedule} onCancel={() => this.cancelAddSchedule()}>
                    <Dialog.Body>
                        <Form model={addScheduleForm}>
                            <Form.Item label={t('staff_schedule_dialog_center')} labelWidth="120">
                                <Select
                                    value={addScheduleForm.centerId}
                                    onChange={(value) => this.setAddScheduleForm('centerId', value)}
                                >
                                    {
                                        centerList.map(center => (
                                            <Select.Option
                                                key={center.id}
                                                label={center.name}
                                                value={center.id}
                                            />
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            {/* <Form.Item label={t('staff_schedule_dialog_service')}  labelWidth="120"> */}
                            {/*     <Select */}
                            {/*         value={addScheduleForm.serviceIds} */}
                            {/*         multiple={true} */}
                            {/*         disabled={!addScheduleForm.centerId} */}
                            {/*         onChange={(values) => this.setAddScheduleForm('serviceIds', values)} */}
                            {/*     > */}
                            {/*         { */}
                            {/*             staffServices.filter(service => { */}
                            {/*                 if (!addScheduleForm.centerId) */}
                            {/*                     return false; */}
                            {/*                 const found = centerList.find(c => c.id === addScheduleForm.centerId).services.find(ser => { */}
                            {/*                     return ser === service.id */}
                            {/*                 }); */}
                            {/*                 return !!found; */}
                            {/*             }) */}
                            {/*             .map(service => ( */}
                            {/*                 <Select.Option */}
                            {/*                     key={service.id} */}
                            {/*                     label={service.name} */}
                            {/*                     value={service.id} */}
                            {/*                 /> */}
                            {/*             )) */}
                            {/*         } */}
                            {/*     </Select> */}
                            {/*     <Button */}
                            {/*         style={{ marginLeft: 8 }} */}
                            {/*         disabled={!addScheduleForm.centerId} */}
                            {/*         onClick={() => this.setAllService()}> */}
                            {/*         Select All */}
                            {/*     </Button> */}
                            {/* </Form.Item> */}
                            <Form.Item label={t('staff_schedule_dialog_time')}  labelWidth="120">
                                <TimeSelect
                                    start="08:00"
                                    step="00:15"
                                    end="23:00"
                                    onChange={(time) => this.setAddScheduleForm('from', time)}
                                    value={addScheduleForm.from}
                                    placeholder="From"
                                />
                                {' - '}
                                <TimeSelect
                                    start="08:00"
                                    step="00:15"
                                    end="23:00"
                                    minTime={addScheduleForm.from}
                                    onChange={(time) => this.setAddScheduleForm('to', time)}
                                    value={addScheduleForm.to}
                                    placeholder="To"
                                />
                            </Form.Item>
                            {/*
                            <Form.Item label={t('staff_schedule_dialog_repeat')} labelWidth="120">
                                <Switch value={addScheduleForm.repeat}
                                        onChange={(value) => this.setAddScheduleForm('repeat', value)} />
                            </Form.Item>
                            */}
                            <Form.Item label={t('staff_schedule_dialog_alldays')} labelWidth="120">
                                <Switch value={addScheduleForm.alldays} onChange={(value) => this.setAddScheduleForm('alldays', value)} />
                            </Form.Item>
                            {
                                addScheduleForm.repeat ?
                                    <Form.Item label={t('staff_schedule_dialog_day')} labelWidth="120">
                                        <Select value={addScheduleForm.days}
                                                multiple={true}
                                                onChange={(value) => this.setAddScheduleForm('days', value)}>
                                            <Select.Option value={0} label={t('staff_schedule_dialog_day_sunday')} />
                                            <Select.Option value={1} label={t('staff_schedule_dialog_day_monday')} />
                                            <Select.Option value={2} label={t('staff_schedule_dialog_day_tuesday')} />
                                            <Select.Option value={3} label={t('staff_schedule_dialog_day_wednesday')} />
                                            <Select.Option value={4} label={t('staff_schedule_dialog_day_thursday')} />
                                            <Select.Option value={5} label={t('staff_schedule_dialog_day_friday')} />
                                            <Select.Option value={6} label={t('staff_schedule_dialog_day_saturday')} />
                                        </Select>
                                    </Form.Item> : null
                            }
                            {
                                addScheduleForm.repeat ?
                                    <Form.Item label={t('staff_schedule_dialog_start_date')} labelWidth="120">
                                        <DatePicker
                                            value={addScheduleForm.startingDate}
                                            onChange={(value) => this.setAddScheduleForm('startingDate', value)}
                                        />
                                    </Form.Item> : null
                            }
                            {
                                addScheduleForm.repeat ?
                                    <Form.Item label={t('staff_schedule_dialog_end_date')} labelWidth="120">
                                        <DatePicker
                                            value={addScheduleForm.endDate}
                                            onChange={(value) => this.setAddScheduleForm('endDate', value)}
                                        />
                                    </Form.Item> : null
                            }
                            {
                                addScheduleForm.alldays ?
                                    <Form.Item label={t('staff_schedule_dialog_month')} labelWidth="120">
                                        <DatePicker
                                            selectionMode="month"
                                            value={addScheduleForm.month}
                                            onChange={(value) => this.setAddScheduleForm('month', value, function() {obj.setAllDays(value)})}
                                        />
                                    </Form.Item> : null
                            }
                            { 
                                addScheduleForm.alldays ?
                                    <Form.Item label={t('staff_schedule_dialog_day')}  labelWidth="120">
                                        <Select
                                            value={addScheduleForm.scheduleDays}
                                            multiple={true}
                                            disabled={false}
                                            onChange={(values) => this.setAddScheduleForm('scheduleDays', values)}
                                        >
                                            {
                                                scheduleDayOptions.map(day => (
                                                    <Select.Option
                                                        key={day.id}
                                                        label={day.name}
                                                        value={day.id}
                                                    />
                                                ))
                                            }
                                        </Select>
                                    </Form.Item> : null
                             }
                            {
                                addScheduleForm.repeat || addScheduleForm.alldays ?
                                null : 
                                <Form.Item label={t('staff_schedule_dialog_date')} labelWidth="120">
                                    <DatePicker
                                        value={addScheduleForm.date}
                                        onChange={(value) => this.setAddScheduleForm('date', value)}
                                    />
                                </Form.Item>
                            }
                            <Form.Item abelWidth="120">
                                <div className="error-message">
                                    {addScheduleForm.errorMessage}
                                </div>
                            </Form.Item>
                        </Form>
                    </Dialog.Body>
                    <Dialog.Footer>
                        <Button onClick={() => this.finishAddSchedule()}>{t('staff_schedule_dialog_add')}</Button>
                        <Button onClick={() => this.cancelAddSchedule()}>{t('staff_schedule_dialog_cancel')}</Button>
                    </Dialog.Footer>
                </Dialog>


                <Dialog title="Edit Schedule" visible={this.state.show && this.state.isEditing} onCancel={() => this.cancelEditSchedule()}>
                    <Dialog.Body>
                        {
                            editingSchedule ?
                                <Form model={editingSchedule}>
                                    <Form.Item label={t('staff_schedule_dialog_center')} labelWidth="120">
                                        <Select
                                            value={editingSchedule.centerId}
                                            onChange={(value) => this.editScheduleProperty('centerId', value)}
                                        >
                                            {
                                                centerList.map(center => (
                                                    <Select.Option
                                                        key={center.id}
                                                        label={center.name}
                                                        value={center.id}
                                                    />
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label={t('staff_schedule_dialog_service')} labelWidth="120">
                                        <Select
                                            value={editingSchedule.serviceIds}
                                            multiple={true}
                                            onChange={(values) => this.editScheduleProperty('services', values)}
                                        >
                                            {
                                                staffServices.map(service => (
                                                    <Select.Option
                                                        key={service.id}
                                                        label={service.name}
                                                        value={service.id}
                                                    />
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    {
                                        this.state.selectedEvents.length === 1 &&
                                        <Form.Item label={t('staff_schedule_dialog_date')} labelWidth="120">
                                            <DatePicker
                                                value={editingSchedule.startTime}
                                                onChange={(value) => this.editScheduleDate(value)}
                                            />
                                        </Form.Item>
                                    }
                                    {
                                        this.state.selectedEvents.length === 1 &&
                                        <Form.Item label={t('staff_schedule_dialog_time')} labelWidth="120">
                                            <TimeSelect
                                                start="08:00"
                                                step="00:15"
                                                end="22:00"
                                                onChange={(time) => this.editScheduleFrom(time)}
                                                value={editingSchedule.startTime}
                                                placeholder="From"
                                            />
                                            {' - '}
                                            <TimeSelect
                                                start="08:00"
                                                step="00:15"
                                                end="22:00"
                                                minTime={editingSchedule.startTime}
                                                onChange={(time) => this.editScheduleTo(time)}
                                                value={editingSchedule.endTime}
                                                placeholder="To"
                                            />
                                        </Form.Item>
                                    }
                                    <Form.Item abelWidth="120">
                                        <div className="error-message">
                                            {editingSchedule.errorMessage}
                                        </div>
                                    </Form.Item>
                                </Form> : null
                        }
                    </Dialog.Body>
                    <Dialog.Footer style={{ display: 'flex' }}>
                        <Button onClick={() => this.deleteSchedule()} type="danger">{t('button_delete')}</Button>
                        <div style={{ flex: 1 }} />
                        <Button onClick={() => this.finishEditSchedule()}>{t('button_save')}</Button>
                        <Button onClick={() => this.cancelEditSchedule()}>{t('staff_schedule_button_cancel')}</Button>
                    </Dialog.Footer>
                </Dialog>

                <Dialog title="Failed Schedule" visible={!!failedSchedule} onCancel={() => this.closeFailedSchedule()}>
                    <Dialog.Body>
                        <p>{t('schedule_create_unsuccessfully')}</p>
                        {failedScheduleList.map((item, index) => (
                           <p key={index}>{item}</p>
                        ))}
                    </Dialog.Body>
                    {/* <Dialog.Footer style={{ display: 'flex' }}> */}
                    {/*     <Button onClick={() => this.deleteSchedule()} type="danger">{t('button_delete')}</Button> */}
                    {/*     <div style={{ flex: 1 }} /> */}
                    {/*     <Button onClick={() => this.finishEditSchedule()}>{t('button_save')}</Button> */}
                    {/*     <Button onClick={() => this.cancelEditSchedule()}>{t('staff_schedule_button_cancel')}</Button> */}
                    {/* </Dialog.Footer> */}
                </Dialog>
            </div>
        );
    }
}