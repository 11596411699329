import React, { Component } from 'react';
import moment from 'moment';
import './tab-basic-info-view.scss';
import { translate } from 'react-i18next';
import { baseURL } from "../../../apiClient";
import AccountIcon from "../../icon.component";
@translate()
export default class StaffBasicInfoViewTab extends Component {
    render() {
        const { t, staff } = this.props;
        return (
            <main className='basic-info'>
                <div className="basic-info-tab">
                    <div className="tab-left">
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_id')}
                            </div>
                            <div className="value">
                                {staff.id}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                △{t('staff_tab_basic_info_title_surname')}
                            </div>
                            <div className="value">
                                <div className="sub-value">
                                    {staff.details["eng"].surname}
                                </div>
                                <div className="sub-value">
                                    {staff.details["cht"].surname}
                                </div>
                                <div className="sub-value">
                                    {staff.details["chs"].surname}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                △{t('staff_tab_basic_info_title_name')}
                            </div>
                            <div className="value">
                                <div className="sub-value">
                                    {staff.details["eng"].name}
                                </div>
                                <div className="sub-value">
                                    {staff.details["cht"].name}
                                </div>
                                <div className="sub-value">
                                    {staff.details["chs"].name}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_basic_salary')}
                            </div>
                            <div className="value">
                                {staff.salary}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_divide_into')}
                            </div>
                            <div className="value">
                                {staff.commission}
                            </div>
                        </div>    
                        <div className="row">
                            <div className="title">
                                主销售分成
                            </div>
                            <div className="value">
                                {staff.mainSales} %
                            </div>
                        </div>   
                        <div className="row">
                            <div className="title">
                                副销售分成
                            </div>
                            <div className="value">
                                {staff.minorSales} %
                            </div>
                        </div>                  
                        {/*
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_hkid')}
                            </div>
                            <div className="value">
                                {staff.hkid}
                            </div>
                        </div>
                        */}
                        <div className="row">
                            <div className="title">
                                { t('base_service_color') }
                            </div>
                            <div className="value">
                                <div style={{width:"30px",height:"30px",backgroundColor:staff.color}}></div>
                            </div>
                        </div>
                        {/*
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_address')}
                            </div>
                            <div className="value">
                                {staff.address}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                △Description:
                            </div>
                            <div className="value">
                                {staff.details["eng"].qualification}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                △繁體中文-敘述：
                            </div>
                            <div className="value">
                                {staff.details["cht"].qualification}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                △简体中文-敘述：
                            </div>
                            <div className="value">
                                {staff.details["chs"].qualification}
                            </div>
                        </div>
                        */}
                    </div>
                    <div className="tab-right">
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_status')}
                            </div>
                            <div className="value">
                                {staff.status}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_staff_type')}
                            </div>
                            <div className="value">
                                {staff.type}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_tel')}
                            </div>
                            <div className="value">
                                {staff.phone}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                { t('image_base_title') }
                            </div>
                            <div className="value">
                                <AccountIcon className="icon" icon={staff.icon} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}