import './day-view.scss';
import React, { Children, PureComponent,useCallback } from 'react';
import moment from 'moment';
import Event from "./event.component";
import { Calendar, momentLocalizer } from 'react-big-calendar'
// import events from '../../../src/utils/event'
// import { event } from 'jquery';


// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer



const CURRENT_DATE = moment().toDate();


// const ColoredDateCellWrapper = ({ children, value }) =>
//     React.cloneElement(Children.only(children), {
//         style: {
//             ...children.style,
//             backgroundColor: value < CURRENT_DATE ? "lightgreen" : "lightblue"
//         }
//     });

// const ColorDayWrapper = ({ children, value }) =>
//     React.cloneElement(Children.only(children), {
//         style: {
//             ...children.style,
//             backgroundColor: value > 1 ? "lightgreen" : "lightblue"
//         }
//     });



export const collide = (a, b) => {
    return ((a.top < b.top) && (a.top + a.height > b.top)) ||
        ((b.top < a.top) && (b.top + b.height > a.top)) ||
        (a.top === b.top) ||
        (a.top + a.height === b.top + b.height);
};
const collideWithLevel = (style, level) => {
    for (let s of level) {
        if (collide(style, s)) {
            return true;
        }
    }
    return false;
};

const PADDING_LEFT = 100;

export default class DayView extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            judgment: 'day',
            selectedBooking: null
        };
        this.renderEvents = this.renderEvents.bind(this);
        this.onMouseMoveEvent1 = this.onMouseMoveEvent1.bind(this);
        this.onLoadEvent1 = this.onLoadEvent1.bind(this);
        this.onScrollEvent1 = this.onScrollEvent1.bind(this);
        this.onWindowResize1 = this.onWindowResize1.bind(this);
        this.myRef = React.createRef();
        // window.onload = this.onLoadEvent1;
        window.onresize = this.onWindowResize1;
    }

    static defaultProps = {
        day: new Date(),
        minTime: 1 * 3600000,
        maxTime: 22 * 3600000 - 1,
        groups: [],
        hourHeight: 60,
        scheduleWidth: 120
    };

    maxScrollLeft = 0;
    nodeToolbar = null;
    nodeToolbarLabel = null;
    nodeEventRoots = [];
    minWidth = 0;
    maxWidth = 0;
    wInnerWidth = 0;
    diffWidth = 0;
    eventType = "day"

    onWindowResize1(e) {
        if (this.myRef && this.myRef.current) {
            this.maxWidth = this.myRef.current.clientWidth;
            this.wInnerWidth = e.target.innerWidth;
            this.minWidth = this.wInnerWidth - this.diffWidth;
            this.onMouseMoveEvent1();
        }
    }

    onScrollEvent1() {
        this.onMouseMoveEvent1();
    }

    onLoadEvent1() {
        if (this.myRef && this.myRef.current) {
            // this.myRef.current.parentElement.onscroll = this.onScrollEvent1;
            this.maxWidth = this.myRef.current.clientWidth;
            this.minWidth = this.myRef.current.parentElement.clientWidth;
            this.wInnerWidth = window.innerWidth;
            this.diffWidth = this.wInnerWidth - this.minWidth;
            this.onMouseMoveEvent1();
        }
    }

    onMouseMoveEvent1() {
        this.maxScrollLeft = 0;
        if (this.myRef && this.myRef.current) {
            this.nodeEventRoots = [];
            this.checkMaxScrollLeft(this.myRef.current.parentElement);
            if (this.nodeToolbar) {
                this.nodeToolbarLabel.style.setProperty("display", "none");
                this.nodeToolbarLabel.parentElement.style.setProperty("justify-content", "unset");
                // this.nodeToolbar.style.setProperty("padding-left", this.maxScrollLeft + "px");
            }
        }
    }

    checkMaxScrollLeft(obj) {
        if (obj) {
            this.maxScrollLeft = Math.max(this.maxScrollLeft, obj.scrollLeft);
            if (obj.children && obj.children.length) {
                for( let i = 0 ; i < obj.children.length ; i++ ) {
                    this.checkMaxScrollLeft(obj.children[i]);
                }
            }
            if (obj.className === "rbc-btn-group") {
                this.nodeToolbar = obj;
            } else if (obj.className === "rbc-toolbar-label") {
                this.nodeToolbarLabel = obj;
            } else if (obj.className === "rbc-event") {
                this.nodeEventRoots.push(obj);
            }
        }
    }

    renderTimeLines(day, minTime, maxTime) {
        const start = day.valueOf();
        minTime = start + minTime;
        maxTime = start + maxTime;
        const result = [];
        const INTERVAL = 3600000;
        for (let t = minTime; t < maxTime; t += INTERVAL) {
            result.push(
                <div key={t} className="time-line" style={{ height: this.props.hourHeight }}>
                    <div className="time-label">
                        {moment(t).format('ha')}
                    </div>

                    <div className="half-line" style={{ top: this.props.hourHeight / 2 }}>

                    </div>
                </div>
            )
        }
        return result;
    }

    renderEvents(day, minTime, maxTime, children) {
        const minValue = day.valueOf() + this.props.minTime;
        let events = this.filterEvent(children);
        let data = events.map(function (item, index) {
            let res = item.props.reservation
            let data = {
                reservation: res,
                start: new Date(res.startTime),
                end: new Date(res.endTime),
                id: index,
                title: res.service?(res.service.service_details.length>0?res.service.service_details[0].name:''):'',
                resourceId: res.staff_schedule.staffId
            }
            return data
        })
        this.state.events = data.slice(0);
            // this.setState({
            //     events: data
            // })
            // const styleMap = {};
            const styles = events.map((event) => {
            const props = { ...event.props };
            const schedule = props.reservation.staff_schedule;

            // const s = moment(schedule.startTime).valueOf();
            // const e = moment(schedule.endTime).valueOf();
            const s = moment(props.reservation.startTime).valueOf();
            const e = moment(props.reservation.endTime).valueOf();

            const staffIndex = this.props.groups.findIndex(group => group.id === event.props.reservation.staff_schedule.staff.id);

            const style = {
                top: (s - minValue) / 3600000 * this.props.hourHeight,
                left: PADDING_LEFT + staffIndex * this.props.scheduleWidth,
                height: (e - s) / 3600000 * this.props.hourHeight,
                width: this.eventType === "week"?140:this.props.scheduleWidth,
            };

            // console.log(this.eventType);
            return style;
        });
        
        // const stylesToIndent = [...styles];

        // let indentLevel = PADDING_LEFT;
        // let currentLevel = [];
        // while(stylesToIndent.length > 0) {
        //     for(let i = stylesToIndent.length - 1; i >= 0; i--) {
        //         const style = stylesToIndent[i];
        //         if (!collideWithLevel(style, currentLevel)) {
        //             style.left = indentLevel;
        //             currentLevel.push(style);
        //             stylesToIndent.splice(i, 1);
        //         }
        //     }
        //     // clean up
        //     indentLevel += this.props.scheduleWidth;
        //     currentLevel = [];
        // }

        return events.map((event, i) => {
            const props = { ...event.props };

            props.style = {
                ...styles[i],
                ...props.style
            };

            return <Event key={event.key} ref={event.ref} {...props} />
        });
    }

    filterEvent(children = []) {
        if (!children.filter) {
            return children.props._eventComponent ? children : null;
        }
        return children.filter(child => child.props._eventComponent);
    }

    filterChildren(children = []) {
        if (!children.filter) {
            return children.props._eventComponent ? null : children;
        }
        return children.filter(child => !child.props._eventComponent);
    }

    event({ event }) {
        if(event && event.reservation && event.reservation.rest == 'yes') {
            return  (
                <div>
                    <span>休息</span>
                    -
                    <span>{event.reservation.staff_schedule?event.reservation.staff_schedule.staff.staff_details[0].surname:''}{event.reservation.staff_schedule.staff?event.reservation.staff_schedule.staff.staff_details[0].name:''}</span>
                </div>
            )
        } else {
            return (
                <div className='weekEvent'>
                    <span>{event.title}</span><br />
                    <span>{
                        event.reservation.user
                        ? event.reservation.user.surname ? `${event.reservation.user.surname} ${event.reservation.user.name}` : `${event.reservation.user.name}`
                        : `${event.reservation.userInfo.name}`
                        }</span><br />
                    <span>{event.reservation.staff_schedule?event.reservation.staff_schedule.staff.staff_details[0].surname:''}{event.reservation.staff_schedule.staff?event.reservation.staff_schedule.staff.staff_details[0].name:''}</span><br />
                    <span>{event.reservation.room?event.reservation.room.title:''}</span><br />
                </div>
            )
        }
    }

    tooltipAccessor(event) {
        if(event && event.reservation && event.reservation.rest === 'yes'){
            return  `休息 - ${event.reservation.staff_schedule?event.reservation.staff_schedule.staff.staff_details[0].surname:''}${event.reservation.staff_schedule.staff?event.reservation.staff_schedule.staff.staff_details[0].name:''}`
        } else {
            return `${event.title} ${event.reservation.staff_schedule.staff?event.reservation.staff_schedule.staff.staff_details[0].surname:''}${event.reservation.staff_schedule.staff?event.reservation.staff_schedule.staff.staff_details[0].name:''}\n ${event.reservation.userInfo?event.reservation.userInfo.name:''} ${event.reservation.room?event.reservation.room.title:''}`
        }
    }
    MyMonthEvent({ event }) {
        let start = moment(event.reservation.startTime).format('hh:mm')
        let end = moment(event.reservation.endTime).format('hh:mm')
        // console.log(event)
        if(event && event.reservation && event.reservation.rest === 'yes'){
            return  (<span>{`休息 - ${event.reservation.staff_schedule?event.reservation.staff_schedule.staff.staff_details[0].surname:''}${event.reservation.staff_schedule.staff?event.reservation.staff_schedule.staff.staff_details[0].name:''}`}</span>)
        } else {
            return (
                <div className='monthEvent'>
                    <span>{start + "-" + end}</span>&nbsp;<span>{event.reservation.staff_schedule.staff?event.reservation.staff_schedule.staff.staff_details[0].surname:''}{event.reservation.staff_schedule.staff?event.reservation.staff_schedule.staff.staff_details[0].name:''}</span>&nbsp;<span>{event.title}</span>&nbsp;<span>{event.reservation.userInfo?event.reservation.userInfo.name:''}</span>
                </div>
            )
        }
    }


    eventStyleGetter(event, start, end, isSelected) {
        var color = event.reservation.service.color

        var style = {
            color: 'white',
            display: 'block',
            boxShadow: " 0 0px 3px 1px white",
        };

        if(event && event.reservation && event.reservation.rest == 'yes') {
            style.backgroundColor = '#e8e8e8'
            style.borderRadius ='0px'
            style.border = '1px solid #fff'
            style.color = '#f00'
        } else {
            style.backgroundColor = color
            style.borderRadius = '4px'
            style.border = '1px solid rgba(64, 64, 64, 0.8)'
            style.color = '#fff'
        }
       
        return {
            style: style
        };
    }

    eventViewChanged(event) {
        this.props.refreshData(event);
    }

    componentDidUpdate() {
        this.onMouseMoveEvent1();
        this.nodeEventRoots.forEach((item) => {
            // console.log(item.style.getPropertyValue("left"));
            let w = item.style.getPropertyValue("width");
            let num = parseFloat(w.slice(0, w.length - 1));
            if (num < 16) {
                num = 16;
            }
            item.style.setProperty("width", num + "%");
            if (this.eventType === "month") {
                item.style.setProperty("border", "unset");
            }
        });
    }

    componentDidMount() {
        this.onLoadEvent1();
    }

    render() {
        let { children, day, minTime, maxTime } = this.props;
        day = moment(day).startOf('day');
        let timeRangeFormat = ({ start, end }, culture, local)=>  local.format(start, 'HH:mm', culture)
        let timeGutterFormat = (data, culture, local)=>  local.format(data, 'HH:mm', culture)

        return (
            <div className="day-view" ref={this.myRef}>
                <div className="time-line-container" style={{ "display": "none" }}>
                    {this.renderTimeLines(day, minTime, maxTime)}
                    <div className="event-container">
                        <div className="group-bar" style={{ paddingLeft: PADDING_LEFT }}>
                            {this.props.groups.map(group => (
                                <div className="group" key={group.id} style={{ width: this.props.scheduleWidth }}>
                                    {group.title}
                                </div>
                            ))}
                        </div>
                        {this.renderEvents(day, minTime, maxTime, children)}
                    </div>
                </div>
                {this.filterChildren(children)}
                <div style={{ height:this.state.judgment =='month'?'190vh':'auto' }}>
                    <Calendar
                        formats={{eventTimeRangeFormat: timeRangeFormat, timeGutterFormat: timeGutterFormat}}
                        components={{
                            event: this.event,
                            month: {
                                event: this.MyMonthEvent,
                            },
                        }}
                        tooltipAccessor={this.tooltipAccessor}
                        views={
                            {
                                day: true,
                                week: true,
                                month: true,
                            }
                        }
                        date={this.props.day}
                        defaultDate={new Date()}
                        popup={true}
                        defaultView={"day"}
                        onNavigate={date => {
                            this.setState({ selectedDate: date });
                          }}
                        localizer={localizer}
                        events={this.state.events}
                        startAccessor="start"
                        endAccessor="end"
                        step={5}
                        timeslots={6}
                        min={new Date(2021,1,1,8,0,0)}
                        max={new Date(2021,1,1,23,0,0)}
                        resources={this.state.judgment === 'day' ? this.props.groups : null}
                        resourceAccessor={(event) => {
                            return event.resourceId
                        }}
                        resourceIdAccessor="id"
                        resourceTitleAccessor="title"
                        onView={(event) => {
                            this.setState({
                                judgment: event
                            })
                            this.eventType = event;
                            this.eventViewChanged(event);
                        }}
                        onSelectEvent={(event) => {
                            this.props.children.map(item => {
                                item.props.ChildChange(event.reservation)
                            })
                        }}
                        eventPropGetter={(this.eventStyleGetter)}
                        messages={{
                            showMore: total => (
                                <div
                                    style={{ cursor: 'pointer', zIndex: 999 }}
                                    onMouseOver={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >{`+${total} more`}
                                </div>
                            ),
                        }}
                    />
                </div>
            </div>
        )
    }
}