
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-view.scss';
import { Tabs } from 'element-react';
import { baseURL } from "../../../apiClient";
import ImageBoard from '../../image-board.component';

@translate()
export default class CenterViewBoard extends Component {
    state = {
        tabs: [
            {
                id: "eng",
                title: "English",
                center: "Center Name:",
                address: "Address:",
                description: "Description:",
                openingHour: "Opening Hour:",
            },
            {
                id: "cht",
                title: "繁體中文",
                center: "服務名稱：",
                address: "地址：",
                description: "描述：",
                openingHour: "辦公時間：",
            }
        ],
        selectedTab: "0",
    }
    render() {
        const { t, center } = this.props;
        const { tabs, selectedTab } = this.state;
        let slides = [];
        if (center && center.center_slides) {
            if (center && center.center_slides) {
                for (const slide of center.center_slides) {
                    if (slide.path) {
                        slides.push(`${baseURL}/${slide.path}`);
                    }
                }
            }
        }
        return (
            center ? <div className="center-board-view">
                <div className="tab">
                    <Tabs activeName={selectedTab} onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })}>
                        {
                            tabs.map((tab, i) => {
                                return (
                                    <Tabs.Pane key={i} label={tabs[i].title} name={i.toString()}>
                                        <div className="block">
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].center}
                                                </div>
                                                <div className="value">
                                                    {center.center_details[tabs[i].id] ? center.center_details[tabs[i].id].name : null}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].address}
                                                </div>
                                                <div className="value">
                                                    {center.center_details[tabs[i].id] ? center.center_details[tabs[i].id].address : null}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].description}
                                                </div>
                                                <div className="value">
                                                    {center.center_details[tabs[i].id] ? center.center_details[tabs[i].id].description : null}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].openingHour}
                                                </div>
                                                <div className="value">
                                                    {center.center_details[tabs[i].id] ? center.center_details[tabs[i].id].openingHour : null}
                                                </div>
                                            </div>
                                        </div>
                                    </Tabs.Pane>
                                )
                            })
                        }
                    </Tabs>
                </div>
                <div className="row">
                    <div className="title">
                        {t('center_detail_content_title_status')}
                    </div>
                    <div className="value">
                        {t(center.active ? 'center_detail_content_value_active' : 'center_detail_content_value_inactive')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('center_detail_content_title_tel')}
                    </div>
                    <div className="value">
                        {center.phone}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('center_detail_content_title_email')}
                    </div>
                    <div className="value">
                        {center.email}
                    </div>
                </div>
                {/*
                <div className="row">
                    <div className="title">
                        {t('center_detail_content_title_address_coordinates')}
                    </div>
                    <div className="value">
                        {`${center.latitude}, ${center.longitude}`}
                    </div>
                </div>
                */}
                <ImageBoard edit={false} localImages={slides} />
            </div> : null
        );
    }
}