import React, { Component } from 'react';
import './main.scss';
import { connect } from 'react-redux';
import { Menu, Popover, Button, Select } from 'element-react';
import { setLanguage } from "../../redux/staff/staff.action"
import { Route, Switch, withRouter } from 'react-router-dom';
import history from '../../history';
import ChangePasswordPage from '../main-content/change-password/change-password.screen'
import UserSummaryPage from '../main-content/user/user-summary/user-summary.screen'
import UserAllPage from '../main-content/user/user-all/user-all.screen'
import UserDetailPage from '../main-content/user/user-detail/user-detail.screen'
import UserFeedbackPage from '../main-content/user/user-feedback/user-feedback.screen'
import bookingTable from "../main-content/booking/bookingTable/bookingTable.screen"
import StaffAllPage from '../main-content/staff/staff-all/staff-all.screen'
import StaffDetailPage from '../main-content/staff/staff-detail/staff-detail.screen'
import StaffCommissionPage from '../main-content/staff/staff-commission/staff-commission.screen'
import StaffSalesPage from '../main-content/staff/staff-sales/staff-sales.screen'
import ServiceAllPage from '../main-content/service/service-all/service-all.screen'
import ServiceDetailPage from '../main-content/service/service-detail/service-detail.screen'
import CenterAllPage from '../main-content/center/center-all/center-all.screen'
import CenterDetailPage from '../main-content/center/center-detail/center-detail.screen'
import StaffSchedulePage from '../main-content/staff/staff-schedule/staff-schedule.screen'
import BookingOverviewPage from '../main-content/booking/booking-overview.screen'
import AddBookingPage from '../main-content/booking/add-booking.screen'
import CategoryAllPage from '../main-content/category/category-all/category-all.screen'
import CategoryDetailPage from '../main-content/category/category-detail/category-detail.screen'
import NotificationAllPage from '../main-content/notification/notification-all/notification-all.screen'
import NotificationDetailPage from '../main-content/notification/notification-detail/notification-detail.screen'
import RoomAllPage from '../main-content/room/room-all/room-all.screen'
import RoomDetailPage from '../main-content/room/room-detail/room-detail.screen'
import HomePage from '../main-content/web/homepage/homepage.screen'
import MobileDescription from '../main-content/web/mobile-description/mobile-description.screen'
import ReportManagement from '../main-content/report-management/expend-report'
import BookingReviseRecord from '../main-content/booking/booking-revise-record/booking-revise-record'
import ConsumeReport from '../main-content/report-management/consume-report'
import TimesPackagesManagement from '../main-content/times-packages-management/index'
import StaffHoliday from '../main-content/staff/staff-holiday/staff-holiday'
import { translate } from 'react-i18next';
import logo from '../../assets/logo_nav.png';

const mapDispatchToProps = (dispatch) => {
    return {
        setLanguage: (languageId) => {
            dispatch(setLanguage(languageId))
        }
    }
}

@withRouter
@translate()
@connect((state) => {
    return {
        staff: state.staff.staff,
        permissions: state.staff.permissions,
    };
}, mapDispatchToProps)
export default class MainScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            Sidebar: true,
            SidebarPhone: false,
            windowWidth: document.documentElement.clientWidth,
            lang: { name: "繁", key: "cht" }
        };

        let key = sessionStorage.getItem('languageId');
        if (key !== null && key !== "") {
            this.state.lang.key = key;
        }
    }

    handleMobileMenuClick() {
        this.setState({
            SidebarPhone: !this.state.SidebarPhone
        })
    }

    logout() {
        sessionStorage.removeItem('session');
        sessionStorage.removeItem('permissions');
        history.push("/login");
    }

    switchTab = (url) => {
        if (url === '/main/change-password') {
            console.log("this", this)
            this.setState({
                visible: !this.state.visible
            });
        }
        console.log(this.props.staff);
        history.push(url);
    }

    changeLanguage(languageId) {
        this.props.setLanguage(languageId);
        window.location.reload();
    }

    handleResize = e => {
        this.setState({
            windowWidth: e.target.innerWidth
        })
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize.bind(this)) //监听窗口大小改变
        this.switchTab("/main/booking-overview");
        sessionStorage.setItem("permissions",JSON.stringify(this.props.permissions))
    }

    componentWillUnmount() { //一定要最后移除监听器，以防多个组件之间导致this的指向紊乱
        window.removeEventListener('resize', this.handleResize.bind(this))
    }


    render() {
        const { staff, permissions, match, t } = this.props;

        // console.log('permissions', permissions)

        const langs = [
            { name: "ENG", key: "eng" },
            { name: "繁", key: "cht" }
        ];
        return (
            <div className="main-page">
                {/* 左边导航 */}
                <div className={this.state.Sidebar ? "left-menu" : "left-menu  left-menu_close"} id="menu" style={{ display: this.state.windowWidth < 998 ? "none" : "block" }}>
                    {/* <div className="left-menu" id="menu"> */}
                    <div className="sidebar-logo-container">
                        <a href="#/" className="sidebar-logo-link">
                            <img className="sidebar-logo" src={logo} alt="" />
                        </a>
                    </div>
                    <Menu onSelect={(index) => this.switchTab("/main/" + index)}>
                        {/* {permissions.reservation ? <Menu.Item index="booking-overview">{t('main_tab_book')}</Menu.Item> : null} */}
                        {permissions.reservation ? <Menu.SubMenu index="booking-overview" title={t('main_tab_book')}>
                            <Menu.Item index="booking-overview">{t('main_tab_booking_timetable')}</Menu.Item>
                            <Menu.Item index="booking-table">{t('main_tab_booking_list')}</Menu.Item>
                            <Menu.Item index="booking-revise-record">{t('main_tab_booking_revise_record')}</Menu.Item>
                            <Menu.Item index="booking-overview/add">{t('main_tab_booking')}</Menu.Item>
                        </Menu.SubMenu> : null}
                        {permissions.user ? <Menu.SubMenu index="user" title={t('main_tab_customer_management')}>
                            <Menu.Item index="user-summary">{t('main_tab_customer_summary')}</Menu.Item>
                            <Menu.Item index="user-all">{t('main_tab_customer_all')}</Menu.Item>
                            <Menu.Item index="user-feedback">{t('main_tab_service_feedback')}</Menu.Item>
                        </Menu.SubMenu> : null}
                        {permissions.staff ? <Menu.SubMenu index="staff" title={t('main_tab_staff_management')}>
                            <Menu.Item index="staff-all">{t('main_tab_staff_all')}</Menu.Item>
                            <Menu.Item index="staff-holiday">{t('main_tab_staff_holiday')}</Menu.Item>
                        </Menu.SubMenu> : null}
                        {permissions.service ? <Menu.Item index="service">{t('main_tab_service_management')}</Menu.Item> : null}
                        {permissions.center ? <Menu.Item index="center">{t('main_tab_center_management')}</Menu.Item> : null}
                        {permissions.center ? <Menu.Item index="room">{t('main_tab_room_management')}</Menu.Item> : null}
                        {/*
                        {permissions.company ? <Menu.Item index="company">{t('main_tab_about_hwl')}</Menu.Item> : null}
                        {permissions.document ? <Menu.Item index="document">{t('main_tab_terms_conditions')}</Menu.Item> : null}
                        {permissions.healthTips ? <Menu.Item index="healthTips">{t('main_tab_health_tips')}</Menu.Item> : null}
                        {permissions.press ? <Menu.Item index="press">{t('main_tab_press')}</Menu.Item> : null}
                        {permissions.notification ? <Menu.Item index="notification">{t('main_tab_push_notification')}</Menu.Item> : null}
                        {permissions.web ? <Menu.SubMenu index="web" title={t('main_tab_web_management')}>
                            <Menu.Item index="web/homepage">{t('main_tab_web_management_homepage')}</Menu.Item>
                            <Menu.Item index="web/mobile-app">{t('main_tab_web_management_mobile')}</Menu.Item>
                        </Menu.SubMenu> : null}
                        */}
                        {permissions.category ? <Menu.Item index="category">{t('main_tab_category_management')}</Menu.Item> : null}
                        {/* {permissions.document ? <Menu.Item index="report">{t('main_tab_report_management')}</Menu.Item> : null} */}
                        <Menu.Item index='times-combo'>次卡套餐管理</Menu.Item>
                        {permissions.document ? <Menu.SubMenu index="report" title={t('main_tab_report_management')}>
                            {/* <Menu.Item index="report">消耗报表</Menu.Item> */}
                            <Menu.Item index="consume-report">消费报表</Menu.Item>
                        </Menu.SubMenu> : null}
                    </Menu>
                </div>
                {/* 移动端 */}
                <div className={this.state.SidebarPhone ? "left-menu-phone" : "left-menu-phone  left-menu-phone-close"} style={{ display: this.state.windowWidth < 998 ? "block" : "none" }}>
                    <div className="sidebar-logo-container">
                        <a href="#/" className="sidebar-logo-link">
                            <img className="sidebar-logo" src={logo} alt="" />
                        </a>
                    </div>
                    <Menu onSelect={(index) => { this.handleMobileMenuClick(); this.switchTab("/main/" + index) }}>
                        {/* {permissions.reservation ? <Menu.Item index="booking-overview">{t('main_tab_book')}</Menu.Item> : null} */}
                        {permissions.reservation ? <Menu.SubMenu index="booking-overview" title={t('main_tab_book')}>
                            <Menu.Item index="booking-overview">{t('main_tab_booking_timetable')}</Menu.Item>
                            <Menu.Item index="booking-table">{t('main_tab_booking_list')}</Menu.Item>
                            <Menu.Item index="booking-revise-record">{t('main_tab_booking_revise_record')}</Menu.Item>
                            <Menu.Item index="booking-overview/add">{t('main_tab_booking')}</Menu.Item>
                        </Menu.SubMenu> : null}
                        {permissions.user ? <Menu.SubMenu index="user" title={t('main_tab_customer_management')}>
                            <Menu.Item index="user-summary">{t('main_tab_customer_summary')}</Menu.Item>
                            <Menu.Item index="user-all">{t('main_tab_customer_all')}</Menu.Item>
                            <Menu.Item index="user-feedback">{t('main_tab_service_feedback')}</Menu.Item>
                        </Menu.SubMenu> : null}
                        {permissions.staff ? <Menu.SubMenu index="staff" title={t('main_tab_staff_management')}>
                            <Menu.Item index="staff-all">{t('main_tab_staff_all')}</Menu.Item>
                            <Menu.Item index="staff-holiday">{t('main_tab_staff_holiday')}</Menu.Item>
                        </Menu.SubMenu> : null}
                        {/* {permissions.service ? <Menu.Item index="service">{t('main_tab_service_management')}</Menu.Item> : null} */}
                        {permissions.center ? <Menu.Item index="center">{t('main_tab_center_management')}</Menu.Item> : null}
                        {permissions.center ? <Menu.Item index="room">{t('main_tab_room_management')}</Menu.Item> : null}
                        {/*
                        {permissions.company ? <Menu.Item index="company">{t('main_tab_about_hwl')}</Menu.Item> : null}
                        {permissions.document ? <Menu.Item index="document">{t('main_tab_terms_conditions')}</Menu.Item> : null}
                        {permissions.healthTips ? <Menu.Item index="healthTips">{t('main_tab_health_tips')}</Menu.Item> : null}
                        {permissions.press ? <Menu.Item index="press">{t('main_tab_press')}</Menu.Item> : null}
                        {permissions.notification ? <Menu.Item index="notification">{t('main_tab_push_notification')}</Menu.Item> : null}
                        {permissions.web ? <Menu.SubMenu index="web" title={t('main_tab_web_management')}>
                            <Menu.Item index="web/homepage">{t('main_tab_web_management_homepage')}</Menu.Item>
                            <Menu.Item index="web/mobile-app">{t('main_tab_web_management_mobile')}</Menu.Item>
                        </Menu.SubMenu> : null}
                        */}
                        {permissions.category ? <Menu.Item index="category">{t('main_tab_category_management')}</Menu.Item> : null}
                        <Menu.Item index='times-combo'>次卡套餐管理</Menu.Item>
                        {permissions.document ? <Menu.Item index="report">{t('main_tab_report_management')}</Menu.Item> : null}
                    </Menu>
                    {
                        this.state.windowWidth < 998
                            ? (<Button className="Putaway" icon="arrow-left" style={{ color: "#fff" }} onClick={() => this.setState({ SidebarPhone: !this.state.SidebarPhone })}></Button>)
                            : null
                    }
                </div>

                <div className={this.state.SidebarPhone
                    ? "layer"
                    : "layer left-menu-phone-close"}
                    onClick={() => this.setState({ SidebarPhone: !this.state.SidebarPhone })}
                ></div>
                {/* 右边 */}
                <div className="main-content">
                    {/* 右边头部导航 */}
                    <div className="main-bar">
                        <div className="language-panel">
                            {
                                this.state.windowWidth > 998 && this.state.windowWidth < 1200
                                    ? (<Button icon="menu" onClick={() => this.setState({ Sidebar: !this.state.Sidebar })}></Button>)
                                    : null
                            }
                            {
                                this.state.windowWidth < 998
                                    ? (<Button icon="menu" onClick={() => this.setState({ SidebarPhone: !this.state.SidebarPhone })}></Button>)
                                    : null
                            }
                        </div>
                        <div className="space"></div>
                        <Select
                            value={this.state.lang.key}
                            style={{ paddingRight: "10px" }}
                            onChange={(key) => this.props.setLanguage(key)}>
                            {langs.map(lang => (
                                <Select.Option key={lang.key} label={lang.name} value={lang.key} />
                            ))}
                        </Select>
                        <Popover className="pop" width="200" placement="bottom-end" trigger="click" visible={!this.state.visible}
                            content={(<div>
                                <p>{staff.id}</p>
                                <div className="pop-button">
                                    <Button onClick={() => { this.switchTab("/main/change-password") }} size="small">{t('main_pop_button_change_password')}</Button>
                                    <Button onClick={() => this.logout()} size="small">{t('main_pop_button_log_out')}</Button>
                                </div>
                            </div>
                            )}>
                            <img className="icon" src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="" />
                        </Popover>
                    </div>
                    {/* 右边主体 */}
                    <div className="main-tab-content">
                        <Switch>
                            <Route exact path={`${match.url}/booking-overview`} component={BookingOverviewPage} />
                            <Route path={`${match.url}/booking-revise-record`} component={BookingReviseRecord}></Route>
                            <Route exact path={`${match.url}/booking-overview/add/:serviceId/:date/:centerId/:staffId`} component={AddBookingPage} />
                            <Route exact path={`${match.url}/booking-overview/add`} component={AddBookingPage} />
                            <Route path={`${match.url}/user-summary`} component={UserSummaryPage} />
                            <Route path={`${match.url}/booking-table`} component={bookingTable} />
                            <Route path={`${match.url}/user-all`} component={UserAllPage} />
                            <Route path={`${match.url}/change-password`} component={ChangePasswordPage} />
                            <Route path={`${match.url}/user-detail/:userId`} component={UserDetailPage} />
                            <Route path={`${match.url}/user-feedback`} component={UserFeedbackPage} />
                            <Route path={`${match.url}/staff-all`} component={StaffAllPage} />
                            <Route path={`${match.url}/staff-holiday`} component={StaffHoliday} />
                            <Route path={`${match.url}/staff-schedule-all`} component={StaffAllPage} />
                            <Route path={`${match.url}/staff-schedule/:staffId`} component={StaffSchedulePage} />
                            <Route path={`${match.url}/staff-detail/:staffId`} component={StaffDetailPage} />
                            <Route path={`${match.url}/staff-commission/:status/:staffId`} component={StaffCommissionPage} />
                            <Route path={`${match.url}/staff-sales/:type/:staffId`} component={StaffSalesPage} />
                            <Route path={`${match.url}/service`} component={ServiceAllPage} />
                            <Route path={`${match.url}/service-detail/:serviceId`} component={ServiceDetailPage} />
                            <Route path={`${match.url}/center`} component={CenterAllPage} />
                            <Route path={`${match.url}/center-detail/:centerId`} component={CenterDetailPage} />
                            {/* <Route path={`${match.url}/company`} component={CompanyDetailPage} /> */}
                            {/* <Route path={`${match.url}/document`} component={DocumentDetailPage} /> */}
                            {/* <Route path={`${match.url}/healthTips`} component={HealthTipsAllPage} /> */}
                            {/* <Route path={`${match.url}/healthTips-detail/:healthTipsId`} component={HealthTipsEditBoard} /> */}
                            {/* <Route path={`${match.url}/press`} component={PressAllPage} /> */}
                            {/* <Route path={`${match.url}/press-detail/:pressId`} component={PressEditBoard} /> */}
                            <Route path={`${match.url}/times-combo`} component={TimesPackagesManagement}></Route>
                            <Route path={`${match.url}/category`} component={CategoryAllPage} />
                            <Route path={`${match.url}/category-detail/:categoryId`} component={CategoryDetailPage} />
                            <Route path={`${match.url}/notification`} component={NotificationAllPage} />
                            <Route path={`${match.url}/notification-detail/:notificationId`} component={NotificationDetailPage} />
                            <Route path={`${match.url}/room`} component={RoomAllPage} />
                            <Route path={`${match.url}/room-detail/:roomId`} component={RoomDetailPage} />
                            <Route path={`${match.url}/web/homepage`} component={HomePage} />
                            <Route path={`${match.url}/web/mobile-app`} component={MobileDescription} />
                            <Route path={`${match.url}/report`} component={ReportManagement} />
                            <Route path={`${match.url}/consume-report`} component={ConsumeReport} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}
