

const initialCounterState = {
    session: {},
    fetching: false,
    fetched: false,
    error: null,
}

function counter(state = initialCounterState, action) {
    switch (action.type) {
        case "FETCH_SESSION": {
            return { ...state, fetching: true }
        }
        case "FETCH_SESSION_REJECTED": {
            return { ...state, fetching: false, error: action.payload }
        }
        case "FETCH_SESSION_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                session: action.payload,
            }
        }
        case "REFRESH_TOKEN": {
            const session = {
                ...state.session,
                accessToken: action.payload.accessToken,
                expiresAt: action.payload.expiresAt,
            };
            sessionStorage.setItem('session', JSON.stringify(session));
            return {
                ...state,
                fetching: false,
                fetched: true,
                session,
            }
        }
        default:
            return state;
    }
}

export default counter;