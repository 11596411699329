import React, { Component } from 'react';
import './tab-basic-info-edit.scss';
import sha256 from 'sha256';
import { translate } from 'react-i18next';
import { Radio, Select, Button, Input, ColorPicker } from 'element-react';
import { SketchPicker } from 'react-color';
import AccountIcon from "../../icon.component";

@translate()
export default class StaffBasicInfoTab extends Component {
    state = {
        staff: {
            displayColorPicker: false,
            defaultColor: "#fff",
        },
    };

    async componentWillReceiveProps(nextProps) {
        const { staff } = nextProps;
        if (this.props.staff === staff) {
            return;
        }
        this.setState({
            staff: staff ? staff : {},
            staffUpdate: null,
        });
    }

    async componentWillMount() {
        const { staff } = this.props;
        this.setState({
            staff: staff ? staff : {},
            staffUpdate: null,
        });
    }

    async componentDidMount() {
        const { staff } = this.props;
        this.setState({
            staff: staff ? staff : {},
            staffUpdate: null,
        });
    }
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    render() {
        const { t, localIcon, updateStaffDetail, updateStaff, uploadIcon, submit } = this.props;
        const { staff } = this.state;
        console.log(this.state.staff)
        return (
            <div className="basic-info-edit-tab">
                <div className="tab-body">
                    <div className="tab-left">
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_id')}
                            </div>
                            <div className="value">
                                {staff.id ? staff.id : t('staff_tab_basic_info_edit_id')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                            </div>
                            <div className="value">
                                <div className="sub-value">
                                    English
                                </div>
                                <div className="sub-value">
                                    繁體中文
                                </div>
                                {/*
                                <div className="sub-value">
                                    简体中文
                            </div>
                            */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                △{t('staff_tab_basic_info_title_surname')}
                            </div>
                            <div className="value">
                                <div className="sub-value">
                                    <Input type="text" value={
                                        staff.details && staff.details["eng"] && staff.details["eng"].surname ?
                                            staff.details["eng"].surname : ""}
                                        onChange={(value) => updateStaffDetail("eng", "surname", value)} />
                                </div>
                                <div className="sub-value">
                                    <Input type="text" value={
                                        staff.details && staff.details["cht"] && staff.details["cht"].surname ?
                                            staff.details["cht"].surname : ""}
                                        onChange={(value) => updateStaffDetail("cht", "surname", value)} />
                                </div>
                                {/*
                                <div className="sub-value">
                                    <Input type="text" value={
                                        staff.details && staff.details["chs"] && staff.details["chs"].surname ?
                                            staff.details["chs"].surname : ""}
                                        onChange={(value) => updateStaffDetail("chs", "surname", value)} />
                                </div>
                                */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                △{t('staff_tab_basic_info_title_name')}
                            </div>
                            <div className="value">
                                <div className="sub-value">
                                    <Input type="text" value={
                                        staff.details && staff.details["eng"] && staff.details["eng"].name ?
                                            staff.details["eng"].name : ""}
                                        onChange={(value) => updateStaffDetail("eng", "name", value)} />
                                </div>
                                <div className="sub-value">
                                    <Input type="text" value={
                                        staff.details && staff.details["cht"] && staff.details["cht"].name ?
                                            staff.details["cht"].name : ""}
                                        onChange={(value) => updateStaffDetail("cht", "name", value)} />
                                </div>
                                {/*
                                <div className="sub-value">
                                    <Input type="text" value={
                                        staff.details && staff.details["chs"] && staff.details["chs"].name ?
                                            staff.details["chs"].name : ""}
                                        onChange={(value) => updateStaffDetail("chs", "name", value)} />
                                </div>
                                */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_basic_salary')}
                            </div>
                            <div className="value">
                                <Input type="text" value={staff.salary ? staff.salary : ""} onChange={(value) => updateStaff("salary", value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_divide_into')}
                            </div>
                            <div className="value">
                                <Input type="text" value={staff.commission ? staff.commission : ""} onChange={(value) => updateStaff("commission", value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                主销售分成
                            </div>
                            <div className="value">
                                <Input type="text" value={staff.mainSales ? staff.mainSales : ""} onChange={(value) => updateStaff("mainSales", value)} /> %
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                副销售分成
                            </div>
                            <div className="value">
                                <Input type="text" value={staff.minorSales ? staff.minorSales : ""} onChange={(value) => updateStaff("minorSales", value)} /> %
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_gender')}
                            </div>
                            <div className="value">
                                <Radio.Group value={staff.gender ? staff.gender : null} onChange={(value) => {
                                    updateStaff("gender", value)
                                }}>
                                    <Radio value="male">MALE</Radio>
                                    <Radio value="female">FEMALE</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_status')}
                            </div>
                            <div className="value">
                                <Select onChange={(value) => updateStaff('status', value)} value={staff.status}>
                                    <Select.Option key='working' label={t('staff_tab_basic_info_status_working')} value={'working'} />
                                    <Select.Option key='resigned' label={t('staff_tab_basic_info_status_resigned')} value={'resigned'} />
                                </Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_staff_type')}
                            </div>
                            <div className="value">
                                <Select onChange={(value) => updateStaff('staffType', value)} value={staff.type}>
                                    <Select.Option key='super admin' label={t('staff_tab_basic_info_type_super_admin')} value={'super admin'} />
                                    <Select.Option key='admin' label={t('staff_tab_basic_info_type_admin')} value={'admin'} />
                                    <Select.Option key='normal' label={t('staff_tab_basic_info_type_normal')} value={'normal'} />
                                    <Select.Option key='therapist' label={t('staff_tab_basic_info_type_therapist')} value={'therapist'} />
                                    <Select.Option key='beautician' label={t('staff_tab_basic_info_type_beautician')} value={'beautician'} />
                                </Select>
                            </div>
                        </div>
                        {/*
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_hkid')}
                            </div>
                            <div className="value">
                                <Input type="text" value={staff.hkid ? staff.hkid : ""} onChange={(value) => updateStaff("hkid", value)} />
                            </div>
                        </div>
                        */}
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_tel')}
                            </div>
                            <div className="value">
                                <Input type="text" value={staff.phone ? staff.phone : ""} onChange={(value) => updateStaff("phone", value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                顯示顔色 :
                            </div>
                            <div className="value">
                                {/* <ColorPicker value={staff.color}
                                    onChange={(value) => {
                                        console.log(value)
                                        updateStaff("color", value)
                                    }}
                                ></ColorPicker> */}
                                <div className="colorSelect" onClick={this.handleClick}>
                                    <div>
                                        <div style={{ backgroundColor: staff.color === null ? this.state.defaultColor : staff.color }}></div>
                                        <div></div>
                                    </div>
                                </div>
                                {this.state.displayColorPicker ? <SketchPicker color={staff.color === null ? this.state.defaultColor : staff.color} onChange={(color, event) => {
                                    if (color.hex) {
                                        console.log('color',color)
                                        updateStaff("color", color.hex)
                                    }
                                }} /> : null}
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="title">
                                電郵*:
                            </div>
                            <div className="value">
                                <Input type="text" value={staff.email ? staff.email : ""} onChange={(value) => updateStaff("email", value)} />
                            </div>
                        </div> */}

                        {/*
                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_email')}
                            </div>
                            <div className="value">
                                <Input type="text" value={staff.email ? staff.email : ""} onChange={(value) => updateStaff("email", value)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="title">
                                {t('staff_tab_basic_info_title_address')}
                            </div>
                            <div className="value">
                                <Input type="textarea" autosize={{ minRows: 5 }} value={staff.address ? staff.address : ""} onChange={(value) => updateStaff("address", value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                △Description:
                        </div>
                            <div className="value">
                                <Input type="textarea" autosize={{ minRows: 5 }} value={
                                    staff.details && staff.details["eng"] && staff.details["eng"].qualification ?
                                        staff.details["eng"].qualification : ""}
                                    onChange={(value) => updateStaffDetail("eng", "qualification", value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                △繁體中文-敘述：
                        </div>
                            <div className="value">
                                <Input type="textarea" autosize={{ minRows: 5 }} value={
                                    staff.details && staff.details["cht"] && staff.details["cht"].qualification ?
                                        staff.details["cht"].qualification : ""}
                                    onChange={(value) => updateStaffDetail("cht", "qualification", value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                △简体中文-敘述：
                            </div>
                            <div className="value">
                                <Input type="textarea" autosize={{ minRows: 5 }} value={
                                    staff.details && staff.details["chs"] && staff.details["chs"].qualification ?
                                        staff.details["chs"].qualification : ""}
                                    onChange={(value) => updateStaffDetail("chs", "qualification", value)} />
                            </div>
                        </div>
                        */}
                    </div>
                    <div className="tab-right">
                        <div className="row">
                            <AccountIcon className="icon" icon={localIcon || staff.icon} />
                        </div>
                        <div className="row">
                            <Button onClick={() => uploadIcon()}>{t('staff_tab_basic_info_edit_button_upload')}</Button>
                        </div>
                        {staff.id ? <div className="row">
                            <Button onClick={() => updateStaff("password", sha256(staff.id))}>{t('staff_tab_basic_info_edit_button_reset')}</Button>
                        </div> : null}
                    </div>
                </div>
                <div className="tab-footer">
                    <Button onClick={() => submit()}>{t(staff.id ? 'button_save' : 'button_create')}</Button>
                </div>
            </div>
        );
    }
}