import client from '../apiClient';

export async function getWeb() {
    let response = await client.get(`/web`);
    return response.data;
}

export async function updateWeb(details) {
    let response = await client.patch(`/web`, details);
    return response.data;
}

export async function uploadSlides(images) {
    const formData = new FormData();
    for (let id of Object.keys(images)) {
        formData.append("slides", images[id].file);
    }
    let response = await client.post(`/web/slides`, formData, {
        params: {
            ids: Object.keys(images),
        },
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}