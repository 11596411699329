
import React, { Component } from 'react';
import './notification-detail.scss';
import { translate } from 'react-i18next';
import * as notificationApi from "../../../../api/notification.api";
import * as bodyConditionApi from "../../../../api/bodyCondition.api";
import { withRouter } from 'react-router-dom';
import { Button } from 'element-react';
import NotificationViewBoard from "../../../../components/notification/board-view/board-view.component";
import NotificationEditBoard from "../../../../components/notification/board-edit/board-edit.component";

@withRouter
@translate()
export default class NotificationDetailPage extends Component {
    state = {
        edit: false,
        notification: null,
        bodyConditions: null,
    }
    
    async componentDidMount() {
        const bodyResponse = await bodyConditionApi.getBodyConditions();
        this.setState({
            bodyConditions: bodyResponse,
        });
        if (this.props.match.params.notificationId !== "create") {
            await this.getData(this.props.match.params.notificationId);
        } else {
            this.setState({ edit: true });
        }
    }

    async getData(notificationId) {
        const notificationResponse = await notificationApi.getNotification(notificationId);
        this.setState({
            notification: {
                ...notificationResponse,
                notification_details: this.transformTranslationArray(notificationResponse.notification_details),
            },
        }, () => {
            console.log("Notification: ", this.state.notification);
        });
    }

    transformTranslationArray(arr) {
        const resultObj = {};
        for (let i = 0; i < arr.length; i += 1) {
            resultObj[arr[i].languageId] = arr[i];
        }
        return resultObj;
    }

    async changeMode (notificationId) {
        await this.getData(notificationId);
        this.setState({
            edit: !this.state.edit,
        });
    }

    render() {
        const { t } = this.props;
        const { notification, edit, bodyConditions } = this.state;
        return (
            <div className="notification-detail-page" >
                <div className="main-title">
                    {t(!edit?'notification_detail_title_view':(notification?'notification_detail_title_edit':'notification_detail_title_create'))}
                </div>
                <div className="page-body">
                    {edit ?
                        <NotificationEditBoard bodyConditions={bodyConditions} notification={notification} changeMode={(notificationId) => this.changeMode(notificationId)} /> : <NotificationViewBoard notification={notification} bodyConditions={bodyConditions} />}
                </div>
                <div className="page-footer">
                    {!edit?<Button onClick={() => this.setState({ edit: !edit })}>{t('button_edit')}</Button>:null}
                </div>
            </div>
        );
    }
}