
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-view.scss';
import { Tabs } from 'element-react';

@translate()
export default class MobileDescriptionViewBoard extends Component {
    state = {
        tabs: [
            {
                id: "eng",
                language: "English",
                title: "Title:",
                description: "Description:",
            },
            {
                id: "cht",
                language: "繁體中文",
                title: "標題:",
                description: "描述:",
            },
            {
                id: "chs",
                language: "简体中文",
                title: "标题:",
                description: "描述:",
            },
        ],
        selectedTab: "0",
    }
    render() {
        const { web } = this.props;
        const { tabs, selectedTab } = this.state;
        let details = {};
        if (web && web.details) {
            details = web.details;
        }
        return (
            web ? <div className="mobile-description-board-view">
                <div className="tab">
                    <Tabs activeName={selectedTab} onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })}>
                        {
                            tabs.map((tab, i) => {
                                return (
                                    <Tabs.Pane key={i} label={tabs[i].language} name={i.toString()}>
                                        <div className="block">
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].title}
                                                </div>
                                                <div className="value">
                                                    {details.mobile_app && details.mobile_app[tabs[i].id] && details.mobile_app[tabs[i].id].title ? details.mobile_app[tabs[i].id].title : null}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].description}
                                                </div>
                                                <div className="value">
                                                    {details.mobile_app && details.mobile_app[tabs[i].id] && details.mobile_app[tabs[i].id].description ? details.mobile_app[tabs[i].id].description : null}
                                                </div>
                                            </div>
                                        </div>
                                    </Tabs.Pane>
                                )
                            })
                        }
                    </Tabs>
                </div>
            </div> : null
        );
    }
}