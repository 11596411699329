import React, { Component } from 'react';
import './change-password.scss';
import { Button, Input } from 'element-react';
import { changePassword } from "../../../redux/staff/staff.action";
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { setError } from "../../../redux/staff/error.action"
import store from '../../../redux/store';

@translate()
@connect((state) => {
    return {
        staff: state.staff.staff,
    };
})

export default class ChangePasswordPage extends Component {

    state = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        displayMessage: '',
    };

    async submit() {
        try {
            const { oldPassword, newPassword, confirmNewPassword } = this.state;
            if (newPassword !== confirmNewPassword) {
                throw new Error("New password not match!");
            }
            await changePassword(this.props.staff.id, oldPassword, newPassword, confirmNewPassword);
        } catch (error) {
            store.dispatch(setError(error.userMessage || error.message));
        }
    }

    render() {
        const { displayMessage, oldPassword, newPassword, confirmNewPassword } = this.state;
        const { t } = this.props;
        return (
            <div className="change-password-page">
                <div className="change-password-panel">
                    <div className="change-password-panel-title">
                        {t('change_password_panel_title')}
                    </div>
                    <div className="change-password-panel-form ">
                        <Input type="password" name="oldPassword" placeholder={t('change_password_original_password')} value={oldPassword} onChange={(value) => this.setState({ oldPassword: value })} /><br />
                        <Input type="password" name="newPassword" placeholder={t('change_password_new_password')} value={newPassword} onChange={(value) => this.setState({ newPassword: value })} /><br />
                        <Input type="password" name="confirmNewPassword" placeholder={t('change_password_confirm_new_password')} value={confirmNewPassword} onChange={(value) => this.setState({ confirmNewPassword: value })} /><br />
                        <div className="panel-form-btn">
                            <Button onClick={() => this.submit()}> {t('change_password_submit')}</Button>
                        </div>
                        {displayMessage}
                    </div>
                </div>
            </div >
        );
    }
}
