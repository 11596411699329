import React, { Component } from 'react';
import moment from 'moment';
import './tab-cms-permission-view.scss';
import { translate } from 'react-i18next';
import { baseURL } from "../../../apiClient";

@translate()
export default class StaffCMSPermissionViewTab extends Component {
    render() {
        const { t, permissions } = this.props;
        return (
            <div className="cms-permission-tab">
                <div className="sub-title">
                    {t('staff_tab_cms_permission_sub_title_hwl_center')}
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_center')}
                    </div>
                    <div className="value">
                        {permissions["center"] ? (permissions["center"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_category')}
                    </div>
                    <div className="value">
                        {permissions["category"] ? (permissions["category"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_service')}
                    </div>
                    <div className="value">
                        {permissions["service"] ? (permissions["service"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_company')}
                    </div>
                    <div className="value">
                        {permissions["company"] ? (permissions["company"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_document')}
                    </div>
                    <div className="value">
                        {permissions["document"] ? (permissions["document"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_health_tips')}
                    </div>
                    <div className="value">
                        {permissions["healthTips"] ? (permissions["healthTips"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_body_condition')}
                    </div>
                    <div className="value">
                        {permissions["bodyCondition"] ? (permissions["bodyCondition"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_notification')}
                    </div>
                    <div className="value">
                        {permissions["notification"] ? (permissions["notification"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_web')}
                    </div>
                    <div className="value">
                        {permissions["web"] ? (permissions["web"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="sub-title">
                    {t('staff_tab_cms_permission_sub_title_customers')}
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_user')}
                    </div>
                    <div className="value">
                        {permissions["user"] ? (permissions["user"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_family')}
                    </div>
                    <div className="value">
                        {permissions["family"] ? (permissions["family"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_reservation')}
                    </div>
                    <div className="value">
                        {permissions["reservation"] ? (permissions["reservation"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_transaction')}
                    </div>
                    <div className="value">
                        {permissions["transaction"] ? (permissions["transaction"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="sub-title">
                    {t('staff_tab_cms_permission_sub_title_staff')}
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_staff')}
                    </div>
                    <div className="value">
                        {permissions["staff"] ? (permissions["staff"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_schedule')}
                    </div>
                    <div className="value">
                        {permissions["staffSchedule"] ? (permissions["staffSchedule"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_self')}
                    </div>
                    <div className="value">
                        {permissions["self"] ? (permissions["self"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_permission')}
                    </div>
                    <div className="value">
                        {permissions["permission"] ? (permissions["permission"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_cms_permission_title_press')}
                    </div>
                    <div className="value">
                        {permissions["press"] ? (permissions["press"] === "write" ? t('edit') : t('view')) : t('no_this_permission')}
                    </div>
                </div>
            </div>
        );
    }
}