import client from '../apiClient';
import {mergeDetails} from "../utils/dataTransform";


/**
 * @typedef {object} BookableScheduleFilterOption
 * @property {string} [staffId]
 * @property {string} [centerId]
 */
/**
 *
 * @param serviceId
 * @param startTime
 * @param endTime
 * @param {BookableScheduleFilterOption} filter
 * @returns {Promise<void>}
 */
export async function getBookableSchedules(serviceId, startTime, endTime, filter={}) {

    console.log( " ============= getBookableSchedules ============== " )

    console.log(serviceId)
    console.log(startTime)
    console.log(endTime)

    console.log( " ============= end getBookableSchedules ============== " )
    let response = await client.get('/staffSchedule', {
        params: {
            serviceId,
            startTime,
            endTime,
            ...filter,
        }
    });
    return response.data.map((schedule) => {
        let price = 0;
        if (schedule.staff) {
            schedule.staff = mergeDetails(schedule.staff, 'staff');
            price += schedule.staff.staff_services ? schedule.staff.staff_services[0].extraFee : 0;
        }
        if (schedule.center) {
            schedule.center = mergeDetails(schedule.center, 'center');
            price += schedule.center.center_services ? schedule.center.center_services[0].extraFee : 0;
        }
        if (schedule.service_schedules) {
            schedule.service = mergeDetails(schedule.service_schedules[0].service, 'service');
            delete schedule.service_schedules;
            price += schedule.service.price;
        }
        schedule.price = price;
        return schedule;
    });
}

/**
 *
 * @param staffId
 * @param {number} startTime
 * @param {number} endTime
 * @param filter
 * @returns {Promise<void>}
 */
export async function getStaffSchedule(staffId, startTime, endTime, filter={}) {

    console.log( " ============= getStaffSchedule ============== " )

    console.log(staffId)
    console.log(startTime)
    console.log(endTime)

    console.log( " ============= end getStaffSchedule ============== " )

    let response = await client.get(`/staffSchedule/staff/${staffId}`, {
        params: {
            startTime,
            endTime,
            ...filter
        }
    });
    return response.data;
}

export async function createStaffSchedule(schedules) {
    if (schedules.length <= 0) {
        return [];
    }
    const response = await client.post('/staffSchedule', {
        schedules
    });
    return response.data;
}

export async function updateStaffSchedule(schedules) {
    if (schedules.length <= 0) {
        return [];
    }
    // if(schedules[0].services.length > 0 ){
    //     var tempServices = schedules[0].services.map(service => {
    //         return service.id
    //     })
    //     schedules[0].services = tempServices
    // }
    const response = await client.patch('/staffSchedule', {
        schedules
    });
    return response.data;
}

export async function deleteStaffSchedule(scheduleIds) {
    if (scheduleIds.length <= 0) {
        return {};
    }
    const response = await client.post('/staffSchedule/delete', {
        staffScheduleIds: scheduleIds
    });
    return response.data;
}