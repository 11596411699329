import React, { Component } from 'react';
import './user-summary.scss';
import { connect } from 'react-redux';
import { Tabs } from 'element-react';
import * as userApi from "../../../../api/user.api";
import { translate } from "react-i18next";
import UserSearchBar from "../../../../components/user/search-bar/search-bar.component";
import UserBookingTab from "../../../../components/user/tab-booking/tab-booking.component";
import UserBodyConditionTab from "../../../../components/user/tab-body-condition/tab-body-condition.component";
import UserFamilyTab from "../../../../components/user/tab-family/tab-family.component";
import UserTransactionTab from "../../../../components/user/tab-transaction/tab-transaction.component";
import UserTopUpTab from "../../../../components/user/tab-top-up/tab-top-up.component";
import UserTransferTab from "../../../../components/user/tab-transfer/tab-transfer.component";
import UserViewBoard from "../../../../components/user/board-view/board-view.component";
@translate()
@connect((state) => {
    return {
        permissions: state.staff.permissions,
    };
})
export default class UserSummaryPage extends Component {

    state = {
        lastTextChange: 0,
        selectedUser: undefined,
        selectedTab: "1",
    };

    refresh() {
        this.onClickItem(this.state.selectedUser.id);
    }

    onClickItem = async (userId) => {
        this.setState({ selectedUser: await userApi.getUser(userId) });
    }

    render() {
        const { t, permissions } = this.props;
        const { selectedTab, selectedUser } = this.state;
        return (
            <div className="user-summary-page">
                <div className="main-title">{t('user_summary_title')}</div>
                <UserSearchBar className="search-bar" onUserSelected={(user) => this.onClickItem(user.id)} />
                {selectedUser !== undefined ?
                    <div className="customer-summary">
                        <div className="main-title">{t('user_summary_customer_summary')}</div>
                        <UserViewBoard user={selectedUser} />
                        <div className="customer-information">
                            <Tabs onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })} type="card" value={selectedTab}>
                                {permissions.reservation ? <Tabs.Pane label={t('user_summary_tab_booking')} name="1">{selectedTab === "1" ? <UserBookingTab user={selectedUser} refresh={() => this.refresh()} /> : null}</Tabs.Pane> : null}
                                {permissions.bodyCondition ? <Tabs.Pane label={t('user_summary_tab_body_condition')} name="2">{selectedTab === "2" ? <UserBodyConditionTab bodyConditions={selectedUser.bodyConditions} /> : null}</Tabs.Pane> : null}
                                {permissions.family ? <Tabs.Pane label={t('user_summary_tab_family')} name="3">{selectedTab === "3" ? <UserFamilyTab user={selectedUser} /> : null}</Tabs.Pane> : null}
                                {permissions.transaction ? <Tabs.Pane label={t('user_summary_tab_transaction')} name="4">{selectedTab === "4" ? <UserTransactionTab user={selectedUser} /> : null}</Tabs.Pane> : null}
                                {permissions.transaction ? <Tabs.Pane label={t('user_summary_tab_top_up')} name="5">{selectedTab === "5" ? <UserTopUpTab user={selectedUser} refresh={() => this.refresh()} /> : null}</Tabs.Pane> : null}
                                {permissions.transaction ? <Tabs.Pane label={t('user_summary_tab_transfer')} name="6">{selectedTab === "6" ? <UserTransferTab user={selectedUser} refresh={() => this.refresh()} /> : null}</Tabs.Pane> : null}
                            </Tabs>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
}
