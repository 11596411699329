import client from '../apiClient';


// 导出execl
export async function exprotExecl(data){
    let response = await client.get(`/reservation/exportExcel`,{
        params:data,
        responseType: "blob"
    })
    return response.data
}

export async function listUserReport(query){
    let response = await client.get(`/report/user`,{
        params:query,
    })
    return response
}

export async function listSerivceReport(query){
    let response = await client.get(`/report/service`,{
        params:query,
    })
    return response
}

export async function listTotalReport(query){
    let response = await client.get(`/report/total`,{
        params:query,
    })
    return response
}

// 导出execl
export async function listExprotExecl(data){
    let response = await client.post(`/report/exportExcel`,
        data,{responseType: "blob"})
    return response.data
}

export async function getExpendExprot(data) {
    let response = await client.post(`/report/consume`,data)
    return response
}

// 导出 expend-execl
export async function expendExecl(data){
    let response = await client.post(`/report/expend-execl`,
        data,{responseType: "blob"})
    return response.data
}