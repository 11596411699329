
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-edit.scss';
import moment from 'moment';
import * as userApi from "../../../api/user.api";
import * as serviceApi from '../../../api/service.api'
import * as staffApi from "../../../api/staff.api";
import { Radio, Button, Input, DatePicker, MessageBox, Layout, Form, Select, Cascader } from 'element-react';
import fileDialog from "file-dialog";
import 'react-datepicker/dist/react-datepicker.css';
import history from '../../../history';
import AccountIcon from "../../icon.component";

@translate()
export default class UserEditBoard extends Component {
    state = {
        user: {},
        userUpdate: null,
        icon: null,
        localIcon: null,
        bodyConditions: null,
        defaultBC: null,
        customerClass: [],
        intentionalService: [],
        introducer: [],
        rules: {
            surname: [
                { required: true, message: '請輸入客戶姓名', trigger: 'blur' }
            ],
            name: [
                { required: true, message: '請輸入客戶姓名', trigger: 'blur' }
            ],
            gender: [
                { required: true, message: '請選擇客戶性別', trigger: 'change' }
            ],
            phone: [
                { required: true, message: '請輸入客戶電話', trigger: 'blur' }
            ],
        },
        prop: {
            value: 'value',
            label: "name"
        }
    };

    updateUserBC(index) {
        const newUserBCs = this.state.bodyConditions;
        newUserBCs[index].selected = !newUserBCs[index].selected;
        this.setState({
            bodyConditions: newUserBCs,
        });
    }

    updateUser(title, value) {
        const newUser = { ...this.state.user };
        newUser[title] = value;
        this.setState({ user: newUser });
        if (this.state.user.id) {
            const newUser = { ...this.state.userUpdate };
            newUser[title] = value;
            this.setState({ userUpdate: newUser });
        }
    }

    async uploadIcon() {
        this.setState({ icon: (await fileDialog({ accept: 'image/*' }))[0] },
            () => {
                if (this.state.icon) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.setState({ localIcon: e.target.result });
                    };
                    reader.readAsDataURL(this.state.icon);
                }
            }
        );
    }


    async submit() {
        const { customerClass, intentionalService, introducer } = this.state
        console.log('customerClass', customerClass)
        console.log('intentionalService', intentionalService)
        console.log('introducer', introducer)
        const { user, userUpdate, icon, bodyConditions, defaultBC } = this.state;
        let userId;
        this.refs.form.validate(async (valid) => {
            if (valid) {
                if (user.id) {
                    userId = user.id;
                    if (userUpdate) {
                        await userApi.updateUser(userId, userUpdate).then(() => {
                            history.push("/main/user-all");
                        }).catch(error => {
                            console.log(error)
                            MessageBox.alert(error.userMessage);
                        });
                    }
                } else {
                    await userApi.createUser(user).then(res => {
                        history.push("/main/user-all");
                        userId = res.id
                    }).catch((error) => {
                        MessageBox.alert(error.userMessage);
                    })
                }
                if (icon) {
                    await userApi.uploadIcon(userId, icon)
                }
            } else {
                console.log('error submit!!');
                return false;
            }
        });
        let newBodyConditions = [];
        let bcChanged = false;
        for (let i = 0; i < bodyConditions.length; i++) {
            if (defaultBC[i].selected !== bodyConditions[i].selected) {
                bcChanged = true;
            }
            if (bodyConditions[i].selected) {
                newBodyConditions.push(bodyConditions[i].id);
            }
        }
        if (bcChanged) {
            await userApi.assignBodyCondition(userId, newBodyConditions);
        }
    }

    async getVipList() {
        await userApi.getVipList().then((result) => {
            const data = result.map(item => {
                return {
                    value: item.id,
                    label: item.level
                }
            })
            this.setState({ customerClass: data })
        }).catch(error => {
            MessageBox.alert(error.userMessage);
        });
    }

    async getServiceList() {
        await serviceApi.getServiceList().then(async result => {
            let data = await this.ServiceTree(result.rows)
            console.log(data)
            this.setState({ intentionalService: data })
        }).catch(error => {
            MessageBox.alert(error.userMessage);
        })
    }

    async ServiceTree(datas) {
        let parents = []
        datas.forEach(item => {
            if (item.stratum == 1) {
                parents.push({
                    value: item.id,
                    name: item && item.category_details && item.category_details[0].title,
                })
            }
        })
        let childrens = datas.filter(item => item.stratum != 1)
        await this.formatService(parents, childrens)
        return parents
    }

    async formatService(parents, childrens) {
        parents.forEach(parent => {
            childrens.forEach((children, index) => {
                if (children.stratumId == parent.value) {
                    let temp = JSON.parse(JSON.stringify(childrens))
                    temp.splice(index, 1)
                    let services = children.services.map(service => {
                        return {
                            value: service.id,
                            name: service.service_details[0].name
                        }
                    })
                    let child = {
                        value: children.id,
                        name: children && children.category_details && children.category_details[0].title,
                    }
                    if (children.services.length > 0) {
                        child.children = services
                    }
                    parent.children ? parent.children.push(child) : parent.children = [child]
                    this.formatService([child], temp)
                }
            })
        })
    }

    async getUsersAndStaffs() {
        const datas = await Promise.all([userApi.getAllList(), staffApi.getStaffsAll()]);
        let all = datas.map((data, index) => {
            let arr = data.map(item => {
                return {
                    value: item.id,
                    label: item.staff_details ? item.staff_details[0].name + " " + item.staff_details[0].surname : item.name
                }
            })

            return {
                value: index == 0 ? 'user' : 'staff',
                label: index == 0 ? 'users' : 'staffs',
                children: arr
            }
        })
        this.setState({ introducer: all })
    }

    async componentDidMount() {
        const { user, bodyConditions } = this.props;
        await this.getVipList()
        await this.getServiceList()
        await this.getUsersAndStaffs()
        if (bodyConditions) {
            this.setState({
                user: user ? user : {},
                bodyConditions: bodyConditions.map((bodyCondition) => {
                    let selected = false;
                    if (user) {
                        for (let i = 0; i < user.bodyConditions.length; i++) {
                            if (user.bodyConditions[i].active && user.bodyConditions[i].id === bodyCondition.id) {
                                selected = true;
                                break;
                            }
                        }
                    }
                    const newBodyCondition = {
                        selected,
                        ...bodyCondition,
                    }
                    return newBodyCondition;
                }),
            }, () => {
                this.setState({
                    defaultBC: this.state.bodyConditions.map((bodyCondition) => {
                        return { ...bodyCondition };
                    })
                });
            });
        }
    }

    async inputOnBlur(value, key) {
        let reference = value
        await userApi.getUsers(1, 'id', 'ASC', 100, reference || '').then(res => {
            let data = res.rows.map(item => {
                return item[key]
            })
            if (data.includes(reference)) {
                MessageBox.alert(`${key}已存在`, '警告')
            }
        })
    }

    render() {
        const { t } = this.props;
        const { user, bodyConditions, localIcon } = this.state;
        return (
            bodyConditions
                ? <main className='border-edit-contanier'>
                    <section className='border-edit-main'>
                        <div className='icon-edit-contanier'>
                            <div className='avatar-main'>
                                <div className='avatar'>
                                    <AccountIcon className="icon" icon={localIcon || user.cmsIcon} onClick={() => this.uploadIcon()} />
                                </div>
                                <div className='detele-button'>
                                    <Button >{t('delete')}</Button>
                                </div>
                            </div>
                        </div>
                        <article className='page-info'>
                            <div className='basic-info'>
                                <Form ref="form" model={this.state.user} rules={this.state.rules} labelWidth="120" labelPosition='top'>
                                    <Layout.Row gutter="50">
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_all_table_column_surname')} prop='surname'>
                                                <Input value={user.surname ? user.surname : ""} onChange={(value) => this.updateUser("surname", value)} />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_board_edit_name')} prop='name'>
                                                {/* onBlur={this.inputOnBlur.bind(this,user.name,"name") } */}
                                                <Input value={user.name ? user.name : ""} onChange={(value) => this.updateUser("name", value)} />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="24">
                                            <Form.Item label={t('user_board_edit_gender')} prop='gender' style={{ display: 'flex' }}>
                                                <Radio.Group
                                                    style={{ marginTop: '16px', display: 'flex' }} value={user.gender ? user.gender : null}
                                                    onChange={(value) => { this.updateUser("gender", value) }}>
                                                    <Radio value="male">{t('male')}</Radio>
                                                    <Radio value="female">{t('female')}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_board_edit_birthday')} prop='birthday'>
                                                <DatePicker
                                                    value={user.birthday ? moment(user.birthday).toDate() : null}
                                                    onChange={date => {
                                                        if (!date) {
                                                            this.updateUser("birthday", moment().format("YYYY-MM-DD"));
                                                        } else {
                                                            this.updateUser("birthday", moment(date).format("YYYY-MM-DD"));
                                                        }
                                                    }}
                                                    disabledDate={time => time.getTime() > Date.now() - 8.64e7}
                                                    className='date-select'
                                                />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_board_edit_hkid')} prop='hkid'>
                                                <Input value={user.hkid ? user.hkid : ""} onChange={(value) => this.updateUser("hkid", value)} />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_board_edit_tel')} prop='phone'>
                                                {/*之前失去焦点时判断是否电话已经被使用  onBlur={this.inputOnBlur.bind(this,user.phone,'phone')} */}
                                                <Input value={user.phone ? user.phone : ""} onChange={(value) => this.updateUser("phone", value)} />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_board_edit_email')} prop='email'>
                                                <Input value={user.email ? user.email : ""} onChange={(value) => this.updateUser("email", value)} />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_board_edit_intentional_service')}>
                                                <Cascader
                                                    clearable
                                                    className='date-select'
                                                    value={user.intentionalService && user.intentionalService.length > 0 ? user.intentionalService : []}
                                                    placeholder="請選擇"
                                                    options={this.state.intentionalService}
                                                    props={this.state.prop}
                                                    filterable={true}
                                                    changeOnSelect={true}
                                                    onChange={(value) => this.updateUser("intentionalService", value)}
                                                ></Cascader>
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_board_edit_consume_class')}>
                                                <Cascader
                                                    clearable
                                                    className='date-select'
                                                    value={user.consumeClass && user.consumeClass.length > 0 ? user.consumeClass : []}
                                                    placeholder="請選擇"
                                                    options={this.state.intentionalService}
                                                    props={this.state.prop}
                                                    filterable={true}
                                                    changeOnSelect={true}
                                                    onChange={(value) => this.updateUser("consumeClass", value)}
                                                ></Cascader>
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_board_edit_customer_class')}>
                                                <Select
                                                    clearable
                                                    className='date-select'
                                                    value={user.customerClass ? user.customerClass : ""} placeholder="請選擇客戶分類"
                                                    onChange={(value) => this.updateUser("customerClass", value)}>
                                                    {
                                                        this.state.customerClass.map(el => {
                                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_board_edit_introducer')}>
                                                <Cascader
                                                    clearable
                                                    value={user.introducer && user.introducer.length > 0 ? user.introducer : []}
                                                    placeholder="請選擇"
                                                    options={this.state.introducer}
                                                    filterable={true}
                                                    onChange={(value) => this.updateUser("introducer", value)}
                                                ></Cascader>
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="12">
                                            <Form.Item label={t('user_board_edit_address')} prop='address'>
                                                <Input value={user.address ? user.address : ""} onChange={(value) => this.updateUser("address", value)} />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="24">
                                            <Form.Item label={t('user_board_edit_remark')} prop='remark'>
                                                <Input type="textarea" autosize={{ minRows: 3 }} value={user.remark ? user.remark : ""} onChange={(value) => this.updateUser("remark", value)} />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="24">
                                            <Form.Item>
                                                <div className='basic-info-form-btn'>
                                                    <Button onClick={this.submit.bind(this)}>
                                                        {t(user.id ? 'button_save' : 'button_create')}
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </Layout.Col>
                                    </Layout.Row>
                                </Form>
                            </div>
                        </article>
                    </section>
                </main>
                : null
        );
    }
}