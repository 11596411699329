import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './pdf.scss';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas'
import "jspdf-autotable";
import {  Layout } from 'element-react';
import moment from 'moment';
import logo from '../../../assets/MIUSEE_Beauty_logo.png';

@translate()
export default class UserSerivePackages extends Component {
  state = {
      pdfs: [0, 1],
      date : new Date(),
      show:true
  }

    async componentDidMount() {
        await this.download()
        await setTimeout(() => {
            this.props.closedPDF()
        })
    }

    px2mm(px) {
        var ratio = 0;
        var div = document.createElement('div')
        div.style.width = '1cm'
        div.id = 'puc'
        document.body.appendChild(div)
        var w = getComputedStyle(div, null).width
        ratio = w.substr(0, w.length - 2)
        div.parentNode.removeChild(div)

        var r = ratio / 10
        return Math.round(px / r)
    }

    download(){
        const element = document.getElementById('demo');  // 这个dom元素是要导出的pdf的div容器
        const w = element.offsetWidth;  // 获得该容器的宽
        const h = element.offsetHeight;  // 获得该容器的高
        const offsetTop = element.offsetTop; // 获得该容器到文档顶部的距离  
        const offsetLeft = element.offsetLeft; // 获得该容器到文档最左的距离
        const canvas = document.createElement("canvas")
        let abs = 0;
        const win_i = document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
        const win_o = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）
        if(win_o > win_i){
          abs = (win_o - win_i) / 2; // 获得滚动条宽度的一半
        }
        canvas.width = w * 2; // 将画布宽&&高放大两倍
        canvas.height = h * 2;
        const context = canvas.getContext('2d');
        context.scale(2, 2);
        context.translate(-offsetLeft - abs, -offsetTop);
        // 这里默认横向没有滚动条的情况，因为offset.left()，有无滚动条的时候存在差值，因此translate的时候，要把这个差值去掉
        html2canvas(element, {
          allowTaint: true,
          scale: 2 // 提升画面质量，但是会增加文件大小
        }).then( canvas => {
          const contentWidth = canvas.width;
          const contentHeight = canvas.height;
          // 一页pdf显示html页面生成的canvas高度
          const pageHeight = this.px2mm(contentHeight / 2);
          // 未生成pdf的html页面高度
          let leftHeight = this.px2mm(contentHeight);
          // 页面偏移
          let position = 0;
          // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          const imgWidth = 297;
          const imgHeight = this.px2mm(contentHeight / 2)
          
          const pageDate = canvas.toDataURL('image/jpeg', 1.0);
          
          const pdf = new jsPDF('landscape', 'mm', 'a4');
          // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面的高度（841.89）
          // 当内容未超过pdf一页显示的范围，无需分页
          if(leftHeight < pageHeight) {
            pdf.addImage(pageDate,'JPEG', 0, position, imgWidth, imgHeight);
          }else { // 分页
            console.log('leftHeight',leftHeight)
            console.log('pageHeight',pageHeight)
            while (leftHeight > 0){
                pdf.addImage(pageDate, 'JPEG', 0, position, imgWidth, imgHeight) 
                  leftHeight -= pageHeight;
                  position -= 210;
                  // 避免添加空白页
                  if(leftHeight > 0){
                  pdf.addPage()
                }
            }
          }

          const { user,pdfDatas } = this.props

          const date = Date().split(" ");

          const userId = user.id &&  user.id !== '' ? user.surname + '' + user.name : user.name

          // we use a date string to generate our filename.
          const dateStr = date[1] + date[2] + date[3] + date[4];
          // ticket title. and margin-top + margin-left
          // we define the name of our PDF file.
          pdf.save(`${userId}_${pdfDatas.name}_${dateStr}.pdf`);
        })
    }

    render() {
      const { user,pdfDatas } = this.props
      const { date, show, pdfs } = this.state
      let pdfTop = {
        id : 'MB' + moment(date).format('YYYYMMDD')+moment(date).format('HHmmss'),
        center: 'MIUSEE BEAUTY',
        date: moment(date).format('YYYY-MM-DD'),
        userId: user.id ? user.id : '非會員用戶',
        name: user.id ? user.surname + '' + user.name : user.name,
      }
        return (
            <main id="demo" className='exprot-pdf'>
              <div className='pdf-contanier'>
                {
                  pdfs.map(item => {
                    return (
                      <section className='pdf-main' style={{padding:"0 10px"}}  key={item}>
                          <header>
                            <div className='pdf-top'>
                                <img className="pdf-logo" src={logo} alt="" />
                            </div>
                            <div className='pdf-main-header'>
                                <Layout.Row>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{marginBottom:'5px'}}>店铺 : {pdfTop.center}</p>
                                    </Layout.Col>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{textAlign:"right",marginBottom:'5px'}} >收據編號 : {pdfTop.id}</p>
                                    </Layout.Col>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{marginBottom:'5px'}}>會員編號(如適用) : {pdfTop.userId}</p>
                                    </Layout.Col>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{textAlign:"right",marginBottom:'5px'}}>日期 : {pdfTop.date}</p>
                                    </Layout.Col>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{marginBottom:'5px'}}>客戶名稱 : {pdfTop.name}</p>
                                    </Layout.Col>
                                    <Layout.Col xs="12" sm="12" md="12" lg="12">
                                        <p style={{textAlign:"right",marginBottom:'5px'}}>時間 : {moment(date).format('HH:mm A')}</p>
                                    </Layout.Col>
                                </Layout.Row>
                            </div>
                          </header>

                          
                          <div>
                              <div className='pdf-main-content'>
                                  <div className="hr"></div>
                                  <div style={{padding:"0 5px"}}>
                                    <p className='colum-title'>套餐名稱</p>
                                    <p>{pdfDatas.name}</p>
                                  </div>
                                  <div style={{padding:"0 5px",flex:"1"}}>
                                    <p className='colum-title'>套餐包含服務</p>
                                    <div>
                                      {
                                        pdfDatas.extra.map((item,index) => {
                                          return (
                                            <div key={item.id}>
                                                <p>{index+1}). {item.containService.map(e => e.name).join(" / ")}</p> 
                                                <div style={{marginLeft:"20px"}}>
                                                  <p>总次數： {item.times}</p>
                                                  <p>單價：{item.price}</p>
                                                </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  </div>
                                  <div style={{padding:"0 5px"}}>
                                    <p className='colum-title'>套餐價格</p>
                                    <p>{pdfDatas.price}</p>
                                  </div>
                                  <div style={{padding:"0 5px"}}>
                                    <p className='colum-title'>套餐到期時間</p>
                                    <p>{moment(pdfDatas.expireDate).format("YYYY-MM-DD HH:mm:ss")}</p>
                                  </div>
                                  <div style={{padding:"0 5px"}}>
                                    <p className='colum-title'>套餐購買時間</p>
                                    <p>{moment(pdfDatas.updatedAt).format("YYYY-MM-DD HH:mm:ss") }</p>
                                  </div>
                              </div>
                              
                              <div className='tokens'>
                                  <div className='token'>
                                      客戶簽名 : &nbsp; <span style={{flex : "1",borderBottom: "1px solid rgb(26, 26, 26)"}}></span>
                                  </div>
                              </div>
                          </div>
                          

                          <div className='conditions'>
                              <p>條款細則</p>
                              <ul>
                                  <li>1. 必須年滿18歲</li>
                                  { show ? 
                                  <div>
                                      <li>2. 本公司所有半永久定妝項目6個月內免費贈送補色一次(特價項目除外)，逾期無效。</li>
                                  <li>3. 首次療程日期起12個月之內補色費用為800元一次。</li>
                                  <li>4. 本公司補色服務不設指定塑眉師，如需指定，需付款500至1000元指定費用(視乎塑眉師而定)。<br/>
                                  注 : 如補色期間，所指定塑眉師的工作有特殊的變動，導致無法安排同一位塑眉師為你跟進，本公司將會免費安排其他相同年資的塑眉師為您跟進
                                  </li>
                                  <li>5. 任何服務之預約如需更改，必須提前72小時更改(僅限一次)，臨時更改預約，訂金將不獲任何退款及補償。補色服務的預約不會接受任何更改，如需更改，將視為放棄服務處理。</li>
                                  <li>6. 購買的療程為特價項目，不設退換服務</li>
                                  </div> : null }
                              </ul>
                          </div>
                      </section>
                    )
                  })
                }
              </div>
            </main>
        )
    }
}

