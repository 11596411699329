import React, { Component } from 'react';
import './room-all.scss';
import * as roomApi from "../../../../api/room.api";
import * as centerApi from "../../../../api/center.api";
import { Select, Button, Dialog } from 'element-react';
import { translate } from 'react-i18next';
import history from '../../../../history';
import PageTable from "../../../../components/page-table.component";
// import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog.component";
import ResultLimit from "../../../../components/result-limit.component";

@translate()
export default class RoomAllPage extends Component {

    state = {
        centers: [],
        results: [],
        lastTextChange: 0,
        currentPage: 1,
        orderBy: "createdAt",
        direction: "ASC",
        limit: 40,
        selectedCenter: "ALL",
        totalResult: 0,
        showConfirm: false,
        columns: [
            {
                label: this.props.t('room_all_table_column_center'),
                prop: "center",
            },
            {
                label: this.props.t('room_all_table_column_title'),
                prop: "title",
            },
            {
                label: this.props.t('room_all_table_column_service'),
                prop: "service",
            },
            {
                label: this.props.t('room_all_table_column_status'),
                prop: "status",
            },
            {
                label: this.props.t('room_all_table_column_view_detail'),
                prop: "detail",
                render: (row, index) => {
                    // console.log(row, index);
                    return (
                        <span>
                            <Button onClick={() => this.switchTab("/main/room-detail/" + row.id)}>{this.props.t('button_edit')}</Button>
                            {/* <Button onClick={() => this.setState({
                                showConfirm: true,
                            })}>{this.props.t('button_delete')}</Button> */}
                        </span>
                    );
                }
            },
        ],
        loading: true,
    };

    switchTab = (url) => {
        history.push(url);
    }

    setCenter(center) {
        this.setState({
            selectedCenter: center,
        }, () => this.updateRoomRecords());
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.updateRoomRecords());
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.updateRoomRecords())
    }

    async deleteRoom(id) {
        await roomApi.deleteRoom(id);
        this.updateRoomRecords();
    }

    async updateRoomRecords() {
        const { currentPage, orderBy, direction, limit, selectedCenter } = this.state;
        const { t } = this.props;
        const response = await roomApi.getRooms(currentPage, orderBy, direction, limit, selectedCenter === "ALL" ? "" : selectedCenter);
        console.log('room:', response);
        this.setState({
            centers: (await centerApi.getCenters()).map((center) => {
                const newData = {
                    id: center.id,
                    name: center.center_details[0].name,
                }
                return newData;
            }),
            results: response.rows.map((room) => {
                const data = {
                    id: room.id,
                    center: room.center.center_details[0].name,
                    title: room.title,
                    service: room.room_services.map((room_service) => {
                        return room_service.service.service_details[0].name;
                    }).toString(),
                    status: room.active ? t('active') : t('inactive'),
                    session_interval: `${room.sessionInterval} mins`,
                }
                return data;
            }),
            totalResult: response.count,
            loading: false,
        });
    }

    async componentDidMount() {
        await this.updateRoomRecords();
    }

    render() {
        const { t } = this.props;
        const { limit, columns, currentPage, totalResult, results, centers, selectedCenter, showConfirm, confirmFunction, loading } = this.state;
        return (
            <div className="room-all-page">
                <div className="main-title">
                    {t('room_all_main_title')}
                </div>
                <div className="page-header">
                    {t('room_all_center_filter_title')}
                    <div className="center-filter">
                        <Select className="selector" onChange={(key) => this.setCenter(key)} className="result-limit-select" value={selectedCenter}>
                            <Select.Option key={0} label={t('room_all_center_filter_all')} value={"ALL"} />
                            {
                                centers.map((center, i) => {
                                    return <Select.Option key={i + 1} label={center.name} value={center.id} />
                                })
                            }
                        </Select>
                    </div>
                    <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} />
                    <div className="space"></div>
                    <div className="add-room-button">
                        <Button onClick={() => this.switchTab("/main/room-detail/create")}>{t('room_all_button_add_new_room')}</Button>
                    </div>
                </div>
                <PageTable
                    limit={limit}
                    currentPage={currentPage}
                    totalResult={totalResult}
                    results={results}
                    columns={columns}
                    setCurrentPage={(page) => this.setCurrentPage(page)}
                    loading={loading}
                />
                {/* <ConfirmDialog 
                    visible={showConfirm}
                    onCancel={() => this.setState({ showConfirm: false })}
                    onConfirm={() => {
                        this.setState({ showConfirm: false });
                    }}
                /> */}
            </div>
        );
    }
}