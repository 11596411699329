import { combineReducers } from 'redux';
import staff from './staff/staff.reducer';
import session from './staff/session.reducer';
import language from './staff/language.reducer';
import error from './staff/error.reducer';


export default combineReducers({
  staff,
  session,
  language,
  error,
});