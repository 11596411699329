
import React, { Component } from 'react';
import './room-detail.scss';
import { translate } from 'react-i18next';
import * as roomApi from "../../../../api/room.api";
import * as centerApi from "../../../../api/center.api";
import { Checkbox, Select, Button, Input } from 'element-react';
import { withRouter } from 'react-router-dom';
import history from '../../../../history';

@withRouter
@translate()
export default class RoomDetailPage extends Component {
    state = {
        room: null,
        roomUpdate: null,
        centers: null,
        selectedCenter: {},
    }

    async submit() {
        const { room, roomUpdate, selectedCenter } = this.state;
        let roomId;
        if (room && room.id) {
            roomId = room.id;
            if(roomUpdate) {
                await roomApi.updateRoom(room.id, roomUpdate);
            }
        } else {
            roomId = (await roomApi.createRoom(roomUpdate)).id;
        }
        if (selectedCenter) {
            const services = selectedCenter.services
                .filter((service) => service.selected)
                .map((service) => service.id);
            await roomApi.assignService(roomId, services);
        }
        history.push("/main/room");
    }

    async componentDidMount() {
        const centerResponse = await centerApi.getCenters();
        this.setState({
            centers: centerResponse,
        }, async () => {
            console.log("centers:", this.state.centers);
            if (this.props.match.params.roomId !== "create") {
                await this.getData(this.props.match.params.roomId);
            }
        });
    }

    async getData(roomId) {
        const roomResponse = await roomApi.getRoom(roomId);
        const newRoomData = {
            ...roomResponse,
            services: roomResponse.room_services.map((room_service) => {
                return room_service.service.id;
            }),
        };
        delete newRoomData.room_services;
        this.changeSelectedCenter(newRoomData.centerId, newRoomData.services);
        this.setState({
            room: newRoomData,
        }, () => {
            console.log("Room: ", this.state.room);
            console.log("selectedCenter: ", this.state.selectedCenter);
        });
    }

    transformTranslationArray(arr) {
        const resultObj = {};
        for (let i = 0; i < arr.length; i += 1) {
            resultObj[arr[i].languageId] = arr[i];
        }
        return resultObj;
    }

    updateRoom(updateObj) {
        const newRoomUpdate = {
            ...this.state.roomUpdate,
        };
        newRoomUpdate.basic = {
            ...newRoomUpdate.basic,
            ...updateObj,
        }
        const newRoom = {
            ...this.state.room,
            ...updateObj,
        };
        this.setState({
            roomUpdate: newRoomUpdate,
            room: newRoom,
        }, () => {
            console.log(this.state.room);
        });
    }

    updateSelectedService(index) {
        const newSelectedCenter = { ...this.state.selectedCenter };
        newSelectedCenter.services[index].selected = !newSelectedCenter.services[index].selected;
        this.setState({
            newSelectedCenter,
        });
    }

    changeSelectedCenter(centerId, serviceIds) {
        const { centers } = this.state;
        let selectedCenter;
        for (const center of centers) {
            if (center.id === centerId) {
                selectedCenter = { ...center };
                selectedCenter.services = center.center_services.map((center_service) => {
                    const serviceItem = {
                        selected: false,
                    };
                    if (serviceIds) {
                        for (const serviceId of serviceIds) {
                            if (serviceId === center_service.service.id) {
                                serviceItem.selected = true;
                            }
                        }
                    }
                    serviceItem.name = center_service.service.service_details[0].name;
                    serviceItem.id = center_service.service.service_details[0].serviceId;
                    return serviceItem;
                });
                break;
            }
        }
        delete selectedCenter.center_services;
        this.updateRoom({ centerId: selectedCenter.id });
        this.setState({
            selectedCenter,
        }, () => {
            console.log('selectedCenter', selectedCenter);
        });
    }

    render() {
        const { t } = this.props;
        const { room, selectedCenter, centers } = this.state;
        return (
            centers ? <div className="room-detail-page" >
                <div className="main-title">
                    {t(room ? 'room_detail_title_edit' : 'room_detail_title_create')}
                </div>
                <div className="page-body">
                    <div className="row">
                        <div className="title">
                            {t('room_detail_content_title_title')}
                        </div>
                        <div className="value">
                            <Input value={room && room.title || ''} onChange={(value) => this.updateRoom({ title: value })} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('room_detail_content_title_center')}
                        </div>
                        <div className="value">
                            <Select onChange={(value) => this.changeSelectedCenter(value)} value={selectedCenter.id}>
                                {
                                    centers.map((center) => {
                                        return (
                                            <Select.Option key={center.id} label={center.center_details[0].name} value={center.id} />
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    {selectedCenter && selectedCenter.services ?
                        <div className="row">
                            <div className="title">
                                {t('room_detail_content_title_service')}
                            </div>
                            <div className="value">
                                <div className="select-box">
                                    {
                                        selectedCenter.services.map((service, i) => {
                                            return (
                                                <div className="select-option" key={service.id}>
                                                    <Checkbox checked={service.selected} onChange={() => this.updateSelectedService(i)}>
                                                        {service.name}
                                                    </Checkbox>
                                                </div>
                                            );
                                        })
                                    }

                                </div>
                            </div>
                        </div> : null
                    }
                    <div className="row">
                        <div className="title">
                            {t('room_detail_content_title_status')}
                        </div>
                        <div className="value">
                            <Select onChange={(value) => this.updateRoom({active: value})} value={room && room.active}>
                                <Select.Option key={true} label={t('room_detail_content_value_active')} value={true} />
                                <Select.Option key={false} label={t('room_detail_content_value_inactive')} value={false} />
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="page-footer">
                    <Button onClick={() => this.submit()}>{t(room && room.id ? 'button_save' : 'button_create')}</Button>
                </div>
            </div> : null
        );
    }
}