import client from '../apiClient';

export async function getCategories() {
    let response = await client.get(`/category`);
    return response.data;
}

export async function getCategory(categoryId) {
    let response = await client.get(`/category/${categoryId}`);
    return response.data;
}

export async function createCategory(body) {
    let response = await client.post(`/category`, body);
    return response.data;
}

export async function updateCategory(categoryId, body) {
    let response = await client.patch(`/category/${categoryId}`, body);
    return response.data;
}

export async function uploadImage(categoryId, slide) {
    const formData = new FormData();
    formData.append("slide", slide);
    let response = await client.post(`/category/${categoryId}/slide`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function deleteCategory(categoryId) {
    let response = await client.delete(`/category/${categoryId}`);
    return response.data;
}

export async function addCategory(arr) {
    let response = await client.post(`/category/stratumClassify`,arr);
    return response.data;
}

export async function all(){
    let response = await client.get(`/category/all`);
    return response;
}

