
import React, { Component } from 'react';
import * as reservationApi from "../../../../api/reservation.api";
import { Table,Message,Loading,Pagination } from 'element-react';
import { translate } from 'react-i18next';
import moment from 'moment';
import './booking-revise-record.scss'


@translate()
export default class extends Component {
    state = {
        data:[],
        columns:[
            {
                label: this.props.t('booking_table_column_date'),
                prop: "startTime",
                sortable: "custom",
                render: this.renderDate,
                width:160
            },
            {
                label: this.props.t('booking_table_column_time'),
                prop: "startTime",
                render: this.renderTime,
                width:160
            },
            {
                label: this.props.t('booking_table_column_user'),
                render : function(data) {
                   return (
                       <div>
                           <span>{data.surname}</span>
                           <span>{data.name}</span>
                       </div>
                   )
                }
            },
            {
                label: this.props.t('booking_table_column_staff'),
                render : function(data) {
                    return (
                        <div>
                            <span>{data.staffName}</span>
                            <span>{data.staffSurname}</span>
                        </div>
                    )
                }
            },
            {
                label: this.props.t('booking_revise_record_content_update'),
                render : (data) => {
                    let content = JSON.parse(data.type) 
                    return (
                        content.length>0 ? <div>
                            {
                                content.map((item,index)=>{
                                    return (
                                        <div key={index}>{this.props.t('booking_revise_record_'+item)}</div>
                                    )
                                })
                            }
                        </div>:<div>无</div>
                    )
                }
            },
            {
                label:this.props.t('booking_revise_record_price'),
                prop:"price"
            },
            {
                label: this.props.t('booking_revise_record_discount_type'),
                prop: "discountType",
                render:(data)=>{
                    return (<span>{data.discountType?this.props.t('booking_discount_type_'+data.discountType):this.props.t('booking_discount_type_normal')}</span>)
                }
            },
            {
                label: this.props.t('booking_revise_record_discount_price'),
                render: function(data){
                    if(data.discountType === 'discount') {
                        return (
                            <span>{ (data.price && data.discount && (data.price * (1 - data.discount/100)).toFixed(2)) || 0 }</span>
                        ) 
                    } else {
                        return (
                            <span>{(data.discount&&(data.discount).toFixed(2)) || 0}</span>
                        ) 
                    }
                }
            },
            {
                label: this.props.t('booking_revise_record_real_deal_price'),
                prop : 'discountPrice',
                render: (row) => {
                    return (
                        <span>{row.discountPrice || 0}</span>
                    )
                }
            },
            {
                label: this.props.t('booking_revise_record_update_date'),
                prop: "createdAt",
                render:function(data){
                    return (<span>{moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>)
                },
                width: 200,
            },
        ],
        count:0,
        countPage:0,
        loading:false,
        request:{
            page:1,
            limit:10,
            reservationId:null
        }
    }

    async componentDidMount(){
        await this.getData(this.state.request)
    }

    async getData(obj){
        this.setState({
            loading:true
        })
        await reservationApi.getReservationRecords(obj).then(result => {
            this.setState({
                data:result.rows,
                count : result.count,
                countPage: result.countPage
            })
        }).catch(error=>{
            Message.error(error)
        }).finally(()=>{
            this.setState({
                loading:false
            })
        })
    }

    renderDate(date) {
        var date = new Date(date.startTime).toJSON();
        var val = new Date(+new Date(date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        return moment(new Date(val)).format('YYYY-MM-DD')
    }

    renderTime(date) {
        var startTime = new Date(date.startTime).toJSON();
        var endTime = new Date(date.endTime).toJSON();
        var from = new Date(+new Date(startTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        var to = new Date(+new Date(endTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        return moment(new Date(from)).format('HH:mm:ss') + "-" + moment(new Date(to)).format('HH:mm:ss')
    }

    async CurrentChange(key, value) {
        if(key == 'limit') {
            let newObject = {...this.state.request,page:1,limit:value}
            await this.setState({
                request:newObject
            })
        } else {
            await this.setState({
                request: Object.assign({}, this.state.request, { [key]: value })
            });
        }
        await this.getData(this.state.request)
    }

    render(){
        const { count,request:{page, limit},loading,columns, data,countPage } = this.state
        const { t } = this.props
        return (
            <main className="revise-record">
                <div className="main-title">{t('booking_revise_record')}</div>
                <section className='table-content'>
                    <Loading loading={loading}>
                        <Table style={{width: '100%'}} columns={columns} data={data} className='table-main'/>
                    </Loading>
                    {
                        count > 0 ? <Pagination layout="sizes ,prev, pager, next, jumper" total={count} small={true} pageSize={limit} currentPage={page}  onCurrentChange={(value)=>{this.CurrentChange('page', value)}} onSizeChange={(value)=>{this.CurrentChange('limit', value)}}></Pagination> : null
                    }
                </section>
            </main>
        )
    }
}
