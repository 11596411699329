
import i18n from '../../utils/i18n';

const initialErrorState = {
    errorMessage: null,
}

function error(state = initialErrorState, action) {
    switch (action.type) {
        case "SET_ERROR": {
            return {
                ...state,
                errorMessage: action.payload,
            }
        }
        case "CLEAR_ERROR": {
            return {
                ...state,
                errorMessage: null,
            }
        }
        default:
            return state;
    }
}

export default error;