
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-edit.scss';
import { Tabs, Button, Input } from 'element-react';
import * as webApi from "../../../../api/web.api"

@translate()
export default class MobileDescriptionEditBoard extends Component {
    state = {
        web: null,
        webUpdate: null,
        tabs: [
            {
                id: "eng",
                language: "English",
                title: "Title:",
                description: "Description:",
            },
            {
                id: "cht",
                language: "繁體中文",
                title: "標題:",
                description: "描述:",
            },
            {
                id: "chs",
                language: "简体中文",
                title: "标题:",
                description: "描述:",
            },
        ],
        selectedTab: "0",
    }

    async submit() {
        const { webUpdate } = this.state;
        if (webUpdate) {
            await webApi.updateWeb(webUpdate);
        }
        this.props.changeMode();
    }

    updateWebDetail(id, languageId, title, value) {
        const newWebUpdate = { ...this.state.webUpdate };
        newWebUpdate.details = newWebUpdate.details || [];
        let newDetail = { id, languageId, value: {} };
        let detailIndex = -1;
        for (let i = 0; i < newWebUpdate.details.length; i++) {
            const detail = newWebUpdate.details[i];
            if (detail.languageId === languageId && detail.id === id) {
                newDetail = { ...detail };
                detailIndex = i;
                break;
            }
        }
        newDetail.value[title] = value;

        if (detailIndex === -1) {
            newWebUpdate.details.push(newDetail);
        } else {
            newWebUpdate.details[detailIndex] = newDetail;
        }
        const newWeb = { ...this.state.web };
        if (!newWeb.details) {
            newWeb.details = {};
        }
        if (!newWeb.details[id]) {
            newWeb.details[id] = {};
        }
        if (!newWeb.details[id][languageId]) {
            newWeb.details[id][languageId] = {};
        }
        newWeb.details[id][languageId][title] = value;
        this.setState({
            webUpdate: newWebUpdate,
            web: newWeb,
        }, () => {
            console.log('webUpdate:', this.state.webUpdate);
        });
    }

    async componentDidMount() {
        const { web } = this.props;
        this.setState({
            web: web,
        });
    }

    render() {
        const { t } = this.props;
        const { tabs, selectedTab, web } = this.state;
        let details = {};
        if (web && web.details) {
            details = web.details;
        }
        return (
            web ? <div className="mobile-description-board-edit">
                <div className="tab">
                    <Tabs activeName={selectedTab} onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })}>
                        {
                            tabs.map((tab, i) => {
                                return (
                                    <Tabs.Pane key={i} label={tabs[i].language} name={i.toString()}>
                                        <div className="block">
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].title}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        value={
                                                            details.mobile_app && details.mobile_app[tabs[i].id] && details.mobile_app[tabs[i].id].title || ""}
                                                        onChange={(value) => this.updateWebDetail('mobile_app', tabs[i].id, "title", value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].description}
                                                </div>
                                                <div className="value">
                                                    <Input
                                                        type="textarea"
                                                        autosize={{ minRows: 5 }}
                                                        value={
                                                            details.mobile_app && details.mobile_app[tabs[i].id] && details.mobile_app[tabs[i].id].description || ""}
                                                        onChange={(value) => this.updateWebDetail('mobile_app', tabs[i].id, "description", value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Tabs.Pane>
                                )
                            })
                        }
                    </Tabs>
                </div>
                <div className="board-footer">
                    <Button onClick={() => this.submit()}>{t('button_save')}</Button>
                </div>
            </div> : null
        );
    }
}