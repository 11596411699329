import React, { Component } from 'react';
import './mobile-description.scss';
import { translate } from 'react-i18next';
import * as webApi from '../../../../api/web.api'
import { Button } from 'element-react';
import { withRouter } from 'react-router-dom';
import MobileDescriptionViewBoard from "../../../../components/web/mobile-description/board-view/board-view.component";
import MobileDescriptionEditBoard from "../../../../components/web/mobile-description/board-edit/board-edit.component";

@withRouter
@translate()
export default class MobileDescriptionDetailPage extends Component {
    state = {
        edit: false,
        web: null,
    }
    
    async componentDidMount() {            
        await this.getData();
    }

    async getData() {
        const webResponse = await webApi.getWeb();
        const newWeb = {
            ...webResponse,
            details: this.transformTranslationArray(webResponse.details),
        };
        this.setState({
            web: newWeb,
        }, () => {
            console.log("Web: ", this.state.web);
        });
    }

    transformTranslationArray(arr) {
        const resultObj = {};
        for (let i = 0; i < arr.length; i += 1) {
            resultObj[arr[i].id] = resultObj[arr[i].id] || {};
            resultObj[arr[i].id][arr[i].languageId] = arr[i];
        }
        return resultObj;
    }

    async changeMode () {
        await this.getData();
        this.setState({
            edit: !this.state.edit,
        });
    }

    render() {
        const { t } = this.props;
        const { web, edit } = this.state;
        return (
            <div className="mobile-description" >
                <div className="main-title">
                    {t(!edit?'web_mobile_description_title_view':'web_mobile_description_title_edit')}
                </div>
                <div className="page-body">
                    {edit?
                        <MobileDescriptionEditBoard web={web} changeMode={() => this.changeMode()} />
                        :<MobileDescriptionViewBoard web={web}/>}
                </div>
                <div className="page-footer">
                    {!edit?<Button onClick={() => this.setState({ edit: !edit })}>{t('button_edit')}</Button>:null}
                </div>
            </div>
        );
    }
}