import React, { Component } from 'react';
import './center-detail.scss';
import { translate } from 'react-i18next';
import { Button } from 'element-react';
import * as centerApi from "../../../../api/center.api";
import { withRouter } from 'react-router-dom';
import CenterViewBoard from "../../../../components/center/board-view/board-view.component";
import CenterEditBoard from "../../../../components/center/board-edit/board-edit.component";
import history from '../../../../history';

@withRouter
@translate()
export default class CenterDetailPage extends Component {
    state = {
        edit: false,
        center: null,
    }
    
    async componentDidMount() {
        if (this.props.match.params.centerId !== "create") {
            await this.getData(this.props.match.params.centerId);
        } else {
            this.setState({ edit: true });
        }
    }

    async getData(centerId) {
        const centerResponse = await centerApi.getCenter(centerId);
        console.log("centerResponse", centerResponse);
        const newCenterData = {
            ...centerResponse,
            center_details: this.transformTranslationArray(centerResponse.center_details),
        };
        this.setState({
            center: newCenterData,
        }, () => {
            console.log("Center: ", this.state.center);
        });
    }

    transformTranslationArray(arr) {
        const resultObj = {};
        for (let i = 0; i < arr.length; i += 1) {
            resultObj[arr[i].languageId] = arr[i];
        }
        return resultObj;
    }

    async changeMode (centerId) {
        await this.getData(centerId);
        this.setState({
            edit: !this.state.edit,
        });
    }

    render() {
        const { t } = this.props;
        const { center, edit } = this.state;
        return (
            <div className="center-detail-page" >
                <div className="main-title">
                    {t(!edit?'center_detail_title_view':(center?'center_detail_title_edit':'center_detail_title_create'))}
                </div>
                <div className="page-back"><Button onClick={() => history.goBack()}>{t('add_booking_button_back')}</Button></div>
                <div className="page-body">
                    {edit?
                        <CenterEditBoard center={center} changeMode={(centerId) => this.changeMode(centerId)} />:<CenterViewBoard center={center}/>}
                </div>
                <div className="page-footer">
                    {!edit?<Button onClick={() => this.setState({ edit: !edit })}>{t('button_edit')}</Button>:null}
                </div>
            </div>
        );
    }
}