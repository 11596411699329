import React, { Component } from 'react';
import './tab-transfer.scss';
import { translate } from 'react-i18next';
import { Dialog, Button, Input, Radio } from 'element-react';
import * as userApi from "../../../api/user.api";
import { connect } from 'react-redux';
import { setError } from '../../../redux/staff/error.action';

const mapDispatchToProps = (dispatch) => {
    return {
        setError: (errorMessage) => {
        dispatch(setError(errorMessage))
      }
    }
  }
@translate()
@connect((state) => {}, mapDispatchToProps)
export default class UserTransferTab extends Component {
    state = {
        newFamilyId: "",
        amount: 0,
        confirmFunction: null,
        confirmMsg: null,
        confirmTitle: null,
        showConfirm: false,
        transferType: 0,
    };

    async changeUserFamily(userId, familyId, amount) {
        const { transferType } = this.state;
        const { setError, t } = this.props;
        if(!transferType && !familyId) {
            return setError(t('error:please_input_field', { field: t('family_id') }));
        }
        await userApi.changeUserFamily(userId, familyId, amount);
        this.setState({
            amount: 0,
            newFamilyId: "",
        });
        this.props.refresh();
    }

    render() {
        const { t, user } = this.props;
        const { amount, newFamilyId, confirmTitle, showConfirm, confirmMsg, confirmFunction, transferType } = this.state;
        return (
            <div className="customer-transfer-tab">
                <div className="main-title">
                    {t('tab_transfer_title')}
                </div>
                <div className="row">
                    <div className="title">
                        {t('tab_transfer_content_name')}
                    </div>
                    <div className="value">
                        {user.name} {user.surname}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('tab_transfer_content_hkid')}
                    </div>
                    <div className="value">
                        {user.hkid}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('tab_transfer_content_member')}
                    </div>
                    <div className="value">
                        {user.id}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('tab_transfer_content_current_family')}
                    </div>
                    <div className="value">
                        {user.familyId}
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('tab_transfer_content_transfer_family')}
                    </div>
                    <div className="value">
                        <Radio.Group value={transferType} onChange={(value) => this.setState({ transferType: value })}>
                            <Radio value={0}><Input disabled={!!transferType} type="text" placeholder={t('tab_transfer_content_transfer_placeholder')} value={newFamilyId} onChange={(value) => this.setState({ newFamilyId: value })} /></Radio>
                            <Radio value={1}>{t('new_family_account')}</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('tab_transfer_content_balance')}
                    </div>
                    <div className="value">
                        HK$<Input className="input-amount" type="text" value={amount} onChange={(value) => this.setState({ amount: value })} />
                    </div>
                </div>
                <div className="board-footer">
                    <Button onClick={() => this.setState({
                        confirmFunction: () => this.changeUserFamily(user.id, newFamilyId, amount),
                        showConfirm: true,
                    })}>{t('tab_transfer_button_confirm')}</Button>
                </div>
                <Dialog
                    size={'tiny'}
                    title={confirmTitle || t('confirm_dialog_title')}
                    visible={showConfirm}
                    onCancel={() => this.setState({
                        confirmMsg: null,
                        confirmTitle: null,
                        showConfirm: false,
                    })}
                >
                    <div className="dialog">
                        <div className="dialog-body">
                            {confirmMsg || null}
                            <div className="row">
                                <div className="row">
                                    <Button type="primary" onClick={() => {
                                        confirmFunction()
                                        this.setState({
                                            confirmMsg: null,
                                            confirmTitle: null,
                                            showConfirm: false,
                                        });
                                    }}>{t('confirm_dialog_button_yes')}</Button>
                                    <Button onClick={() => this.setState({
                                        confirmMsg: null,
                                        confirmTitle: null,
                                        showConfirm: false,
                                    })}>{t('confirm_dialog_button_no')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
