import React, { Component } from 'react';
import './service-all.scss';
import * as serviceApi from "../../../../api/service.api";
import * as centerApi from "../../../../api/center.api";
import * as categoryApi from "../../../../api/category.api";
import { Select, Button, Form, Layout, Input } from 'element-react';
import { translate } from 'react-i18next';
import history from '../../../../history';
import PageTable from "../../../../components/page-table.component";
import ResultLimit from "../../../../components/result-limit.component";
// import { map } from 'jquery';
@translate()
export default class ServiceAllPage extends Component {
    state = {
        centers: [],
        results: [],
        lastTextChange: 0,
        currentPage: 1,
        orderBy: "createdAt",
        direction: "ASC",
        limit: 40,
        selectedCenter: "ALL",
        totalResult: 0,
        columns: [
            {
                label: this.props.t('service_all_table_column_category'),
                prop: "category",
                sortable: "custom",
            },
            {
                label: this.props.t('service_all_table_column_service_name'),
                prop: "service",
                sortable: "custom",
            },
            {
                label: this.props.t('service_all_table_column_status'),
                prop: "status",
                sortable: "custom",
            },
            {
                label: this.props.t('service_all_table_column_session_price'),
                prop: "price",
            },
            {
                label: this.props.t('service_all_table_column_session_interval'),
                prop: "sessionInterval",
            },
            {
                label: this.props.t('service_all_table_column_center'),
                prop: "center",
            },
            {
                label: this.props.t('service_all_table_column_view_detail'),
                prop: "detail",
                // 等效插槽
                render: (row) => {
                    return <span>
                        <Button onClick={() => this.switchTab("/main/service-detail/" + row.id)}>{this.props.t('button_view')}</Button>
                    </span>
                }
            },
            {
                label: this.props.t('operate'),
                prop: "detail",
                render: (row) => {
                    return <span>
                        <Button onClick={() => { this.deleteService(row) }}>{this.props.t('button_delete')}</Button>
                    </span>
                }
            },
        ],
        loading: true,
        serviceForm : {
            categoryId:null,
            actives:null,
            serviceName:null,
        },
        catetories:[]
    };

    switchTab = (url) => {
        history.push(url);
    }

    async deleteService(row) {
        const confirmed = window.confirm(this.props.t('you_sure_delete'));
        if (confirmed) {
            serviceApi.deleteService(row.id).then(res => {
                this.updateServiceRecords()
            })
        }
    }

    setCenter(center) {
        this.setState({
            selectedCenter: center,
        }, () => this.updateServiceRecords());
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.updateServiceRecords());
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.updateServiceRecords())
    }

    async updateServiceRecords() {
        const { currentPage, orderBy, direction, limit, selectedCenter,serviceForm } = this.state;
        const { t } = this.props;
        const categoryRes = (await categoryApi.getCategories()).rows;//查询分类
        const response = await serviceApi.getServices(currentPage, orderBy, direction, limit, selectedCenter === "ALL" ? "" : selectedCenter, serviceForm);
        let arr = response.rows.filter((e) => Object.keys(e).length > 0)
        let serviceMap = {}

        arr.forEach((service,index) => {
            if(service && service.category) {
                serviceMap[service.categoryId] = serviceMap[service.categoryId] || {
                    category:service.category.title,
                    categoryChildren:[],
                    sort:index
                }
            }
        })

        for ( var i in serviceMap) {
            arr.forEach(service => {
                if(service && service.category) {
                    if(i === service.categoryId) {
                        serviceMap[i].categoryChildren.push(service)
                    }
                }
            })
        }

        let sortService = []

        Object.keys(serviceMap).sort((a,b) => a-b).forEach(service => {
            serviceMap[service].categoryChildren.forEach(item => {
                sortService.push(item) 
            })
        })
        

        this.setState({
            catetories: categoryRes.filter(item=>item.stratum > 1).map(category => {
                return { id :category.id ,label:category.category_details[0].title}
            }),
            centers: (await centerApi.getCenters()).map((center) => {
                const newData = {
                    id: center.id,
                    name: center.center_details[0].name,
                }
                return newData;
            }),
            results: sortService.map((item) => {
                let categoryTitle = item.category.title;
                if (item.category.stratum === 2 && item.category.stratumId) {
                    categoryRes.forEach(e => {
                        if (e.stratum === 1 && e.category_details[0].categoryId === item.category.stratumId) {
                            categoryTitle = `${e.category_details[0].title} / ${item.category.title}`
                        }
                    });
                }
                const data = {
                    id: item.id,
                    price: item.price.toFixed(2),
                    category: categoryTitle,//分类名称
                    service: item.name,//服务名称
                    status: item.active ? t('active') : t('inactive'),//状态
                    sessionInterval: `${item.sessionInterval} mins`,//服务时间
                    center: item.centers.map((center) => { return center.name })[0],//分店
                }
                return data;
            }),
            totalResult: response.count,//列表条数
            loading: false,
        });
    }

    searchService(type,value) {
        this.setState({
            serviceForm: Object.assign({}, this.state.serviceForm, { [type]: value })
        },()=>{
            this.updateServiceRecords()
        });
    }

    async componentDidMount() {
        await this.updateServiceRecords();
    }

    setSort(e) {
        if (e.order) {
            this.setState({
                orderBy: e.prop,
                direction: e.order === "ascending" ? "ASC" : "DESC",
            });
            this.updateServiceRecords();
        }
    }

    render() {
        const { t } = this.props;
        const { limit, columns, currentPage, totalResult, results, centers, selectedCenter, sort, loading,serviceForm,catetories } = this.state;
        return (
            <div className="service-all-page">
                <div className="main-title">
                    {t('service_all_main_title')}
                </div>
                <div className="page-header">
                    {t('service_all_center_filter_title')}
                    <div className="center-filter">
                        <Select className="selector result-limit-select" onChange={(key) => this.setCenter(key)} value={selectedCenter}>
                            <Select.Option key={0} label={t('service_all_center_filter_all')} value={"ALL"} />
                            {centers.length > 0
                                ?
                                centers.map((center, i) => {
                                    return <Select.Option key={i + 1} label={center.name} value={center.id} />
                                })
                                : null
                            }
                        </Select>
                    </div>
                    <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} />
                    <div className="space"></div>
                    <div className="add-service-button">
                        {/* 新增服务 */}
                        <Button onClick={() => this.switchTab("/main/service-detail/create")}>{t('service_all_button_add_new_service')}</Button>
                    </div>
                </div>

                {/* 查詢 */}
                <div className='service-form'>
                    <Form ref="select" model={serviceForm} labelWidth="75" labelPosition='left'>
                        <Layout.Row gutter="45">
                            <Layout.Col xs="24" sm="8" md="8">
                                <Form.Item label={t('分類')}>
                                    <Select value={serviceForm.categoryId} onChange={this.searchService.bind(this,'categoryId')} style={{width:'100%'}} clearable >
                                    { catetories.length > 0 ? catetories.map((category) => {
                                        return (
                                            <Select.Option key={category.id} label={category.label} value={category.id} />
                                        )
                                    }) : null }
                                    </Select>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="8" md="8">
                                <Form.Item label={t('狀態')}>
                                    <Select value={serviceForm.active} onChange={this.searchService.bind(this,'active')} style={{width:'100%'}} clearable>
                                        <Select.Option key={true} label={t('service_detail_content_value_active')} value={1} />
                                        <Select.Option key={false} label={t('service_detail_content_value_inactive')} value={0} />
                                    </Select>
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col xs="24" sm="8" md="8">
                                <Form.Item label={t('服務名稱')}>
                                    <Input style={{height:'40px'}} value={serviceForm.serviceName} onChange={this.searchService.bind(this,'serviceName')} />
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </div>
                
                {/* 分页列表 */}
                <PageTable
                    limit={limit}
                    currentPage={currentPage}
                    totalResult={totalResult}
                    results={results}
                    sort={sort}
                    columns={columns}
                    setSort={(e) => this.setSort(e)}
                    setCurrentPage={(page) => this.setCurrentPage(page)}
                    loading={loading}
                />
            </div>
        );
    }
}