import i18n from 'i18next';
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import translations from '../i18n/index';

i18n
//   .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'cht',
    lng: 'cht',
    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',
    resources: translations,

    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true
    }
  });


export default i18n;