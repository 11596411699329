import React, { Component } from 'react';
import { Loading, TimeSelect } from 'element-react';
import './booking-inform.scss';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Dialog, Button, Select, Input, Message, Radio, MessageBox } from 'element-react';
import * as reservationApi from "../../../api/reservation.api";
import { setError } from "../../../redux/staff/error.action";
import history from "../../../history";
import moment from "moment";
import * as scheduleApi from "../../../api/schedule.api";
import * as serviceApi from "../../../api/service.api";
import * as staffApi from "../../../api/staff.api";
import store from '../../../redux/store';


const mapDispatchToProps = (dispatch) => {
    return {
        setError: (message) => {
            dispatch(setError(message))
        }
    }
}


@translate()
@connect((state) => ({}), mapDispatchToProps)
export default class BookingInform extends Component {
    state = {
        selectedRoom: null,
        selectedBooking: null,
        services: [],
        staffs: [],
        form: {
            from: undefined,
            to: undefined,
            service: undefined,
            staff: undefined,
            date: undefined,
            remark: undefined
        },
        staffOptions: [],
        Optional: [],
        input_val: 0,
        discountType: '',
        discount: 0,
        staffInfo: {},
        loading: false
    };

    async componentDidMount() {
        await this.setState({ loading : true })
        const { booking } = this.props;
        if (booking) {
            // this.updateRoomInform(booking);
            // this.updateStaffSchedule(booking)
            await this.updateRoomInform(booking);
            this.setState({
                form: {
                    date: moment(booking.startTime).startOf('day').toDate(),
                    from: moment(booking.startTime).toDate(),
                    to: moment(booking.endTime).toDate(),
                    service: booking.service.id,
                    staff: booking.staff_schedule.staff.staff_details[0].staffId,
                    remark: booking.remark,
                    staffScheduleId: booking.staffScheduleId
                },
                discountType: booking.type,
                discount: Number(booking.discount),
                input_val: booking.price
            })
            const data = store.getState()
            const { staff } = data.staff
            this.setState({
                staffInfo: staff
            })
            let date = {
                date: moment(booking.startTime).startOf('day').toDate(),
                from: moment(booking.startTime).toDate(),
                to: moment(booking.endTime).toDate(),
                service: booking.service.id,
            }
            this.updateStaffSchedule(date)
        }

        const [
            services,
            staffs
        ] = await Promise.all([
            serviceApi.getServices(1, 'id', 'ASC', 1000, ''),
            staffApi.getStaffs(1, null, null, 1000, ''),
        ]);

        const servicesFilter = services.rows.filter(async service => service.id)
        const newServices = servicesFilter.filter((item) => Object.keys(item).length > 0)
        this.setState({
            services: newServices.sort((a, b) => ('' + a.name).localeCompare(b.name)),
            staffs: staffs.rows,
            loading: false
        })
    }

    // async componentWillReceiveProps(nextProps) {
    //     if (nextProps.booking !== this.props.booking) {
    //         this.setState({
    //             form: {
    //                 date: moment(nextProps.booking.startTime).startOf('day').toDate(),
    //                 from: moment(nextProps.booking.startTime).toDate(),
    //                 to: moment(nextProps.booking.endTime).toDate(),
    //                 // service: nextProps.booking.service.service_details[0].id,
    //                 service: nextProps.booking.service.id,
    //                 staff: nextProps.booking.staff_schedule.staff.staff_details[0].staffId,
    //                 remark: nextProps.booking.remark,
    //                 staffScheduleId: nextProps.booking.staffScheduleId
    //             },
    //             discountType: nextProps.booking.type,
    //             discount: Number(nextProps.booking.discount),
    //             input_val: nextProps.booking.price
    //         });
    //         let data = {
    //             date: moment(nextProps.booking.startTime).startOf('day').toDate(),
    //             from: moment(nextProps.booking.startTime).toDate(),
    //             to: moment(nextProps.booking.endTime).toDate(),
    //             service: nextProps.booking.service.id,
    //         }
    //         this.updateStaffSchedule(data)
    //         this.updateRoomInform(nextProps.booking);
    //     }
    // }

    MessageUtice(type, message, showClose = true,num = 5000){
        return Message({
            type,
            message,
            customClass: 'zZindex',
            showClose,
            duretion: num
        })
    }

    async updateStaffSchedule(form) {
        let obj = Object.assign({}, form)
        if (obj.service === "") {
            obj.service = undefined
        }
        this.handleTime(form.date, form.from)
        const schedules = await scheduleApi.getBookableSchedules(obj.service, this.handleTime(obj.date, obj.from), this.handleTime(obj.date, obj.to), {
            centerId: undefined,
            staffId: undefined,
        })

        let Optional = schedules.map(item => {
            return item.staffId
        })
        // let result = Object.assign({}, this.state.form, { staff: '' })
        let index = Optional.indexOf(this.state.form.staff)
        console.log(index)
        let result = {}
        if(index < 0) {
            result = {
                staff: null,
                date: this.state.form.date,
                from: this.state.form.from,
                remark: this.state.form.remark,
                service: this.state.form.service,
                staffScheduleId: this.state.form.staffScheduleId,
                to : this.state.form.to
            }
        } else {
            result = { ...this.state.form }
        }
        this.setState({
            form : result,
            staffOptions: schedules,
            Optional: Optional
        })
    }

    async updateRoomInform(booking) {
        const { t } = this.props;
        const freeRooms = await reservationApi.getFreeRooms(booking.room.centerId, booking.serviceId, booking.staff_schedule.startTime, booking.staff_schedule.endTime);
        const exchangeBooking = await reservationApi.getExchangeBooking(booking.id);
        const roomOptions = [
            {
                label: t('original_room'),
                options: [{
                    value: booking.roomId,
                    label: booking.room.title,
                }],
            },
            {
                label: t('available_room'),
                options: freeRooms.map((room) => {
                    return {
                        value: room.id,
                        label: room.title,
                    };
                }),
            },
        ];
        if (exchangeBooking.length) {
            roomOptions.push({
                label: t('exchange_room'),
                options: [
                    {
                        value: 'exchange',
                        label: t('exchange_room'),
                    },
                ]
            });
        }
        const exchangeOptions = exchangeBooking.map((reservation) => {
            let label = ""
            if (reservation.user) {
                label = reservation.userId + "-" + reservation.user.surname + " " + reservation.user.name
            } else {
                label = reservation.userInfo.name
            }
            return {
                value: reservation.id,
                // label: `${reservation.userId}-${reservation.user.surname} ${reservation.user.name}`,
                label: label
            };
        });
        this.setState({
            roomOptions,
            exchangeOptions,
            selectedRoom: booking.roomId,
        });
    }

    async delete(reservationId) {
        await reservationApi.cancelReservation(reservationId).then(() => {
            const { rebook } = this.state;
            if (rebook) {
                const { booking } = this.props;
                const { staff_schedule } = booking;
                // history.push(`/main/booking-overview/add/${user.id}/${booking.serviceId}/${moment(staff_schedule.startTime).format('YYYY-MM-DD')}/${staff_schedule.centerId}/${staff_schedule.staffId}`);
                history.push(`/main/booking-overview/add/${booking.serviceId}/${moment(staff_schedule.startTime).format('YYYY-MM-DD')}/${staff_schedule.centerId}/${staff_schedule.staffId}`);
            }
            this.onClose();
        }).catch(error => {
            Message.error(error.userMessage)
        });
    }

    async update() {
        const { selectedRoom, selectedBooking, form, staffs, staffOptions,Optional } = this.state;
        const { t, setError, booking } = this.props;

        if( !form.staff ||  !form.staffScheduleId) {
            this.MessageUtice('error','服務或者美容師必填')
            return
        }
        let staffScheduleId = form.staffScheduleId
        let staffId = form.staffId
        staffOptions.forEach(item => {
            console.log(item.staff.staff_services[0].staffId)
            console.log(form.staff)
            if (item.staff.staff_services[0].staffId === form.staff) {
                staffScheduleId = item.ids[0]
                staffId = item.staff.staffId
            }
        })

        var data = {};
        data.serviceId = form.service;
        data.staffId = staffId
        data.startTime = this.handleTime(form.date, form.from);
        data.endTime = this.handleTime(form.date, form.to);
        data.staffScheduleId = staffScheduleId;
        data.remark = form.remark
        data.roomId = selectedRoom
        data.price = Number(this.state.input_val)
        data.type = this.state.discountType
        data.discount = this.state.discount

        if (selectedRoom === 'exchange' && selectedBooking === null) {
            return setError(t('error:please_input_field', { field: t('exchange_target') }));
        }
        if (selectedRoom === 'exchange') {
            await reservationApi.exchangeBookingRoom(booking.id, selectedBooking);
        }
        // else if (selectedRoom !== booking.roomId) {
        //     await reservationApi.changeBookingRoom(booking.id, selectedRoom);
        // }

        await reservationApi.updateReservation(booking.id, data).then(res => {
            this.MessageUtice('success','更新成功')
            this.onClose();  
        }).catch(error => {
            this.MessageUtice('error',error.userMessage)
        });
    }

    handleTime(date, time) {
        const year = moment(date).year()
        const month = moment(date).month()
        const day = moment(date).date()
        var fromTime = moment(time)
        fromTime.year(year)
        fromTime.month(month)
        fromTime.date(day)
        return (fromTime.toDate()).toISOString()
    }

    async inputOnBlur(value, key) {
        const { booking } = this.props
        if (Number(booking.price) !== Number(value)) {
            MessageBox.confirm('確認修改價格嗎, 是否繼續?', '提示', {
                type: 'warning'
            }).then(() => {
                this.setState({
                    input_val: value
                })
                Message({
                    type: 'success',
                    message: '修改成功!'
                });
            }).catch(() => {
                this.setState({
                    input_val: booking.price
                });
                Message({
                    type: 'info',
                    message: '已取消修改'
                });
            });
        }
    }

    async onChange(key, value) {
        if (key === "service" || key === "from" || key === "to") {
            await new Promise(res => this.setState({
                form: {
                    ...this.state.form,
                    [key]: value,
                },
            }, res));
            await this.updateStaffSchedule(this.state.form)
        }
        await new Promise(res => this.setState({
            form: {
                ...this.state.form,
                [key]: value,
            },
        }, res));
    }

    onClose() {
        this.setState({
            selectedRoom: null,
            selectedBooking: null,
            showConfirm: false
        });
        this.props.onCancel();
    }

    Input(e) {
        let data = Object.assign(this.state.form, { remark: e })
        this.setState({
            form: data
        })
    }

    render() {
        const { t, booking, visible, onCancel } = this.props;
        const { form, staffs, services, selectedRoom, roomOptions, exchangeOptions, selectedBooking, showConfirm, Optional, input_val, discount, discountType, staffInfo, loading } = this.state
        return (
            booking ? <Dialog
                size={'small'}
                title={t('booking_inform')}
                visible={visible}
                onCancel={() => onCancel()}
            >
                <div className="dialog">
                    <Loading text="拼命加載中!" loading={loading}>
                        <div className="row">
                            <div className="title">
                                {t('staff_schedule_dialog_service') + '：'}
                            </div>
                            <div className="value">
                                <Select
                                    clearable
                                    value={form.service}
                                    onChange={(e) => this.onChange('service', e)}
                                    disabled={services.length <= 0}
                                    placeholder="Select Service">
                                    {services.map(service => (
                                        <Select.Option key={service.id} label={service.name} value={service.id} disabled={form.service === service.id ? true : false} />
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('staff_schedule_dialog_time') + '：'}
                            </div>
                            <div className="value">
                                {/* {moment(booking.staff_schedule.startTime).format('HH:mm')} - {moment(booking.staff_schedule.endTime).format('HH:mm')} */}
                                <TimeSelect start="08:00" step="00:15" end="22:00" onChange={(time) => this.onChange('from', time)} value={form.from} placeholder="From" />
                                {' - '}
                                <TimeSelect start="08:00" step="00:15" end="22:00" minTime={form.from} onChange={(time) => this.onChange('to', time)} value={form.to} placeholder="To" />
                            </div>
                        </div>
                        {/*<div className="row">*/}
                        {/*<div className="title">*/}
                        {/*{t('user_board_view_surname')}*/}
                        {/*</div>*/}
                        {/*<div className="value">*/}
                        {/*{booking.user.surname}*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        <div className="row">
                            <div className="title">
                                {t('user_board_view_name')}
                            </div>
                            <div onClick={booking.user ? () => history.push(`/main/user-detail/${booking.user.id}`) : undefined} className="value"
                                style={{ color: '#1AA2E2', cursor: 'pointer', }}>
                                {
                                    booking.user
                                        ? booking.user.surname ? `${booking.user.surname} ${booking.user.name}` : `${booking.user.name}`
                                        : `${booking.userInfo.name}`
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('user_board_view_gender')}
                            </div>
                            <div className="value">
                                {booking.user ? booking.user.gender : ""}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('user_board_view_tel')}
                            </div>
                            <div className="value">
                                {JSON.parse(sessionStorage.getItem("permissions")).reservation == 'read' ? null : booking.user ? booking.user.phone : booking.userInfo.phone}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">{'美容師：'}</div>
                            <div className="value">
                                <Select
                                    value={form.staff}
                                    disabled={staffs.length <= 0}
                                    onChange={(e) => this.onChange('staff', e)}
                                    placeholder="Select">
                                    <Select.Option label={t("add_booking_option_any")} value={''} />
                                    {staffs.map(staff => (
                                        <Select.Option key={staff.id} label={staff.staff_details[0].name} value={staff.id} disabled={Optional.indexOf(staff.id) >= 0 ? false : true} />
                                    ))}
                                </Select>
                                {/* { */}
                                {/*     booking.staff_schedule.staff.staff_details[0].surname */}
                                {/* }{ */}
                                {/*     booking.staff_schedule.staff.staff_details[0].name */}
                                {/* } */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">
                                {t('room') + '：'}
                            </div>
                            <div className="value">
                                <Select value={selectedRoom} onChange={(value) => this.setState({ selectedRoom: value })}>
                                    {roomOptions ?
                                        roomOptions.map((group, i) => {
                                            return (
                                                <Select.OptionGroup key={i} label={group.label}>
                                                    {
                                                        group.options.map(el => {
                                                            return (
                                                                <Select.Option key={el.value} label={el.label} value={el.value}>
                                                                    <span style={{ float: 'left' }}>{el.label}</span>
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select.OptionGroup>
                                            )
                                        }) : null
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="title">{'原價格：'}</div>
                            <div className="value">
                                <Input value={input_val} disabled={staffInfo.type !== "admin" && staffInfo.type !== "super admin"} onBlur={this.inputOnBlur.bind(this, input_val, 'input_val')} onChange={(value) => { this.setState({ input_val: value }) }} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="title">{'折扣類型：'}</div>
                            <div className="value">
                                <Radio.Group value={discountType} onChange={(value) => { this.setState({ discountType: value }) }}>
                                    <Radio value="normal">照常</Radio>
                                    <Radio value="relief">减免</Radio>
                                    <Radio value="discount">打折</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        {discountType !== "normal"
                            ? <div className="row">
                                <div className="title">{discountType === 'relief' ? '減免金額：' : '折扣比：'}</div>
                                <div className="value">
                                    <Input value={discount} onChange={value => this.setState({ discount: Number(value) })}></Input>
                                </div>
                            </div>
                            : null
                        }
                        <div className="row">
                            <div className="title">
                                {t('user_board_view_remark')}
                            </div>
                            <div className="value">
                                <Input value={form.remark} 
                                    autosize={true} 
                                    type="textarea" 
                                    placeholder="請輸入內容" 
                                    onChange={(event) => this.Input(event)} 
                                    />
                            </div>
                        </div>
                        {
                            selectedRoom === 'exchange' ?
                                <div className="row">
                                    <div className="title">
                                        {t('exchange_booking_room')}
                                    </div>
                                    <div className="value">
                                        <Select value={selectedBooking} onChange={(value) => this.setState({ selectedBooking: value })}>
                                            {exchangeOptions ?
                                                exchangeOptions.map((option) => {
                                                    return (
                                                        <Select.Option key={option.value} label={option.label} value={option.value}>
                                                            <span style={{ float: 'left' }}>{option.label}</span>
                                                        </Select.Option>
                                                    )
                                                }) : null
                                            }
                                        </Select>
                                    </div>
                                </div> : null
                        }
                        <Dialog.Footer className="dialog-footer">
                            <Button onClick={() => this.setState({ showConfirm: true })}>{t('delete')}</Button>
                            {/* <Button onClick={() => this.setState({ showConfirm: true, rebook: true })}>{t('delete_and_rebook')}</Button> */}
                            <div className="space" />
                            <Button onClick={() => onCancel()}>{t('cancel')}</Button>
                            <Button type="primary" onClick={() => this.update()}>{t('update')}</Button>
                        </Dialog.Footer>
                        <Dialog
                            size={'tiny'}
                            title={t('confirm_dialog_title')}
                            visible={showConfirm}
                            onCancel={() => this.setState({ showConfirm: false })}
                        >
                            <div className="dialog">
                                <div className="dialog-body">
                                    <div className="row">
                                        <Button type="primary" onClick={() => this.delete(booking.id)}>{t('confirm_dialog_button_yes')}</Button>
                                        <Button onClick={() => this.setState({ showConfirm: false, rebook: false })}>{t('confirm_dialog_button_no')}</Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </Loading>
                </div>
            </Dialog> : null
        );
    }
}
