
export default {
    translation: {
        main_tab_report_management_balance:"點數支付",
        main_tab_report_management_integral:"積分支付",
        main_tab_report_management_boss:"老闆代付",
        main_tab_report_management_normal:"普通支付",
        main_tab_report_management_times:"次卡支付",
        user_board_edit_classification: '消費分類：',
        staff_tab_consumption_time: '消費時間',
        commission_record: '佣金',
        pos_Trading_price: '交易價格',
        pos_Amount_of_relief: '減免金額',
        pos_Project_original_price: '原價',
        pos_number: '數量',
        pos_project_name: '項目名稱',
        Point_Expenses_record: '消費記錄',
        Point_consumption_record: "積分消費記錄",
        booking_table_deduct_points: '扣除積分',
        booking_table_Customer_Number: '客戶編號',
        checked: '成功',
        waiting: '等待',
        unchecked: '失败',
        male: '男',
        female: '女',
        service_detail_content_integral: '服務積分：',
        Category_Name: '分類名稱：',
        English_version: '英文版：',
        Chinese_Version: '中文版：',
        secondary_classification_details: '二級分類詳情',
        Owning_parent: '所屬父級：',
        please_choose: '請選擇',
        please_select_a_language: '請選擇填選語言',
        first_class_classification_name: '一級分類名稱：',
        borad_category_description: '分類描述：',
        service_secondary_classification_table: '二級分類表：',
        oneClass: "一級分類",
        towClass: "二級分類",
        save: '保存',
        cht: '繁體',
        eng: '英語',
        base_service_color: '相關顔色*:',
        board_service_color: '相關顔色:',
        board_service_description: '服務描述：',
        board_precautions: '注意事項：',
        you_sure_delete: '確定要刪除嗎？',
        image_board_title: '相關圖片：',
        image_base_title: '相關圖片*:',
        operate: "操作",
        button_edit: "編輯",
        button_save: "儲存",
        button_create: "新增",
        button_view: "檢視",
        button_delete: "刪除",
        login_title: 'Miusee - 後台管理系統',
        login_staff_id: '職員編號',
        login_password: '密碼',
        login_button: '登入',
        main_tab_book: '預約概覽',
        main_tab_booking_timetable: '預約時間表',
        main_tab_booking_list: '預約列表',
        main_tab_booking_revise_record: "預約列表修改記錄",
        main_tab_booking: '預約',
        main_tab_customer_management: '客戶管理',
        main_tab_customer_summary: '客戶概覽',
        main_tab_customer_all: '所有登記用戶',
        main_tab_service_feedback: '服務評價',
        main_tab_staff_management: '職員管理',
        main_tab_staff_all: '所有職員',
        main_tab_staff_holiday: '職員假期',
        main_tab_staff_schedule: '職員工作行程設定',
        main_tab_service_management: '服務管理',
        main_tab_center_management: '中心管理',
        main_tab_room_management: '房間管理',
        main_tab_about_hwl: '關於康和樂',
        main_tab_terms_conditions: '使用條款及細則',
        main_tab_health_tips: '健康貼士',
        main_tab_press: '媒體報導',
        main_tab_push_notification: '推送通知',
        main_tab_web_management: '網站管理',
        main_tab_web_management_homepage: '主頁',
        main_tab_web_management_mobile: '手機應用程式描述',
        main_tab_category_management: '服務分類管理',
        main_tab_report_management: '報表管理',
        main_pop_button_change_password: '更改密碼',
        main_pop_button_log_out: '登出',
        change_password_panel_title: '更改密碼',
        change_password_original_password: '原本密碼',
        change_password_new_password: '新密碼',
        change_password_confirm_new_password: '確認密碼',
        change_password_submit: '提交',
        user_summary_title: '搜尋客戶概覽 (客戶編號/身份證/電話號碼)',
        user_summary_search_bar_placeholder: '輸入客戶編號、姓名、電話號碼或身份證進行搜尋',
        user_search_item_member_id: '客戶編號:',
        user_search_item_name: '姓名：',
        user_search_item_gender: '性別：',
        user_search_item_hkid: '身份證：',
        user_search_item_tel: '電話：',
        user_board_view_member_id: '客戶編號：',
        user_board_view_name: '姓名：',
        user_board_view_gender: '性別：',
        user_board_view_hkid: '身份證：',
        user_board_view_tel: '電話號碼：',
        user_board_view_remark: '備注：',
        user_board_view_surname: '姓氏：',
        user_board_view_birthday: '出生日期：',
        user_board_view_family_id: '家庭戶口編號：',
        user_board_view_email: '電郵：',
        user_board_view_address: '地址：',
        user_board_view_family_balance: '現有家庭戶口結餘：',
        user_board_view_photo: '相片：',
        user_board_view_body_condition: '體質：',
        user_board_edit_member_id: '客戶編號：',
        user_board_edit_name: '名*：',
        user_board_edit_gender: '性別：',
        user_board_edit_hkid: '身份證：',
        user_board_edit_tel: '電話*：',
        user_board_edit_surname: '姓*:',
        user_board_edit_birthday: '出生日期：',
        user_board_edit_family_id: '家庭戶口編號：',
        user_board_edit_email: '電郵：',
        user_board_edit_address: '地址：',
        user_board_edit_remark: '備註：',
        user_board_edit_photo: '相片：',
        user_board_edit_body_condition: '體質：',
        user_board_edit_intentional_service: '意向服務：',
        user_board_edit_consume_class: '消費分類：',
        user_board_edit_customer_class: '客戶分類：',
        user_board_edit_introducer: '介紹人選擇：',
        user_summary_customer_summary: '客戶摘要',
        user_summary_tab_booking: '預覽預約記錄',
        user_summary_tab_body_condition: '用戶體質',
        user_summary_tab_family: '相關家庭成員戶口',
        user_summary_tab_transaction: '賬戶交易記錄',
        user_summary_tab_top_up: '增值',
        user_summary_tab_transfer: '轉移戶口結餘',
        tab_booking_result_limit_show: '每頁顯示',
        tab_booking_result_limit_per: '項結果',
        tab_booking_total_page: "顯示 {{head}} 至 {{end}} ，共 {{total}} 項結果",
        tab_booking_table_column_ymd: "日期 (年月日)",
        tab_booking_table_column_time: "時間",
        tab_booking_table_column_center: "分店",
        tab_booking_table_column_category: "分類",
        tab_booking_table_column_service: "服務名稱",
        tab_booking_table_column_doctor: "治療師／塑眉師",
        tab_booking_table_button_upload: "上載",
        tab_booking_table_button_share: "分享",
        tab_booking_table_button_not_share: "不分享",
        tab_booking_table_column_prescription: "藥單",
        tab_booking_table_column_report: "報告",
        tab_booking_table_column_action: "動作",
        tab_booking_table_column_pay: "付款",
        tab_booking_check_in_title: "確認客人報道／沒有出現？",
        tab_booking_check_in_date: "日期／時間",
        tab_booking_check_in_name: "名字",
        tab_booking_check_in_hkid: "身份證",
        tab_booking_check_in_appointment: "預約",
        tab_booking_check_in_therapist: "治療師／塑眉師",
        tab_booking_check_in_balance: "從家庭賬戶扣除",
        tab_booking_check_in_note: "備註",
        tab_booking_check_in_confirm: "確認",
        tab_booking_check_in_cancel: "取消",
        tab_booking_check_in_update: "修改",
        tab_booking_attended_title: "檢閱預約",
        tab_booking_confirm_title_check_out: "結帳？",
        tab_booking_share_status: "更改報告分享狀態？",
        tab_booking_delete_report: "刪除報告?",
        tab_booking_paid_title: "預先繳費？",
        tab_booking_pay_dialog_content: "收費總額 = 港幣$ {{amount}} 從戶口餘額扣除",
        tab_booking_pay_dialog_yes: "是",
        tab_booking_pay_dialog_no: "否",
        tab_booking_check_in: "掛號",
        tab_booking_check_out: "結帳",
        tab_booking_attended: "已出席",
        tab_booking_pay: "付款",
        tab_booking_paid: "已付款",
        tab_body_condition_table_column_ymd: "日期 (年月日)",
        tab_body_condition_table_column_bd: "體質",
        tab_family_table_column_id: "客戶編號",
        tab_family_table_column_surname: "姓",
        tab_family_table_column_name: "名",
        tab_family_table_column_tel: "電話",
        tab_family_table_column_gender: "性別",
        tab_family_table_column_hkid: "身份證",
        tab_family_title: "相關家庭成員戶口",
        tab_transaction_title: "交易記錄",
        tab_transaction_table_column_date: "日期",
        tab_transaction_table_column_time: "時間",
        tab_transaction_table_column_staff: "由(員工編號)處理",
        tab_transaction_table_column_user: "職員",
        tab_transaction_table_column_action: "增值/扣除",
        tab_transaction_action_top_up: "增值",
        tab_transaction_action_deduct: "點數扣除",
        tab_transaction_action_normal:"普通支付",
        tab_transaction_action_times_deduct: "次卡扣除",
        tab_transaction_action_boss_deduct: "boss支付",
        tab_transaction_action_integral_deduct: "積分扣除",
        tab_transaction_action_refund: "套餐餘額回退",
        tab_transaction_table_column_service: "服務",
        tab_transaction_table_column_amount: "價格",
        tab_top_up_title: "戶口結餘",
        tab_top_up_content_name: "姓名",
        tab_top_up_content_hkid: "身份證",
        tab_top_up_content_balance: "增值",
        tab_top_up_button_confirm: "確認",
        tab_transfer_title: "由個人／家庭戶口轉賬至另一戶口",
        tab_transfer_content_name: "姓名",
        tab_transfer_content_hkid: "身份證",
        tab_transfer_content_member: "客戶編號",
        tab_transfer_content_current_family: "現時家庭編號",
        tab_transfer_content_transfer_family: "轉賬至",
        tab_transfer_content_transfer_placeholder: "輸入家庭編號",
        tab_transfer_content_balance: "戶口結餘轉賬",
        tab_transfer_button_confirm: "確認",
        error_board_title: "錯誤",
        error_board_button_ok: "確認",
        user_name: "{{name}} {{surname}}",
        user_all_title: "客戶管理",
        user_all_table_column_id: "客戶編號",
        user_all_table_column_surname: "姓氏",
        user_all_table_column_name: "名",
        user_all_table_column_tel: "電話號碼",
        user_all_table_column_gender: "性別",
        user_all_table_column_hkid: "身份證",
        user_all_table_column_family_code: "家庭編號",
        user_all_table_column_family_balance: "家庭戶口結餘",
        user_all_table_column_detail: "檢視內容",
        user_all_button_add_user: "新增客戶",
        user_all_search_bar_placeholder: "搜尋",
        user_view_title: "客戶管理 > 檢視客戶資料",
        user_edit_item_id: "自動建立編號",
        user_feedback_title: "服務反饋",
        user_feedback_table_column_date: "日期",
        user_feedback_table_column_surname: "姓氏",
        user_feedback_table_column_name: "名",
        user_feedback_table_column_tel: "電話",
        user_feedback_table_column_center: "分店",
        user_feedback_table_column_appointment: "預約",
        user_feedback_table_column_staff_name: "職員姓名",
        user_feedback_table_column_general_rating: "整體評分",
        user_feedback_table_column_staff_rating: "職員評分",
        user_feedback_table_column_view_feedback: "檢視顧客反饋",
        user_feedback_dialog_title: "服務反饋",
        user_feedback_dialog_title_date_time: "服務日子/時間",
        user_feedback_dialog_title_user_name: "Name",
        user_feedback_dialog_title_contact: "聯絡資料",
        user_feedback_dialog_title_center: "分店",
        user_feedback_dialog_title_appointment: " 預約",
        user_feedback_dialog_title_staff_name: "員工",
        user_feedback_dialog_title_overall: "整體",
        user_feedback_dialog_title_therapy: "治療",
        user_feedback_dialog_title_note: "備註",
        booking_appointment_list: "預約列表",
        booking_table_column_date: "日期",
        booking_table_column_time: "時間",
        booking_table_column_user: "客戶",
        booking_table_column_phone: "客戶號碼",
        booking_table_column_staff: "美容師",
        booking_table_column_server: "項目",
        booking_table_column_status: "狀態",
        booking_table_column_action: "動作",
        booking_table_column_Issue_an_order: "出單",
        booking_table_download: "下載",
        booking_table_pay_boss: "老闆結算",
        booking_table_pay_normal: "普通結算",
        booking_table_pay_redeem: "積分兌換",
        booking_table_settlement_method: "結算方式",
        booking_table_commission_ratio_selection: "佣金比選擇",
        booking_table_full_commission: "全佣",
        booking_table_full_ninety_percentage_commission: "90%佣金",
        booking_revise_record: "預約列表修改記錄",
        booking_revise_record_room_update: "房間修改",
        booking_revise_record_price_update: "價格修改",
        booking_revise_record_discount_update: "優惠修改",
        booking_revise_record_content_update: "修改內容",
        booking_revise_record_price: "價格",
        booking_revise_record_discount_type: "折扣類型",
        booking_revise_record_discount_price: "扣除金額",
        booking_revise_record_real_deal_price: "真實交易金額",
        booking_revise_record_update_date: "修改時間",
        booking_discount_type_relief: "減免金額",
        booking_discount_type_discount: "優惠打折",
        booking_discount_type_normal: "照常收費",
        staff_all_title: "所有員工",
        staff_all_table_column_id: "員工編號",
        staff_all_table_column_type: "員工類別",
        staff_all_table_column_surname: "姓氏",
        staff_all_table_column_name: "名",
        staff_all_table_column_salary_commission: "工資提成",
        staff_all_table_column_expected_commission: "預計提成",
        staff_all_table_column_mobile: "電話號碼",
        staff_all_table_column_gender: "性別",
        staff_all_table_column_detail: "查閱內容",
        staff_all_table_column_schedule: "檢視員工服務時間表",
        staff_all_button_add_staff: "新增員工",
        staff_all_search_bar_placeholder: "用員工編號/姓名搜尋",
        staff_detail_title: "員工管理 > 檢視員工資料內容",
        staff_commission_salary_title: "員工管理 > 月初至今提成",
        staff_commission_expected_title: "員工管理 > 預計剩餘提成",
        staff_detail_tab_basic_info: "基本資料",
        staff_detail_tab_service_detail: "服務資料",
        staff_detail_cms_permission: "後台管理系統許可",
        staff_detail_tab_salary_preview: "工資預覽",
        staff_tab_basic_info_title_id: "員工*:",
        staff_tab_basic_info_title_surname: "姓*:",
        staff_tab_basic_info_title_name: "名*:",
        staff_tab_basic_info_title_gender: "性別*:",
        staff_tab_basic_info_title_hkid: "身份證*:",
        staff_tab_basic_info_title_tel: "電話*:",
        staff_tab_basic_info_title_email: "電郵*:",
        staff_tab_basic_info_title_address: "地址*:",
        staff_tab_basic_info_title_qualification: "資歷許可（只供治療師／塑眉師):",
        staff_tab_basic_info_title_status: "狀態*:",
        staff_tab_basic_info_title_basic_salary: "基本薪資*:",
        staff_tab_basic_info_title_divide_into: "提成*:",
        staff_tab_basic_info_status_working: "工作中",
        staff_tab_basic_info_status_resigned: "已辭職",
        staff_tab_basic_info_title_staff_type: "員工類別*:",
        staff_tab_basic_info_type_super_admin: "超級管理員",
        staff_tab_basic_info_type_admin: "管理員",
        staff_tab_basic_info_type_normal: "普通",
        staff_tab_basic_info_type_therapist: "塑眉師",
        staff_tab_basic_info_type_beautician: "美容師",
        staff_tab_service_detail_title_center: "分店*：",
        staff_tab_service_detail_title_service_type: "服務分類*：",
        staff_tab_service_detail_title_project: "项目",
        staff_tab_service_detail_title_price: "價格",
        staff_tab_service_detail_title_commission: "提成",
        staff_tab_cms_permission_title_center: "分店資料",
        staff_tab_cms_permission_title_category: "服務分類",
        staff_tab_cms_permission_title_service: "服務資料",
        staff_tab_cms_permission_title_company: "關於康和樂",
        staff_tab_cms_permission_title_document: "條款及細則",
        staff_tab_cms_permission_title_health_tips: "健康小貼士",
        staff_tab_cms_permission_title_body_condition: "體質",
        staff_tab_cms_permission_title_notification: "推送通知",
        staff_tab_cms_permission_title_web: "網站",
        staff_tab_cms_permission_title_user: "客戶資料",
        staff_tab_cms_permission_title_family: "相關家庭成員戶口",
        staff_tab_cms_permission_title_reservation: "預約 (包括管理報告)",
        staff_tab_cms_permission_title_staff: "員工管理",
        staff_tab_cms_permission_title_schedule: "員工服務時間表",
        staff_tab_cms_permission_title_self: "員工資料",
        staff_tab_cms_permission_title_permission: "後台系統管理許可",
        staff_tab_cms_permission_title_press: "媒體報導",
        staff_tab_cms_permission_sub_title_hwl_center: "康和樂分店管理許可",
        staff_tab_cms_permission_sub_title_customers: "客戶管理許可",
        staff_tab_cms_permission_sub_title_staff: "後台員工管理許可",
        staff_tab_cms_permission_no_permission: "沒有權限",
        staff_tab_salary_commission: "月初至今提成",
        staff_tab_salary_expected_commission: "預計剩餘提成",
        staff_tab_basic_info_edit_id: "自動建立編號",
        staff_tab_basic_info_edit_button_upload: "上載",
        staff_tab_basic_info_edit_button_reset: "重設密碼",
        staff_tab_cms_permission_edit: "編輯",
        staff_tab_cms_permission_view: "檢視",
        service_all_main_title: "服務",
        service_all_center_filter_title: "分店：",
        service_all_center_filter_all: "所有",
        service_all_table_column_category: "分類",
        service_all_table_column_service_name: "服務名稱：",
        service_all_table_column_status: "狀態",
        service_all_table_column_session_interval: "服務時間：",
        service_all_table_column_session_price: "服務價格",
        service_all_table_column_center: "分店：",
        service_all_table_column_view_detail: "檢視內容",
        service_all_button_add_new_service: "新增服務",
        service_detail_title_view: "服務管理 > 檢視服務內容",
        service_detail_title_edit: "服務管理 > 編輯服務內容",
        service_detail_title_create: "服務管理 > 新增服務",
        service_detail_content_title_center: "分店：",
        service_detail_content_title_category: "所屬分類：",
        service_detail_content_title_walk_in: "是否預約登記：",
        service_detail_content_value_reservation: "預約登記",
        service_detail_content_value_walk_in: "非預約登記 walk-in",
        service_detail_content_title_status: "狀態：",
        service_detail_content_value_inactive: "無效",
        service_detail_content_value_active: "有效",
        service_detail_content_title_images: "相關圖片：",
        service_detail_content_title_video: "相關影片：",
        service_detail_content_value_video: "上載（最多一條影片）",
        service_detail_content_title_session_interval: "服務時節：",
        service_detail_content_value_minutes: "分鐘",
        service_detail_content_title_price: "價錢：",
        center_all_main_title: "分店管理",
        center_all_button_add_new_center: "建立新分店",
        center_all_table_column_center: "分店",
        center_all_table_column_status: "狀態",
        center_all_table_column_address: "地址",
        center_all_table_column_phone: "電話號碼",
        center_all_table_column_opening: "營業時間",
        center_all_table_column_view: "檢視資料",
        center_detail_title_view: "分店管理 > 檢視分店資料",
        center_detail_title_edit: "分店管理 > 編輯分店",
        center_detail_title_create: "分店管理 > 建立新分店",
        center_detail_content_title_status: "狀態：",
        center_detail_content_value_inactive: "無效",
        center_detail_content_value_active: "有效",
        center_detail_content_title_tel: "電話：",
        center_detail_content_title_opening_hour: "營業時間：",
        center_detail_content_value_open_time: "開店時間",
        center_detail_content_value_close_time: "關店時間",
        center_detail_content_title_address_coordinates: "地址坐標：",
        enter_detail_content_title_latitude: "緯度：",
        enter_detail_content_title_longitude: "經度：",
        center_detail_content_title_images: "圖片：",
        staff_type_normal: "正常",
        staff_type_admin: "管理員",
        staff_type_super_admin: "超級管理員",
        staff_type_therapist: "治療師／塑眉師",
        company_detail_title_view: "關於康和樂 > 檢視",
        company_detail_title_edit: "關於康和樂 > 修改",
        company_detail_content_value_video: "上載（最多一條影片）",
        company_detail_content_title_images: "圖片：",
        company_detail_content_title_video: "影片：",
        document_detail_title_view: "條款及細則 > 檢視",
        document_detail_title_edit: "條款及細則 > 修改",
        health_tips_all_main_title: "健康小貼士",
        health_tips_all_button_add: "新增文章",
        health_tips_all_table_title_date: "日期",
        health_tips_all_table_title_body_condition: "體質",
        health_tips_all_table_title_title: "標題",
        health_tips_all_table_title_bookmark_count: "收藏次數",
        health_tips_all_table_title_action: "操作",
        health_tips_main_title_add: "健康小貼士 > 新增文章",
        health_tips_main_title_edit: "健康小貼士 > 編輯文章",
        health_tips_title_date: "日期：",
        health_tips_sub_title_condition: "客戶體質",
        health_tips_sub_title_slideshow: "標題圖片集",
        health_tips_detail_content_title_images: "圖片",
        health_tips_detail_content_title_video: "影片",
        health_tips_detail_content_value_video: "上載（最多一條影片）",
        health_tips_sub_title_article: "文章內容",
        press_all_main_title: "媒體報導",
        press_all_button_add: "新增報導",
        press_all_table_title_date: "日期",
        press_all_table_title_title: "標題",
        press_all_table_title_action: "操作",
        press_main_title_add: "媒體報導 > 新增報導",
        press_main_title_edit: "媒體報導 > 編輯報導",
        press_title_date: "日期：",
        press_sub_title_slideshow: "標題圖片集",
        press_detail_content_title_images: "圖片",
        press_detail_content_title_video: "影片",
        press_detail_content_value_video: "上載（最多一條影片）",
        press_sub_title_article: "報導內容",
        category_all_main_title: "分類",
        category_all_button_add_new_category: "新增分類",
        category_all_table_column_view_detail: "檢視",
        category_all_table_column_category: "一級分類",
        category_all_table_column_category_tow: "二級分類",
        category_tow_name: "二級分類名稱：",
        category_detail_content_title_images: "圖片：",
        category_detail_title_view: "分類詳細資料",
        category_detail_title_edit: "編輯分類",
        category_detail_title_create: "建立分類",
        notification_all_main_title: "推送訊息",
        notification_all_search_bar_placeholder: "使用標題內容搜尋",
        notification_all_table_column_title: "標題",
        notification_all_table_column_status: "狀態",
        notification_all_status_pushed: "已推送",
        notification_all_status_un_sent: "未推送",
        notification_all_table_column_push_time: "推送訊息時間",
        notification_all_table_column_target: "目標",
        notification_all_table_column_action: "操作",
        notification_all_button_add_new_notification: "新增推送信息",
        notification_all_target_type_gender: "性別",
        notification_all_target_type_age_range: "年齡層",
        notification_all_target_type_body_condition: "體質",
        notification_all_target_type_users: "個別客戶",
        notification_all_target_type_birthday: "生日",
        notification_all_target_type_all_users: "所有客戶",
        notification_detail_title_view: "推送訊息內容",
        notification_detail_title_edit: "修改推送訊息",
        notification_detail_title_create: "建立推送訊息",
        notification_detail_content_title_images: "圖片",
        notification_detail_content_title_target: "目標",
        notification_detail_content_title_push_time: "推送訊息時間",
        notification_detail_content_push_time_place_holder: "請輸入日期",
        notification_detail_content_title_status: "狀態",
        notification_detail_content_title_repeat: "每",
        notification_detail_content_repeat_per_day: "日重複",
        notification_detail_radio_all_users: "所有客戶",
        notification_detail_radio_group: "類別",
        notification_detail_target_age_range: "年齡層：",
        notification_detail_target_gender: "性別：",
        notification_detail_gender_male: "男",
        notification_detail_gender_female: "女",
        notification_detail_target_birthday: "生日",
        notification_detail_birthday_message: "於",
        notification_detail_birthday_message_days: "日前推送提示",
        notification_detail_target_body_condition: "體質：",
        notification_detail_radio_users: "個別客戶",
        notification_birthday_january: "一月",
        notification_birthday_february: "二月",
        notification_birthday_march: "三月",
        notification_birthday_april: "四月",
        notification_birthday_may: "五月",
        notification_birthday_june: "六月",
        notification_birthday_july: "七月",
        notification_birthday_august: "八月",
        notification_birthday_september: "九月",
        notification_birthday_october: "十月",
        notification_birthday_november: "十一月",
        notification_birthday_december: "十二月",
        room_all_main_title: "房間管理",
        room_all_table_column_center: "中心",
        room_all_table_column_title: "題目",
        room_all_table_column_service: "服務",
        room_all_table_column_status: "狀態",
        room_all_table_column_view_detail: "詳細資料",
        room_all_center_filter_title: "中心",
        room_all_center_filter_all: "全部",
        room_all_button_add_new_room: "新增房間",
        room_detail_title_edit: "編輯房間",
        room_detail_title_create: "創建房間",
        room_detail_content_title_title: "題目",
        room_detail_content_title_center: "中心",
        room_detail_content_title_service: "服務",
        room_detail_content_title_status: "狀態",
        room_detail_content_value_active: "活動中",
        room_detail_content_value_inactive: "暫停",
        video_uploaded: "影片已上載！",
        confirm_dialog_title: "確認？",
        confirm_dialog_button_yes: "是",
        confirm_dialog_button_no: "否",
        web_homepage_title_view: '網頁-預覽',
        web_homepage_title_edit: '網頁-編輯',
        web_homepage_title_images: '橫幅圖像',
        web_homepage_title_section_1: '第一節',
        web_homepage_title_section_2: '第二節',
        web_mobile_description_title_view: '網頁 - 手機應用簡介預覽',
        web_mobile_description_title_edit: '網頁 - 手機應用簡介編輯',
        booking_overview_title: "預約時間表",
        booking_overview_sub_title_center: "中心：",
        booking_overview_sub_title_service: "服務：",
        booking_overview_sub_title_therapist: "塑眉師：",
        booking_overview_option_all: "所有",
        booking_overview_button_add_booking: "新增預約",
        add_booking_title: "新增預約",
        message_title: "預約資料",
        add_booking_button_back: "返回",
        add_booking_user_name: "預約客戶*",
        add_booking_user_number: "客戶號碼*",
        register_as_new_client: "登記成為新會員",
        add_booking_sub_title_appointment: "預約*",
        add_booking_sub_title_date: "日期/時間*",
        add_booking_sub_title_center: "中心",
        add_booking_sub_title_therapist: "塑眉師",
        add_booking_sub_title_client: "客戶",
        add_booking_sub_title_client_number: "聯絡號碼",
        add_booking_remark: "備注",
        add_booking_option_any: "任何",
        add_booking_table_time: "時間",
        add_booking_table_therapist: "塑眉師",
        add_booking_table_center: "中心",
        add_booking_table_price: "價錢",
        add_booking_table_action: "動作",
        add_booking_table_button_book: "預約",
        add_booking_success: "預約成功！",
        add_booking_failure: "預約失敗！請檢查塑眉師的時間表",
        staff_schedule_title: "職員工作行程 ＞ 設定工作行程以提供預約服務",
        staff_schedule_button_prev: "＜ 上一個",
        staff_schedule_button_next: "下一個 ＞",
        staff_schedule_button_add_schedule: "+ 工作行程",
        staff_schedule_button_cancel: "取消",
        staff_schedule_dialog_title: "新增工作行程",
        staff_schedule_dialog_center: "中心",
        staff_schedule_dialog_service: "服務",
        staff_schedule_dialog_time: "時間",
        staff_schedule_dialog_repeat: "將來重複使用？",
        staff_schedule_dialog_alldays: "所有日子",
        staff_schedule_dialog_date: "日期",
        staff_schedule_dialog_day: "日子",
        staff_schedule_dialog_month: "月份",
        staff_schedule_dialog_day_sunday: "星期日",
        staff_schedule_dialog_day_monday: "星期一",
        staff_schedule_dialog_day_tuesday: "星期二",
        staff_schedule_dialog_day_wednesday: "星期三",
        staff_schedule_dialog_day_thursday: "星期四",
        staff_schedule_dialog_day_friday: "星期五",
        staff_schedule_dialog_day_saturday: "星期六",
        staff_schedule_dialog_start_date: "開始日期",
        staff_schedule_dialog_end_date: "結束日期",
        staff_schedule_dialog_add: "新增",
        staff_schedule_dialog_cancel: "取消",
        center: '中心',
        time_from: '開始時間',
        time_to: '結束時間',
        day_of_week: '日子',
        starting_date: '開始日期',
        end_date: '結束日期',
        date: '日期',
        user_inform_title: '客戶資料',
        staff_tab_cms_permission_title_transaction: "交易(付款，增值)",
        view: '閱覽',
        edit: '編輯',
        no_this_permission: '沒有權限',
        remark: '備註：',
        male: '男',
        female: '女',
        type: '分類',
        schedule: '行程',
        active: '活躍',
        inactive: '無效',
        new: '新',
        family: '家庭',
        id: '編號',
        account: '戶口',
        booking: '預約',
        inform: '資料',
        room: '房間',
        original: '原本的',
        available: '可提供的',
        exchange: '交換',
        target: '目標',
        update: '更新',
        delete: '刪除',
        cancel: '取消',
        time: '時間',
        past: '過去',
        enable: '啓用',
        rebook: '重新預約',
        download_report: '下載報告',
        timeline: '時間表',
        schedule_create_unsuccessfully: '由於時間衝突，未能成功創建以下計劃日程：',
        select_time: '選擇時間',
        select_client_category: '請選擇客戶分類',

        edit_schedule: '$t(edit)$t(schedule)',
        past_time: '$t(past)$t(time)',
        original_room: '$t(original)$t(room)',
        available_room: '$t(available)$t(room)',
        exchange_room: '$t(exchange)$t(room)',
        exchange_booking_room: '$t(exchange)$t(booking)$t(room)',
        exchange_target: '$t(exchange)$t(target)',
        booking_inform: '$t(booking)$t(inform)',
        family_id: '$t(family)$t(id)',
        new_family_account: '$t(new)$t(family)$t(account)',
        past_time_enable: '$t(past_time)$t(enable)',
        delete_and_rebook: '$t(delete)及$t(rebook)',
        input_color: '請輸入顯示顏色'
    },
    error: {
        please_input_field: '請輸入{{field}}',
        field_type_number: '{{field}}應為數字',
        max_min_correct: '請確定最高及最低值為正確！',
    }
};


