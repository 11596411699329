export function setError(errorMessage) {
    return async function (dispatch) {
        dispatch({ type: "SET_ERROR", payload: errorMessage })
    }
}


export function clearError() {
    return async function (dispatch) {
        dispatch({ type: "CLEAR_ERROR" })
    }
}