import React, { Component } from 'react';
import './notification-all.scss';
import * as notificationApi from "../../../../api/notification.api"
import { Input, Button } from 'element-react';
import { translate } from 'react-i18next';
import history from '../../../../history';
import PageTable from "../../../../components/page-table.component";
import ResultLimit from "../../../../components/result-limit.component";

@translate()
export default class NotificationAllPage extends Component {

    state = {
        results: [],
        lastTextChange: 0,
        currentPage: 1,
        orderBy: "createdAt",
        direction: "ASC",
        limit: 40,
        reference: "",
        totalResult: 0,
        columns: [
            {
                label: this.props.t('notification_all_table_column_title'),
                prop: "title",
            },
            {
                label: this.props.t('notification_all_table_column_status'),
                prop: "status",
            },
            {
                label: this.props.t('notification_all_table_column_push_time'),
                prop: "pushTime",
            },
            {
                label: this.props.t('notification_all_table_column_target'),
                prop: "target",
            },
            {
                label: this.props.t('notification_all_table_column_action'),
                prop: "action",
                render: (row) => {
                    return <span>
                        <Button onClick={() => this.switchTab("/main/notification-detail/" + row.id)}>{this.props.t('button_view')}</Button>
                        <Button onClick={() => this.deleteNotification(row.id)}>{this.props.t('button_delete')}</Button>
                    </span>
                }
            },
        ],
        loading: true,
    };

    async updateNotificationRecords() {
        const { t } = this.props;
        const { currentPage, orderBy, direction, limit, reference } = this.state;
        const response = await notificationApi.getNotifications(currentPage, orderBy, direction, limit, reference);
        console.log("Notifications:", response);
        this.setState({
            results: response.rows.map((notification) => {
                const translate = (target) => {
                    switch (target.targetType) {
                        case "gender":
                            return `${t('notification_all_target_type_gender')}`;
                        case "age_range":
                            return `${t('notification_all_target_type_age_range')}`;
                        case "body_condition":
                            return `${t('notification_all_target_type_body_condition')}`;
                        case "users":
                            return `${t('notification_all_target_type_users')}`;
                        case "birthday":
                            return `${t('notification_all_target_type_birthday')}`;
                        default:
                            return `${t('notification_all_target_type_all_users')}`;
                    }
                };
                const data = {
                    id: notification.id,
                    title: notification.notification_details[0].title,
                    status: t(notification.pushed ? 'notification_all_status_pushed' : 'notification_all_status_un_sent'),
                    pushTime: notification.pushTime,
                    target: notification.notification_targets.map((t, i) => {
                        return <div key={i}>{translate(t)}</div>
                    })
                }
                return data;
            }),
            totalResult: response.count,
            loading: false
        });
    }

    async componentDidMount() {
        await this.updateNotificationRecords();
    }

    onSearch = async (reference) => {
        const initiateTime = Date.now();
        this.setState({ lastTextChange: initiateTime });
        setTimeout(async () => {
            if (this.state.lastTextChange === initiateTime) {
                this.setState({ reference });
                this.updateNotificationRecords();
            }
        }, 500);
    }

    async deleteNotification(id) {
        await notificationApi.deleteNotification(id);
        this.updateNotificationRecords();
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.updateNotificationRecords());
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.updateNotificationRecords())
    }

    switchTab = (url) => {
        history.push(url);
    }


    render() {
        const { t } = this.props;
        const { limit, columns, currentPage, totalResult, results, loading } = this.state;
        return (
            <div className="notification-all-page">
                <div className="main-title">
                    {t('notification_all_main_title')}
                </div>
                <div className="page-header">
                    <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} />
                    <div className="space"></div>
                    <div className="search-bar">
                        <Input placeholder={t('notification_all_search_bar_placeholder')} onChange={(text) => this.onSearch(text)} />
                    </div>
                    <div className="add-notification-button">
                        <Button onClick={() => this.switchTab("/main/notification-detail/create")}>{t('notification_all_button_add_new_notification')}</Button>
                    </div>
                </div>
                <PageTable
                    limit={limit}
                    currentPage={currentPage}
                    totalResult={totalResult}
                    results={results}
                    columns={columns}
                    setCurrentPage={(page) => this.setCurrentPage(page)}
                    loading={loading}
                />
            </div>
        );
    }
}