import i18n from '../../utils/i18n';
import { i18n as elementI18n } from 'element-react'
import eng from 'element-react/src/locale/lang/en'
import cht from 'element-react/src/locale/lang/zh-TW'
import chs from 'element-react/src/locale/lang/zh-CN'

const elementLanguages = {
    eng,
    cht,
    chs,
};

const initialCounterState = {
    languageId: "eng",
    error: null,
};

function counter(state = initialCounterState, action) {
    switch (action.type) {
        case "SET_LANGUAGE_ID": {
            i18n.changeLanguage(action.payload);
            elementI18n.use(elementLanguages[action.payload]);
            return {
                ...state,
                languageId: action.payload,
            }
        }
        default:
            return state;
    }
}

export default counter;