import client from '../apiClient';

export async function getNotifications(page, orderBy, direction, limit, reference) {
    let response = await client.get(`/notification`, {
        params: {
            page,
            orderBy,
            direction,
            limit,
            reference,
        }
    });
    return response.data;
}

export async function getNotification(notificationId) {
    let response = await client.get(`/notification/${notificationId}`);
    return response.data;
}

export async function createNotification(body) {
    let response = await client.post(`/notification`, body);
    return response.data;
}

export async function updateNotification(notificationId, body) {
    let response = await client.patch(`/notification/${notificationId}`, body);
    return response.data;
}

export async function deleteNotification(notificationId) {
    let response = await client.delete(`/notification/${notificationId}`);
    return response.data;
}

export async function assignTargets(notificationId, targets) {
    let response = await client.post(`/notification/${notificationId}/assignTargets`, {
        targets,
    });
    return response.data;
}

export async function uploadImage(notificationId, slide) {
    const formData = new FormData();
    formData.append("slide", slide);
    let response = await client.post(`/notification/${notificationId}/slide`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}