import moment from 'moment';

export const gcdTwoNum = (a, b) => {
    if ( ! b) {
        return a;
    }
    return gcdTwoNum(b, a % b);
};

export const gcdList = (arr) => {
    return arr.reduce(gcdTwoNum);
};

export const moveToNextDay = (momentDate, day) => {
    const dateDay = momentDate.day();
    let diff = day - dateDay;
    if (diff <= 0) {
        diff += 7;
    }
    momentDate.add(diff, 'days');
    return momentDate;
};

export const getDatesForRepeatedDays = (startingDate, endDate, days) => {
    const result = [].concat.apply([], days.map(day => {
        return getDatesForRepeatedDay(startingDate, endDate, day);
    }));
    console.log('days', days, result);
    return result;
};

export const getDatesForRepeatedDay = (startingDate, endDate, day) => {
    startingDate = startingDate.clone().startOf('day');
    endDate = endDate.clone().startOf('day');

    const resultDates = [];
    if (startingDate.day() === day) {
        resultDates.push(startingDate.clone());
    }
    moveToNextDay(startingDate, day);
    while (startingDate.isSameOrBefore(endDate)) {
         resultDates.push(startingDate.clone());
         moveToNextDay(startingDate, day);
    }
    return resultDates;
};

export const getTimeForRepeatedTimeInADay = (date, fromTime, toTime, serviceDuration) => {

    fromTime = fromTime.clone();
    toTime = toTime.clone();
    const year = date.year();
    const month = date.month();
    const day = date.date();
    fromTime.year(year); toTime.year(year);
    fromTime.month(month); toTime.month(month);
    fromTime.date(day); toTime.date(day);
    toTime.subtract(serviceDuration, 'ms');

    const resultTimes = [];
    while (fromTime.isSameOrBefore(toTime)) {
        resultTimes.push(fromTime.clone());
        fromTime.add(serviceDuration, 'ms');
    }
    return resultTimes;
};