import React from 'react';
import {DatePicker} from "element-react";
import classNames from 'classnames';
import './date-picker-button.scss';
import moment from 'moment';

const DatePickerButton = (props) => {
    const datePickerProps = {...props};
    delete datePickerProps.children;
    delete datePickerProps.style;
    delete datePickerProps.className;

    return (
        <div
            className={classNames('date-picker-button', props.style)}
            style={props.style}
        >
            <i className="el-icon-date" />
            <div className="content-container">
                {props.children || moment(props.date).toISOString()}
            </div>
            {
                !props.disabled &&
                <DatePicker
                    {...datePickerProps}
                />
            }
        </div>
    )
};

export default DatePickerButton;