import React, { Component } from 'react';
import moment from 'moment';
import './tab-body-condition.scss';
import { translate } from 'react-i18next';
import ResultLimit from "../../result-limit.component";
import PageTable from "../../page-table.component";

@translate()
export default class UserBodyConditionTab extends Component {
    state = {
        currentPage: 1,
        limit: 40,
        bodyConditions: [],
        showBodyConditions: [],
        totalResult: 0,
        head: 0,
        end: 0,
        columns: [
            {
                label: this.props.t('tab_body_condition_table_column_ymd'),
                prop: "ymd",
            },
            {
                label: this.props.t('tab_body_condition_table_column_bd'),
                prop: "body_condition",
            },
        ],
    };

    setBodyConditions(props) {
        const bodyConditionsMap = {};
        props.bodyConditions.forEach((cond) => {
            const date = moment(cond.date).format("YYYY-MM-DD");
            bodyConditionsMap[date] = bodyConditionsMap[date] || [];
            bodyConditionsMap[date].push(cond.name);
        });

        const resultArray = [];
        for (let date in bodyConditionsMap) {
            resultArray.push({
                ymd: date,
                body_condition: bodyConditionsMap[date].join(', '),
            });
        }

        this.setState({
            totalResult: resultArray.length,
            bodyConditions: resultArray,
        });
    }

    updateBodyConditions() {
        const { currentPage, limit, bodyConditions } = this.state;
        const head = (currentPage - 1) * limit;
        const lastPage = Math.ceil(bodyConditions.length / limit);
        let end;
        if (currentPage === lastPage) {
            end = bodyConditions.length;
        } else {
            end = head + limit;
        }
        this.setState({
            showBodyConditions: bodyConditions.slice(head, end),
            head,
            end,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.bodyConditions) {
            this.setBodyConditions(nextProps);
            this.updateBodyConditions();
        }
    }

    async componentDidMount() {
        if (this.props.bodyConditions) {
            this.setBodyConditions(this.props);
            setTimeout(() => this.updateBodyConditions(), 0);
        }
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.updateBodyConditions());
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.updateBodyConditions())
    }

    render() {
        const { t } = this.props;
        const { currentPage, limit, showBodyConditions, columns, totalResult, head, end } = this.state;
        return (
            <div className="customer-body-condition-tab">
                <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} />
                <PageTable
                    limit={limit}
                    currentPage={currentPage}
                    totalResult={totalResult}
                    results={showBodyConditions}
                    sort={false}
                    columns={columns}
                    setCurrentPage={(page) => this.setCurrentPage(page)}
                />
            </div>
        );
    }
}
