import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Message, Loading } from 'element-react';
import PageTable from "../../page-table.component";
import ResultLimit from "../../result-limit.component";
import moment from 'moment';
import * as reservationApi from "../../../api/reservation.api";




@translate()
export default class UserTransferTab extends Component {
    state = {
        userId: this.props.user.id,
        limit: 40,
        page: 1,
        data: [],
        loading: false,
        count: 0,
        sort: null,
        columns: [
            {
                label: this.props.t('booking_table_column_date'),
                prop: "createdAt",
                sortable: "custom",
                render: this.renderDate
            },
            {
                label: this.props.t('booking_table_Customer_Number'),
                prop: 'userId',
            },
            {
                label: this.props.t('booking_table_column_user'),
                render: (row) => {
                    return (
                        <div>
                            <span>{row.userSurname}</span>
                            <span>{row.userName}</span>
                        </div>
                    )
                }
            },
            {
                label: this.props.t('booking_table_column_server'),
                prop: 'sericeName',
            },
            {
                label: this.props.t('booking_table_deduct_points'),
                render: (row) => {
                    return (
                        <span>{row.deduction}</span>
                    )
                }
            }
        ],
    };

    async componentDidMount() {
        await this.getList()
    }

    async getList() {
        const { page, limit, userId } = this.state;

        await this.setState({
            loading: true
        })

        await reservationApi.getUserIntegral({ page, limit, userId }).then(res => {
            console.log('getUserIntegral', res)
            this.setState({
                data: res.rows,
                count: res.count,
                page: res.page
            })
        }).catch(err => {
            Message.error(err);
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    setCurrentPage(page) {
        this.setState({ page }, () => this.getList())
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.getList());
    }

    renderDate(date) {
        var date = new Date(date.createdAt).toJSON();
        var val = new Date(+new Date(date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
        return moment(new Date(val)).format('YYYY-MM-DD HH:mm')
    }

    render() {
        const { loading, columns, data, count, limit, page, sort } = this.state
        return (
            <div>
                <Loading loading={loading}>
                    <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit} />
                    <PageTable
                        limit={limit}
                        currentPage={page}
                        totalResult={count}
                        results={data}
                        sort={sort}
                        columns={columns}
                        setSort={(e) => this.setSort(e)}
                        setCurrentPage={(page) => this.setCurrentPage(page)}
                        loading={loading}
                    />
                </Loading>
            </div>
        )
    }
}
