
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-view.scss';
import { Tabs } from 'element-react';
import ImageBoard from '../../../image-board.component';
import { baseURL } from "../../../../apiClient";

@translate()
export default class HomepageViewBoard extends Component {
    state = {
        tabs: [
            {
                id: "eng",
                language: "English",
                title: "Title:",
                description: "Description:",
            },
            {
                id: "cht",
                language: "繁體中文",
                title: "標題:",
                description: "描述:",
            },
            {
                id: "chs",
                language: "简体中文",
                title: "标题:",
                description: "描述:",
            },
        ],
        selectedTab: "0",
    }

    render() {
        const { t, web } = this.props;
        const { tabs, selectedTab } = this.state;
        let details = {};
        let slides = [];
        if (web && web.details) {
            details = web.details;
        }
        if (web && web.slides) {
            for(const slide of web.slides) {
                if(slide.path) {
                    slides.push(`${baseURL}/${slide.path}`);
                }
            }
        }
        return (
            web ? <div className="homepage-board-view">
                {web.slides.length !== 0 ?
                    <ImageBoard edit={false} localImages={slides} /> : null}
                <div className="tab">
                    <Tabs activeName={selectedTab} onTabClick={(tab) => this.setState({ selectedTab: tab.props.name })}>
                        {
                            tabs.map((tab, i) => {
                                return (
                                    <Tabs.Pane key={i} label={tabs[i].language} name={i.toString()}>
                                        <div className="block">
                                            <div className="sub-title">
                                                {t('web_homepage_title_section_1')}
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].title}
                                                </div>
                                                <div className="value">
                                                    {details.section_1 && details.section_1[tabs[i].id] && details.section_1[tabs[i].id].title ? details.section_1[tabs[i].id].title :null}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].description}
                                                </div>
                                                <div className="value">
                                                    {details.section_1 && details.section_1[tabs[i].id] && details.section_1[tabs[i].id].description ? details.section_1[tabs[i].id].description : null}
                                                </div>
                                            </div>
                                            <div className="sub-title">
                                                {t('web_homepage_title_section_2')}
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].title}
                                                </div>
                                                <div className="value">
                                                    {details.section_2 && details.section_2[tabs[i].id] && details.section_2[tabs[i].id].title ? details.section_2[tabs[i].id].title : null}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">
                                                    {tabs[i].description}
                                                </div>
                                                <div className="value">
                                                    {details.section_2 && details.section_2[tabs[i].id] && details.section_2[tabs[i].id].description ? details.section_2[tabs[i].id].description : null}
                                                </div>
                                            </div>
                                        </div>
                                    </Tabs.Pane>
                                )
                            })
                        }
                    </Tabs>
                </div>
            </div> : null
        );
    }
}