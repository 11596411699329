import React, { Component } from 'react';
import './page-holder.scss';
import Pagination from "react-js-pagination";
import withRouter from "react-router-dom/es/withRouter";
import { translate } from 'react-i18next';

@withRouter
@translate()
export default class PageHolder extends Component {
    render() {
        const { t, limit, currentPage, totalResult, results, setCurrentPage } = this.props;
        return (
            <div className="page-holder">
                {t('tab_booking_total_page', {
                    head: 1 + (limit * (currentPage - 1)),
                    end: results.length + (limit * (currentPage - 1)),
                    total: totalResult
                })}
                <div className="space"></div>
                <Pagination
                    firstPageText="<<"
                    prevPageText="<"
                    nextPageText=">"
                    lastPageText=">>"
                    activePage={currentPage}
                    itemsCountPerPage={limit}
                    totalItemsCount={totalResult}
                    pageRangeDisplayed={5}
                    onChange={(Page) => setCurrentPage(Page)}
                />
            </div>
        )
    }
}