
import './search-bar.scss';

import React, { Component } from 'react';
import { translate } from 'react-i18next';
import clickOutSide from 'react-onclickoutside';
import { Input } from "element-react";
import * as userApi from "../../../api/user.api";
import classNames from 'classnames';
import UserPreviewCard from "./user-preview-card.component";

@translate()
@clickOutSide
export default class UserSearchBar extends Component {

  state = {
    searchResult: [],
    showSearchResult: false,
    reference: '',
  };

  componentDidMount() {
    const { defaultUser } = this.props;
    if (defaultUser) {
      this.setState({ reference: defaultUser }, () => this.getData(defaultUser));
    }
  }

  onClickItem(user) {
    this.props.onUserSelected && this.props.onUserSelected(user);
    this.setState({
      showSearchResult: false,
    });
  }

  handleClickOutside = (evt) => {
    this.setState({
      showSearchResult: false,
    });
  };

  async getData(reference) {
    let response;
    if (reference) {
      response = await userApi.getUsers(1, 'id', 'ASC', 100, {reference:reference || ''});
    }
    this.setState({ searchResult: reference ? response.rows : null });
  };

  onSearch(reference) {
    const changeTime = Date.now();
    this.setState({
      reference,
      lastChange: changeTime,
    });
    setTimeout(() => {
      if (this.state.lastChange === changeTime) {
        this.getData(reference);
      }
    }, 200);
  }

  render() {
    const { t } = this.props;
    const { showSearchResult, searchResult } = this.state;

    return (
      <div className={classNames('user-search-bar-component', this.props.className)}>
        <div className="search-bar">
          <Input placeholder={t('user_summary_search_bar_placeholder')}
            onClick={() => this.setState({ showSearchResult: true })} onChange={(reference) => this.onSearch(reference)}
          />
          {
            (showSearchResult) ?
              <div className="search-result">
                {
                  searchResult ?
                    searchResult.map((searchResult) => {
                      return (
                        <UserPreviewCard
                          className="search-result-item"
                          user={searchResult}
                          key={searchResult.id}
                          onClick={() => {
                            console.log('Click 2');
                            this.onClickItem(searchResult);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      )
                    }) : null
                }
              </div> : null
          }
        </div>
      </div>
    );
  }
}