import React, { Component } from 'react';
import './tab-family.scss';
import { translate } from 'react-i18next';
import { Table } from 'element-react';
import * as familyApi from "../../../api/family.api";

@translate()
export default class UserFamilyTab extends Component {
    state = {
        members: [],
        columns: [
            {
                label: this.props.t('tab_family_table_column_id'),
                prop: "id",
            },
            {
                label: this.props.t('tab_family_table_column_surname'),
                prop: "surname",
            },
            {
                label: this.props.t('tab_family_table_column_name'),
                prop: "name",
            },
            {
                label: this.props.t('tab_family_table_column_tel'),
                prop: "tel",
            },
            {
                label: this.props.t('tab_family_table_column_gender'),
                prop: "gender",
            },
            {
                label: this.props.t('tab_family_table_column_hkid'),
                prop: "hkid",
            },
        ],
    };
    async componentDidMount() {
        await this.initTab(this.props);
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
            await this.initTab(nextProps);
        }
    }

    async initTab(props) {
        const response = await familyApi.getFamily(props.user.familyId);
        this.setState({
            members: response.usersInfo.map((user) => {
                const data = {
                    id: user.id,
                    surname: user.surname,
                    name: user.name,
                    tel: user.phone,
                    gender: user.gender === "male" ? "M" : "F",
                    hkid: user.hkid,
                };
                return data;
            })
        });
    }

    render() {
        const { t } = this.props;
        const { members, columns } = this.state;
        return (
            <div className="customer-family-tab">
                <div className="main-title">
                    {t('tab_family_title')}
                </div>
                <Table
                    style={{ width: '100%' }}
                    columns={columns}
                    data={members}
                />
            </div>
        );
    }
}
