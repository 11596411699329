import React, { Component } from 'react';
import './booking-inform.scss';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Dialog, Button, Select, Input, Radio, Message, MessageBox } from 'element-react';
import * as reservationApi from "../../../api/reservation.api";
import { setError } from "../../../redux/staff/error.action";
import history from "../../../history";
import moment from "moment";
import store from '../../../redux/store';

const mapDispatchToProps = (dispatch) => {
    return {
        setError: (message) => {
            dispatch(setError(message))
        }
    }
}


@translate()
@connect((state) => ({}), mapDispatchToProps)
export default class BookingInform extends Component {
    state = {
        selectedRoom: null,
        selectedBooking: null,
        input_val: 0,
        discountType: '',
        discount: 0,
        staffInfo: {}
    };

    componentDidMount() {
        const { booking } = this.props;
        if (booking) {
            console.log(booking)
            this.setState({
                discountType: booking.discountType,
                discount: Number(booking.discount),
                input_val: booking.price
            })
            const data = store.getState()
            const { staff } = data.staff
            this.setState({
                staffInfo: staff
            })
            this.updateRoomInform(booking);
        }
    }

    async updateRoomInform(booking) {
        const { t } = this.props;
        const freeRooms = await reservationApi.getFreeRooms(booking.room.centerId, booking.serviceId, booking.staff_schedule.startTime, booking.staff_schedule.endTime);
        const exchangeBooking = await reservationApi.getExchangeBooking(booking.id);
        const roomOptions = [
            {
                label: t('original_room'),
                options: [{
                    value: booking.roomId,
                    label: booking.room.title,
                }],
            },
            {
                label: t('available_room'),
                options: freeRooms.map((room) => {
                    return {
                        value: room.id,
                        label: room.title,
                    };
                }),
            },
        ];
        if (exchangeBooking.length) {
            roomOptions.push({
                label: t('exchange_room'),
                options: [
                    {
                        value: 'exchange',
                        label: t('exchange_room'),
                    },
                ]
            });
        }
        const exchangeOptions = exchangeBooking.map((reservation) => {
            let label = ""
            if (reservation.user) {
                label = reservation.userId + "-" + reservation.user.surname + " " + reservation.user.name
            } else {
                label = reservation.userInfo.name
            }
            return {
                value: reservation.id,
                // label: `${reservation.userId}-${reservation.user.surname} ${reservation.user.name}`,
                label: label
            };
        });
        this.setState({
            roomOptions,
            exchangeOptions,
            selectedRoom: booking.roomId,
            input_val: booking.price
        });
    }

    async delete(reservationId) {
        await reservationApi.cancelReservation(reservationId);
        const { rebook } = this.state;
        if (rebook) {
            const { booking } = this.props;
            const { user, staff_schedule } = booking;
            // history.push(`/main/booking-overview/add/${user.id}/${booking.serviceId}/${moment(staff_schedule.startTime).format('YYYY-MM-DD')}/${staff_schedule.centerId}/${staff_schedule.staffId}`);
            history.push(`/main/booking-overview/add/${booking.serviceId}/${moment(staff_schedule.startTime).format('YYYY-MM-DD')}/${staff_schedule.centerId}/${staff_schedule.staffId}`);
        }
        this.onClose();
    }

    async update() {
        const { selectedRoom, selectedBooking, discountType, discount, staffInfo } = this.state;

        const { t, setError, booking } = this.props;

        await reservationApi.updateReservation(booking.id, { price: Number(this.state.input_val), type: discountType, discount, staffId: staffInfo.id,roomId:selectedRoom }).then(() => {})

        if (selectedRoom === 'exchange' && selectedBooking === null) {
            return setError(t('error:please_input_field', { field: t('exchange_target') }));
        }
        if (selectedRoom === 'exchange') {
            await reservationApi.exchangeBookingRoom(booking.id, selectedBooking);
        } 
        // else if (selectedRoom !== booking.roomId) {
        //     await reservationApi.changeBookingRoom(booking.id, selectedRoom);
        // }

        await this.onClose();
    }

    onClose() {
        this.setState({
            selectedRoom: null,
            selectedBooking: null,
        });
        this.props.onCancel();
    }

    isNumber(value) {
        return typeof value === 'number' && !isNaN(value)
    }

    async inputOnBlur(value, key) {
        const { booking } = this.props
        if (Number(booking.price) !== Number(value)) {
            MessageBox.confirm('确认修改价格吗, 是否继续?', '提示', {
                type: 'warning'
            }).then(() => {
                this.setState({
                    input_val: value
                })
                Message({
                    type: 'success',
                    message: '修改成功!'
                });
            }).catch(() => {
                this.setState({
                    input_val: booking.price
                });
                Message({
                    type: 'info',
                    message: '已取消修改'
                });
            });
        }
    }

    render() {
        const { t, booking, visible, onCancel } = this.props;
        const { selectedRoom, roomOptions, exchangeOptions, selectedBooking, showConfirm, discountType, discount, staffInfo, input_val } = this.state
        return (
            booking ? <Dialog
                size={'small'}
                title={t('booking_inform')}
                visible={visible}
                onCancel={() => onCancel()}
            >
                <div className="dialog">
                    <div className="row">
                        <div className="title">
                            {t('staff_schedule_dialog_service')+'：'}
                        </div>
                        <div className="value">
                            {booking.service.service_details[0].name}
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('staff_schedule_dialog_time')+'：'}
                        </div>
                        <div className="value">
                            {/* {moment(booking.staff_schedule.startTime).format('HH:mm')} - {moment(booking.staff_schedule.endTime).format('HH:mm')} */}
                            {moment(booking.startTime).format('HH:mm')} - {moment(booking.endTime).format('HH:mm')}
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('user_board_view_name')}
                        </div>
                        <div onClick={() => history.push(`/main/user-detail/${booking.user.id}`)} className="value"
                            style={{ color: '#1AA2E2', cursor: 'pointer', }}>
                            {booking.user ? `${booking.user.surname} ${booking.user.name}` : `${booking.userInfo.name}` }
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('user_board_view_gender')}
                        </div>
                        <div className="value">
                            {booking.user ? booking.user.gender : ""}
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('user_board_view_tel')}
                        </div>
                        <div className="value">
                            {JSON.parse(sessionStorage.getItem("permissions")).reservation == 'read'? null : booking.user ? booking.user.phone : booking.userInfo.phone}
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{ '美容師：' }</div>
                        <div className="value">
                            {booking.staff_schedule.staff.staff_details[0].surname}{booking.staff_schedule.staff.staff_details[0].name}
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">
                            {t('room')+'：'}
                        </div>
                        <div className="value">
                            <Select value={selectedRoom} onChange={(value) => this.setState({ selectedRoom: value })}>
                                {roomOptions ?
                                    roomOptions.map((group, i) => {
                                        return (
                                            <Select.OptionGroup key={i} label={group.label}>
                                                {
                                                    group.options.map(el => {
                                                        return (
                                                            <Select.Option key={el.value} label={el.label} value={el.value}>
                                                                <span style={{ float: 'left' }}>{el.label}</span>
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select.OptionGroup>
                                        )
                                    }) : null
                                }
                            </Select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="title">
                            {'原價格：'}
                        </div>
                        <div className="value">
                            <Input value={input_val} disabled={staffInfo.type !== "admin" && staffInfo.type !== "super admin"} onBlur={this.inputOnBlur.bind(this, input_val, 'input_val')} onChange={(value) => { this.setState({ input_val: value }) }} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="title">
                            { '折扣類型：' }
                        </div>
                        <div className="value">
                            <Radio.Group value={discountType} onChange={(value) => { this.setState({ discountType: value }) }}>
                                <Radio value="normal">照常</Radio>
                                <Radio value="relief">减免</Radio>
                                <Radio value="discount">打折</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    {
                        discountType !== "normal" ?
                            <div className="row">
                                <div className="title">
                                    {discountType === 'relief' ? '减免金额：' : '折扣比：'}
                                </div>
                                <div className="value">
                                    <Input value={discount} onChange={value => this.setState({ discount: Number(value) })}></Input>
                                </div>
                            </div> : null
                    }
                    {
                        booking.remark !== '' ?
                            <div className="row">
                                <div className="title">
                                    {t('user_board_view_remark')}
                                </div>
                                <div className="value">
                                    {
                                        booking.remark
                                            ? booking.remark
                                            : ''
                                    }
                                </div>
                            </div> : null
                    }
                    {
                        selectedRoom === 'exchange' ?
                            <div className="row">
                                <div className="title">
                                    {t('exchange_booking_room')}
                                </div>
                                <div className="value">
                                    <Select value={selectedBooking} onChange={(value) => this.setState({ selectedBooking: value })}>
                                        {exchangeOptions ?
                                            exchangeOptions.map((option) => {
                                                return (
                                                    <Select.Option key={option.value} label={option.label} value={option.value}>
                                                        <span style={{ float: 'left' }}>{option.label}</span>
                                                    </Select.Option>
                                                )
                                            }) : null
                                        }
                                    </Select>
                                </div>
                            </div> : null
                    }
                    <Dialog.Footer className="dialog-footer" style={{ "display": "inline-block" }}>
                        {/* <Button onClick={() => this.setState({ showConfirm: true })}>{t('delete')}</Button>
                        <Button onClick={() => this.setState({ showConfirm: true, rebook: true })}>{t('delete_and_rebook')}</Button>
                        <div className="space" /> */}
                        <Button onClick={() => onCancel()}>{t('cancel')}</Button>
                        <Button type="primary" disabled={discountType ==this.props.booking.type&& Number(input_val) == this.props.booking.price&&selectedRoom == this.props.booking.roomId } onClick={() => this.update()}>{t('update')}</Button>
                    </Dialog.Footer>
                    <Dialog
                        size={'tiny'}
                        title={t('confirm_dialog_title')}
                        visible={showConfirm}
                        onCancel={() => this.setState({ showConfirm: false })}
                    >
                        <div className="dialog">
                            <div className="dialog-body">
                                <div className="row">
                                    <Button type="primary" onClick={() => this.delete(booking.id)}>{t('confirm_dialog_button_yes')}</Button>
                                    <Button onClick={() => this.setState({ showConfirm: false, rebook: false })}>{t('confirm_dialog_button_no')}</Button>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </Dialog> : null
        );
    }
}
