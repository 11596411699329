import client from '../apiClient';

export async function getTransactions(familyId, page, orderBy, direction, limit) {
    let response = await client.get(`/transaction/${familyId}`, {
        params: {
            page,
            orderBy,
            direction,
            limit,
        }
    });
    return response.data;
}
