
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './board-dialog.scss'
import { Button, Dialog, Input, Notification } from 'element-react';
import ImageBoard from "../../image-board.component";
import fileDialog from "file-dialog";
import { baseURL } from "../../../apiClient";

@translate()
export default class boardDialog extends Component {
    
    state = {
        localImages: [`${process.env.PUBLIC_URL}/resources/upload.png`],
        images: null,
        dialogVisible: true,
        cht: {},
        eng: {},
        id: ''
    }

    async componentDidMount() {
        const { secondary } = this.props;
        console.log('二级分类详情', secondary)
        await this.setState({
            ...this.state,
            localImages: secondary.slide ? [`${baseURL}/${secondary.slide}`] : [],
            cht: secondary.category_details.filter((item) => item.languageId === 'cht')[0],
            eng: secondary.category_details.filter((item) => item.languageId === 'eng')[0],
            id: secondary.id,
        })

    }
    //上传,更新照片
    async uploadImage(index, remove = false) {
        const newImages = this.state.images ? { ...this.state.images } : {};
        newImages[index] = {};
        if (remove) {
            newImages[index].file = null;
        } else {
            newImages[index].file = (await fileDialog({ accept: 'image/*' }))[0];
        }
        this.setState({ 
            ...this,state,
            images: newImages 
        },
            () => {
                if (this.state.images[index].file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const newLocalImages = [...this.state.localImages]
                        newLocalImages[index] = e.target.result;
                        this.setState({ 
                            ...this,state,
                            localImages: newLocalImages 
                        });
                    }
                    reader.readAsDataURL(this.state.images[index].file);
                } else {
                    const newLocalImages = [...this.state.localImages]
                    newLocalImages[index] = `${process.env.PUBLIC_URL}/resources/upload.png`;
                    this.setState({ 
                        ...this,state,
                        localImages: newLocalImages 
                    });
                }
            }
        );
    }
    //设置二级详情
    async setCategory(key1, key2, e) {
        await this.setState({
            ...this.state,
            [key1]: {
                ...this.state[key1],
                [key2]: e
            }
        })
    }
    //关闭弹窗，回调父组件方法
    onClose(isBool = false) {
        const { isReadonly } = this.props;
        if (isBool && !isReadonly) {
            const { cht, eng, id } = this.state;
            let ch = Object.values(cht)
            let en = Object.values(eng)
            let isNullCh = ch.includes('')
            let isNullEh = en.includes('')
            if (ch.length < 5 || en.lengthL < 5 || isNullCh || isNullEh) {
                Notification({
                    title: '警告',
                    message: '请将语言信息填充完整！',
                    type: 'warning'
                });
                return;
            }
            let updata = {
                id,
                category_details: [cht, eng]
            };
            this.props.success(updata.id, updata)
        }
        //关闭
        this.props.close()
    }

    render() {
        const { dialogVisible, cht, eng, localImages } = this.state;
        const { isReadonly, t } = this.props;
        return (cht&&eng
            ?   <main className='main-category'>
                    <div className='dialog'>
                        <Dialog
                            title=""
                            size='small'
                            top={'9%'}
                            visible={dialogVisible}
                            onCancel={() => this.onClose()}
                            lockScroll={true}
                        >
                            <Dialog.Body>
                                <p>{ t('Chinese_Version') }</p>
                                <main className="row">
                                    <section className="row-left">
                                        { t('Category_Name') }
                                    </section>
                                    <section className="row-right">
                                        <Input
                                            disabled={isReadonly}
                                            value={cht.title}
                                            onChange={(e) => this.setCategory('cht', 'title', e)}
                                        />
                                    </section>
                                </main>
                                <main className="row">
                                    <section className="row-left">
                                        { t('borad_category_description') }
                                    </section>
                                    <section className="row-right">
                                        <Input
                                            disabled={isReadonly}
                                            type="textarea"
                                            autosize={{ minRows: 4 }}
                                            value={cht.description}
                                            onChange={(e) => this.setCategory('cht', 'description', e)}
                                        />
                                    </section>
                                </main>
                                <p>{ t('English_version') }</p>
                                <main className="row">
                                    <section className="row-left">
                                        { t('Category_Name') }
                                    </section>
                                    <section className="row-right">
                                        <Input
                                            disabled={isReadonly}
                                            value={eng.title}
                                            onChange={(e) => this.setCategory('eng', 'title', e)}
                                        />
                                    </section>
                                </main>
                                <main className="row">
                                    <section className="row-left">
                                        { t('borad_category_description') }
                                    </section>
                                    <section className="row-right">
                                        <Input
                                            disabled={isReadonly}
                                            type="textarea"
                                            autosize={{ minRows: 4 }}
                                            value={eng.description}
                                            onChange={(e) => this.setCategory('eng', 'description', e)}
                                        />
                                    </section>
                                </main>
                                <div className='dialog-img'>
                                    <ImageBoard
                                        edit={false} localImages={localImages}
                                        uploadImage={(index, remove) => this.uploadImage(index, remove)}
                                    />
                                </div>
                                
                            </Dialog.Body>
                            <Dialog.Footer className="tow-dialog-footer">
                                <Button onClick={() => this.onClose()}>{t('tab_booking_check_in_cancel')}</Button>
                                <Button type="primary" onClick={() => this.onClose(true)}>{t('tab_booking_check_in_confirm')}</Button>
                            </Dialog.Footer>
                        </Dialog>
                    </div>
                </main>
            : null
        )
    }
}