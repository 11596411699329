/* eslint-disable camelcase */
import cht from './cht';
import chs from './chs';
import eng from './eng';

export default {
  eng,
  cht,
  chs,
};
