import React, { Component } from 'react';
import "./index.scss"
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dialog, Button, Form, Layout, Input, MessageBox, Message } from 'element-react';
import { Cascader } from 'antd'
import * as serviceApi from '../../../api/service.api'
import * as packagesApi from "../../../api/servicePackages";
const { SHOW_CHILD } = Cascader;


@translate()
@connect((state) => {
    return {
        staffId: state.staff.staff.id,
    };
})
export default class AddTimesPackages extends Component {

    state = {
        visible:true,
        combo:{
            times:[
                {
                    serviceIds:[],
                    times:null,
                    price:null,
                }
            ],
        },
        rules: {
            name: [
                { required: true, message: '请输入套餐名称', trigger: 'blur' },
            ],
        },
        intentionalService: [],
        prop: {
            value: 'value',
            label: "name",
            multiple: true
        },
        total:0
    }

    get totalPrice (){
        const { combo:{times} } = this.state
        let sum =  times.reduce(function (previousValue, currentValue) {
            return previousValue + (parseFloat(currentValue.price || 0) * parseInt(currentValue.times || 0))
        }, 0)
        return sum || 0
    }

    addDomain(e){
        e.preventDefault();
        this.state.combo.times.push({
            serviceIds:[],
            time:null,
            price:null
        });
        this.forceUpdate()
    }

    removeDomain(item, e) {
        e.preventDefault();
        if (this.state.combo.times.length <=1 ) return
        var index = this.state.combo.times.indexOf(item);
        if (index !== -1) {
            this.state.combo.times.splice(index, 1);
            this.forceUpdate();
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        
        this.refs.form.validate((valid) => {
            if (valid) {
                let data = this.state.combo
                let times = data.times.map(item => {
                    return {
                       price:parseFloat(item.price),
                       times:parseInt(item.times),
                       serviceIds:(item.serviceIds.map(service => service.slice(-1))).flat()
                    }
                })

                const { staffId,success } = this.props

                let query = {
                    ...data,
                    price:parseFloat(this.totalPrice),
                    times,
                    bookId:staffId
                }

                packagesApi.create(query).then(result => {
                    const { code } = result.data
                    if ( code != 200 ) return
                    success()
                }).catch(error => {
                    Message.error(error)
                })
            } else {
                return false;
            }
        });
    }

    MessageUtice(type, message, showClose = true,num = 5000){
        return Message({
            type,
            message,
            customClass: 'zZindex',
            showClose,
            duretion: num
        })
    }

    onChange(key, value) {
        if (key == 'price' && value) {
           value = (value.match(/^\d*(\.?\d{0,2})/g)[0])
        }
        this.setState({
           combo: Object.assign({}, this.state.combo, { [key]: value })
        });
    }

    arrHasSameValue = (arr1, arr2) => {
        let arr = []
        for (let i = 0; i < arr1.length; i++) {
            for (let j = 0; j < arr2.length; j++) {
                console.log(arr1[i].toString())
                console.log(arr2[j].toString())
                if ( arr1[i].toString() == arr2[j].toString()) {
                    arr.push(arr1[i])
                }
            }
        }
        return arr;
    }

    onSeriveTimeChange(index, key, value) {
        if (key == 'times'&& value) {
            value =  value.replace(/[^\d]/g, '')
        }else if (key == 'price' && value) {
            value = (value.match(/^\d*(\.?\d{0,2})/g)[0])
        } else if ( key == "serviceIds") {
            if (value.length > 0) {
                const { times } = this.state.combo
                let datas = JSON.parse(JSON.stringify(times))
                datas.splice(index,1)

                if (datas.length > 0) {
                    let data = []
                    datas.forEach(item => {
                        item.serviceIds.forEach(e => data.push(e))
                    })
                    let result = this.arrHasSameValue(data,value)
                    value.forEach((item,index)=>{
                        result.forEach(e=>{
                            if(item.toString() == e.toString()) {
                                this.MessageUtice('error','套餐中已包含该服务',true,2000)
                                value.splice(index,1)
                            }
                        })
                    })
                }
            }
        }
        this.state.combo.times[index][key] = value;
        this.forceUpdate();
    }

    async getServiceList() {
        await serviceApi.getServiceList().then(async result => {
            let data = await this.serviceTree(result.rows)
            this.setState({ intentionalService: data })
        }).catch(error => {
            MessageBox.alert(error.userMessage);
        })
    }

    async serviceTree(datas) {
        let parents = []
        datas.forEach(item => {
            if (item.stratum == 1) {
                parents.push({
                    value: item.id,
                    label: item && item.category_details && item.category_details[0].title,
                    disabled: false,
                })
            }
        })
        let childrens = datas.filter(item => item.stratum != 1)
        await this.formatService(parents, childrens)
        return parents
    }

    async formatService(parents, childrens) {
        parents.forEach(parent => {
            childrens.forEach((children, index) => {
                if (children.stratumId == parent.value) {
                    let temp = JSON.parse(JSON.stringify(childrens))
                    temp.splice(index, 1)
                    let services = children.services.map(service => {
                        return {
                            value: service.id,
                            label: service.service_details[0].name,
                            disabled: false,
                        }
                    })
                    let child = {
                        value: children.id,
                        label: children && children.category_details && children.category_details[0].title,
                        disabled: false,
                    }
                    if (children.services.length > 0) {
                        child.children = services
                    }
                    parent.children ? parent.children.push(child) : parent.children = [child]
                    this.formatService([child], temp)
                }
            })
        })
    }

    async componentDidMount() {
        await this.getServiceList()
    }
    

    render() {
        const { combo, visible, intentionalService,sum } = this.state
        const { times } = combo
        const {isClosed} =this.props;

        return (
            <main className='add-times-contanier'>
                <Dialog title="次卡套餐设置" visible={ visible } onCancel={ isClosed } lockScroll={ false }>
                    <div className='add-times-main'>
                        <Form ref="form" model={combo} labelWidth="100" labelPosition='left' rules={this.state.rules}>
                            <Layout.Row className='service-times-main' gutter={20}>
                                <Layout.Col lg="12" md="12" sm="24">
                                    <Form.Item label="套餐名称" prop="name">
                                        <Input value={combo.name} onChange={this.onChange.bind(this, 'name')}></Input>
                                    </Form.Item>
                                </Layout.Col>
                                <Layout.Col lg="12" md="12" sm="24">
                                    <Form.Item label="套餐总价格">
                                        <Input value={this.totalPrice} disabled></Input>
                                    </Form.Item>
                                </Layout.Col>
                            </Layout.Row>

                            <hr style={{marginBottom:"22px"}}></hr>
                            {
                                times.map((time,index) => {
                                    return (
                                        <Layout.Row key={index}  gutter={20}>
                                            <Layout.Col lg="12" md="12" sm="24">
                                                <Form.Item label="服务" prop={`times:${index}`} rules={{
                                                    type: 'object', required: true,
                                                    fields: {
                                                        serviceIds: { type: 'array', required: true, message: '服务不能为空', trigger: 'change' }
                                                    }
                                                }}>
                                                    <Cascader style={{width:"100%"}} className="custom-cascader" allowClear value={time.serviceIds} multiple={true} maxTagCount="responsive" showCheckedStrategy={SHOW_CHILD} options={intentionalService}
                                                        onChange={(value,selectedOptions)=>{this.onSeriveTimeChange(index,'serviceIds',value)}}
                                                    ></Cascader>
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col lg="12" md="12" sm="24">
                                                <Form.Item label="次数" prop={`times:${index}`} rules={{
                                                    type: 'object', required: true,
                                                    fields: {
                                                        times: [{ required: true, message: '次数不能为空', trigger: 'blur' },{ validator: (rule, value, callback) => {
                                                            var times = parseInt(value, 10);
                                                            if (!Number.isInteger(times)) {
                                                                callback(new Error('请输入数字值'));
                                                            } else if (times <= 0 ) {
                                                                callback(new Error('请输入大于0的数值'));
                                                            } else {
                                                                callback();
                                                            }
                                                        }, trigger: 'blur'}]
                                                    }
                                                }}>
                                                    <Input value={time.times} onChange={(value)=>this.onSeriveTimeChange(index,'times',value)}></Input>
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col lg="12" md="12" sm="24">
                                                <Form.Item label="单价" prop={`times:${index}`} rules={{
                                                    type: 'object', required: true,
                                                    fields: {
                                                        price: [{ required: true, message: '单价不能为空', trigger: 'blur' },{ validator: (rule, value, callback) => {
                                                            var price = parseInt(value, 10);
                                                            if (!Number.isInteger(price)) {
                                                                callback(new Error('请输入数字值'));
                                                            } else if (price <= 0 ) {
                                                                callback(new Error('请输入大于0的数值'));
                                                            } else {
                                                                callback();
                                                            }
                                                        }, trigger: 'blur'}]
                                                    }
                                                }}>
                                                    <Input value={time.price} onChange={(value)=>this.onSeriveTimeChange(index,'price',value)}></Input>
                                                </Form.Item>
                                            </Layout.Col>
                                            <Layout.Col lg="12" md="12" sm="24">
                                                <Form.Item style={{textAlign:"right"}}>
                                                    <Button onClick={this.removeDomain.bind(this, time)}>删除</Button>
                                                </Form.Item>
                                            </Layout.Col>
                                        </Layout.Row>   
                                    )
                                })
                            } 
                            <Layout.Row className='icon-main'>
                                <Layout.Col lg="24" md="24" sm="24">
                                    <Button onClick={this.addDomain.bind(this)}>增加</Button>
                                    <Button type="primary" onClick={this.handleSubmit.bind(this)}>提交</Button>
                                </Layout.Col>
                            </Layout.Row>
                        </Form>
                    </div>
                    
                </Dialog>
            </main>
        )
    }
}