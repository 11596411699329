import React, { Component } from 'react';
import moment from 'moment';
import './tab-service-detail-view.scss';
import { translate } from 'react-i18next';
import { baseURL } from "../../../apiClient";
import PageTable from "../../page-table.component";

@translate()
export default class StaffServiceDetailViewTab extends Component {
    render() {
        const { t, staff } = this.props;

        const columns = [
            {
                label: this.props.t('staff_tab_service_detail_title_project'),
                prop: "name",
            },
            {
                label: this.props.t('staff_tab_service_detail_title_price'),
                prop: "extraFee",
            },
            {
                label: "百分比提成",
                prop: "commission",
            },
            {
                label:"固定提成",
                prop:"fixedCommission"
            }
        ];

        return (
            <div className="service-detail-tab">
                <div className="row">
                    <div className="title">
                        {t('staff_tab_service_detail_title_center')}
                    </div>
                    <div className="value">
                        {
                            staff.centers.map((center, i) => {
                                return (
                                    <div key={i}>
                                        {center.name}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="title">
                        {t('staff_tab_service_detail_title_service_type')}
                    </div>
                    <div className="value">
                        {
                            <PageTable
                                results={staff.services}
                                totalResult={staff.services.length}
                                columns={columns}
                                limit={staff.services.length}
                                currentPage={1}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}