import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import './index.scss';
import { Button, Input, DatePicker, Message , MessageBox} from 'element-react';
import PageTable from "../../page-table.component";
import ResultLimit from "../../result-limit.component";
import UserAddPackage from "./user-add-service-packages"
import PDF from "./pdf"
import * as packagesApi from "../../../api/servicePackages";
import { removeEmptyFiled } from '../../../utils/removeEmptyFiled'
import { isJSON } from '../../../utils/isJSON'
import moment from 'moment';


@translate()
@connect((state) => {
    return {
        staffId: state.staff.staff.id,
    };
})
export default class UserSerivePackages extends Component {
    state = {
        search:{
            page:1,
            limit:40,
            expireDate:null,
        },
        total:0,
        loading: false, 
        popover:false,
        columns:[
            {
                label: "套餐名稱",
                prop: "name",
            },
            {
                label: "套餐包含服務",
                width:600,
                render:(row)=>{
                    let results = isJSON(row.extra)
                    let datas = results  ? JSON.parse(row.extra)  : row.extra
                    let newDatas = isJSON(datas) ? JSON.parse(datas) : datas
                    { return newDatas && newDatas.length > 0 && newDatas.map(item => {
                        let services = item.containService.map(i => i.name)
                        return (
                            <div key={item.id}>
                                <p>{services.join(" / ")}</p> 
                                <div className='table-colum'>
                                    <p>总次數： {item.times}</p>
                                    <p>剩余次数：{item.overTimes}</p>
                                    <p>單價：{item.price}</p>
                                </div>
                            </div>
                        )
                    })}
                }
            },
            {
                label: "套餐價格",
                prop: "price",
            },
            {
                label: "套餐到期時間",
                prop: "expireDate",
                render:(row)=>{
                    return <div>{moment(row.expireDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                }
            },
            {
                label: "套餐購買時間",
                prop: "createdAt",
                render:(row)=>{
                    return <div>{moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>
                }
            },
            {
                label:"操作",
                render:(row)=>{
                    let results = isJSON(row.extra)
                    let datas = results  ? JSON.parse(row.extra)  : row.extra
                    let newDatas = isJSON(datas) ? JSON.parse(datas) : datas
                    let query = {...row}
                    query.extra = newDatas
                    return (
                        <div style={{textAlign:"center"}}>
                            <Button type="text" style={{marginRight:"20px"}} onClick={()=>this.exportPDF(query)}>出單</Button>
                            <Button type="text" onClick={()=>this.refund(row.id)}>回退</Button>
                        </div>
                    )
                }
            }
        ],
        pdfDatas:{},
        visible:false,
        datas:[]
    }

    exportPDF(row){
        this.setState({
            pdfDatas: row
        }, () => {
            this.setState({
                pdfDowload: true
            })
        })
    }

    refund(id){
        const {staffId ,refresh} = this.props
        MessageBox.confirm('此操作將把套餐中的餘額退還到用戶餘額, 是否继续?', '提示', {
            type: 'error'
        }).then(() => {
            let query = { id, bookId:staffId }
            packagesApi.refundPackage(query).then(result => {
                this.getDatas()
                refresh()
                Message({
                    type: 'success',
                    message: '回退成功!'
                });
            }).catch(error => {
                this.MessageUtice('error',error)
            })
        }).catch(() => {
            Message({
                type: 'info',
                message: '已取消删除'
            });
        });
    }

    setResultLimit(limit) {
        this.setState({ limit }, () => this.getDatas());
    }

    setCurrentPage(currentPage) {
        this.setState({ currentPage }, () => this.getDatas())
    }

    MessageUtice(type, message, showClose = true,num = 5000){
        return Message({
            type,
            message,
            customClass: 'zZindex',
            showClose,
            duretion: num
        })
    }

    getDatas() {
        const { user } = this.props
        let query = removeEmptyFiled(this.state.search)
        query.userId = user.id
        query.expireDate = moment(this.state.search.expireDate).endOf("day").toISOString()
        this.setState({loading:true})
        packagesApi.useListPackages(query).then(result => {
            console.log(result.data)
            const { result:datas,count } = result.data
            this.setState({
                total:count,
                datas
            })
        }).catch(error => {
            this.MessageUtice('error',error)
            this.setState({loading:false})
        }).finally(()=>{
            this.setState({loading:false})
        })
    }

    MessageUtice(type, message, showClose = true,num = 5000){
        return Message({
            type,
            message,
            customClass: 'zZindex',
            showClose,
            duretion: num
        })
    }

    componentDidMount() {
        this.getDatas()
    }

    render() {
        const { user } = this.props
        const { search,total,datas,loading,columns, visible, pdfDowload, pdfDatas} = this.state
        const { page ,limit } = search

        return (
            <main className='tab-service-page'>
                <header className='tab-service-header'>
                    <Button onClick={()=>this.setState({visible:true})}>购买次卡套餐</Button>
                </header>
                <section className='tab-service-main'>
                    <ResultLimit setResultLimit={(limit) => this.setResultLimit(limit)} limit={limit}/>
                    <div className='table-header-main'>
                        <Input style={{maxWidth:'200px',marginRight:'20px'}} value={search.serviceName} placeholder="搜寻服务" onChange={(value)=>this.setState({search:Object.assign({}, this.state.search, { serviceName: value })},()=>{this.getDatas()})}></Input>
                        <DatePicker value={search.expireDate} onChange={(value)=>this.setState({search:Object.assign({}, this.state.search, { expireDate: value })},()=>{this.getDatas()})}  placeholder="选择购买日期或者到期日期" disabledDate={time=>time.getTime() < Date.now() - 8.64e7}/>
                        <Input style={{maxWidth:'200px',margin:'0 20px'}} value={search.name} onChange={(value)=>this.setState({search:Object.assign({}, this.state.search, { name: value })},()=>{this.getDatas()})}  placeholder="所属套餐"/>
                        <Input style={{maxWidth:'200px'}} value={search.price} onChange={(value)=>this.setState({search:Object.assign({}, this.state.search, { price: value })},()=>{this.getDatas()})}  placeholder="所属套餐价格"/>
                    </div>
                    <PageTable limit={limit} currentPage={page} totalResult={total} results={datas} columns={columns} setCurrentPage={(page) => this.setCurrentPage(page)} loading={loading}/>
                </section>
                {
                    visible ? <UserAddPackage user={user} successed = {() => {this.setState({visible : false},()=>{this.getDatas()})}} isClosed={ () => this.setState({visible : false})}></UserAddPackage> : null
                }
                {/* PDF */}
                {/* style={{ "position": "absolute", "zIndex": "-999", "opacity": "0" }} */}
                <div>
                    {
                        pdfDowload ? <PDF user={user} pdfDatas={pdfDatas} closedPDF={() => { this.setState({ pdfDowload: false }) }}></PDF> : null
                    }
                </div>
            </main>
        )
    }
}