import client from '../apiClient';

export async function getUsers(page, orderBy, direction, limit, query, userIds) {
    var params = {
            page,
            orderBy,
            direction,
            limit,
            userIds,
        };
    for (var key in query) {
        params[key] = query[key];
    }
    let response = await client.get('/cmsUser', {
        params: params
    });
    return response.data;
}

export async function getUser(userId) {
    let response = await client.get(`/cmsUser/${userId}`);
    return response.data;
}

export async function createUser(body) {
    let response = await client.post('/cmsUser', body);
    return response.data;
}

export async function updateUser(userId, body) {
    let response = await client.patch(`/cmsUser/${userId}`, body);
    console.log("response",response)
    return response.data;
}


export async function uploadIcon(userId, icon) {
    const formData = new FormData();
    formData.append("icon", icon);
    let response = await client.post(`/cmsUser/${userId}/cmsIcon`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function assignBodyCondition(userId, bodyConditions) {
    let response = await client.post(`/cmsUser/${userId}/assignBodyCondition`, {
        bodyConditions,
    });
    return response.data;
}


export async function changeUserFamily(userId, newFamilyId, amount) {
    let response = await client.patch(`/cmsUser/${userId}/changeUserFamily`, {
        newFamilyId,
        amount,
    });
    return response.data;
}

export async function getVipList(){
    let response = await client.get('/vip/vipLevelist')
    return response.data
}

export async function getAllList(){
    let response = await client.get('/cmsUser/list')
    return response.data
}