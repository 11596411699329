import client from '../apiClient';


export async function create(body) {
    let response = await client.post('/packages', body);
    return response;
}

export async function list(query) {
    let response = await client.get('/packages', {
        params:query
    });
    return response;
}


export async function packagesAll() {
    let response = await client.get('/packages/all');
    return response.data;
}

export async function buyPackages(data) {
    let response = await client.post('/packagesTransaction',data);
    return response;
}

export async function useListPackages(data) {
    let response = await client.get('/packagesTransaction',{params:data});
    return response;
}

export async function refundPackage(data) {
    let response = await client.get('/packagesTransaction/refund',{params:data})
}